import React, { useState, useEffect, useRef } from "react";
import {
  Link, useHistory
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import {
  InformationCircleIcon
} from '@heroicons/react/outline';

import FeaturePaywallWrapper from "../account/featurePaywallWrapper"
import { readableNumber } from "../../utils/text";


const SubredditStats = ({ subreddit, currentUser, hasStarter }) => {
  const calcs = subreddit.calculated;

  if (!calcs || Object.keys(calcs).length === 0) {
    return <div className="opacity-50 py-8">Subreddit stats not calculated yet. This could take up to an hour for new subreddits.</div>
  }

  return (
    <FeaturePaywallWrapper featureKey={"subreddit-stats"} className={'max-w-full py-0'} currentUser={currentUser}>
      <div className="mb-8 text-xs sm:text-sm">
        <div>
          <table className="w-full sm:w-auto">
            <thead>
              <tr>
                <th className="w-24">
                  <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide flex items-center">
                    Growth
                  </h3>
                </th>
                <th className="px-1 sm:pl-5 py-1 text-right">New Members</th>
                <th className="px-1 sm:pl-5 py-1 text-right">Percent Growth</th>
                <th className="px-1 sm:pl-5 py-1 text-right flex items-center">Percentile</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={`p-1`}>Daily</th>
                <td className={`p-1 text-right ${calcs.per_day_members ? '' : 'text-gray-400'}`}>{calcs.per_day_members !== undefined ? readableNumber(calcs.per_day_members) : '--'}</td>
                <td className={`p-1 text-right ${calcs.growth_members_daily ? '' : 'text-gray-400'}`}>{calcs.growth_members_daily !== undefined ? calcs.growth_members_daily.toFixed(2) + '%' : '--'}</td>
                <td className={`p-1 text-right ${calcs.percentile_growth_members_daily ? '' : 'text-gray-400'}`}>{calcs.percentile_growth_members_daily !== undefined ? calcs.percentile_growth_members_daily + '%' : '--'}</td>
              </tr>
              <tr>
                <th className={`p-1`}>Weekly</th>
                <td className={`p-1 text-right ${calcs.per_week_members ? '' : 'text-gray-400'}`}>{calcs.per_week_members !== undefined ? readableNumber(calcs.per_week_members) : '--'}</td>
                <td className={`p-1 text-right ${calcs.growth_members_weekly ? '' : 'text-gray-400'}`}>{calcs.growth_members_weekly !== undefined ? calcs.growth_members_weekly.toFixed(2) + '%' : '--'}</td>
                <td className={`p-1 text-right ${calcs.percentile_growth_members_weekly ? '' : 'text-gray-400'}`}>{calcs.percentile_growth_members_weekly !== undefined ? calcs.percentile_growth_members_weekly + '%' : '--'}</td>
              </tr>
              <tr>
                <th className={`p-1`}>Monthly</th>
                <td className={`p-1 text-right ${calcs.per_month_members ? '' : 'text-gray-400'}`}>{calcs.per_month_members !== undefined ? readableNumber(calcs.per_month_members) : '--'}</td>
                <td className={`p-1 text-right ${calcs.growth_members_monthly ? '' : 'text-gray-400'}`}>{calcs.growth_members_monthly !== undefined ? calcs.growth_members_monthly.toFixed(2) + '%' : '--'}</td>
                <td className={`p-1 text-right ${calcs.percentile_growth_members_monthly ? '' : 'text-gray-400'}`}>{calcs.percentile_growth_members_monthly !== undefined ? calcs.percentile_growth_members_monthly + '%' : '--'}</td>
              </tr>
              <tr>
                <th className={`p-1`}>Yearly</th>
                <td className={`p-1 text-right ${calcs.per_year_members ? '' : 'text-gray-400'}`}>{calcs.per_year_members !== undefined ? readableNumber(calcs.per_year_members) : '--'}</td>
                <td className={`p-1 text-right ${calcs.growth_members_yearly ? '' : 'text-gray-400'}`}>{calcs.growth_members_yearly !== undefined ? calcs.growth_members_yearly.toFixed(2) + '%' : '--'}</td>
                <td className={`p-1 text-right ${calcs.percentile_growth_members_yearly ? '' : 'text-gray-400'}`}>{calcs.percentile_growth_members_yearly !== undefined ? calcs.percentile_growth_members_yearly + '%' : '--'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mb-8 text-xs sm:text-sm">
        <div>
          <table className="w-full sm:w-auto">
            <thead>
              <tr>
                <th className="w-24">
                  <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide flex items-center">
                  Post Reach
                  </h3>
                </th>
                <th className="px-1 sm:pl-5 py-1 text-right">Average</th>
                <th className="px-1 sm:pl-5 py-1 text-right">Max</th>
                <th className="px-1 sm:pl-5 py-1 text-right flex items-center">Percentile</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={`p-1`}>Upvotes</th>
                <td className={`p-1 text-right ${calcs.average_upvotes ? '' : 'text-gray-400'}`}>{calcs.average_upvotes !== undefined ? calcs.average_upvotes.toFixed(2) : '--'}</td>
                <td className={`p-1 text-right ${calcs.max_upvotes ? '' : 'text-gray-400'}`}>{calcs.max_upvotes !== undefined ? calcs.max_upvotes : '--'}</td>
                <td className={`p-1 text-right ${calcs.percentile_reach ? '' : 'text-gray-400'}`}>{calcs.percentile_reach !== undefined ? calcs.percentile_reach + '%' : '--'}</td>
              </tr>
              <tr>
                <th className={`p-1`}>Comments</th>
                <td className={`p-1 text-right ${calcs.average_comments ? '' : 'text-gray-400'}`}>{calcs.average_comments !== undefined ? calcs.average_comments.toFixed(2) : '--'}</td>
                <td className={`p-1 text-right ${calcs.max_comments ? '' : 'text-gray-400'}`}>{calcs.max_comments !== undefined ? calcs.max_comments : '--'}</td>
                <td className={`p-1 text-right ${calcs.percentile_average_comments ? '' : 'text-gray-400'}`}>{calcs.percentile_average_comments !== undefined ? calcs.percentile_average_comments + '%' : '--'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mb-2 text-xs sm:text-sm">
        <div>
          <table className="w-full sm:w-auto">
            <thead>
              <tr>
                <th className="w-24">
                  <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide flex items-center">
                    Activity
                  </h3>
                </th>
                <th className="px-1 sm:pl-5 py-1 text-right">Daily</th>
                <th className="px-1 sm:pl-5 py-1 text-right flex items-center">Percentile</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={`p-1`}>Posts</th>
                <td className={`p-1 text-right ${calcs.velocity ? '' : 'text-gray-400'}`}>{calcs.velocity !== undefined ? calcs.velocity : '--'}</td>
                <td className={`p-1 text-right ${calcs.percentile_velocity ? '' : 'text-gray-400'}`}>{calcs.percentile_velocity !== undefined ? calcs.percentile_velocity + '%' : '--'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </FeaturePaywallWrapper>
  );
}

export default SubredditStats;
