// reducers.js
import { combineReducers } from 'redux';

import types from './types';

const INITIAL_STATE = {
  fetchedUnreadCounts: false,
  fetchingTrackedKeywords: false,
  trackedKeywords: [],
  trackedKeywordUnreadCount: 0,

  fetchingSavedLists: false,
  savedLists: [],

  updating: false,
  updatingHashes: [],

  // fetching: false,
  // fetchedHashes: [],
  // unread: null,  // {SavedSearch.hash: int} // note: start as null as we know we have NEVER ran this (see sidebar.jsx)
  // savedLists: [], // It's a if SavedSearch.search_type == "MA"
  // fetchingSavedLists: false,
}

const savedSearchReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {

    case types.FETCH_SAVED_SEARCHES: {
      return {
        ...state,
        fetchingTrackedKeywords: true,
        fetchingSavedLists: true,
      }
    }

    case types.FETCH_SAVED_SEARCHES_SUCCESS: {
      const { data } = action;
      const newTrackedKeywords = data.results.filter(s => s.search_type !== 'MA');
      return {
        ...state,
        fetchedUnreadCounts: true, // just to make sure we fetched it the first time
        savedLists: data.results.filter(s => s.search_type === 'MA'),
        fetchingSavedLists: false,
        trackedKeywords: newTrackedKeywords,
        fetchingTrackedKeywords: false,
        trackedKeywordUnreadCount: newTrackedKeywords.map(s => s.count_unread).reduce((partialSum, a) => partialSum + a, 0),
      }
    }

    case types.FETCH_SAVED_SEARCHES_FAIL: {
      const { error } = action;
      return {
        ...state,
        fetchingTrackedKeywords: false,
        fetchingSavedLists: false,
      }
    }

    case types.UPDATE_SAVED_SEARCHES: {
      const { hashes } = action;
      return {
        ...state,
        updating: true,
        updatingHashes: hashes,
      }
    }

    case types.UPDATE_SAVED_SEARCHES_SUCCESS: {
      const { data } = action;

      const newTrackedKeywords = state.trackedKeywords.map(s => {
        if (data.results.filter(ns => ns.hash === s.hash)[0]){
          return data.results.filter(ns => ns.hash === s.hash)[0]
        }
        return s;
      })
      return {
        ...state,
        updating: false,
        updatingHashes: [],
        savedLists: state.savedLists.map(s => {
          if (data.results.filter(ns => ns.hash === s.hash)[0]){
            return data.results.filter(ns => ns.hash === s.hash)[0]
          }
          return s;
        }),
        trackedKeywords: newTrackedKeywords,
        trackedKeywordUnreadCount: newTrackedKeywords.map(s => s.count_unread).reduce((partialSum, a) => partialSum + a, 0),
      }
    }

    case types.UPDATE_SAVED_SEARCHES_FAIL: {
      const { error } = action;
      return {
        ...state,
        updating: false,
        updatingHashes: [],
      }
    }

    case types.CLEAR_UNREAD_COUNT: {
      const { hash } = action;
      const newTrackedKeywords = state.trackedKeywords.map(s => {
        if (hash === s.hash){
          return Object.assign(s, {'count_unread': 0})
        }
        return s;
      })
      return {
        ...state,
        trackedKeywords: newTrackedKeywords,
        trackedKeywordUnreadCount: newTrackedKeywords.map(s => s.count_unread).reduce((partialSum, a) => partialSum + a, 0),

      }
    }

    default: return state;
  }
}

export default savedSearchReducer;