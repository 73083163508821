import React, { useState, useRef, useEffect, Fragment } from "react";
import { connect } from 'react-redux';
import { PlusIcon } from '@heroicons/react/solid'
import { useHistory } from "react-router-dom";
import { Transition } from '@headlessui/react'
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import { collectionsService } from '../../services/collections';
import { audiencesOperations } from "../../redux/audiences"

import ErrorBar from "../common/errorBar";
import LoaderFancy from "../common/loaderfancy";
import PublicAudienceCard from "./publicaudiencecard";


const PublicAudiences = ({
  showIcons,
  noPublicScroll, // don't show scrollbar on the public collections
  fetchAudiences,
  userAudiences,
  gridClass, cardClass,
}) => {
  // state
  const history = useHistory();
  const [publicCollections, setPublicCollections] = useState([]);
  const [fetchingPublicCollections, setFetchingPublicCollections] = useState(true);
  const [showCollections, setShowCollections] = useState(false);
  const [error, setError] = useState(null);
  const [creating, setCreating] = useState(null);


  // effects
  useEffect(() => {
    // get the public collections
    getPublicCollections()
  }, []);

  // actions
  const getPublicCollections = () => {
    collectionsService.getPublicCollections((results) => {
      setPublicCollections(results.data.sort((a,b) => (a.count_items < b.count_items) ? 1 : ((b.count_items < a.count_items) ? -1 : 0)))
      setFetchingPublicCollections(false);
      setShowCollections(true)
    }, (response, error) => {
      setFetchingPublicCollections(false)
      setError(error)
    })
  } 


  const selectExistingAudience = (audience) => {
    // copies over a public collection to a user's personal
    setCreating(true)
    collectionsService.createCollection(audience.name, (results) => {
      // created collection, now populate with items
      const createdAudience = results.data;
      const items = audience.items.map((s) => {return {'external_type': s.external_type, 'external_id': s.external_id}});
      toast.success("Successfully copied this curated audience to your saved audience list.")
      collectionsService.addCollectionItems(createdAudience.hash, items, (results) => {
        // done, go to the new audience!
        setCreating(false);
        history.push(`/audience/${createdAudience.hash}/`);
        fetchAudiences() // refetch whole list
      }, (response, error) => {
        setCreating(false)
        setError(error)
      })
    }, (response, error) => {
      setCreating(false)
      setError(error)
    })
  }

  return (
    <React.Fragment>
      {fetchingPublicCollections || !publicCollections ? (
        <div className="h-96 relative"><LoaderFancy /></div>
      ) : publicCollections.length === 0 ? (
        <div className="h-96 relative"></div>
      ) : (
        <div className={`${noPublicScroll ? '' : 'h-96 overflow-auto no-scrollbar'}`}>
          <Transition show={showCollections} as={Fragment}
            enter="transition transform ease-in duration-100"
            enterFrom="opacity-50 translate-y-1"
            enterTo="opacity-100 translate-y-0"
          >
            <div className={`${gridClass} grid grid-cols-1 gap-4 sm:grid-cols-3`} disabled={creating}>
              {(publicCollections || []).map((collection) => (
                <PublicAudienceCard key={collection.hash} audience={collection} showIcons={showIcons} className={cardClass}
                  userHasSaved={userAudiences && userAudiences.map(a => a.name).includes(collection.name)}
                  onClick={() => {
                    // if user has audience, navigate there. otherwise add it
                    if (userAudiences && userAudiences.find(a => a.name === collection.name)) {
                      history.push(`/audience/${userAudiences.find(a => a.name === collection.name).hash}/`)
                    } else {
                      selectExistingAudience(collection)
                    }
                  }}
                />
              ))}
            </div>
          </Transition>
        </div>
      )}

      {error ? (
        <ErrorBar error={error} setError={setError} />
      ) : ''}  
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAudiences = (callback, onError) => {
    dispatch(audiencesOperations.fetchAudiences(callback, onError))
  };
  return {
    fetchAudiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicAudiences);

