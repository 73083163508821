import { postRequest, getRequest } from '../utils/axios';
import axios from 'axios';

import { toQueryString } from '../utils/urls.js'


export const discoverService = {
    performSearch,
};

function performSearch(keywords, onSuccess, onError) {
  return getRequest(
    `/api/v1/discover/${toQueryString({'keyword': keywords.map(k => encodeURIComponent(k))})}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

