import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link,
  useHistory,
  useLocation
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { InformationCircleIcon } from '@heroicons/react/outline'
import { ModalRoute, ModalContainer } from 'react-router-modal';

import Layout from "../../components/layout";
import ErrorBoundary from "../../components/common/errorboundary";
import ProgressRing from "../../components/common/progressring";
import Loader from "../../components/common/loader";

import SubscriptionSelectModal from "./subscriptionSelectModal";
import SubscriptionDayPassModal from "./subscriptionDayPassModal";
import SubscriptionCancelModal from "./subscriptionCancelModal";
import SubscriptionLimitModal from "./subscriptionLimitModal";
import Limits from "./limits";

import { trialService } from '../../services/trial';
import { profileService } from '../../services/profile';
import { paymentService } from '../../services/payment';
import { authService } from "../../services/auth";
import { trialOperations } from "../../redux/trial"
import { userOperations } from "../../redux/user"

import { trackEvent, identifyUser } from '../../utils/tracking';
import { capitalizeFirst } from "../../utils/text";
import { timeStampToEasyTime } from "../../utils/time"
import { localStorageAvailable } from '../../utils/localStorage';
import { hasPro, hasStarter } from '../../utils/account';


// renders a user's subscription (handles current/expired trial, as well as monthly/annual/LTD/day subscription)
const SubscriptionDetails = ({match, currentUser, fetchTrial, hideTitle, showButtons, fetchCurrentUser}) => {
  const location = useLocation()
  const history = useHistory()

  const [loadingTrial, setLoadingTrial] = useState(false); 
  const [trial, setTrial] = useState(false); 
  const [error, setError] = useState(null); 
  const [success, setSuccess] = useState(null); 
  const [checkingOut, setCheckingOut] = useState(false); 
  const [refetchLimits, setRefetchLimits] = useState(null); 
  const subscription = currentUser.subscription; 
  // const isEffectiveProSubscriber = hasPro(currentUser);

  // // effects
  useEffect(() => {
    if (currentUser && currentUser.trial && currentUser.trial.active ){
      // fetches info for current trial
      getTrialDetails();
    }
  }, []);

  // renders a success/error message after stripe checkout
  useEffect(() => {
    // Check to see if this is a redirect back from Stripe Checkout
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has('success')) {
      // refetch user now that we have a subscription
      setCheckingOut(true);
      setTimeout(() => {
        setSuccess("Order placed! You will receive an email confirmation with your invoice.");
        queryParams.delete('success');

        fetchCurrentUser((response) => {
          setCheckingOut(false);

          trackEvent("Subscribed", {
            "plan": response.subscription.key,
            "billing_cycle": response.subscription.billing_cycle,
            "last_feature_paywall": localStorageAvailable() && localStorage.getItem('last_feature_paywall'),
          });
          identifyUser(response); // identify in third-party tools
          setRefetchLimits(new Date())
          document.getElementById('main').scrollTo(0,0);
        });
      }, 1000); // delay to make sure we have received the webhook
    }
    if (queryParams.has('canceled')) {
      setSuccess("Order canceled -- continue to shop around and checkout when you're ready.");
      queryParams.delete('canceled')
      document.getElementById('main').scrollTo(0,0);
    }

    history.replace({
      search: queryParams.toString(),
    })
  }, [history, location.search]);

  const getTrialDetails = () => {
    // fetch the details on the user's trial (either credit or time)
    setLoadingTrial(true)
    fetchTrial((data) => {
      setLoadingTrial(false)
      setTrial(data)
    })
  }

  const pauseSubscription = () => {
    alert("To pause your subscription, please send an email to fed@gummysearch.com with the timeframe (1, 2, or 3 months). I'll send you a confirmation message and your billing will be paused until the re-activation date. Send me an email if you want to re-activate early.")
  }

  const onClickCancel = () => {
    // if churnkey is available, launch it
    if (currentUser && currentUser.subscription && typeof(window.churnkey) !== "undefined" && currentUser.subscription.hmac_hash){
      window.churnkey.init('show', {
        subscriptionId: currentUser.subscription.stripe_subscription_id, // recommended
        customerId: currentUser.subscription.stripe_customer_id, // required
        authHash: currentUser.subscription.hmac_hash, // required
        appId: 'wq8d877hj', // required
        mode: currentUser.env.name === 'production' ? 'live' : 'test', // set to 'test' to hit test billing provider environment
        provider: 'stripe', // set to 'stripe', 'chargebee', 'braintree', 'paddle'
        record: false, // set to false to skip session playback recording
        onCancel(customer) {
          window.location.href = "/account/subscription/" // reload to show plan is gone
        },
        handleSupportRequest() {
          alert("Please email fed@gummysearch.com with your request.")
        },
      })
      return;
    }

    // standard page for cancel flow
    history.push("/account/subscription/cancel/")
  }

  // const increaseLimits = () => {
  //   alert("Buying limit packs is coming soon! In the meantime, to increase limits, please send an email to fed@gummysearch.com with what you need.")
  // }


  const updateCreditCard = () => {
    paymentService.getPaymentPortalUrl({},
    (response) => {
      if (response.data.redirect_url){
        window.location.href = response.data.redirect_url
      } else {
        alert("Missing stripe redirect url")
      }
    }, (error, response) => {
      // setCheckingOut(null);
      setError(response);
    });
  }

  if (!currentUser) return '';
  const loading = currentUser && currentUser.trial && currentUser.trial.active && loadingTrial;

  return (
    <div>
      {!hideTitle ? (
        <div className="flex items-center mb-2">
          <h2 className="text-lg font-medium">Your Account</h2>

          <div className="ml-auto">
            <Link to="/account/subscription/"
              className="text-sm opacity-75 hover:opacity-100"
            >
              Manage
            </Link>
          </div>
        </div>
      ) : ''}
      
      <div className="bg-gray-800 p-4 sm:p-6 rounded-md">
        {error ? (
          <div className="mx-auto p-2 px-3 text-semibold bg-red-500 rounded-md mb-4">{error}</div>
        ) : ''}

        {success ? (
          <div className="mx-auto p-2 px-3 text-semibold bg-green-500 rounded-md mb-4">{success}</div>
        ) : ''}

        <div>
          {loading ? (
            <Loader />
          ) : subscription ? (
            <div className="">
              <label className="flex items-center text-sm font-medium text-gray-500">
                <div>Type</div>
              </label>
              <div className="flex items-center font-semibold">
                {capitalizeFirst(subscription.key)}
              </div>

              <label className="mt-2 flex items-center text-sm font-medium text-gray-500">
                <div>Cycle</div>
              </label>
              <div className="flex items-center font-semibold">
                {capitalizeFirst(subscription.billing_cycle)}
              </div>

              <label className="mt-2 flex items-center text-sm font-medium text-gray-500">
                <div>Status</div>
              </label>
              <div className="flex items-center font-semibold">
                {subscription.trial_expires_at ? (
                  <span className={`font-semibold`}>Trial until {timeStampToEasyTime(subscription.trial_expires_at)}</span>
                ) : (
                  <span className={`text-green-400 font-semibold`}>Active</span>
                )}
              </div>

              <div className="flex mt-2 space-x-8">

                <div>
                  <label className="flex items-center text-sm font-medium text-gray-500">
                    <div>Limits</div>
                  </label>
                  <div>
                    <Limits currentUser={currentUser} refetchLimits={refetchLimits} />
                  </div>
                </div>

                {false && currentUser && currentUser.subscription && hasPro(currentUser) ? (
                  <div>
                    <label className="flex items-center text-sm font-medium text-gray-500">
                      <div>AI Minute Usage</div>
                    </label>
                    <div>
                      
                    </div>
                  </div>
                ) : ''}
                
              </div>

              {showButtons && !['day'].includes(subscription.billing_cycle) ? (
                <React.Fragment>
                  <hr className="my-2 border-gray-700" />
                  <div className="flex space-x-2 sm:space-x-4 mt-4 flex-wrap text-sm sm:text-base">
                    <Link to="/account/subscription/select/"
                      className="px-4 py-2 text-center border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                      // disabled={['day'].includes(subscription.billing_cycle)}
                    >
                      Change Plan
                    </Link>

                    {['monthly', 'yearly'].includes(subscription.billing_cycle) ? (
                      <React.Fragment>
                        <div className="px-4 py-2 text-center cursor-pointer border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                          onClick={updateCreditCard}
                        >
                          Billing Portal
                        </div>

                        {/*<div className="px-4 py-2 text-center cursor-pointer border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                          onClick={pauseSubscription}
                        >
                          Pause Plan
                        </div>*/}
                      </React.Fragment>
                    ) : ''}

                    {!['day'].includes(subscription.billing_cycle) ? (
                      <React.Fragment>
                        <div //to="/account/subscription/cancel/"
                          onClick={onClickCancel}
                          className="px-4 py-2 text-center cursor-pointer border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                        >
                          Cancel Plan
                        </div>
                        <Link to="/account/subscription/limits/"
                          className="px-4 py-2 text-center cursor-pointer border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                          // onClick={increaseLimits}
                        >
                          Increase Limits
                        </Link>
                      </React.Fragment>
                    ) : ''}

                    <a href="mailto:fed@gummysearch.com?subject=Subscription Support" className="px-4 py-2 text-center cursor-pointer border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                      // onClick={pauseSubscription}
                    >
                      Support
                    </a>

                    <div className="px-4 py-2 text-center cursor-pointer border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                      onClick={authService.logout}
                    >
                      Log Out
                    </div>

                  </div>
                </React.Fragment>
              ) : ''}
            </div>
          ) : trial && trial.active ? (
            <div className="">
              <label className="flex items-center text-sm font-medium text-gray-500">
                <div>Type</div>
              </label>
              <div className="flex items-center font-semibold">
                Pro (Free Trial)
              </div>

              <label className="mt-2 flex items-center text-sm font-medium text-gray-500">
                <div>Status</div>
              </label>
              <div className="flex items-center font-semibold">
                <span className={`${trial.active ? 'text-green-400' : 'text-red-500'} font-semibold`}>{trial.active ? 'Active' : 'Inactive'}</span>
              </div>

              <label className="mt-2 flex items-center text-sm font-medium text-gray-500">
                <div>Expires</div>
              </label>
              <div className="flex items-center font-semibold">
                {trial.expires}
              </div>

              <label className="mt-2 flex items-center text-sm font-medium text-gray-500">
                <div>About</div>
              </label>
              <div className="">
                You are on a time-based free trial of GummySearch Pro. This is a gift added to your account from Fed, enjoy! To continue using GummySearch after your trial expires, check out one of the <Link to="/account/subscription/select/" className="underline">subscription plans</Link> or <Link to="/account/subscription/day-pass/" className="underline">buy a day pass</Link>.
              </div>

              <label className="mt-2 flex items-center text-sm font-medium text-gray-500">
                <div>Limits</div>
              </label>
              <div>
                <Limits currentUser={currentUser} refetchLimits={refetchLimits} />
              </div>
              {true || showButtons ? (
                <React.Fragment>
                  <hr className="my-2 border-gray-700" />
                  <div className="flex space-x-4 mt-4">
                    <Link to="/account/subscription/select/"
                      className="px-4 py-2 text-center border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                    >
                      Browse Paid Plans
                    </Link>

                    <a href="mailto:fed@gummysearch.com?subject=Subscription Support" className="px-4 py-2 text-center cursor-pointer border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                      // onClick={pauseSubscription}
                    >
                      Contact Support
                    </a>
                  </div>
                </React.Fragment>
              ) : ''}
            </div>
          ) : (
            <div className="">
              <label className="flex items-center text-sm font-medium text-gray-500">
                <div>Type</div>
              </label>
              <div className="flex items-center font-semibold">
                Free Tier
              </div>

              <label className="mt-2 flex items-center text-sm font-medium text-gray-500">
                <div>About</div>
              </label>
              <div className="">
                The GummySearch free tier gives you unlimited access to the community discovery features. You also get 50 conversation searches.
                For <Link to="/account/subscription/select/" className="underline">premium features</Link>, you need a subscription or an active day pass.
              </div>

              <label className="mt-2 flex items-center text-sm font-medium text-gray-500">
                <div>Limits</div>
              </label>
              <div>
                <Limits currentUser={currentUser} refetchLimits={refetchLimits} />
              </div>
              {true || showButtons ? (
                <React.Fragment>
                  <hr className="my-2 border-gray-700" />
                  <div className="flex space-x-4 mt-4">
                    <Link to="/account/subscription/select/"
                      className="px-4 py-2 text-center border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                    >
                      Browse Paid Plans
                    </Link>

                    <a href="mailto:fed@gummysearch.com?subject=Subscription Support" className="px-4 py-2 text-center cursor-pointer border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                      // onClick={pauseSubscription}
                    >
                      Contact Support
                    </a>
                  </div>
                </React.Fragment>
              ) : ''}
            </div>
          )}
        </div>
      </div>

      <ModalRoute
        path={`/account/subscription/select/`}
        parentPath={`/account/subscription/`}
        component={SubscriptionSelectModal}
        props={{
          'currentUser': currentUser,
          'setSuccess': setSuccess,
          'fetchCurrentUser': fetchCurrentUser,
          'setRefetchLimits': setRefetchLimits,
        }}
      />
      <ModalRoute
        path={`/account/subscription/cancel/`}
        parentPath={`/account/subscription/`}
        component={SubscriptionCancelModal}
        props={{
          'currentUser': currentUser,
          'setSuccess': setSuccess,
          'fetchCurrentUser': fetchCurrentUser,
          'setRefetchLimits': setRefetchLimits,
        }}
      />
      <ModalRoute
        path={`/account/subscription/limits/`}
        parentPath={`/account/subscription/`}
        component={SubscriptionLimitModal}
        props={{
          'currentUser': currentUser,
          'setSuccess': setSuccess,
          'fetchCurrentUser': fetchCurrentUser,
          'setRefetchLimits': setRefetchLimits,
        }}
      />
      <ModalRoute
        path={`/account/subscription/day-pass/`}
        parentPath={`/account/subscription/`}
        component={SubscriptionDayPassModal}
        props={{
          'currentUser': currentUser,
          'setSuccess': setSuccess,
          'setRefetchLimits': setRefetchLimits,
        }}
      />
      <ModalContainer />
    </div>
  )
}

const mapStateToProps = state => {
  // const {
  //   used, total, fetching,
  // } = state.trial;

  return {
    // 'trialTotal': total,
    // 'trialUsed': used,
    // 'trialFetching': fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchTrial = (callback) => {
    dispatch(trialOperations.fetchTrial(callback))
  };
  const fetchCurrentUser = (callback) => {
    dispatch(userOperations.fetchCurrentUser(callback))
  };
  return {
    fetchCurrentUser,
    fetchTrial,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetails);
