import React, { useEffect } from "react"
import {
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import FormFooter from "./footer";
import StoryContent from "./storyContent";

import { objectiveOptions, learningStyleOptions } from './constants';


const Story = ({history, currentUser, ...subrouteProps}) => {

  const { nextPage, isLastPage } = subrouteProps;

  // effects
  useEffect(() => {
    // skip this step if user selected "other"
    if (currentUser && ["OT","MR", "BR"].includes(currentUser.profile.objective)){
      onSubmit();
    }
  }, []);

  const onSubmit = () => {
    history.push(isLastPage ? '/audiences/' : `/welcome/${nextPage}/`);
  }

  // various options from the previous screen
  const objectiveKey = currentUser.profile.objective;
  const objective = objectiveOptions.filter(o => o.key === objectiveKey)[0];
  const learningStyleKey = currentUser.profile.learning_style;
  const learningStyle = learningStyleOptions.filter(l => l.key === learningStyleKey)[0];

  return (
    <div className="max-w-5xl w-full mx-auto min-h-full flex flex-col">
      <Helmet><title>Welcome | Story</title></Helmet>

      <div className="inline-block bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle py-6 px-4 sm:p-6">
        <div className="text-center">
          <div className="relative mb-4">
            <hr className="opacity-25" />
            <div className="absolute -top-3 w-full text-center">
              <span className="p-1 text-sm rounded-sm bg-gray-800 text-gray-500 uppercase font-semibold">
                Steps to success on GummySearch
              </span>
            </div>
          </div>

          {objective ? (
            <React.Fragment>
              <p className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
                A little story just for you
              </p>

              {objectiveKey !== "OT" ? (
                <p className="mt-4 max-w-2xl text-gray-100 font-semibold mx-auto opacity-50">
                  Objective: {objective.label}
                </p>
              ) : ''}
              
              {/*{learningStyle && learningStyle.key === "ST" ? (
                <p className="mt-4 max-w-2xl text-gray-100 font-semibold mx-auto">
                  Here's a little story about how to {objective.label.toLowerCase()} using the GummySearch platform.
                </p>
              ) : learningStyle ? (
                <p className="mt-4 max-w-2xl text-gray-100 font-semibold mx-auto">
                  You will be able to find some relevant {learningStyle.label.toLowerCase()} about how to {objective.label.toLowerCase()} on your GummySearch dashboard. For now, here's a little story to inspire your journey.
                </p>
              ) : (
                <p className="mt-4 max-w-2xl text-gray-100 font-semibold mx-auto">
                  A little story written just for you
                </p>
              )}*/}
              

              <div className="text-left mt-4">
                <StoryContent objectiveKey={objectiveKey} objective={objective}/>
              </div>
            </React.Fragment>
          ) : 'Unknown objective. please go visit another page...'}
          
          
        </div>
      </div>

      <FormFooter submit={onSubmit} {...subrouteProps} />
    </div>
  );
}

export default Story;
