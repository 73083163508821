import { postRequest, getRequest, patchRequest, deleteRequest } from '../utils/axios';
import axios from 'axios';


export const digestsService = {
    getDigestSettings,
    createDigestSettings,
    updateDigestSettings,

    getNotificationChannels,
    createNotificationChannel,
    updateNotificationChannel,
    deleteNotificationChannel,
};

function getDigestSettings(onSuccess, onError) {
  return getRequest(
    `/api/v1/digest-settings/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}


// just creates with default settings
function createDigestSettings(onSuccess, onError) {
  return postRequest(
    `/api/v1/digest-settings/`,
    {},
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function updateDigestSettings(data, onSuccess, onError) {
  return patchRequest(
    `/api/v1/digest-settings/`, data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}



function getNotificationChannels(onSuccess, onError) {
  return getRequest(
    `/api/v1/notification-channels/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}


function createNotificationChannel(data, onSuccess, onError) {
  return postRequest(
    `/api/v1/notification-channels/`, data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function updateNotificationChannel(id, data, onSuccess, onError) {
  return patchRequest(
    `/api/v1/notification-channels/${id}/`, data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function deleteNotificationChannel(id, onSuccess, onError) {
  return deleteRequest(
    `/api/v1/notification-channels/${id}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

