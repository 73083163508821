import React, { useState, useEffect } from "react";

import { CSVLink } from "react-csv";

import Filters from "./filters";
import SaveButton from "../conversations/savebutton";
import ConversationListPopover from '../conversations/conversationlistpopover'

import { withRouter } from "react-router";
import { getParamsFromUrl, toQueryString } from '../../utils/urls';
import { getConversationCsvDict } from '../../utils/reddit';
import { slugify } from '../../utils/text';

const Header = ({ applyNewFilters,
  filters,
  results,
  savedSearch,
  fetching,
  currentUser,
  location,
  history,
  selectedIds,
}) => {
  const [queryModalOpen, setQueryModalOpen] = useState(false);
  const [downloadableResults, setDownloadableResults] = useState([]);

  // make download just for pro?
  const isProSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "pro");

  useEffect(() => {
    // gets the comments + submissions, and puts them into a format to be downloaded.
    if (results && selectedIds && selectedIds.length){
      setDownloadableResults(getConversationCsvDict(results.filter(r => selectedIds.includes(r.id))));
    } else {
      setDownloadableResults(getConversationCsvDict(results || []));
    }
  }, [
    results, selectedIds,
  ]); // Compile CSV when we got resukts

  return (
    <header className="flex-shrink-0 relative flex items-center">

      <div className="min-w-0 flex-1 flex items-center">
        <div className="min-w-0 flex-1">
          <div className="relative text-gray-400 focus-within:text-white">
            <label htmlFor="search" className="sr-only">Search</label>
            <input id="search" type="text" placeholder="Search Keyword" autoComplete="off" spellCheck="off" //autoFocus="off"
              className="block bg-gray-800 w-full border-transparent p-4 pl-12 placeholder-gray-500 rounded-md focus:border-transparent sm:text-sm focus:ring-0"
              onKeyDown={(event) => {
                if(event.key === 'Enter') {
                  const value = event.target.value;
                  applyNewFilters({'keyword': value});   
                }
              }}
            />
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>
        <div className="mr-2 flex-shrink-0 flex items-center ml-2">
          <div className="text-xs font-medium rounded-md relative py-1 px-3 text-gray-300 hover:bg-gray-700 cursor-pointer"
            onClick={() => setQueryModalOpen(true)}
          >
            <svg className="h-6 w-6 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
            </svg>
            <div>Query</div>
          </div>

          {currentUser && currentUser.features.tracked_keywords ? (
            <SaveButton fetching={fetching} params={filters} existingSavedSearch={!fetching && savedSearch} disabled={!filters || !filters.keyword} />
          ) : ''}

          {currentUser ? (
            <CSVLink
              data={downloadableResults}
              filename={`gummysearch-download-${filters.keyword ? slugify(filters.keyword) : 'all'}.csv`}
              // className="btn btn-primary"
              target="_blank"
              // onClick={close}
              disabled={!results.length || !currentUser.features.download_csv}
              className="text-xs font-medium rounded-md relative py-1 px-3 text-gray-300 hover:bg-gray-700 cursor-pointer"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
              </svg>
              <div>CSV</div>
              {selectedIds && selectedIds.length ? (
                <span className="absolute p-0.5 px-2 text-snug text-xs rounded-full -top-1 -right-1 bg-gray-700">
                 {selectedIds.length}
                </span>
              ) : ''}
            </CSVLink>
          ) : ''}
          

          {/*<ConversationListPopover onSelect={(s) => console.log("selected", s)} disabled={!results.length}>
            <div className="text-xs font-medium rounded-lg relative py-1 px-3 ml-2 text-gray-300 hover:bg-gray-700 cursor-pointer">
              <svg className="h-6 w-6 mx-auto"  xmlns="http://www.w3.org/2000/svg" fill={'rgba(8, 145, 178,0.15)'} viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
              </svg>
              <div>Save</div>

              {selectedIds && selectedIds.length ? (
                <span className="absolute p-0.5 px-2 text-snug text-xs rounded-full -top-1 right-0 bg-gray-700">
                 {selectedIds.length}
                </span>
              ) : ''}
            </div>
          </ConversationListPopover>*/}

        </div>
      </div>
      {/*<div className="flex-1 hidden md:block"></div>*/}

      {queryModalOpen ? (
        <div className="fixed z-30 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen p-2 text-center sm:block">
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity cursor-pointer" aria-hidden="true"
              onClick={() => setQueryModalOpen(false)}
            ></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-gray-800 text-white rounded-lg p-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
              <Filters
                filters={filters}
                apply={applyNewFilters}
                close={() => setQueryModalOpen(false)}
                clearFilters={() => console.error("not implemented")}
                currentUser={currentUser}
              />
            </div>
          </div>
        </div>
      ) : ''}
    </header>
  )
};

export default withRouter(Header);

