import { postRequest, getRequest } from '../utils/axios';
import axios from 'axios';


export const statusService = {
    fetchStatus,
};

function fetchStatus(onSuccess, onError) {
  return getRequest(
    `/api/v1/status/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}
