import React, { useState, useEffect } from "react";
import {
  Link,
} from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";
import BriefcaseIcon from '@heroicons/react/solid/BriefcaseIcon';

import ErrorBar from "../common/errorBar";

import { paymentService } from '../../services/payment';


const SubscriptionDayPassModal = ({ closeModal, history, currentUser }) => {
  const [checkingOut, setCheckingOut] = useState(false); 
  const [error, setError] = useState(null);

  const selectDayPass = () => {
    // go to stripe checkout flow
    setError(null);
    setCheckingOut(true);
    const affiliateReferrer = typeof(window.Rewardful) !== "undefined" ? window.Rewardful.referral : '';
    paymentService.initiateCheckout(
      {'product': 'pro', 'billing_cycle': 'day', 'affiliate_referrer': affiliateReferrer},
    (response) => {
      if (response.data.redirect_url){
        window.location.href = response.data.redirect_url
      } else {
        alert("Missing stripe redirect url")
      }
    }, (error, response) => {
      setCheckingOut(null);
      setError(response);
    });
  }

  return (
    <Modal close={closeModal} widthClass={'max-w-5xl bg-gray-900 py-12'}>
      <Helmet><title>Account | Subscription | Day Pass</title></Helmet>
      
      <h1 className="text-3xl mb-6 text-center font-extrabold">Purchase Day Passes</h1>

      <div
        // key={plan.name}
        // onClick={() => setSelectedPlan(plan)}
        className={`md:m-auto max-w-3xl flex-1 max-w-3xl bg-gray-800 rounded-3xl p-4 border-2 border-cyan-500`}
      >
        
        <div className="flex">
          <div>
            <span className="flex items-center justify-center w-10 h-10 rounded-full bg-cyan-500">
              <BriefcaseIcon className="h-6 w-6" />
            </span>
          </div>
          <div className="w-auto ml-4 text-left">
            <div className="text-xl font-medium text-slate-headline flex items-center">
              <div className="flex items-center">
                GummySearch Pro Day Pass
              </div>
              <div className="ml-auto font-bold">
                {/*<span className="line-through opacity-50 mr-2">$10</span>*/}
                {/*<span>$10</span>*/}
              </div>
            </div>
            <p className="text-base mt-2 text-md text-gray-300">
              Is this a SaaS product, or an amusement park? Find out for just $10.
            </p>
            <p className="text-base mt-2 text-md text-gray-300">
              Get access to GummySearch Pro (+2000 AI minutes) for 24 hours.
              Best for those that want to try before you commit to a subscription, or do a one-off research session.
            </p>
            <p className="text-base mt-2 text-md text-gray-300">
              You can buy multiple day passes and activate them when you need them.
            </p>
          </div>
        </div>
        <div className="mt-4">
          {/* buy button */}
          <button className={`max-w-sm mx-auto flex items-center cursor-pointer font-bold justify-center w-full py-2 rounded-lg hover:opacity-90 border text-white bg-green-500 border-transparent`}
            onClick={() => selectDayPass()}
            disabled={checkingOut}
          >
            {checkingOut ? (
              `Loading...`
            ) : 'Buy Day Pass - $10'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SubscriptionDayPassModal;

