import React from "react";

import {
  Route,
  Redirect,
} from "react-router-dom";

import Authentication from './authentication.jsx';
import { authService } from '../../services/auth';


const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

// Route component that takes properties
const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      if (!rest.authRequired && !rest.passAuthProps){
        // simple, just render the route with the props passed in
        return renderMergedProps(component, routeProps, rest);
      } else {
        // wrap in Auth component to see current user, pass returned Auth props into the component
        const redirectUrl = `${routeProps.location.pathname}${routeProps.location.search || ''}` // location to redirect to if user is not logged in
        return (
          <Authentication>
            {
              (authProps) => (  // authenticated, accessToken, refreshToken, currentUser, refreshingToken
                <React.Fragment>
                  {rest.authRequired && !authProps.authenticated && !authProps.refreshingToken ? (
                    <Redirect to={`/login/?next=${redirectUrl}`} />
                  ) : rest.authRequired && !authProps.authenticated && authProps.refreshingToken ? (
                    <div className="text-white p-4">
                      Refreshing token...{/* Refreshing token... */}
                      <br/><span className="underline" onClick={authService.logout}>Log out</span>
                    </div>
                  ) : rest.authRequired && !authProps.currentUser ? (
                    <div className="text-white p-4">
                      Fetching user...{/* Fetching user... */}
                      <br/><span className="underline" onClick={authService.logout}>Log out</span>
                    </div>
                  ) : rest.activeAccountRequired && authProps.currentUser && !authProps.currentUser.subscription && !authProps.currentUser.trial.active && !redirectUrl.includes('/trial-over/') ? (
                    <Redirect to={`/trial-over/?from=${redirectUrl}`} />
                  ) : (
                    <React.Fragment>
                      { renderMergedProps(component, routeProps, Object.assign(rest, authProps)) }
                    </React.Fragment>
                  )}
                </React.Fragment>
              )
            }
          </Authentication>
        )
      }
    }}/>
  );
}

export default PropsRoute;