import React from "react"
// import { Link } from "gatsby"

// import Image from "../image"

const BlogPost = ({node, showImage, currentUser}) => {

  const imagesPrefix = '../../images/';
  const baseUrl = currentUser ? currentUser.env.landing_page_url : 'https://gummysearch.com';

  return (
    <div key={node.frontmatter.slug} className="flex group rounded-lg shadow-lg overflow-hidden">
      {showImage && node.frontmatter.imageUrl && !node.frontmatter.imageUrl.startsWith(imagesPrefix) ? (
        <a href={`${baseUrl}${node.frontmatter.slug}`} target="_blank" className="flex-shrink-0 w-48 bg-gray-700">
          {/* first one won't work yet. need to put into static folder */}
          {node.frontmatter.imageUrl && node.frontmatter.imageUrl.startsWith(imagesPrefix) ? (
            <img className="h-32 w-full h-full object-cover opacity-75" src={node.frontmatter.imageUrl.replace(imagesPrefix, baseUrl)} alt={node.frontmatter.title} />
          ) : node.frontmatter.imageUrl ? (
            <img className="h-32 w-full h-full object-cover opacity-75" src={node.frontmatter.imageUrl} alt={node.frontmatter.title} />
          ) : (
            <div className="h-32 bg-gray-600"></div>
          )}
        </a>
      ) : ''}
      <div className="flex-1 bg-gray-800 text-white p-4 flex flex-col justify-between">
        <div className="flex-1">
          <a href={`${baseUrl}${node.frontmatter.slug}`} target="_blank" className="block hover:underline">
            <p className="text-lg font-semibold text-gray-200 leading-6">{node.frontmatter.title}</p>
          </a>
          <div className="mt-2 flex space-x-1 text-sm text-gray-300">
            {/*<time dateTime={node.frontmatter.published}>{node.frontmatter.published}</time>
            <span className="px-1 opacity-75" aria-hidden="true">&middot;</span>*/}
            {node.frontmatter.style === "VI" ? (
              <span className="opacity-50 flex items-center">
                <svg className={`h-5 w-5 mr-1`} xmlns="http://www.w3.org/2000/svg" fill={"none"} viewBox="0 0 24 24" stroke={"currentColor"}>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                Video Demo
              </span>
            ) : node.frontmatter.style === "BP" ? (
              <span className="opacity-50 flex items-center">
                <svg className={`h-5 w-5 mr-1`} xmlns="http://www.w3.org/2000/svg" fill={"none"} viewBox="0 0 24 24" stroke={"currentColor"}>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                 </svg>
                {node.timeToRead} min read
              </span>
            ) : (
              <span className="opacity-50">{node.timeToRead} min read</span>
            )}
          </div>
        </div>
        <p className="mt-auto pt-4 text-base text-gray-400">{node.frontmatter.description}</p>
        {/*<div className="mt-2 flex items-center">
          <div className="">
            <div className="text-sm font-medium text-gray-900">
              <div className="text-white">
                {node.frontmatter.author}
              </div>
            </div>
          </div>
        </div>*/}
      </div>
    </div>
  );
}

export default BlogPost;
