import { postRequest, getRequest, deleteRequest } from '../utils/axios';
import { toQueryString } from '../utils/urls';
import axios from 'axios';


export const searchesService = {
    recentSearches,
    saveRedirect,
    saveView,
    hiddenCommunities,
    addHiddenCommunity,
    removeHiddenCommunity,

    recentAnalysisSearches,
};

// search_type is like "DI" or "RS,RC"
function recentSearches(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/searches/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function saveRedirect(redirect_type, platform_id, platform_name, onSuccess, onError) {
  return postRequest(
    `/api/v1/redirects/`,
    {
      'redirect_type': redirect_type,
      'platform_id': platform_id,
      'platform_name': platform_name,
    },
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function saveView(data, onSuccess, onError) {
  return postRequest(
    `/api/v1/reddit/conversations/record_view/`,
    data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function hiddenCommunities(onSuccess, onError) {
  return getRequest(
    `/api/v1/hidden-communities/`, (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function addHiddenCommunity(community_type, platform_id, platform_name, context, onSuccess, onError) {
  return postRequest(
    `/api/v1/hidden-communities/`,
    {
      'community_type': community_type,
      'platform_id': platform_id,
      'platform_name': platform_name,
      'context': context,
    },
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function removeHiddenCommunity(id, onSuccess, onError) {
  return deleteRequest(
    `/api/v1/hidden-communities/${id}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function recentAnalysisSearches(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/analysis/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

