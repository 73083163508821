import React, { useState, useEffect } from "react";
import {
  Link,
} from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";

import { Listbox } from '@headlessui/react'
import {
  CheckIcon, SelectorIcon
} from '@heroicons/react/outline';

import { paymentService } from "../../services/payment";

// import { collectionsService } from '../../services/collections';
import { classNames } from '../../utils/tailwind';
import { trackEvent } from '../../utils/tracking';

import ErrorBar from "../common/errorBar";

const SubscriptionCancelModal = ({ closeModal, history, currentUser, fetchCurrentUser, setRefetchLimits }) => {
    const [submitting, setSubmitting] = useState(false); 
    const [error, setError] = useState(null); 
    const [success, setSuccess] = useState(null); 
    const [clarification, setClarification] = useState(''); 
    const [anythingElse, setAnythingElse] = useState(''); 

    const allReasons = [
      {'key': 'EX', 'label': "I wasn't intending to purchase, just browsing", 'clarification': "That's OK! What did you think? What was your favorite part?"},
      // {'key': 'UN', 'label': "I don't understand how to use it", 'clarification': "I'm sorry. What do you think could make it less confusing?"},
      {'key': 'MA', 'label': "My target audience isn't on Reddit", 'clarification': "Who are you looking for? If I find some relevant communities, would you want me to let you know?"},
      {'key': 'VA', 'label': "I didn't get enough value to justify the cost", 'clarification': "What kind of value would you need to have received in order for the price to be fair?"},
      {'key': 'MI', 'label': "Missing features I expected", 'clarification': "What do you think is missing?"},
      {'key': 'TE', 'label': "Too expensive for me", 'clarification': "What kind of value would you need to have received in order for the price to be fair?"},
      {'key': 'BT', 'label': "Bad timing, might consider later", 'clarification': "What needs to happen for the timing to be better for you?"},
      {'key': 'TS', 'label': "I got what I needed during the trial period", 'clarification': "Would you consider using GummySearch in the future?"},
      {'key': 'OT', 'label': "Other", 'clarification': 'Please clarify...'},
    ]
    const [selectedReason, setSelectedReason] = useState(null); 

    // this is the action to submit feedback and cancel
    const submitAndCancel = (e) => {
      e.preventDefault();

      if (submitting || !selectedReason) return;

      setSubmitting(true);
      paymentService.downgradeToFreeTrial(
        {
          "cancel_reason": selectedReason.key,
          "cancel_anything_else": anythingElse,
        },
        (response) => {
        setSubmitting(false);
        setSuccess("Subscription canceled -- You are now on the free tier");

        trackEvent("Downgraded", {
          "plan": "free",
          "reason": selectedReason.key,
          "reason_label": selectedReason.label,
          "anything_else": anythingElse
        });

        fetchCurrentUser();
        setRefetchLimits(new Date())
      }, (error, response) => {
        setSubmitting(null);
        setError(response || 'Error downgrading to free tier. Please contact us.');
      });

    }


  return (
    <Modal close={closeModal} widthClass={'max-w-3xl bg-gray-900 p-6'}>
      <Helmet><title>Account | Subscription | Cancel</title></Helmet>
      
      <h1 className="text-3xl mb-6 text-center font-extrabold">Cancel your subscription</h1>

      {error ? (
        <div className="bg-red-500 p-2 px-3 font-medium rounded-sm mb-4">
          {error}
        </div>
      ) : ''}

      {success ? (
        <div className="bg-green-500 p-2 px-3 font-medium rounded-sm mb-4">
          {success}. <span className="underline cursor-pointer" onClick={closeModal}>Close</span>.
        </div>
      ) : ''}
      

      <div className="mb-4">
          If you cancel your subscription, you will:
          <ul className="list-disc pl-4">
            <li>Be downgraded to the free tier <b className="underline">today</b></li>
            <li>Be limited in number of keyword searches you can make</li>
            <li>Lose keyword tracking capabilities</li>
            <li>Lose your current subscription price</li>
          </ul>
      </div>
      <div className="mb-2">
          If you want to <b>pause your subscription</b> (and keep current price), or <b>schedule cancelling your plan</b> on a date in the future, please email fed@gummysearch.com.
      </div>
      <form className="relative mt-4" onSubmit={submitAndCancel}>   
        <div>
          <label className="text-xs uppercase font-semibold mb-1 block opacity-50">Why do you want to cancel?</label>
          <Listbox id="reason-select" value={selectedReason && selectedReason.key} onChange={(s) => {
            setSelectedReason(allReasons.filter(r => r.key === s)[0])
            setTimeout(() => {
              document.getElementById('anything-else').focus()
            }, 10)  
          }}>
            <div className="relative">
              <Listbox.Button id="reason-select-button" className="relative w-full cursor-pointer border border-solid border-gray-500 rounded-md shadow-sm pl-3 pr-6 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500">
                <span className="block truncate">{selectedReason && selectedReason.label || 'Reason for cancelling'}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                
                {allReasons.map((a) => (
                  <Listbox.Option
                    key={a.key}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-cyan-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-8 pr-4'
                      )
                    }
                    value={a.key}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {a.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-cyan-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>
        </div>
      

        <div className="mt-4">
          <label className="text-xs uppercase font-semibold mb-1 block opacity-50">{selectedReason && selectedReason.clarification || 'Anything else I should know?'}</label>
          <textarea
            type="text" id="anything-else"
            name="anythingelse"
            autoComplete="off" //autoFocus={isEffectiveProSubscriber ? "on" : ""}
            className={`h-32 w-full text-sm cursor-pointer border border-solid bg-transparent border-gray-500 rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500`}
            placeholder={`Thank you for your feedback!`}
            value={anythingElse}
            onChange={(e) => {
              setAnythingElse(e.target.value);
            }}
          />
        </div>

        <button
          type="submit"
          disabled={submitting || success}
          onClick={(e) => {
            if (!selectedReason || (selectedReason.key === "OT" && !anythingElse)){
              e.preventDefault();
              alert("Please select reason for cancelling, your feedback is super appreciated!")
            }
          }}

          className="mt-4 mx-0 w-full text-sm text-center p-2 border border-solid border-transparent font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
        >
          {submitting ? 'Submitting...' : 'Cancel Subscription'}
        </button>

        <div className="pt-4 text-center">
          <span onClick={closeModal} className="cursor-pointer underline opacity-50 hover:opacity-100">Close</span>
        </div>
      </form>

    </Modal>
  );
};

export default SubscriptionCancelModal;

