import React from "react"
import {
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import FormFooter from "./footer";
import FeaturePopover from '../common/featurePopover'


const StoryContent = ({objective, objectiveKey}) => {

  return (
    <div className="space-y-4 text-gray-200">
      {objectiveKey === "FP" ? (
        <React.Fragment>
          <p>
            {objective.name} is an aspiring indie hacker who wants to start a new side business.
            He hasn't settled on an idea, but wants to explore a few different customer personas to find an unmet need.
          </p>
          <p>
             {objective.name} uses GummySearch to explore some <FeaturePopover id={'example-audiences'} label={'example audiences'} /> to
             quickly dive into some pre-set customer personas. He sees how easy it is to explore the conversations in a given niche, and decides
             to <FeaturePopover id={'discover'} label={'discover Subreddits'} /> for some areas he's personally passionate about. He makes a
             few new <FeaturePopover id={'audiences'} label={'audiences'} /> full of those communities, and
             starts browsing the <FeaturePopover id={'suggestions'} label={'suggested conversations'} /> for pain points, solutions requests,
             and business opportunities.
          </p>
          <p>
             After spotting a few themes, {objective.name} starts <FeaturePopover id={'saved-conversations'} label={'saving conversations'} /> for
             various problems that multiple people appear to have. He uses the <FeaturePopover id={'reddit-search'} label={'advanced Reddit search'} /> to
             explore some current competitive solutions to these problems that folks talk (and sometimes complain) about. He even sets up a few <FeaturePopover id={'keyword-tracking'} label={'tracked keywords'} /> to
             stay on top of new conversations for these issues & existing solutions.
          </p>
          <p>
            {objective.name} has a few ideas for solutions to problems he's discovered. He's feeling inspired, confident, and energized
            to start validating a few of his ideas. He even has found some Reddit users he's going to reach out to for his validation interviews.
          </p>
        </React.Fragment>
      ) : objectiveKey === "VS" ? (
        <React.Fragment>
          <p>
            {objective.name} is a mobile app developer with a few apps in the app store. Some have been successful,
            some not so much. She has a new app idea, and has learned from experience. {objective.name} wants
            to validate her idea before investing in building it out completely.
          </p>
          <p>
             {objective.name} uses GummySearch to <FeaturePopover id={'discover'} label={'discover subreddits'} /> for the niche her app will service.
             Furthermore, she finds a few relevant communities and saves them to an <FeaturePopover id={'audiences'} label={'audience'} /> for easy browsing.
             She uses the <FeaturePopover id={'reddit-search'} label={'advanced Reddit search'} /> to search for keywords that describe the pain point her
             new app will solve for users. She actually finds a lot of conversations mentioning this problem, and adds them to
             her <FeaturePopover id={'saved-conversations'} label={'saved conversations'} /> for easy access. She also discovers a few
             existing solutions, and <FeaturePopover id={'reddit-search'} label={'searches'} /> those app names to find out what people love about those apps, as well as complain about.
             To make sure she stays on top of new conversations about those solutions, she saves the brand names as <FeaturePopover id={'keyword-tracking'} label={'tracked keywords'} />.
          </p>
          <p>
            With her initial research done, {objective.name} is ready to talk to some potential customers. She looks at the <FeaturePopover id={'subreddit-stats'} label={'subreddit stats'} /> to
            find an active community, looks at the format of the <FeaturePopover id={'subreddit-influencers'} label={'top-performing posts'} />, and makes a Reddit post asking if anybody would like to talk to her about the problem she is solving.
            She also reaches out to users from her <FeaturePopover id={'saved-conversations'} label={'saved conversations'} /> who have explicitly mentioned a need for this solution.
          </p>
          <p>
            {objective.name} has quickly learned a ton about her potential users, and has some folks lined up to talk about the problem she is planning to solve.
            She's feeling excited to talk to potential customers, has some important questions ready, and is confident in her validation process.
          </p>
        </React.Fragment>
      ) : objectiveKey === "CU" || objectiveKey === "OT" ? (
        <React.Fragment>
          <p>
            {objective.name} is the creator of an online course for software developers looking to find their first job. He's invested a lot of time in
            building out his course, but very little time marketing it.
          </p>
          <p>
             {objective.name} uses GummySearch to <FeaturePopover id={'discover'} label={'discover subreddits'} /> for software developers.
             He finds a lot of relevant communities and saves them to an <FeaturePopover id={'audiences'} label={'audience'} /> for easy browsing. Additionally, he makes a second
             audience for career-related Subreddits.
             He uses the <FeaturePopover id={'reddit-search'} label={'advanced Reddit search'} /> on keywords relevant to his course ("first job", "career help").
             He finds a lot of conversations where he can add value by answering questions, contributing to the conversation, and plugging his course when applicable.
          </p>
          <p>
            To stay in tune with new conversations that could be opportunities to promote his course, {objective.name} sets up
            some <FeaturePopover id={'keyword-tracking'} label={'tracked keywords'} />. He finds that he can track keywords not just for his course website, but some of his
            blog posts as well. {objective.name} enables <FeaturePopover id={'email-digests'} label={'email digests'} /> to
            always know of new relevant conversations, and uses the <FeaturePopover id={'tracked-keyword-stats'} label={'tracked keyword stats'} /> to make sure he's only tracking keywords
            that provide more signal than noise.
          </p>
          <p>
            {objective.name} is getting some great success with his Reddit social listening strategy. He's gaining trust from
            his potential customers by helping them with their questions, driving traffic to his blog, and most importantly making sales for his course. Eventually, he starts seeing organic mentions of other people promoting his course on Reddit.
          </p>
        </React.Fragment>
      ) : objectiveKey === "CO" ? (
        <React.Fragment>
          <p>
            {objective.name} is the owner of a B2C productivity SaaS. She wants to start a company blog and start using content as a scalable acquisition strategy.
          </p>
          <p>
             {objective.name} uses GummySearch to <FeaturePopover id={'discover'} label={'discover subreddits'} /> full of her target readers.
             She finds a lot of relevant productivity communities and saves them to an <FeaturePopover id={'audiences'} label={'audience'} /> for easy browsing.
             To come up with content ideas, she browses the <FeaturePopover id={'suggestions'} label={'suggested conversations'} /> for advice/solution requests.
             She <FeaturePopover id={'saved-conversations'} label={'saves conversations'} /> that seem to follow a common theme, and uses
             the <FeaturePopover id={'reddit-search'} label={'advanced Reddit search'} /> to dive deep into individual topics.
          </p>
          <p>
            After coming up with content ideas and writing them, she looks at the <FeaturePopover id={'subreddit-stats'} label={'subreddit stats'} /> to
            pick an engaged community, looks at the format of the <FeaturePopover id={'subreddit-influencers'} label={'top-performing posts'} />, and makes
            a Reddit post containing her content and link to her blog.
            She sets up some <FeaturePopover id={'keyword-tracking'} label={'tracked keywords'} /> related to each blog post, and
            enables <FeaturePopover id={'email-digests'} label={'email digests'} /> to be notified of new conversations where folks on Reddit are asking for similar resources.
          </p>
          <p>
            {objective.name} is getting some great success with her Reddit posting & social listening strategy. She's gaining trust from
            the community by answering their questions, driving traffic to her blog, and even getting a good conversion rate of reader to signup.
            Her blog posts start getting shared by her readers, and eventually rank in search engines.
          </p>
        </React.Fragment>
      ) : objectiveKey === "RE" ? (
        <React.Fragment>
          <p></p>
        </React.Fragment>
      ) : 'No story for this objective yet...'}
    </div>
  );
}

export default StoryContent;
