import React, { useState, useEffect } from "react";
// import {
//   Link
// } from "react-router-dom";

import ErrorBoundary from '../common/errorboundary.jsx';
import RedditorPopover from "./redditorpopover";
import CommentTree from "./commenttree"

import { styleKeyword, findKeywordsAndTruncate, textContainsKeyword } from '../../utils/text';
import { timeStampToEasyTime, timeStampToFullTime } from '../../utils/time';
import { trackEvent } from '../../utils/tracking';

import { searchesService } from '../../services/searches';
import { redditService } from '../../services/reddit';


const CommentTreeItem = ({ item, keyword, showRedirect, trackRedirect }) => {
  const [expanded, setExpanded] = useState(false);
  const [showReplies, setShowReplies] = useState(false);

  useEffect(() => {
    setShowReplies(keyword ? true : false);
  }, [keyword]); // show replies by default if we have a keyword to highlight

  return (
    <div id={`comment-tree-item-${item.id}`} className={`relative py-2 rounded-sm flex`}>
      <ErrorBoundary>
        <div className="text-gray-500 text-sm mr-3 w-10 text-center flex-shrink-0">
          <span className="font-medium flex">
            <svg className="self-center flex-shrink-0 h-3 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
            </svg>{item.score}
          </span>

          {/*{expanded ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
            </svg>
          )}*/}
        </div>
        <div className="flex-grow">
          <div className="text-gray-300 text-sm sm:text-base" onClick={() => {
            setExpanded(true)
            if (!showReplies && item.replies.length){
              setShowReplies(true)
            }
          }}
            disabled={["[deleted]", "[removed]"].includes(item.body)}
          >
            {item.body && keyword && textContainsKeyword(item.body, keyword) ? (
              <div className={`containsKeywords ${expanded ? '' : 'doubleLineClip cursor-pointer'}`}
                dangerouslySetInnerHTML={{__html: expanded ? styleKeyword(item.body, keyword) : styleKeyword(
                  findKeywordsAndTruncate(item.body, keyword),
                  keyword
                )}}>
              </div>
            ) : item.body ? (
              <div className={`containsKeywords ${expanded ? '' : 'doubleLineClip cursor-pointer'}`}>
                {item.body}
              </div>
            ) : ''}
          </div>
            
          <div className="flex items-center text-xs sm:text-sm mt-1">
            <div className="relative flex items-center space-x-2 text-gray-500">
              {/*<span className=" font-medium flex">
                <svg className="self-center flex-shrink-0 h-3 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                </svg>{item.score}
              </span>
              <span aria-hidden="true">&middot;</span>*/}
              <span className={`font-medium flex items-center hover:text-gray-200 cursor-pointer ${item.replies.length && !showReplies ? 'text-gray-400' : ''}`} disabled={!item.replies.length}
                onClick={() => setShowReplies(!showReplies)}
              >
                {/*<svg className="self-center flex-shrink-0 h-3 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                </svg>*/}
                {item.replies.length}{keyword ? ' filtered ': ' '}{item.replies.length === 1 ? 'reply' : 'replies'}

                {item.replies.length && !showReplies ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                  </svg>
                ) : item.replies.length && showReplies ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                  </svg>
                ) : ''}
              </span>
            </div>

            <div className="ml-auto flex items-center space-x-2 text-gray-500">
              <span className=" hover:text-gray-400">
                <RedditorPopover name={item.author} openOnClick={true} openOnHover={true}
                  getPopoverParentElement={() => document.getElementById(`comment-tree-item-${item.id}`)}
                  positions={["left", "top", "bottom", "right"]}
                >
                  {item.author}
                </RedditorPopover>
              </span>
              <span aria-hidden="true">&middot;</span>
              <span className="hidden sm:inline-block">
                {timeStampToFullTime(item.timestamp_utc)}
              </span>
              <span className="inline-block sm:hidden">
                {timeStampToEasyTime(item.timestamp_utc)}
              </span>
              {showRedirect ? (
                <React.Fragment>
                  <span aria-hidden="true">&middot;</span>
                  <span className="text-sm font-medium flex">
                    <a href={`https://www.reddit.com${item.link}`} target="_blank" rel="noreferrer" className="cursor-pointer opacity-75 hover:opacity-100"
                      onClick={() => {
                        if (trackRedirect){
                          trackEvent("ExternalRedirect", {
                            "platform": "Reddit",
                            "type": "CommentSource",
                            "platform_id": item.id,
                            "title": item.body,
                            // "keyword": keyword,
                          });
                          searchesService.saveRedirect("RCO", item.id, item.body);
                        }
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="self-center flex-shrink-0 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                      </svg>
                    </a>
                  </span>
                </React.Fragment>
              ) : ''}
            </div>
          </div>

          {showReplies && item.replies && item.replies.length ? (
            <div className="mt-2">
              <CommentTree comments={item.replies} keyword={keyword}/>
            </div>
          ) : ''}
        </div>
      </ErrorBoundary>

    </div>
  );
}

export default CommentTreeItem;

