import React, { useState } from "react";
import { Helmet } from "react-helmet";

import CommentContents from "./commentcontents";
import SaveKeywordInfoModal from "../conversations/saveKeywordInfoModal"

import { canAskToSaveKeyword } from '../../utils/keywords';

const CommentDrawer = ({ comment, closeDrawer, filters, params}) => {
  // STATE
  const [showSaveKeywordModal, setShowSaveKeywordModal] = useState(false);
  const [trackKeywordButton, setTrackKeywordButton] = useState(null);

  // ACTIONS

  // asks user if they want to save this keyword to be notified of later
  const askToSaveSearch = () => {
    // make sure we're allowed to ask (single keyword and we haven't asked this modal for it yet)
    if (!canAskToSaveKeyword(params.keyword)) return;
    
    // only show if we have the keyword button on the screen
    const trackKeywordButton = document.getElementById('track-keyword-button') && document.getElementById('track-keyword-button').parentElement;
    if (trackKeywordButton && !trackKeywordButton.classList.contains('tracking')){
      setTrackKeywordButton(trackKeywordButton); // set so that we have it in the modal
      setShowSaveKeywordModal(true); // open the modal
    }
  }


  return (
    <div className="h-full flex flex-col bg-gray-900 text-white shadow-xl overflow-auto">
      <Helmet><title>Conversations | Reddit | Comment</title></Helmet>
      <div className="p-6">
        <CommentContents
          close={closeDrawer}
          keyword={params.keyword}
          backend={filters.backend}
          comment={comment}
          commentId={comment && comment.id}
          onRedirect={() => {
            askToSaveSearch()
          }}
        />
      </div>

      {showSaveKeywordModal ? (
        <SaveKeywordInfoModal
          keyword={params.keyword}
          closeModal={() => setShowSaveKeywordModal(false)}
          trackKeywordButton={trackKeywordButton}
        />
      ) : ''}
    </div>
  );
}

export default CommentDrawer;

