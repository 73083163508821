import React from "react";
import {
  Link,
  withRouter
} from "react-router-dom";


// modal to view subreddit
const NavBar = ({
  tabs,
  location
}) => {

  if (!tabs || tabs.length < 1) return '';

  return (
    <nav className="-mb-px flex space-x-2 sm:space-x-4 max-w-full overflow-x-auto no-scrollbar" x-descriptions="Tab component">
      {tabs.map((tab) => (
        <Link
          key={tab.name}
          to={tab.link}
          disabled={tab.disabled}
          className={`whitespace-nowrap py-2 pb-4 px-2 flex items-center border-solid border-b-2 font-medium focus:outline-none focus:ring-none text-sm ${location.pathname === tab.link || (!tab.exact && location.pathname.startsWith(tab.link)) ? 'border-white text-white' : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-300'} ${tab.disabled || tab.locked ? 'text-gray-600' : ''}`}
        >
          <span>{tab.name}</span>

          {typeof tab.count === 'number' ? (
            <span className="text-gray-600 ml-2 font-bold">{tab.count}</span>
          ) : ''}

          {tab.icon ? (
            <tab.icon className={`${tab.iconColor || 'text-gray-600'} font-bold ml-2 h-4 w-4`} />
          ) : ''}

          {tab.disabled || tab.locked ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
          ) : ''}
        </Link>
      ))}
    </nav>
  )
};

export default withRouter(NavBar);

