import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon
} from '@heroicons/react/outline';
import { Popover, Transition } from '@headlessui/react'

import ErrorBoundary from "../common/errorboundary";

// import { redditService } from '../../services/reddit';
import { collectionsService } from '../../services/collections';
import { readableNumber } from '../../utils/text';


// renders an audience card
const AudienceCard = ({
  audience, hidden, displayType,
  fetchingAudienceItems, bulkFetchedItems,
  deleteAudience
}) => {
  // state
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const countIconsToShow = 8;

  // effects

  useEffect(() => {
    // should have fetched in bulk, but if for some reason we didn't, get this card's communities
    if (!bulkFetchedItems.length && !fetchingAudienceItems){
      fetchCommunities()
    }
  }, [fetchingAudienceItems, audience.hash]); // go fetch the images, names, and counts of each of the subreddits

  const fetchCommunities = () => {
    setLoading(true)
    collectionsService.getCollectionItems(audience.hash, (results) => {
      if (results && results.data){
        setItems(results.data.filter(i => i.external_type === "RSR").sort((a,b) => (a.item.count_users > b.item.count_users) ? 1 : ((b.item.count_users > a.item.count_users) ? -1 : 0)))  // ensure only subreddit for now. if we add, modify  
        setLoading(false)
      }
    }, (response, error) => {
      console.log(error)
      // setLoading(false)
    })
  }

  // get the community icons to show
  const itemsToShow = items.length ? items : bulkFetchedItems;
  const communitiesToShow = itemsToShow.filter(s => s.item.icon).filter((s, i) => i < countIconsToShow);
  const communitiesToCollapse = itemsToShow.filter(s => !communitiesToShow.includes(s));

  // get the stats
  const stats = {
    'total': itemsToShow.map(s => s.item.count_users).reduce((a, b) => a + b, 0),
    'online': itemsToShow.map(s => s.item.count_users_active).reduce((a, b) => a + b, 0),
  }

  // would be hidden if there's a frontend filter applied
  // hide instead of not showing b/c we only want to call getCollectionItems only once
  if (hidden) return '';

  return (
    <Link to={`/audience/${audience.hash}/`} key={audience.hash} className={`group rounded-md shadow-lg bg-gray-800 hover:bg-gray-700 text-white flex flex-col ${displayType === 'card' ? 'p-4' : 'p-3'}`}>
      <ErrorBoundary>
        <div className={displayType === 'card' ? '' : 'sm:flex items-center flex-wrap'}>
          <div className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 hidden opacity-25" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
            </svg>

            <h2 className={`text-xl font-semibold flex items-center w-full ${displayType === 'card' ? '' : 'flex-row-reverse'}`}>
              <div to={`/audience/${audience.hash}/`} className={`cursor-pointer mr-auto ${displayType === 'card' ? '' : 'ml-2'}`}>{audience.name}</div>
              <ReactTooltip id={`audience-card-${audience.hash}`} place="top" effect="solid" backgroundColor="white" textColor="black" />
              <div className={`ml-auto flex items-center space-x-2 ${displayType === 'card' ? '' : 'flex-row-reverse'}`}>
                {audience.type === "PU" ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-shrink-0 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                    data-for={`audience-card-${audience.hash}`} data-tip={`Public Audience`}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                ) : audience.curated ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 flex-shrink-0 text-gray-500"
                    data-for={`audience-card-${audience.hash}`} data-tip={`Curated by GummySearch`}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z" />
                  </svg>
                ) : !audience.curated ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 flex-shrink-0 text-gray-500"
                    data-for={`audience-card-${audience.hash}`} data-tip={`Custom Audience`}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
                  </svg>
                ) : ''}
                {audience.shared_report_enabled ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-shrink-0 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                    data-for={`audience-card-${audience.hash}`} data-tip={`Shared Report Enabled`}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                  </svg>
                ) : ''}

                <Popover className="relative text-base">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={`flex-shrink-0 inline-flex items-center justify-center py-2 ${displayType === 'card' ? '' : ''} focus:outline-none focus:ring-transparent focus:border-none rounded-lg ${open ? 'bg-gray-600 text-gray-200' : 'text-gray-400 hover:text-gray-200 hover:bg-gray-600'}`}
                      >
                        <span className="sr-only">More</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                        </svg>
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={React.Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className={`absolute z-10 top-0 px-2 sm:px-0 ${displayType === 'card' ? 'right-8' : 'left-8'}`}
                        >
                          <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden text-gray-400">
                            <div className="relative grid gap-4 bg-white p-4">
                              <Link to={`/audience/${audience.hash}/`}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg>
                                <div className="ml-2">
                                  <p className="font-medium text-gray-700">View</p>
                                </div>
                              </Link>

                              <div className="-m-3 p-3 cursor-pointer flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                                onClick={(e) => {
                                  e.preventDefault()
                                  deleteAudience(audience)
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                                <div className="ml-2">
                                  <p className="font-medium text-gray-700">Delete</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            </h2>
          </div>

          {fetchingAudienceItems || loading ? (
            <div className={displayType === 'card' ? 'h-20' : 'h-8'}></div>
          ) : itemsToShow.length === 0 ? (
            <div className="h-28 text-center flex items-center">
              <div className="text-sm mx-auto opacity-50">No communities in this audience...</div>
            </div>
          ) : (
            <React.Fragment>
              <div className={displayType === 'card' ? '' : 'sm:ml-auto mr-4'}>
                <div className="mt-1 flex space-x-2 sm:space-x-4 text-xs">

                  <div className="flex items-center text-gray-300">
                    <svg className="h-4 w-4 mr-2 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                      <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                    </svg>
                    {itemsToShow.length} Sub{itemsToShow.length === 1 ? '' : 's'}
                  </div>
                  <div className="flex items-center text-gray-300">
                    <svg className="h-4 w-4 mr-2 opacity-25" xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                    {readableNumber(stats.total)} Members
                  </div>
                  {/*<div className="flex items-center text-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 opacity-25" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z" />
                    </svg>
                    {readableNumber(stats.online)} Online
                  </div>*/}
                  
                  <ReactTooltip id={`audience-stats-${audience.hash}`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                  
                  {audience.calculated && (audience.calculated.growth_members_monthly !== null) ? (
                    <div className={`flex ${audience.calculated.growth_members_monthly ? 'text-gray-400' : 'text-gray-400'}`}>
                      <TrendingUpIcon className="h-4 w-4 mr-1" />
                      <div>{audience.calculated.growth_members_monthly.toFixed(2)}% /mo</div>
                    </div>
                  ) : audience.calculated && (audience.calculated.growth_members_weekly !== null) ? (
                    <div className="text-gray-400 flex">
                      <TrendingUpIcon className="h-4 w-4 mr-1" />
                      <div>{audience.calculated.growth_members_weekly.toFixed(2)}% /wk</div>
                    </div>
                  ) : ''}

                </div>

                {displayType === 'card' ? (
                  <dd className="flex justify-between items-baseline md:block lg:flex">
                    <ReactTooltip id={`collection-${audience.hash}-icon`} effect="solid" backgroundColor="white" textColor="black"
                      className="w-60 text-center"
                    />
                    
                    <div className="flex flex-wrap my-2 flex-row-reverse justify-end pr-4">
                      {communitiesToCollapse.length > 0 ? (
                        <div className="bg-gray-700 h-12 w-12 text-center py-3.5 rounded-md -mr-4 border-2 border-solid border-gray-800 group-hover:bg-gray-600 group-hover:border-gray-700 relative text-xs text-gray-400 font-semibold"
                          data-for={`collection-${audience.hash}-icon`} data-tip={communitiesToCollapse.map(c => 'r/' + c.item.name).join(', ')}
                        >
                          +{communitiesToCollapse.length}
                        </div>
                      ) : ''}

                      {communitiesToShow.map(s => (
                        <div key={s.item.name} className="bg-gray-600 shadow-sm h-12 w-12 p-1 rounded-md -mr-4 border-2 border-solid border-gray-800 group-hover:border-gray-700 relative"
                          data-for={`collection-${audience.hash}-icon`} data-tip={`r/${s.item.name}`}
                        >
                          <img className="h-full w-full rounded-sm" src={s.item.icon} alt={s.item.name} />
                        </div>
                      ))}
                    </div>

                  </dd>
                ) : ''}
              </div>

              
              {/*<div className={displayType === 'card' ? 'mt-1' : 'hidden sm:block'}>
                <dd className="flex justify-between items-baseline sm:block md:flex">
                  <Link to={`/audience/${audience.hash}/`} className="flex-1">
                    <button type="button" className={`${displayType === 'card' ? 'w-full px-4 py-3 rounded-md' : 'px-3 py-2 rounded-sm'} text-center items-center shadow-sm text-xs font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500`}>
                      Explore
                    </button>
                  </Link>
                </dd>
              </div>*/}
              
            </React.Fragment>
          )}
        </div>

      </ErrorBoundary>
    </Link>
  )
}

export default AudienceCard;

