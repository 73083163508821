import React, { useState } from "react"

import ErrorBoundary from "../common/errorboundary";
import ErrorBar from "../common/errorBar";

import AudienceAddSimilar from "./audienceAddSimilar"

import { collectionsService } from '../../services/collections';


// shows field for entering subreddit by name, or some similar communities
const AudienceAdd = ({ collection, refetch, closeModal}) => {
  // state
  const [subredditName, setSubredditName] = useState('');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);


  // actions
  const addToCollection = () => {
    // add to collection by name
    if (saving || !subredditName) return;

    setSaving(true)
    setError(null)

    var name = subredditName.replace('r/','');

    const items = [{'external_type': 'RSR', 'external_id': name}];
    collectionsService.addCollectionItems(collection.hash, items, (results) => {
      refetch();
      closeModal();
      setSaving(false)
    }, (response, error) => {
      console.log(error);
      setError("Error adding subreddit. It's likely an invalid name, or a private subreddit.");
      setSaving(false);
    })
  }


  return (
    <ErrorBoundary>
      <div className="">
        <h3 className="mb-1 text-xs font-semibold text-gray-400 uppercase tracking-wide">
          Add subreddit by name
        </h3>
        <div>
          <input id="collection-name" type="text" autoFocus={true}
            className="bg-gray-700 px-2 py-3 text-sm border-none rounded-md w-full focus:ring-0"
            placeholder={saving ? "Adding..." : "Enter subreddit name and press enter"} autoComplete="off" spellCheck="off"
            disabled={saving} value={subredditName} onChange={e => setSubredditName(e.target.value)}
            onKeyDown={(event) => {
              const value = event.target.value;
              if (event.key === 'Enter') {
                if (value && value !== ''){
                  addToCollection({'name': value})
                }      
              } else {
                // addToCollection(value)
              }
            }}
          />
        </div>
      </div> 
      
      <div className="mt-4">
        <AudienceAddSimilar
          collection={collection}
          refetch={refetch}
        />
      </div> 


      {error ? (
        <ErrorBar error={error} setError={setError} />
      ) : ''}  


    </ErrorBoundary>
  );
}

export default AudienceAdd;
