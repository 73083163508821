import React, { useState, useEffect } from "react"
// import { PlusSmIcon } from '@heroicons/react/solid'

import MakeReviews from './productMakeReviews';

import { productsService } from "../../services/products";

import { capitalizeFirst, findKeywordsAndTruncate } from '../../utils/text';
import { organizeProductMakes } from '../../utils/products';

// search for a new product
const ProductNewProduct = ({
  collection,
  categories,
  loadingCategories,
  baseUrl,
  fetchProductCategories,
}) => {
  const [searchResults, setSearchResults] = useState(null);
  const [productDetails, setProductDetails] = useState(null);
  const [searchAllSubreddits, setSearchAllSubreddits] = useState(false);
  const [searching, setSearching] = useState(false);
  const [adding, setAdding] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [countPostsToShow, setCountPostsToShow] = useState(5);
  const [countCommentsToShow, setCountCommentsToShow] = useState(5);
  const [showAllProducts, setShowAllProducts] = useState(false);

  useEffect(() => {
    // if all makes have 0 or 1 reviews, show all products
    // if (productMakes.filter((m) => m.reviews.length >= 2).length === 0) {
    //   setShowAllProducts(true);
    // } else {
    //   setShowAllProducts(false);
    // }
  }, [productDetails])

  /* Actions */
  const clearResults = () => {
    setSearchResults(null);
    setSearching(false);
    setAdding(false);
    setCountPostsToShow(8);
    setProductDetails(null)
  }

  const searchForProduct = () => {
    if (!keyword) return;
    setSearching(true);
    const params = {
      'name': keyword,
      'subreddit_names': searchAllSubreddits ? [] : collection.subreddit_names,
    }
    productsService.searchProduct(params, (response) => {
      setSearchResults(response.data);
      setSearching(false);
    }, (error) => {
      console.log(error);
      alert("Error searching for product");
      setSearching(false);
    })
  }

  const productMakes = productDetails ? organizeProductMakes(productDetails) : [];

  return (
    <div style={{minHeight: "350px"}}>
      {!searchResults ? (
        <div className="sm:p-20">
          <div className="mb-4">
            <h3 className="text-lg leading-6 font-medium">Search for a product name</h3>
            <div className="text-sm text-gray-400">
              Enter a product name to search for reviews.
            </div>
          </div>

          <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                searchForProduct()
              }
            }}
            placeholder="Ahrefs, SEMrush, etc."
            className="border-2 border-gray-500 bg-transparent rounded-md p-2 w-full"
            autoFocus="yes" autoComplete="off"
          />

          <div className="my-2">
            <input id="searchCollectionSubreddits" type="checkbox" checked={!searchAllSubreddits} onChange={(e) => setSearchAllSubreddits(!e.target.checked)} />
            <label htmlFor="searchCollectionSubreddits" className="ml-2 mr-8">This Audience</label>

            <input id="searchAllSubreddits" type="checkbox" checked={searchAllSubreddits} onChange={(e) => setSearchAllSubreddits(e.target.checked)} />
            <label htmlFor="searchAllSubreddits" className="ml-2">All Subreddits</label>
          </div>

          <button onClick={searchForProduct}
            className="mt-2 bg-cyan-500 text-white rounded-md p-2 w-full"
            disabled={searching || !keyword}
          >
            {searching ? "Searching..." : "Search for Product"}
          </button>
        </div>
      ) : !productDetails ? (
        <div className="pr-2">
          <h3>
            Found relevant posts <span className="text-gray-400">{searchResults.opinion_submissions.length}</span>
          </h3>
          <ul className="list-disc pr-4 ml-6">
            {searchResults.opinion_submissions.slice(0, countPostsToShow).map((submission) => (
              <li className="list-disc" key={submission.id}>
                <h4 className="w-full text-nowrap overflow-hidden whitespace-nowrap text-ellipsis">
                  <span className="text-gray-400">{submission.subreddit_name}</span> {submission.title}
                </h4>
              </li>
            ))}
          </ul>

          {countPostsToShow < searchResults.opinion_submissions.length && (
            <span className="pl-2 cursor-pointer text-gray-500 hover:text-gray-200" onClick={() => setCountPostsToShow(searchResults.opinion_submissions.length)}>
              + Show {searchResults.opinion_submissions.length - countPostsToShow} more
            </span>
          )}
          {searchResults.comments.length > 0 && (
            <div className="mt-4">
              <h3>
                Found relevant comments <span className="text-gray-400">{searchResults.comments.length}</span>
              </h3>
              <ul className="list-disc pr-4 ml-6">
                {searchResults.comments.slice(0, countCommentsToShow).map((comment) => (
                  <li className="list-disc" key={comment.id}>
                    <h4 className="w-full text-nowrap overflow-hidden whitespace-nowrap text-ellipsis">
                      <span className="text-gray-400 mr-1">{comment.subreddit_name}</span>
                      {comment.body}
                      {/* {findKeywordsAndTruncate(comment.body, keyword, 80)} */}
                    </h4>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {countCommentsToShow < searchResults.comments.length && (
            <span className="pl-2 text-gray-500">
              + {searchResults.comments.length - countCommentsToShow} more
            </span>
          )}

          {searchResults.opinion_submissions.length == 0 && searchResults.comments.length == 0 ? (
            <div className="mt-4 text-gray-400">
              No results found, <span className="text-cyan-400 cursor-pointer" onClick={clearResults}>try another search</span>, or expand to all subreddits.
            </div>
          ) : (
            <div className="mt-4">
              <p>Do these results look like what you're looking for?</p>
              <button className="mt-2 bg-cyan-500 text-white rounded-md p-2 w-full"
                disabled={adding || (searchResults.opinion_submissions.length == 0 && searchResults.comments.length == 0)}
                onClick={() => {
                  setAdding(true);
                  const params = {
                    'name': keyword,
                    'subreddit_names': searchAllSubreddits ? [] : collection.subreddit_names,
                  }
                  productsService.addProduct(params, (response) => {
                    setProductDetails(response.data);
                    setAdding(false);

                    // in the background, fetch the updated product categories
                    // fetchProductCategories();
                  }, (error) => {
                    console.log(error);
                    alert("Error adding product");
                    setAdding(false);
                  })
                }}>
                  {adding ? "Analyzing, could take up to 60 seconds" : "Yes, analyze product reviews"}
              </button>
              <button className="mt-2 bg-gray-800 text-white rounded-md p-2 w-full"
                disabled={adding}
                onClick={() => {
                  setProductDetails(null);
                  setSearchResults(null);
                }}>
                  No, try another search
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="pr-2">
          
          <h3 className="flex text-sm items-center w-full">
            <div className="text-gray-300 font-semibold flex items-center">
              {capitalizeFirst(keyword)} reviews
              <p className="ml-2 text-gray-500 truncate">
                <span>{productMakes[0] ? productMakes[0].reviews.length : 0}</span>
              </p>
              {productMakes[0] ? (
                <span className="text-cyan-500 cursor-pointer ml-2" onClick={clearResults}>
                  clear results
                </span>
              ) : ''}
            </div>
          </h3>
          <div className="mt-2 space-y-2">
            {productMakes.map((m , i) => (
              <MakeReviews key={m.make} make={m} index={i} hideNumbers={true} expand={i === 0}/>
            ))}

            {productMakes.length == 0 ? (
              <div className="mt-4 text-gray-400">
                No reviews found, <span className="text-cyan-400 cursor-pointer" onClick={clearResults}>try another search</span>.
                <br/> This feature is new, so it may not work for all products yet.<br/>Please send an email if you have any feedback on the results.
              </div>
            ) : ''}
          </div>
        </div>
      )}
    </div>
  )
}


export default ProductNewProduct;
