import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Helmet } from "react-helmet";

import Modal from "../common/modal";
import NavBar from "../common/navbar";
import PropsRoute from "../common/propsroute";
import SubscriptionSelect from "./subscriptionSelect";

import { RESOURCES } from "./constants";
import { paymentService } from '../../services/payment';
import { readableNumber } from '../../utils/text'

// modal to increase limits of various resources
const SubscriptionLimitResource = ({ history, match, currentUser, limits, closeModal, setSuccess, fetchCurrentUser, setRefetchLimits }) => {
  const { resource } = match.params;
  const currentAdditionalLimit = currentUser && currentUser.subscription && currentUser.subscription.packs && currentUser.subscription.packs[resource] || 0; // replace with current limit extensions
  const [quantity, setQuantity] = useState(currentAdditionalLimit); 
  const [updating, setUpdating] = useState(false); 

  useEffect(() => {
    setQuantity(currentAdditionalLimit)
  }, [resource])

  const thisResource = RESOURCES.filter(r => r.id === resource)[0];
  if (!thisResource) return 'Invalid resource'

  const currentUserPlan = currentUser && currentUser.subscription && currentUser.subscription.key;
  const currentUserBilling = currentUser && currentUser.subscription && currentUser.subscription.billing_cycle;
  if (!currentUserPlan) return 'No active subscription'; // no plan, don't show anything

  const currentIncludedLimit = thisResource['allowances'][currentUserPlan];
  const limitPackOptions = thisResource['options'][currentUserBilling];
  if (!limitPackOptions && currentUserBilling === "day") return "Can't increase limits a day pass user, please buy a subscription. Contact fed@gummysearch.com for questions.";
  if (!limitPackOptions && currentUserBilling === "lifetime") return "To buy more limits as a LTD holder, please contact fed@gummysearch.com to configure your billing for additional limits.";
  if (!limitPackOptions) return "Invalid billing cycle"

  const hasSelectedCurrent = quantity === currentAdditionalLimit;

  const selectPlan = () => {
    if (hasSelectedCurrent || updating) return;

    var message = ""
    if (currentAdditionalLimit > 0 && quantity === 0){
      // deleting existing limit pack
      message = "Are you sure you want to remove your current extra limits?"
    } else if (currentAdditionalLimit > 0 && quantity !== 0){
      // handle case where we update
      message = "Are you sure to update your additional limits?"
    } else if (currentAdditionalLimit === 0 && quantity !== 0){
      // handle case where we create
      message = "Are you ready to upgrade your plan? You will be charged a prorated amount for the additional limits immediately."
    } else {
      alert("unknown case")
      return;
    }

    if (window.confirm(message)){
      // go do it!
      setUpdating(true);
      paymentService.updateLimitPack({'resource': resource, 'quantity': quantity}, () => {
        setUpdating(false);
        closeModal()
        setSuccess("Updated plan limits")
        fetchCurrentUser();
        setRefetchLimits(new Date())
      }, (error, response) => {
        setUpdating(false);
        alert(response || 'Error updating limits')
      });
    }
  }

  return (
    <div>
      <Helmet><title>Account | Subscription | Limits | {resource}</title></Helmet>

      <div className="flex">
        <div className="flex-1">
          <label className="flex items-center text-sm font-medium text-gray-500">
            <div>Included in {currentUserPlan} plan</div>
          </label>
          <div>{currentIncludedLimit} {thisResource.name} {thisResource.timeframe ? <span className="text-xs opacity-50"> {thisResource.timeframe}</span> : ''}</div>
        </div>

        {currentAdditionalLimit ? (
          <div className="flex-1">
            <label className="flex items-center text-sm font-medium text-gray-500">
              <div>Your total (included + purchased)</div>
            </label>
            <div>{currentIncludedLimit + currentAdditionalLimit} {thisResource.name} {thisResource.timeframe ? <span className="text-xs opacity-50"> {thisResource.timeframe}</span> : ''}</div>
          </div>
        ) : ''}
        
      </div>

      <div>
        <label className="mt-2 flex items-center text-sm font-medium text-gray-500">
          <div>Select additional limit to purchase</div>
        </label>
        <div className="mt-0 flex space-x-2 pt-2">
          {limitPackOptions.map(o => (
            <div key={o.quantity}
              onClick={() => setQuantity(o.quantity)}
              className={`flex-1 py-4 relative border-2 cursor-pointer rounded-md ${quantity === o.quantity ? 'border-cyan-500' : 'border-gray-500 hover:border-gray-400'}`}
            >
              <div className="text-lg text-center">
                <span className="opacity-50">+</span> 
                <span className="font-bold">{o.quantity}</span><br/>
              
              </div>
              <div className="text-sm text-center opacity-50">{thisResource.name}{thisResource.timeframe}</div>
              <div className="mt-2 text-green-400 font-semibold text-center">
                ${o.costAdditional}
                {currentUserBilling === 'monthly' ? '/mo' : currentUserBilling === 'yearly' ? '/yr' : '??'}
              </div>
              {o.quantity === currentAdditionalLimit ? (
                <div className="font-semibold text-xs opacity-50 text-center">(current plan)</div>
              ) : ''}
             
              {quantity === o.quantity ? (
                <div className="p-1 absolute -top-2 -left-2 bg-cyan-500 text-white rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                </div>
              ) : ''}
            </div>
          ))}
        </div>
        <div className="mt-4 flex">
          <button
            onClick={selectPlan}
            disabled={hasSelectedCurrent || updating}
            className="ml-auto w-full md:w-auto text-center p-2 px-4 border border-solid border-transparent font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
          >
            {updating ? 'Updating...' : hasSelectedCurrent ? 'Current Plan' : 'Update Plan'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionLimitResource;

