import React from "react";
//import SEO from "../landing/seo";

// layout to be used for Login/Register views
const Layout = ({ children, title, subtitle, hideFooter }) => (
  <div className="min-h-screen bg-gray-900 text-white flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    {/*<SEO title={title} />*/}
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img className="mx-auto h-20 w-auto" src="/images/branding-logo-body-gray.png" alt="Gummy Bear" />
      <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
        { title }
      </h2>
      {subtitle ? (
        <p className="mt-2 text-center text-sm text-white opacity-50 max-w">
          { subtitle }
        </p>
      ) : ''}
    </div>

    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-gray-800 color-white py-8 px-4 shadow rounded-md sm:rounded-lg sm:px-10 mx-4 sm:mx-0">
        { children }
      </div>
      {!hideFooter ? (
        <div className="text-sm p-2 text-xs text-gray-500 text-center">
          By using this site you agree to our <a className="font-semibold text-gray-300 hover:text-gray-white hover:underline" href="https://gummysearch.com/terms-and-conditions/" target="_blank">terms</a> and <a className="font-semibold text-gray-300 hover:text-gray-white hover:underline" href="https://gummysearch.com/privacy-policy/" target="_blank">privacy policy</a>
        </div>
      ) : ''}
    </div>

  </div>
);

export default Layout;

