import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { Popover } from 'react-tiny-popover'
import ReactTooltip from 'react-tooltip';

import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon
} from '@heroicons/react/outline';

import AiMinuteUsage from "../language/aiminuteusage";

import { getRequest } from '../../utils/axios';
import { readableNumber } from '../../utils/text';
import { getParamsFromUrl, toQueryString } from '../../utils/urls';
import { trackEvent } from '../../utils/tracking';
import { searchesService } from '../../services/searches';


// component that wraps another component
// upon hover, shows info about AI minute usage
const AiMinutePopover = ({
  positions, children, getPopoverParentElement,
  limits,
  // minutes,
  hideBar,
  hideChart,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDelaying, setIsDelaying] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const isMobile = window.screen.width < 600;  // no hovering on mobile, just clicks
  const openOnClick = isMobile;
  const openOnHover = !isMobile;

  

  const setDelay = () => {
    // delay showing the popover for a bit
    if (isDelaying) return;

    setIsDelaying(true);
    setTimeout(() => {
      setIsDelaying(false)
    }, 100)
  }

  // content for popover
  const getContent = () => {

    // don't show if havent loaded limited yet
    if (!limits) return ''

    return (
      <div className="z-40 w-96 rounded-md overflow-hidden shadow-lg bg-white" role="menu" aria-orientation="vertical" aria-labelledby="options-menu"
        onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
        onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
      >
        <div className="flex-1 bg-white p-6 ">
          <div className="text-xs uppercase font-semibold mb-1 opacity-50 flex items-center">
            <div>What are AI Minutes?</div>
          </div>
          <div className="text-sm">
            AI minutes are a credit system for AI-powered features in GummySearch.
            For example, finding patterns in 100 reddit posts will save you ~60 minutes of reading time, costing 60 AI minutes.
          </div>

          <div className="mt-4 text-xs uppercase font-semibold mb-1 opacity-50 flex items-center">
            <div>How does it work?</div>
          </div>
          <div className="text-sm">
            GummySearch Pro users get 5000 free AI minutes a month,
            after which usage of AI features requires purchasing more minutes.
            <br/><br/>
            Once you run an AI-based analysis, your results are cached. You can view past results without using more AI minutes, or re-run for new results.
          </div>

          {!hideBar ? (
            <React.Fragment>
              <div className="mt-4 text-xs uppercase font-semibold mb-1 opacity-50 flex items-center">
                <div>30-day usage limit</div>
              </div>
              <div className="mt-2 text-xs uppercase font-semibold mb-1">
                <div className="w-48 h-5 bg-gray-400 rounded-sm relative flex-shrink-0">
                  <div className="absolute h-full p-0.5 text-white text-xs">{limits.ai_minutes_used} / {limits.ai_minutes_allowed}</div>
                  <div className={`rounded-sm p-0.5 h-full ${limits.ai_minutes_used >= limits.ai_minutes_allowed ? 'bg-yellow-500' : 'bg-cyan-600'}`}
                    style={{width: (!limits.ai_minutes_allowed ? 1 : 100 * Math.min(limits.ai_minutes_used / limits.ai_minutes_allowed, 1)) + '%'}}></div>
                </div>
              </div>
            </React.Fragment>
          ) : ''}

          {!hideChart ? (
            <React.Fragment>
              <div className="mt-4 text-xs uppercase font-semibold mb-1 opacity-50 flex items-center">
                <div>Daily usage</div>
              </div>
              <div className="mt-1">
                <AiMinuteUsage limits={limits} lightBackground={true} />
              </div>
            </React.Fragment>
          ) : ''}
          
          
        </div>
      </div>
    )
  }

  var parentElement = window.document.body;
  if (getPopoverParentElement && getPopoverParentElement()){
    parentElement = getPopoverParentElement()
  }

  return (
    <Popover
      isOpen={(isOpen || isHovering) && !isDelaying}
      positions={positions || ['bottom', 'left', 'right', 'top']}
      align="start"
      content={getContent()}
      parentElement={parentElement}
      // onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
      // onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
    >
      <span className={openOnClick ? 'cursor-pointer': ''}
        onClick={() => openOnClick && setIsOpen(!isOpen)}
        onMouseEnter={() => {
          if (openOnHover){
            setDelay(); // delay if first time
            setIsHovering(true)
          }
        }}
        onMouseLeave={() => {
          if (openOnHover){
            setIsDelaying(false)
            setIsHovering(false)
          }
        }}
      >{ children }</span>
    </Popover>
  )
}

const mapStateToProps = state => {
  // const {
  //   audiences,
  // } = state.audiences;

  return {
    // audiences,
  }
};

const mapDispatchToProps = (dispatch) => {
  // const fetchAudiences = (callback, onError) => {
  //   dispatch(audiencesOperations.fetchAudiences(callback, onError))
  // };
  return {
    // fetchAudiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AiMinutePopover);


