import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Helmet } from "react-helmet";

// import Modal from "../common/modal";
// import NavBar from "../common/navbar";
// import PropsRoute from "../common/propsroute";
// import SubscriptionSelect from "./subscriptionSelect";

// import { RESOURCES } from "./constants";
// import { paymentService } from '../../services/payment';
// import { readableNumber } from '../../utils/text'
import { paymentService } from '../../services/payment';

// for current LTD user trying to increase limits, show them message to upgrade their account.
const SubscriptionLimitLTD = ({ history, match, currentUser, limits, closeModal, setSuccess, fetchCurrentUser}) => {
  
  const [checkingOut, setCheckingOut] = useState(false);

  const convert = () => {
    setCheckingOut(true);
    
    paymentService.initiateLtdConvertCheckout(
      {},
      (response) => {
        if (response.data.redirect_url){
          window.location.href = response.data.redirect_url
        } else {
          alert("Missing stripe redirect url")
        }
      }, (error, response) => {
        setCheckingOut(null);
        alert(response || "Couldn't convert LTD account, contact support")
      });
  }

  return (
    <div>
      <Helmet><title>Account | Subscription | Limit | LTD</title></Helmet>

      <div>
        Additional limits are available for purchase on a monthly subscription. LTD holders can increase limits by adding a billing method to their account.
      </div>
      <div className="mt-2">
         You will NOT need to pay anything else for your existing base Starter/Pro plan. Additional costs are only for limits you'd like to increase beyond the plan defaults.
      </div>
      <div className="mt-2">
         You will NOT be automatically charged after adding a billing method. Please add a billing method and then return to this screen to select a limit pack.
      </div>

      <button className={`mt-4 items-center cursor-pointer font-bold justify-center w-full p-2 rounded-md hover:opacity-90 border text-white bg-green-500 border-transparent`}
        onClick={convert}
        disabled={checkingOut}
      >
        {checkingOut ? (
          <div>Redirecting...</div>
        ) : 'Add payment method'}
      </button>
    </div>
  );
};

export default SubscriptionLimitLTD;

