// reducers.js
import { combineReducers } from 'redux';

import types from './types';

const INITIAL_STATE = {
  fetching: true,
  total: null,
  used: null,
}

const trialReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {

    case types.FETCH_TRIAL_COUNTS: {
      return {
        ...state,
        fetching: true,
      }
    }

    case types.FETCH_TRIAL_COUNTS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        total: data.total,  // only in case of credit trial
        used: data.used,  // only in case of credit trial
        fetching: false,
      }
    }

    case types.FETCH_TRIAL_COUNTS_FAIL: {
      const { error } = action;
      return {
        ...state,
        fetching: false,
      }
    }

    default: return state;
  }
}

export default trialReducer;