// operations.js
// import fetch from 'cross-fetch';
import types from './types.js';
import axios from 'axios';

import { getRequest, BASE_URL } from '../../utils/axios';
import { savedSearchesService } from "../../services/savedSearches";

// fetches all saved searches once upon app load (both tracked & manual)
// put into redux state for tracked keywords and saved lists
const fetchAllSavedSearches = (callback, onError) => {
  return dispatch => {
    dispatch({type: types.FETCH_SAVED_SEARCHES});
    return savedSearchesService.getSavedSearches((response) => {
      dispatch({type: types.FETCH_SAVED_SEARCHES_SUCCESS, data: response.data});
      if (callback){
        callback(response.data)
      }
    }, (error, response) => {
      dispatch({type: types.FETCH_SAVED_SEARCHES_FAIL});
      if (onError){
        onError()
      }
    })
  }
}

// based on filter query params, refetch saved searches and update in our store
// this method is used with {sync: true} param to get latest unread counts for a set of them
const updateSavedSearches = (params, callback, onError) => {
  return dispatch => {
    dispatch({type: types.UPDATE_SAVED_SEARCHES, 'hashes': params['hash__in'] || []});
    return savedSearchesService.filterSavedSearches(params, (response) => {
      dispatch({type: types.UPDATE_SAVED_SEARCHES_SUCCESS, data: response.data});
      if (callback){
        callback(response.data)
      }
    }, (error, response) => {
      dispatch({type: types.UPDATE_SAVED_SEARCHES_FAIL});
      if (onError){
        onError()
      }
    })
  }
}

const clearSavedSearchUnread = (hash) => {
  // user just looked at a saved search and therefore clear the count
  return dispatch => {
    dispatch({type: types.CLEAR_UNREAD_COUNT, hash: hash});
  }
}


// refetches all (with a resync), but does so with a pagination limit
// if calling first time, pass limit (and url is blank)
// gets called recursively with next url until there is no more next url
const getUnreadCountsPaginated = (limit, url, onSuccess, onError) => {
  return dispatch => {
    console.log('getUnreadCountsPaginated')
    // // get the url we're calling (either first time or a page)
    // var urlToCall = url
    // if (urlToCall){
    //   // next page is returned as full url. we just need the path
    //   const replacedBaseUrl = BASE_URL.replace('https://', 'http://'); // HACK! DRF returns next as https. this hack is temp, figure out proper networking settings 
    //   urlToCall = urlToCall.replace(replacedBaseUrl, "");
    // } else {
    //   dispatch({type: types.FETCH_UNREAD_COUNTS_INIT}); // clear everything to start
    //   urlToCall =  `/api/v1/saved-searches/?search_type__in=RS,RC&sync=1&page_size=${limit}`; // welcome, first time around
    // }

    // dispatch({type: types.FETCH_UNREAD_COUNTS});

    // // go make the actual call
    // return getRequest(
    //   urlToCall,
    //   (response) => {
    //     // construct the unread counts here based on the serialized saved searches
    //     var unreadCounts = {}
    //     response.data.results.forEach((r) => {
    //       unreadCounts[r.hash] = r.count_unread;
    //     })
    //     dispatch({type: types.FETCH_UNREAD_COUNTS_SUCCESS, data: unreadCounts});

    //     // get next page if it exists
    //     if (response.data.next){
    //       setTimeout(() => {
    //         dispatch(getUnreadCountsPaginated(null, response.data.next))
    //       }, 1000);  // artificial timeout just to not clobber the server
    //     } else {
    //       dispatch({type: types.FETCH_UNREAD_COUNTS_DONE}); // finish all recursive calls
    //       if (onSuccess){
    //         onSuccess()
    //       }
    //     }
    //   },
    //   (error, response) => {
    //     console.log("fail", error, response)
    //     if (onError){
    //       return onError(error, response)
    //     }
    //   }
    // )
  }
}



export default {
  fetchAllSavedSearches,
  updateSavedSearches,
  clearSavedSearchUnread,
}