import React, { useState, useEffect } from "react";
import {
  Link, Switch, Route
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

import NestedResults from "../reddit/nestedresults";
import ErrorBar from "../common/errorBar";

import { redditService } from '../../services/reddit';
import { savedSearchesService } from '../../services/savedSearches';
import { savedSearchOperations } from "../../redux/saved"

import { trackEvent } from '../../utils/tracking';
import { getConversationCsvDict } from '../../utils/reddit';


const SavedConversationsAll = ({
  history,
  location,
  savedSearch,
  currentUser,
  // clearSavedSearchUnread,
  baseUrl, // needed b/c this gets shown on multiple potential screens
  results,
  fetching,
  fetchConversations,
}) => {

  const sortOptions = ['Recent', 'Upvotes', 'Comments']
  const [sortOption, setSortOption] = useState(sortOptions[0]);

  // sort based on user-input
  var sortedResults = results;
  if (sortOption === "Upvotes"){
    sortedResults = sortedResults.sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0));
  } else if (sortOption === "Comments"){
    sortedResults = sortedResults.sort((a,b) => (a.num_comments < b.num_comments) ? 1 : ((b.num_comments < a.num_comments) ? -1 : 0));
  } else if (!sortOption || sortOption === "Recent"){
    // should be default
    sortedResults = sortedResults.sort((a,b) => (a.timestamp_utc < b.timestamp_utc) ? 1 : ((b.timestamp_utc < a.timestamp_utc) ? -1 : 0));
  }

  return (
    <React.Fragment>
      <Helmet><title>Conversations | Saved | All</title></Helmet>
      <NestedResults fetching={fetching}
        results={sortedResults} keyword={null}
        baseUrl={baseUrl + 'all/'} location={location} history={history}
        currentUser={currentUser}

        showHeader={true}
        sortOptions={sortOptions} sortOption={sortOption} setSortOption={setSortOption}
      />
    </React.Fragment>
  );
};

export default SavedConversationsAll;