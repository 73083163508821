import React from "react";
import { connect } from 'react-redux';

import FeaturePaywall from "./featurePaywall";
import { FEATURES } from '../common/constants.js';


// Determines if we should show a paywall, or the feature
const FeaturePaywallWrapper = ({
  hasFeature,
  featureKey,
  className,
  currentUser,
  // helmetTitle, // get from feature constant
  children
}) => {

  const feature = FEATURES[featureKey];
  if (!feature){
    return 'Invalid...';  // if invalid key, don't show anything
  }
 
  // show paywall based on hasFeature key (master override), otherwise if it's not passed in, get the user config key
  const dontShowPaywall = hasFeature !== undefined ? hasFeature : currentUser.features[feature.configKey];

  return (
    <React.Fragment>
      {dontShowPaywall ? (
        <React.Fragment>{ children }</React.Fragment>
      ) : (
        <FeaturePaywall featureKey={featureKey} className={className} currentUser={currentUser} />
      )}
    </React.Fragment>
  );
}


const mapStateToProps = state => {
  // const {
  //   currentUser
  // } = state.user; // need to make this

  return {
    // currentUser
  }
};

const mapDispatchToProps = (dispatch) => {
  // const fetchTrial = (callback) => {
  //   dispatch(trialOperations.fetchTrial(callback))
  // };
  return {
    // fetchTrial,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeaturePaywallWrapper);
