import React, { useState, useEffect, useRef } from "react";
import {
  Link, useHistory
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Popover } from 'react-tiny-popover'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import SubredditTags from "../reddit/subreddittags";
import FeaturePaywallWrapper from "../account/featurePaywallWrapper"
import SelectPopover from "../common/selectPopover";

import { redditService } from '../../services/reddit';
import { velocityToString, capitalizeFirst } from '../../utils/text';
import { dayOfWeekMap, fullDayOfWeekMap } from '../../utils/time';
import { trackEvent } from '../../utils/tracking';
import { toQueryString } from '../../utils/urls';
import { median, stDev, toHistogram, average, range } from '../../utils/numbers';



const SubredditReach = ({ subreddit, currentUser, hasPro }) => {
  const [loading, setLoading] = useState(false);
  const [reach, setReach] = useState({});
  const MAX_COLUMNS_TO_SHOW = 40;
  const [showFlairCount, setShowFlairCount] = useState(4);
  const [showKeywordCount, setShowKeywordCount] = useState(4);
  const METRICS = [
    {'label': 'Upvote', 'key': 'score', 'labelPlural': 'Upvotes'},
    {'label': 'Comment', 'key': 'num_comments', 'labelPlural': 'Comments'}
  ]
  const [metric, setMetric] = useState(METRICS[0]);
  const toPlot = 'average'; // median or average?
  
  
  useEffect(() => {
    if (!hasPro){
      return
    };

    setLoading(true)
    redditService.fetchSubredditReach(subreddit.name, (response) => {
      setReach(response.data)
      setLoading(false)
    }, (error, response) => {
      console.error(response)
      setLoading(false)
    });
  }, []);


  // just quick return the paywall here b/c we do too much math below which requires a protected API response
  if (!hasPro){
    return (
      <FeaturePaywallWrapper featureKey={"subreddit-performance"} className={'max-w-full py-0'} currentUser={currentUser} />
    )
  }

  if (loading || !reach || !reach.results){
    return (
      <div className="flex items-center text-center h-96 justify-center">
        <svg className="animate-spin h-6 block mx-auto w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    )
  }

  // get the stats
  const scores = reach && reach.results ? reach.results.map(r => r[metric.key]) : [];
  const overallAverage = parseInt(average(scores));
  const overallMedian = Math.max(median(scores), 1);
  const overallMax = Math.max(...scores);
  const stats = [
    { label: 'Median', value: overallMedian },
    { label: 'Average', value: overallAverage },
    { label: 'Max', value: overallMax },
  ]
  const getImpact = (scores) => {
    // returns impact based on overall scores, and what we're plotting (median or average)
    if (toPlot === "median"){
      const medianScore = scores.length ? parseInt(median(scores)) : 0;
      return parseInt(medianScore * 100 / overallMedian) - 100
    } else if (toPlot === "average"){
      const averageScore = scores.length ? parseInt(average(scores)) : 0
      return parseInt(averageScore * 100 / overallAverage) - 100
    }
    return -1
  }


  // get histogram and stats of the target matric
  const getDisplayHistogram = (scores, label) => {
    if (!scores) return [];

    // util to make a histogram based on scores and label (comment/upvote)
    const maxUpvotes = Math.max(...scores);
    const minUpvotes = Math.min(...scores);
    // const minUpvotes = 0; // todo: min of scores or 0
    var interval = Math.abs(parseInt((Math.max(minUpvotes + 1, 0) + stDev(scores)) / MAX_COLUMNS_TO_SHOW));
    if (interval > median(scores)){
      interval = parseInt(median(scores)) // adjust if there's a huge outlier
    }
    const scoresHistogram = toHistogram(scores, MAX_COLUMNS_TO_SHOW, interval);
    return scoresHistogram.map((c) => {
      return {
        'label': c.isLast ? `${c.min}+ ${label}s` : (c.min === c.max - 1) ? `${c.min} ${label}${c.min === 1 ? '' : 's'}` : `${c.min} to ${c.max - 1} ${label}${(c.max - 1) === 1 ? '' : 's'}`,
        'min': c.min,
        'max': c.max,
        'count': scores.filter(r => r >= c.min && r < c.max).length,
      }
    });
  }
  const histogram = getDisplayHistogram(scores, metric.label.replace('s', ''));
    
  
  

  // get the various options for post types, flairs, keywords

  // days of week options
  var daysOfWeek = range(0, 6).map(i => {
    const filteredResults = reach.results.filter(r => new Date(r.timestamp_utc * 1000).getDay() === i);
    const scores = filteredResults.map(r => r[metric.key]);
    const medianScore = scores.length ? parseInt(median(scores)) : 0;
    return {
      key: i,
      label: dayOfWeekMap(i),
      count: scores.length,
      average: scores.length ? parseInt(average(scores)) : 0,
      median: medianScore,
      impact: getImpact(scores), // percentage increase for median score 
    }
  });
  var maxImpact = Math.max(...daysOfWeek.map(i => i.impact));
  daysOfWeek = daysOfWeek.map(i => Object.assign(i, {
    'best': i.impact === maxImpact && i.impact > 0, // marks which one is the best
  }))

  // hour of day
  const numHourBuckets = 8; // bucket into 3, so for example 12am - 3am is a bucket
  const hourBucketSize = 24 / numHourBuckets
  var hoursOfDay = range(0, numHourBuckets - 1).map(i => {
    const filteredResults = reach.results.filter(r => parseInt(new Date(r.timestamp_utc * 1000).getHours() / hourBucketSize) === i);
    const scores = filteredResults.map(r => r[metric.key]);
    const startHour = i * hourBucketSize;
    var endHour = startHour + hourBucketSize;
    if (endHour === 24){ endHour = 0};
    const startHourLabel = startHour == 0 ? `12am` : startHour == 12 ? `12pm` : startHour > 12 ? `${startHour - 12}pm` : `${startHour}am`;
    const endHourLabel = endHour == 0 ? `12am` : endHour == 12 ? `12pm` : endHour > 12 ? `${endHour - 12}pm` : `${endHour}am`;
    
    const medianScore = scores.length ? parseInt(median(scores)) : 0;
    return {
      key: i,
      label: `${startHourLabel} - ${endHourLabel}`,
      labelShort: `${startHourLabel}`,
      count: scores.length,
      average: scores.length ? parseInt(average(scores)) : 0,
      median: medianScore,
      impact: getImpact(scores), // percentage increase for median score 
    }
  });
  var maxImpact = Math.max(...hoursOfDay.map(i => i.impact));
  hoursOfDay = hoursOfDay.map(i => Object.assign(i, {
    'best': i.impact === maxImpact, // marks which one is the best
  }));

  // type of day (weekday/weekend)
  var typeOfDay = [
    {'key': 'weekday', 'label': 'Weekday', days: [1,2,3,4,5]},
    {'key': 'weekend', 'label': 'Weekend', days: [6,7]},
  ].map(i => {
    const filteredResults = reach.results.filter(r => i.days.includes(new Date(r.timestamp_utc * 1000).getDay()));
    const scores = filteredResults.map(r => r[metric.key]);
    const medianScore = scores.length ? parseInt(median(scores)) : 0;
    return Object.assign(i, {
      count: scores.length,
      average: scores.length ? parseInt(average(scores)) : 0,
      median: medianScore,
      impact: getImpact(scores), // percentage increase for median score 
    })
  });
  var maxImpact = Math.max(...typeOfDay.map(i => i.impact));
  typeOfDay = typeOfDay.map(i => Object.assign(i, {
    'best': i.impact === maxImpact, // marks which one is the best
  }));

  // flair options
  var flairOptions = reach.flair.map(f => {
    const filteredResults = reach.results.filter(r => r.flair_label === f);
    const scores = filteredResults.map(r => r[metric.key]);
    const medianScore = scores.length ? parseInt(median(scores)) : 0;
    return {
      key: f,
      label: f,
      count: scores.length,
      average: scores.length ? parseInt(average(scores)) : 0,
      median: medianScore,
      impact: getImpact(scores), // percentage increase for median score 
    };
  });
  var maxImpact = Math.max(...flairOptions.map(i => i.impact));
  var maxCount = Math.max(...flairOptions.map(i => i.count));
  flairOptions = flairOptions.map(i => Object.assign(i, {
    'best': i.impact === maxImpact && i.impact > 0, // marks which one is the best
    'percent': Math.abs(100 * i.count / maxCount),
  }))
  .sort((a,b) => (a.impact < b.impact) ? 1 : ((b.impact < a.impact) ? -1 : 0));

  // keyword options
  var keywordOptions = reach.keywords.map(f => {
    const filteredResults = reach.results.filter(r => r.keywords.includes(f));
    const scores = filteredResults.map(r => r[metric.key]);
    const medianScore = scores.length ? parseInt(median(scores)) : 0;
    return {
      key: f,
      label: f,
      count: scores.length,
      average: scores.length ? parseInt(average(scores)) : 0,
      median: medianScore,
      impact: getImpact(scores), // percentage increase for median score 
    };
  });
  var maxImpact = Math.max(...keywordOptions.map(i => i.impact));
  var maxCount = Math.max(...keywordOptions.map(i => i.count));
  keywordOptions = keywordOptions.map(i => Object.assign(i, {
    'best': i.impact === maxImpact && i.impact > 0, // marks which one is the best
    'percent': Math.abs(100 * i.count / maxCount),
  }))
  .sort((a,b) => (a.impact < b.impact) ? 1 : ((b.impact < a.impact) ? -1 : 0));

  // post type options
  var typeOptions = reach.types.map(f => {
    const filteredResults = reach.results.filter(r => r.type === f);
    const scores = filteredResults.map(r => r[metric.key]);
    const medianScore = scores.length ? parseInt(median(scores)) : 0;
    return {
      key: f,
      label: f,
      count: scores.length,
      average: scores.length ? parseInt(average(scores)) : 0,
      median: medianScore,
      impact: getImpact(scores), // percentage increase for median score 
    };
  });
  var maxImpact = Math.max(...typeOptions.map(i => i.impact));
  var maxCount = Math.max(...typeOptions.map(i => i.count));
  typeOptions = typeOptions.map(i => Object.assign(i, {
    'best': i.impact === maxImpact && i.impact > 0, // marks which one is the best
    'percent': Math.abs(100 * i.count / maxCount),
  })).sort((a,b) => (a.impact < b.impact) ? 1 : ((b.impact < a.impact) ? -1 : 0));

  // post length options
  var lengthOptions = reach.lengths.map(l => {
    const filteredResults = reach.results.filter(r => r.type === "text" && r.length === l.key);
    const scores = filteredResults.map(r => r[metric.key]);
    const medianScore = scores.length ? parseInt(median(scores)) : 0;
    const getImpactOfText = (scores) => {
      // Impact function, but only based off of median/average score of the text posts
      const textPostScores = reach.results.filter(r => r.type === "text").map(r => r[metric.key])
      const overallAverage = parseInt(average(textPostScores));
      const overallMedian = Math.max(median(textPostScores), 1);
      if (toPlot === "median"){
        const medianScore = scores.length ? parseInt(median(scores)) : 0;
        return parseInt(medianScore * 100 / overallMedian) - 100
      } else if (toPlot === "average"){
        const averageScore = scores.length ? parseInt(average(scores)) : 0
        return parseInt(averageScore * 100 / overallAverage) - 100
      }
      return -1
    }
    return {
      key: l.key,
      label: l.label,
      value: l.value,
      count: scores.length,
      average: scores.length ? parseInt(average(scores)) : 0,
      median: medianScore,
      impact: scores.length ? getImpactOfText(scores) : 0, // percentage increase for median score 
    };
  });
  const maxLengthImpact = Math.max(...lengthOptions.map(i => i.impact));
  var maxCount = Math.max(...lengthOptions.map(i => i.count));
  lengthOptions = lengthOptions.map(i => Object.assign(i, {
    'best': i.impact === maxLengthImpact && i.impact > 0, // marks which one is the best
    'percent': Math.abs(100 * i.count / maxCount),
  })).sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));


  // calculate the insights based on everything else we've learned here
  var insights = [];
  const bestDayOfWeek = daysOfWeek.filter(d => d.best)[0];
  if (bestDayOfWeek && bestDayOfWeek.impact > 0){
    insights.push({
      'type': 'day-of-week',
      'key': `day-of-week-${bestDayOfWeek.key}`,
      'impact': bestDayOfWeek.impact,
      'item': bestDayOfWeek,
      'title': `<b>${fullDayOfWeekMap(bestDayOfWeek.key)}</b> is the best day to post`,
      'subtitle': `Can increase ${metric.label.toLowerCase()}s by up to <b>${bestDayOfWeek.impact}%</b>`,
    })
  }
  const bestHourOfDay = hoursOfDay.filter(d => d.best)[0];
  if (bestHourOfDay && bestHourOfDay.impact > 0){
    insights.push({
      'type': 'time-of-day',
      'key': `time-of-day-${bestHourOfDay.key}`,
      'impact': bestHourOfDay.impact,
      'item': bestHourOfDay,
      'title': `<b>${bestHourOfDay.label}</b> is best time to post`,
      'subtitle': `Can increase ${metric.label.toLowerCase()}s by up to <b>${bestHourOfDay.impact}%</b>`,
    })
  }
  const bestTypeOfDay = typeOfDay.filter(d => d.best)[0];
  if (bestTypeOfDay && bestTypeOfDay.impact > 0){
    insights.push({
      'type': 'type-of-day',
      'key': `type-of-day-${bestTypeOfDay.key}`,
      'impact': bestTypeOfDay.impact,
      'item': bestTypeOfDay,
      'title': `<b>${bestTypeOfDay.label}</b> posts perform best`,
      'subtitle': `Can increase ${metric.label.toLowerCase()}s by up to <b>${bestTypeOfDay.impact}%</b>`,
    })
  }
  const bestPostType = typeOptions.filter(d => d.best)[0];
  if (bestPostType && bestPostType.impact > 0){
    insights.push({
      'type': 'post-type',
      'key': `post-type-${bestPostType.key}`,
      'impact': bestPostType.impact,
      'item': bestPostType,
      'title': `<b>${capitalizeFirst(bestPostType.label)}</b> posts perform best`,
      'subtitle': `Can increase ${metric.label.toLowerCase()}s by up to <b>${bestPostType.impact}%</b>`,
    })
  }
  const bestFlair = flairOptions.filter(d => d.best)[0];
  if (bestFlair && bestFlair.impact > 0){
    insights.push({
      'type': 'flair',
      'key': `flair-${bestFlair.key}`,
      'impact': bestFlair.impact,
      'item': bestFlair,
      'title': `Posts with <b>${capitalizeFirst(bestFlair.label)}</b> flair perform best`,
      'subtitle': `Can increase ${metric.label.toLowerCase()}s by up to <b>${bestFlair.impact}%</b>`,
    })
  }
  const bestKeyword = keywordOptions.filter(d => d.best)[0];
  if (bestKeyword && bestKeyword.impact > 0){
    insights.push({
      'type': 'keyword',
      'key': `keyword-${bestKeyword.key}`,
      'impact': bestKeyword.impact,
      'item': bestKeyword,
      'title': `Posts with <b>${capitalizeFirst(bestKeyword.label)}</b> in the title perform best`,
      'subtitle': `Can increase ${metric.label.toLowerCase()}s by up to <b>${bestKeyword.impact}%</b>`,
    })
  }
  const bestLength = lengthOptions.filter(d => d.best)[0];
  if (bestLength && bestLength.impact > 0){
    insights.push({
      'type': 'length',
      'key': `length-${bestLength.key}`,
      'impact': bestLength.impact,
      'item': bestLength,
      'title': `Text posts with <b>${capitalizeFirst(bestLength.label)}</b> words perform best`,
      'subtitle': `Can increase ${metric.label.toLowerCase()}s by up to <b>${bestLength.impact}%</b>`,
    })
  }
  insights = insights
    .filter(i => i.impact > 30 && (!i.item || i.item.count >= 2))
    .sort((a,b) => (a.impact < b.impact) ? 1 : ((b.impact < a.impact) ? -1 : 0))
    .map(i => Object.assign(i, {
      'subtitle': `${metric.label}s typically higher by <b>${i.impact}%</b>`
    }));


  return (
    <FeaturePaywallWrapper featureKey={"subreddit-performance"} className={'max-w-full py-0'} currentUser={currentUser}>
      <div id="subreddit-reach" className="">

        <div className="sm:flex sm:space-x-4">

          <div className="flex-1">
            <h3 className="mb-2 flex">
              <div className="text-xs font-semibold text-gray-400 uppercase tracking-wider">
                Posting Insights to maximize
              </div>
              <div className="ml-1">
                <SelectPopover
                  options={METRICS}
                  currentOption={metric}
                  setCurrentOption={(s) => setMetric(s)}
                  labelField={'labelPlural'}
                  displayClassName={'text-xs font-semibold text-gray-400 uppercase tracking-wider'}
                />
              </div>
            </h3>
            <div>
              {insights.map(insight => (
                <div key={insight.key} className="text-sm flex items-center mb-2">
                  <div className="text-green-500 mr-4 flex items-center font-semibold w-14 text-center justify-center">
                    <svg className="h-3 w-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                    </svg>
                    <div>{insight.impact}%</div>
                  </div>

                 {/* Replace with lightbulb */}
                  {/*<svg className="h-3 w-4 mr-2 text-green-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                  </svg>*/}
                  <div className="flex-grow">
                    <div dangerouslySetInnerHTML={{__html: insight.title}} />
                    {/*<div className="ml-auto text-xs text-gray-400">{insight.item.median} {metric.label}s each across {insight.item.count} post{insight.item.count === 1 ? '' : 's'}</div>*/}
                    <div className="ml-auto text-xs text-gray-400">{insight.item.count} post{insight.item.count === 1 ? '' : 's'} with {toPlot} of {insight.item[toPlot]} {metric.label.toLowerCase()}s </div>
                    {/*<div className="mt-0 text-xs text-gray-400" dangerouslySetInnerHTML={{__html: insight.subtitle}} />*/}
                  </div>
                </div>
              ))}

              {insights.length === 0 ? (
                <div className="text-xs opacity-50">No significant insights to maximize {metric.labelPlural.toLowerCase()}</div>
              ) : ''}
            </div>
          </div>

          <div className="flex-1">
            <div className="mb-4">
              <div className="flex items-center">
                <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                  {metric.label} Stats
                </h3>
                {/*<ReactTooltip id={`influencers-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                <span className="cursor-pointer ml-2" data-for='influencers-info' data-tip="Biggest contributors to this subreddit in the past month">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>*/}
              </div>
              <div>
                <div className="grid grid-cols-3 divide-x p-1 divide-gray-500">
                  {stats.map((stat) => (
                    <div key={stat.label} className="px-2 py-0 text-center text-sm font-medium">
                      <div className="text-gray-200">{stat.value}</div>
                      <div className="text-gray-400 text-xs">{stat.label}<span className="hidden sm:inline-not"> {metric.label}s</span></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>


            {/*<dt className="flex items-center mb-2 flex-wrap">
              <div className="flex items-center">
                <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                  {metric.label}s Distribution
                </h3>
              </div>
            </dt>*/}
            
            <div className="mb-4">
              <div className="h-24 text-black relative">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    margin={0}
                    data={histogram}
                  >
                    {/*<XAxis dataKey="min" tick={{ fontSize: 10 }} />*/}
                    <Tooltip content={({ active, payload, label }) => {
                      if (active && payload && payload.length) {
                        const bin = payload[0]
                        const data = bin.payload
                        return (
                          <div className="p-2 bg-gray-800 text-sm text-white rounded-md">
                            <div className="font-semibold">{data.label}</div>
                            <div>{data.count}/{reach.results.length} post{data.count === 1 ? '' : 's'}</div>
                          </div>
                        );
                      }
                      return null
                    }} cursor={{ stroke: "rgb(75 85 99)", strokeWidth: 1, fill: "rgb(31 41 55)" }} />
                    
                    <Bar dataKey={"count"} stackId="a" fill={"#374251"}>
                      {histogram.map((entry, index) => (
                        <Cell key={index} stroke={entry.inFilters ? '#35d399' : '' }/>
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                <div className="text-white flex items-center text-xs border-t border-gray-700 pb-4">
                  <div className="text-gray-500">{histogram[0].min} {metric.label}{histogram[0].min === 1 ? '' : 's'}</div>
                  <div className="text-gray-500 ml-auto">
                    {histogram[histogram.length - 1].label}
                  </div>
                </div>
              </div>
            </div>

            {/*<div className="mt-2 text-sm">
              <hr className="my-4 opacity-25 h-1" />
              <div className="text-center text-xs text-gray-400">This (beta) {metric.label} report is based on {reach.percent_of_week !== 100 ? `${reach.percent_of_week}% of` : ''} past week's posts in r/{subreddit.name}</div>
            </div>*/}
          </div>
        </div>


        <div>

          <div className="sm:flex sm:space-x-4 mt-4">
            <div className="flex-1 relative">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide mb-1">
                Keyword in title impact
              </h3>
              {keywordOptions.length > 1 ? (
                <div>
                  {keywordOptions.filter((t, i) => i < showKeywordCount).map(t => (
                    <div key={t.key} className="flex items-center mb-1 text-xs">
                      <div className="flex-grow bg-transparent overflow-hidden rounded-sm relative flex-shrink-0 h-7">
                        
                        <div className="absolute bg-gray-700 rounded-sm p-1 h-full" style={{width: (t.percent) + '%'}}></div>
                        <div className="absolute p-1 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                          {/*<div className="mr-1 opacity-50 text-xs w-4 text-center flex-shrink-0">{t.count}</div>*/}
                          <div className="">{t.label}</div>
                          <div className="ml-1 opacity-50 text-xs text-center flex-shrink-0">{t.count}</div>
                        </div>
                      </div>
                      <div className={`absolute flex items-center right-0 rounded-md text-right ${t.impact > 0 ? 'text-green-500' : t.impact < 0 ? 'text-red-500' : 'text-gray-300'}`}>
                        {t.impact}%
                        {t.impact >= 0 ? (
                          <svg className={`h-3 w-4 mr-2 flex-shrink-0 ${t.impact === 0 ? 'text-gray-500' : 'text-green-500'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                          </svg>
                        ) : (
                          <svg className="h-3 w-4 mr-2 text-red-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                          </svg>
                        )}
                      </div>
                    </div>
                  ))}
                  {keywordOptions.length > showKeywordCount ? (
                    <div className="py-1 mb-2 text-xs text-gray-400 hover:text-gray-200 rounded-sm flex space-x-1 cursor-pointer"
                      onClick={() => setShowKeywordCount(keywordOptions.length)}
                    >
                      <span>+ show more</span>
                    </div>
                  ) : ''} 
                </div>
              ) : (
                <div className="text-xs opacity-50">No data on popular keywords</div>
              )}
            </div>

            <div className="flex-1 relative mt-4 sm:mt-0">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide mb-1">
                Flair impact
              </h3>
              {flairOptions.length >= 1 ? (
                <div>
                  {flairOptions.filter((f, i) => i < showFlairCount).map(f => (
                    <div key={f.key} className="flex items-center mb-1 text-xs">
                      <div className="flex-grow bg-transparent overflow-hidden rounded-sm relative flex-shrink-0 h-7">
                        
                        <div className="absolute bg-gray-700 rounded-sm p-1 h-full" style={{width: (f.percent) + '%'}}></div>
                        <div className="absolute p-1 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                          {/*<div className="mr-1 opacity-50 text-xs w-5 text-center flex-shrink-0">{f.count}</div>*/}
                          <div className="flex-grow-0">{f.label || '(No Flair)'}</div>
                          <div className="ml-1 opacity-50 text-xs text-center flex-shrink-0">{f.count}</div>
                        </div>
                      </div>
                      <div className={`absolute flex items-center right-0 rounded-md text-right ${f.impact > 0 ? 'text-green-500' : f.impact < 0 ? 'text-red-500' : 'text-gray-300'}`}>
                        {f.impact}%
                        {f.impact >= 0 ? (
                          <svg className={`h-3 w-4 mr-2 flex-shrink-0 ${f.impact === 0 ? 'text-gray-500' : 'text-green-500'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                          </svg>
                        ) : (
                          <svg className="h-3 w-4 mr-2 text-red-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                          </svg>
                        )}
                      </div>
                    </div>
                  ))}
                  {flairOptions.length > showFlairCount ? (
                    <div className="py-1 mb-2 text-xs text-gray-400 hover:text-gray-200 rounded-sm flex space-x-1 cursor-pointer"
                      onClick={() => setShowFlairCount(flairOptions.length)}
                    >
                      <span>+ show more</span>
                    </div>
                  ) : ''} 
                </div>
              ) : flairOptions.length === 1 ? (
                <div className="text-xs opacity-50">This community only has 1 flair option: {flairOptions[0].label}</div>
              ) : (
                <div className="text-xs opacity-50">This community does not use flair</div>
              )}
            </div>
          </div>

          <div className="sm:flex sm:space-x-4 mt-4">
            <div className="flex-1 relative">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide mb-1">
                Post Type Impact
              </h3>
              {typeOptions.length >= 1 ? (
                <div>
                  {typeOptions.map(t => (
                    <div key={t.key} className="flex items-center mb-1 text-xs">
                      <div className="flex-grow bg-transparent overflow-hidden rounded-sm relative flex-shrink-0 h-7">
                        
                        <div className="absolute bg-gray-700 rounded-sm p-1 h-full" style={{width: (t.percent) + '%'}}></div>
                        <div className="absolute p-1 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                          {/*<div className="mr-1 opacity-50 text-xs w-4 text-center flex-shrink-0">{t.count}</div>*/}
                          <div className="">{capitalizeFirst(t.label)} post</div>
                          <div className="ml-1 opacity-50 text-xs text-center flex-shrink-0">{t.count}</div>
                        </div>
                      </div>
                      <div className={`absolute flex items-center right-0 rounded-md text-right ${t.impact > 0 ? 'text-green-500' : t.impact < 0 ? 'text-red-500' : 'text-gray-300'}`}>
                        {t.impact}%
                        {t.impact >= 0 ? (
                          <svg className={`h-3 w-4 mr-2 flex-shrink-0 ${t.impact === 0 ? 'text-gray-500' : 'text-green-500'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                          </svg>
                        ) : (
                          <svg className="h-3 w-4 mr-2 text-red-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                          </svg>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : typeOptions.length === 1 ? (
                <div className="text-xs opacity-50">This community only allows {typeOptions[0].label} posts</div>
              ) : (
                <div className="text-xs opacity-50">No data on type options</div>
              )}
            </div>

            <div className="flex-1 relative mt-4 sm:mt-0">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide mb-1">
                Text Post Length Impact
              </h3>
              {lengthOptions.length >= 1 ? (
                <div>
                  {lengthOptions.map(f => (
                    <div key={f.key} className="flex items-center mb-1 text-xs">
                      <div className="flex-grow bg-transparent overflow-hidden rounded-sm relative flex-shrink-0 h-7">
                        
                        <div className="absolute bg-gray-700 rounded-sm p-1 h-full" style={{width: (f.percent) + '%'}}></div>
                        <div className="absolute p-1 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                          {/*<div className="mr-1 opacity-50 text-xs w-5 text-center flex-shrink-0">{f.count}</div>*/}
                          <div className="flex-grow-0">{f.label || '(No Length)'}</div>
                          <div className="ml-1 opacity-50 text-xs text-center flex-shrink-0">{f.count}</div>
                        </div>
                      </div>
                      <div className={`absolute flex items-center right-0 rounded-md text-right ${f.impact > 0 ? 'text-green-500' : f.impact < 0 ? 'text-red-500' : 'text-gray-300'}`}>
                        {f.impact}%
                        {f.impact >= 0 ? (
                          <svg className={`h-3 w-4 mr-2 flex-shrink-0 ${f.impact === 0 ? 'text-gray-500' : 'text-green-500'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                          </svg>
                        ) : (
                          <svg className="h-3 w-4 mr-2 text-red-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                          </svg>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-xs opacity-50">No post length insights yet</div>
              )}
            </div>
          </div>

          <div className="sm:flex sm:space-x-8 mt-4 h-36">
            <div className="flex-1 h-28 text-black relative">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide mb-1">
                Day of week
              </h3>
              <ResponsiveContainer width="100%" height="100%" className="block">
                <BarChart
                  margin={0}
                  data={daysOfWeek}
                >
                  <XAxis dataKey="label" tick={{ fontSize: 9 }} />
                  <Tooltip content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      const bin = payload[0]
                      const data = bin.payload
                      return (
                        <div className="p-2 bg-gray-800 text-sm text-white rounded-md">
                          <div className="font-semibold">{data.label}</div>
                          <div>Median: <b>{data.median}</b></div>
                          <div>Average: <b>{data.average}</b></div>
                          <div>{data.count}/{reach.results.length} post{data.count === 1 ? '' : 's'}</div>
                        </div>
                      );
                    }
                    return null
                  }} cursor={{ stroke: "rgb(75 85 99)", strokeWidth: 1, fill: "rgb(31 41 55)" }} />
                  
                  
                  <Bar dataKey={toPlot} stackId="a" fill={"#374251"}>
                    {daysOfWeek.map((entry, index) => (
                      <Cell key={index} stroke={entry.best ? '#35d399' : '' }/>
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="flex-1 h-28 text-black relative mt-4 sm:mt-0">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide mb-1">
                Time of day ({new Date().toString().split('(')[1].split(')')[0]})
              </h3>
              <ResponsiveContainer width="100%" height="100%" className="block">
                <BarChart
                  margin={0}
                  data={hoursOfDay}
                >
                  <XAxis dataKey="labelShort" tick={{ fontSize: 9 }} />
                  <Tooltip content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      const bin = payload[0]
                      const data = bin.payload
                      return (
                        <div className="p-2 bg-gray-800 text-sm text-white rounded-md">
                          <div className="font-semibold">{data.label}</div>
                          <div>Median: <b>{data.median}</b></div>
                          <div>Average: <b>{data.average}</b></div>
                          <div>{data.count}/{reach.results.length} post{data.count === 1 ? '' : 's'}</div>
                        </div>
                      );
                    }
                    return null
                  }} cursor={{ stroke: "rgb(75 85 99)", strokeWidth: 1, fill: "rgb(31 41 55)" }} />
                  
                  <Bar dataKey={toPlot} stackId="a" fill={"#374251"}>
                    {hoursOfDay.map((entry, index) => (
                      <Cell key={index}
                        stroke={entry.best ? '#35d399' : '' }
                        // stroke={entry.impact > 100 ? '#35d399' : entry.impact < 0 ? 'rgb(239,68,68)' : 'rgb(245,158,11)' }
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

        </div>

      </div>
    </FeaturePaywallWrapper>
  );
}

export default SubredditReach;
