// operations.js
// import fetch from 'cross-fetch';
import types from './types.js';
import axios from 'axios';

import { trialService } from "../../services/trial";

const fetchTrial = (callback, onError) => {
  return dispatch => {
    dispatch({type: types.FETCH_TRIAL_COUNTS});
    return trialService.fetchTrial((response) => {
      dispatch({type: types.FETCH_TRIAL_COUNTS_SUCCESS, data: response.data});
      if (callback){
        callback(response.data)
      }
    }, (error, response) => {
      dispatch({type: types.FETCH_TRIAL_COUNTS_FAIL});
      if (onError){
        onError()
      }
    })
  }
}

export default {
  fetchTrial,
}