import React, { useState } from "react"
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { CSVLink } from "react-csv";


import { uiOperations } from "../../redux/ui"

import Cluster from "../language/cluster";
import AnalysisClustersShareModal from "../language/analysisClustersShare";

// analysis for set of conversations
const AnalysisClusters = ({
  analysis,
  reset,
  limitsConfigurable,
  emptyMessage,
  viewOption,
  setUiState, //setCurrentOption={(s) => setUiState('subredditSort', s)}
}) => {
  const clustersAnalysis = analysis.results.all.clusters;
  const conversations = analysis.results_context;
  const [shareOpen, setShareOpen] = useState(false);
  const [sharedReportHash, setSharedReportHash] = useState(analysis.shared_report_hash); // set to local state in case they just shared
  // const viewOptions = ['row', 'preview', 'full'];
  // const [viewOption, setViewOption] = useState(viewOptions[2]);
  
  // for one cluster, get one of the results that we did the analysis on
  const getClusterResults = (c) => {
    return (conversations || []).filter(r => {
      return c.items.map(i => i.item.id).includes(r.id)
    });
  }

  const getPatternsCsvDict = (results) => {
    // get the patterns data for download
    return clustersAnalysis.clusters.map(c => {
      const ids = c.items.map(i => i.item.id)
      return {
        'name': c.name,
        'ids': ids.join("\n"),
        'upvotes': results.filter(r => ids.includes(r.id)).map(r => r.score).reduce((partialSum, a) => partialSum + a, 0),
        'comments': results.filter(r => ids.includes(r.id)).map(r => r.num_comments).reduce((partialSum, a) => partialSum + a, 0),
        'phrases': c.items.map(i => i.phrase.replace(/"/g, '""').replace(/,/g, '\,').replace(/'/g, '\'')).join("\n"),
        'titles': c.items.map(i => {
          const result = results.filter(r => r.id === i.item.id)[0];
          return result && result.title && result.title.replace(/"/g, '""').replace(/,/g, '\,').replace(/'/g, '\'') || '';
        }).join("\n"),
        'urls': c.items.map(i => {
          const result = results.filter(r => r.id === i.item.id)[0];
          return result && result.link && `https://reddit.com${result.link}` || '';
        }).join("\n"),
      }
    });
  }

  return (
    <div id="clusters-analysis">

      {/* actual bulk results */}
      <div className="mb-2">

        <div className="rounded-lg">

          {clustersAnalysis.clusters.length > 0 ? (
            <React.Fragment>
              <div className="flex items-right mb-4 mt-2 w-full space-x-2">
                

                <CSVLink
                  data={getPatternsCsvDict(conversations || [])}
                  // data={getPatteCsvDict(filteredResults && filteredResults.filter(r => !selectedIds.length || selectedIds.includes(r.id)) || [])}
                  filename={"gummysearch-patterns.csv"}
                  className="ml-auto bg-gray-700 flex inline-block px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md opacity-100 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  target="_blank"
                  // onClick={close}
                  // className="cursor-pointer -m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 transition ease-in-out duration-150"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 mr-2 h-5 w-5 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                  </svg>
                  <span>Download</span>
                </CSVLink>

                {reset ? (
                  <button type="button" className="bg-gray-700 flex inline-block px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md opacity-100 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={reset}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 mr-2 h-5 w-5 text-white">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                    </svg>
                    <span>Reset</span>
                  </button>
                ) : ''}

                <button type="button" className="bg-cyan-500 flex inline-block px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md opacity-100 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  onClick={() => setShareOpen(true)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 mr-2 h-5 w-5 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
                  </svg>
                  <span>Share</span>
                </button>
              </div>

              <div className="mb-2 flex items-center">
                <h3 className="flex-1 flex text-sm items-center">
                  <span className="text-gray-300 font-semibold">Pattern{clustersAnalysis.clusters.length !== 1 ? 's' : ''}</span>
                  
                  <p className="ml-2 mt-1 text-xs text-gray-300 truncate font-semibold">
                    <span className="opacity-50">{clustersAnalysis.clusters.length}</span>
                  </p>
                </h3>

                <div className="flex ml-auto text-center space-x-1 font-semibold">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                    className={`w-6 h-4 cursor-pointer ${viewOption === 'row' ? '' : 'opacity-50 hover:opacity-100'}`}
                    onClick={() => setUiState('patternDisplayStyle', 'row')}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                    className={`w-6 h-4 cursor-pointer ${viewOption === 'preview' ? '' : 'opacity-50 hover:opacity-100'}`}
                    onClick={() => setUiState('patternDisplayStyle', 'preview')}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                    className={`w-6 h-4 cursor-pointer ${viewOption === 'full' ? '' : 'opacity-50 hover:opacity-100'}`}
                    onClick={() => setUiState('patternDisplayStyle', 'full')}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                  </svg>

                </div>

              </div>
            </React.Fragment>
          ) : ''}

          <div className="space-y-2">
            {clustersAnalysis.clusters.map((c, i) => (
              <Cluster key={c.name} c={c} isFirst={i === 0} results={getClusterResults(c)}
                getPopoverParentElement={() => document.getElementById(`clusters-analysis`)}
                viewOption={viewOption} 
              />
            ))}
          </div>

          {clustersAnalysis.clusters.length === 0 ? (
            <div className="p-6 bg-gray-800 rounded-lg border border-solid border-gray-700 text-white">
              <div className="">
                <h3 className="text-lg leading-6 font-medium">
                  No common patterns found...
                </h3>
                <div className="mt-2 max-w-xl text-sm text-gray-400 space-y-1">
                  <ReactTooltip id={`ai-limits`} place="top" effect="solid" backgroundColor="white" textColor="black" />
                  <div className="flex items-top">
                    Your <span data-for={`ai-limits`} data-tip={"Go to account subscription settings to see how many more AI minutes you have on your account."}
                      className="border-b border-dashed hover:border-solid border-gray-400 cursor-pointer mx-1"
                    >
                      monthly AI minutes
                      </span> were not used in this case.
                  </div>

                  <div className="flex items-top">
                    For higher chance of patterns, increase count of results to analyze.
                  </div>

                  {emptyMessage ? (
                    <div className="flex items-top">
                      {emptyMessage}
                    </div>
                  ) : ''}

                  {limitsConfigurable && reset ? (
                    <div className="pt-2">
                      <span className="underline cursor-pointer flex items-center" onClick={reset}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-1">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                        </svg>
                        Reset
                      </span>
                    </div>
                  ) : ''}
                  
                </div>
              </div>
            </div>
          ) : ''}
          
        </div>
      </div>

      {shareOpen ? (
        <AnalysisClustersShareModal
          analysis={analysis}
          sharedReportHash={sharedReportHash} setSharedReportHash={setSharedReportHash} // keep local state b/c no refetch after share
          closeModal={() => setShareOpen(false)}
        />
      ) : ''}
    </div>
  );
}

const mapStateToProps = state => {
  const {
    patternDisplayStyle,
  } = state.ui;

  const viewOption = patternDisplayStyle || 'preview';
  return {
    viewOption
  }
};

const mapDispatchToProps = (dispatch) => {
  const setUiState = (key, value) => {
    dispatch(uiOperations.setUiState(key, value))
  };
  return {
    setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisClusters);
