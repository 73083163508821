import React, { useEffect, useState, Fragment } from "react";
import { Dialog, Transition, Menu } from '@headlessui/react'

// to pass props to children
const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}


// right drawer component to show at a particular route
const DrawerDoute = ({ match, history, location, closeUrl, children }) => {
  const [open, setOpen] = useState(false);

  // effects
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 1)
  }, []); // Animate open when we get to this route

  const closeDrawer = () => {
    setOpen(false)
    setTimeout(() => {
      history.push(closeUrl);
    }, 300)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden darkStyle" open={open} onClose={closeDrawer}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-75 transition-opacity cursor-pointer" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 max-w-full flex old-pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-4xl">
                { React.cloneElement(children, { closeDrawer }) } 
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default DrawerDoute;

