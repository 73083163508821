
// features dictionary, used by featurePopover.jsx
const FEATURES = {
  'discover': {
    'title': 'Community Discovery',
    'image': '',
    'description': "Quickly find all Subreddits full of your target audience. There are over 130,000 active subreddits, and we'll help you find the relevant ones. Once you've found a few communities, we'll suggest similar ones."
  },
  'audiences': {
    'title': 'Audiences',
    'image': '',
    'description': "Audiences are collections of Subreddits that you can keep organized, browse, and search within independently. If you are exploring multiple customer types, you can segment your research accordingly. Not sure where to start your research? We have a library of example audiences."
  },
  'example-audiences': {
    'title': 'Example Audiences',
    'image': '',
    'description': "GummySearch allows you to create collections of similar Subreddits to browse together. If you don't have a niche in mind, example audiences are sets of subreddits already organized by customer persona, which you can start browsing with one click of your mouse."
  },
  'subreddit-insights': {
    'title': 'Subreddit Insights',
    'image': '',
    'description': "See how this subreddit stacks up against all others on Reddit regarding size, growth, and engagement. Also, get a high-level overview of the types of conversations that happen within this community."
  },
  'subreddit-suggestions': {
    'title': 'Subreddit Suggestions',
    'image': '',
    'description': "See similar subreddits to ones that you are already tracking, add to your audience with one click for further exploration."
  },
  'hot-top-conversations': {
    'title': 'Hot/Top Conversations',
    'image': '',
    'description': "Explore the popular & trending conversations happening across multiple subreddits in your audience."
  },
  'trending-subreddits': {
    'title': 'Trending Subreddits',
    'image': '',
    'configKey': 'audiences_trending',
    'orderType': 'starter',
    'demo': 'https://gummysearch.com/demos/growing-subreddits/',
    'video': 'https://www.youtube.com/embed/TUDM6gcoC28',
    'gif': 'https://drive.google.com/file/d/1zVt8QyKBzVC4VYJf6ChPQw-Car4zpv9D/preview',
    'description': "See the top subreddits by activity, size, growth, and engagement. Filter to find new breakout communities & niches."
  },
  'suggestions': {
    'title': 'Customer development themes',
    'image': '',
    'configKey': 'audience_suggested',
    'orderType': 'starter',
    'demo': 'https://gummysearch.com/demos/customer-discovery/',
    'video': 'https://www.youtube.com/embed/bxLdW8-WaAE',
    'description': "Within your audience, we surface the following AI-tagged customer development themes: Pain Points, Solution Requests, Advice Requests, New Ideas, Conversations about Money, Optimization Opportunities, Self-Promotion, and News. This enables you to very quickly look at relevant historical conversations to get to know your audience."
  },
  'audience-search': {
    'title': 'Audience Search',
    'image': '',
    'configKey': 'audience_search',
    'demo': '',
    'video': '',
    'description': "Search for any keyword within your audience's Subreddits. Quickly find the context that it is mentioned in, save to access later, or reply to the thread."
  },
  'subreddit-analysis': {
    'title': 'Subreddit Analysis',
    'image': '',
    'description': "Is a subreddit thriving, growing, and full of engaged members? Or is it a dead forum from 2008? We crunch the numbers on member activity and give you the scoop at a high level, so you can focus on the right communities. Additionally, you can see the types & topics of conversation in each Subreddit at a glance."
  },
  'similar-subreddits': {
    'title': 'Similar Subreddits',
    'image': '',
    'description': "Discover new Subreddits that are not on your radar yet, based on the communities you are already tracking and their member activity."
  },
  'saved-conversations': {
    'title': 'Bookmarks',
    'image': '',
    'demo': 'https://gummysearch.com/demos/save-reddit-posts/',
    'video': 'https://www.youtube.com/embed/9kyNWODBdxw',
    'description': "Save useful submissions & comments to lists for reference later. Useful for segmenting common pain points expressed, or making outreach lists."
  },
  'reddit-search': {
    'title': 'Advanced Search for Reddit',
    'image': '',
    'configKey': 'advanced_search',
    'orderType': 'starter',
    'demo': 'https://gummysearch.com/demos/advanced-reddit-search/',
    'video': 'https://www.youtube.com/embed/of1l75X1wys',
    'description': "The most powerful way to search Reddit. Search for any keyword across Reddit, either in all of Reddit or specifically in your audience. Peek into conversations quickly, see aggregate charts, and filter on anything that matters to you."
  },
  'keyword-tracking': {
    'title': 'Keyword Tracking',
    'image': '',
    'configKey': 'tracked_keywords',
    'orderType': 'starter',
    'demo': 'https://gummysearch.com/demos/tracking-keywords-on-reddit/',
    'video': 'https://www.youtube.com/embed/nNTxXbAfLYc',
    'description': "Track keywords across all of Reddit, or within your audience. Quickly see new instances of your tracked keywords, so that you can chime in to the conversation. Get notified of new matches over email. See stats on each of your tracked keywords to ensure you're getting more signal and less noise out of your notifications."
  },
  'notification-channels': {
    'title': 'Notification Channels',
    'image': '',
    'configKey': 'tracked_notification_channels',
    'orderType': 'pro',
    'description': "Send keyword mentions to wherever you do your work. Currently available for Email, Slack, or Discord. Building new integrations all the time."
  },
  'csv-export': {
    'title': 'CSV Export',
    'image': '',
    'description': "Export conversation data to a CSV to use however you'd like."
  },
  'tracked-keyword-stats': {
    'title': 'Tracked Keyword Stats',
    'image': '',
    'description': "See how effective your tracked keywords are. We calculate how often your tracked keywords surface relevant conversations, and which ones you end up ignoring. After tracking keywords for a few days/weeks, you will be able to know which ones provide signal, and which ones are noisy and can be untracked."
  },
  'email-digests': {
    'title': 'Email Digests',
    'image': '',
    'description': "Set up email digests to see new instances of your tracked keywords on the schedule that works for you."
  },
  'subreddit-stats': {
    'title': 'Subreddit Stats',
    'image': '',
    'configKey': 'subreddit_stats',
    'orderType': 'pro',
    'demo': 'https://gummysearch.com/demos/growing-subreddits/',
    'description': "See daily/weekly/monthly growth & activity stats for each subreddit, as well as how these stats stack up against all other communities on Reddit."
  },
  'subreddit-influencers': {
    'title': 'Subreddit Influencers',
    'image': '',
    'configKey': 'subreddit_people',
    'orderType': 'pro',
    'demo': 'https://gummysearch.com/demos/subreddit-info/',
    'description': "See the top contributors in each subreddit, their contributions, and the other communities that they participate in."
  },
  'subreddit-moderation': {
    'title': 'Subreddit Moderation',
    'image': '',
    'configKey': 'subreddit_moderation',
    'orderType': 'pro',
    // 'demo': 'https://gummysearch.com/demos/growing-subreddits/',
    'description': "See the the moderation activity in this subreddit, as well as common reasons for post removal. Submit your own content with confidence!"
  },
  'subreddit-performance': {
    'title': 'Subreddit Performance Report',
    'image': '',
    'configKey': 'subreddit_reach',
    'orderType': 'pro',
    'demo': 'https://gummysearch.com/demos/subreddit-performance/',
    'video': 'https://www.youtube.com/embed/1o8bX5eS2Bg',
    'description': "See the kinds of posts that do well in a given subreddit, so that you can submit your own with confidence that they will perform. Quickly get insights on the best post type, flair, time of day, and day of week which tend to make a big difference in the results."
  },
  'content-moderation-insights': {
    'title': 'Content Insights',
    'image': '',
    'demo': 'https://gummysearch.com/demos/subreddit-performance/',
    'video': '',
    'description': "See the types of content that performs well in each subreddit. Submit your own posts with confidence and collect your upvotes!"
  },
  'sentiment-analysis': {
    'title': 'Sentiment Analysis',
    'image': '',
    'description': "Get even deeper insights on Reddit conversations by seeing emotions & intent of each conversation, and filtering lists of conversations to find just what you're looking for."
  },
  'submission-summary': {
    'title': 'Submission Summary',
    'image': '',
    'configKey': 'analyze_submission',
    'orderType': 'pro',
    // 'demo': 'https://gummysearch.com/demos/analyze-reddit-patterns/',
    // 'video': 'https://www.youtube.com/embed/2Jxs5MsHCtY',
    'description': "Use AI to summarize any Reddit post. Save time and extract meaning quickly."
  },
  'pattern-finder': {
    'title': 'AI Pattern Finder',
    'image': '',
    'configKey': 'analyze',
    'orderType': 'pro',
    'demo': 'https://gummysearch.com/demos/analyze-reddit-patterns/',
    'video': 'https://www.youtube.com/embed/2Jxs5MsHCtY',
    'description': "Use AI to summarize common Reddit trends in seconds. Find patterns within hundreds of posts/comments without having to read them yourself. Uses AI minute credit system, which is how much time you save by not reading & analyzing the Reddit posts yourself."
  },
  'ask': {
    'title': 'AI-based Q&A',
    'image': '',
    'configKey': 'analyze_ask_question',
    'orderType': 'pro',
    'demo': '',
    'video': '',
    'gif': 'https://drive.google.com/file/d/1Df8SeRIgR3FOPZhATXXDnjNgm45ZluE_/preview',
    'description': "Ask any question to your audience, and receive a relevant and recent answer with citations from Reddit. Uses AI minutes system, similar to the AI Patterns feature."
  },
  'products': {
    'title': 'Product Reviews',
    'image': '',
    'configKey': 'audience_products',
    'orderType': 'pro',
    'demo': '',
    'video': '',
    'gif': 'https://drive.google.com/file/d/1kiqeZikm1-OI_fcDd4BIjD3obQk1Z3o3/preview',
    'description': "See the most popular product categories discussed in your audience, and the most popular brands within each category."
  },
}


export {
    FEATURES,
}