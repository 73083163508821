import React, { useState } from "react";
// import {
//   withRouter,
// } from "react-router-dom";
import Modal from "../common/modal";
import FeaturePaywallWrapper from "./featurePaywallWrapper";
import { Helmet } from "react-helmet";


const NotificationsWebhookModal = ({
  closeModal,
  history,
  digestSettings,
  updateSettings,
  currentUser,
  createNotificationChannel,
}) => {
  const currentWebhook = digestSettings && digestSettings.notification_channel && digestSettings.notification_channel.channel_type === 'DI' && digestSettings.notification_channel.params.webhook || '';  
  const [webhook, setWebhook] = useState(currentWebhook);
  const [saving, setSaving] = useState(false);

  const save = (e) => {
    e.preventDefault()

    if (!webhook) return;
    if (saving) return;
    // if (!webhook.includes('http://discord.com/api/webhooks/') && !webhook.includes('https://discordapp.com/api/webhooks/')){
    //   return alert("Invalid discord webhook url")
    // }

    setSaving(true);
    const nickname = `Webhook: ${webhook}`
    createNotificationChannel({'channel_type': 'WE', 'params': {'webhook': webhook}, 'nickname': nickname}, () => {
      setSaving(false);
      closeModal();
    }, () => {
      setSaving(false);
    });
  }
  
  return (
    <Modal close={closeModal} widthClass={'max-w-xl'}>
      <Helmet><title>Account | Notifications | Webhook</title></Helmet>
      
      <FeaturePaywallWrapper featureKey={"notification-channels"} className={'max-w-full py-0'} currentUser={currentUser}>
        <div className="flex items-center">
          
          <h3 className="text-lg leading-6 font-medium">
            Configure Webhook Notifications
          </h3>

          <button className="focus:outline-none ml-auto" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="mt-4">
          <div className="text-gray-200 space-y-4">
            <p>
              Set the URL that you want the webhook to send your digests to.
            </p>
            <p>
              The webhook will be a POST with this JSON payload structure:
            </p>
            <p>
              
            </p>
          </div>

          <form className="mt-4 flex flex-wrap space-x-1 sm:space-x-2 space-y-2 md:space-y-0 text-xs md:text-base items-center w-full md:w-auto"
            onSubmit={save}
          >
            <input
              type="url" id="discord-webhook"
              name="discord-webhook"
              autoComplete="off" //autoFocus={isEffectiveProSubscriber ? "on" : ""}
              className={`text-xs md:text-base flex-grow bg-gray-800 cursor-pointer border border-solid border-gray-600 rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500`}
              placeholder={`https://yourserver.com/api/webhooks/`}
              value={webhook} onChange={(e) => {
                setWebhook(e.target.value);
              }}
            />
            <button
              type="submit" disabled={saving}
              className="mt-2 md:mt-0 w-full md:w-auto md:ml-2 text-center p-2 px-4 border border-solid border-transparent font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            >
              Save
            </button>
          </form>
        </div>
      </FeaturePaywallWrapper>
    </Modal>
  );
};

export default NotificationsWebhookModal;

