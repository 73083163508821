import React, { useState } from "react"
// import {
//   Link
// } from "react-router-dom";

import { 
  // PieChart, Pie,
  BarChart, Bar,
  // Cell,
  XAxis,
  // YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// shows chart for usage
const AiMinuteUsage = ({ limits, lightBackground }) => {

  if (!limits || !limits.ai_minute_usage || !limits.ai_minute_usage.days || !limits.ai_minute_usage.days.length) return ''

  // custom tooltip used for timeseries chart!
  const CustomTooltip = ({ active, payload, min, max }) => {

    if (active && payload && payload.length) {
      const bin = payload[0];
      const data = bin.payload;

      return (
        <div className={`p-2 text-xs rounded-md ${lightBackground ? 'bg-gray-200 text-gray-600' : 'bg-gray-700 text-white'}`}>
          <div className="">
            Date: {data.date}
          </div>
          <div className="">
            Used: {data.minutes} minutes
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div className="h-24 text-black pb-4">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={limits.ai_minute_usage.days}
            margin={{}}
          >
            <Tooltip content={<CustomTooltip />} cursor={{
              stroke: lightBackground ? "rgba(75, 85, 99, 0.25)" : "rgb(75 85 99)",
              strokeWidth: 1,
              fill: lightBackground ? "#ecf0f1" : "rgb(31 41 55)"
            }}/>
            <Bar dataKey={"minutes"} stackId="a" fill={lightBackground ? "#bdc3c7" : "rgba(75, 85, 99, 0.75)"}/>

            {limits.ai_minute_usage.replenish ? (
              <text
                x='0%'
                y='15%'
                style={{ fontSize: 11, fill: lightBackground ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,0.6)' }}
                // width={200}
                scaletofit={true}
                // textAnchor='middle'
                // verticalAnchor='middle'
              >
                  Next Replenish: {limits.ai_minute_usage.replenish.minutes} mins on {limits.ai_minute_usage.replenish.date}
              </text>
            ) : ''}
            
          </BarChart>
        </ResponsiveContainer>
        <div className={`text-white flex items-center text-xs border-t ${lightBackground ? 'border-gray-200' : 'border-gray-700'}`}>
          <div className="text-gray-500">{limits.ai_minute_usage.days[0]['date']}</div>
          <div className="text-gray-500 ml-auto">
            {limits.ai_minute_usage.days[limits.ai_minute_usage.days.length - 1]['date']}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AiMinuteUsage;
