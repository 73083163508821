import React, { useEffect } from "react";
//import SEO from "./landing/seo";
import {
  Link
} from "react-router-dom";

import { paymentService } from '../services/payment';

import Sidebar from "./sidebar";
import MobileHeader from "./mobileheader";

const Layout = ({
  children,
  title,
  headerTitle,
  headerSubtitle,
  headerBack,
  headerLinkUrl,
  headerLinkIcon,
  currentUser,
  location,
  hideSidebar, noPadding, tabs=[]
}) => {
  const isMobile = window.screen.width < 768;

  useEffect(() => {
    // checks if user should see some sort of lock screen
    paymentService.checkChurnkeyScreens(currentUser)
  }, [])

  return (
    <div className="h-screen overflow-hidden bg-gray-900 flex darkStyle">
      {/*<SEO title={title} />*/}
      {!hideSidebar && !isMobile ? (
        <Sidebar currentUser={currentUser} />
      ) : ''}

      <main id="main" className="min-w-0 min-h-0 flex-1 flex flex-col overflow-auto text-white h-screen">
        {!hideSidebar && isMobile ? (
          <MobileHeader title={headerTitle} currentUser={currentUser} hideNav={hideSidebar} />
        ) : ''}
        {headerTitle ? (
          <header className="hidden md:block flex-shrink-0 px-8 border-b border-solid border-gray-800">
            <div className="flex items-center h-20">
              <h1 className="text-4xl font-semibold -mt-1 flex items-center">
                {headerBack ? (
                  <Link to={headerBack} className="mr-2 pr-2 opacity-50 hover:opacity-75 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                    </svg>
                  </Link>
                ) : ''}
                <div>
                  <div>{headerTitle}</div>
                  {headerSubtitle ? (
                    <div className="text-sm font-medium opacity-50 mt-0">{headerSubtitle}</div>
                  ) : ''}
                </div>
                {headerLinkUrl ? (
                  <Link to={headerLinkUrl} className="cursor-pointer ml-4 opacity-25 hover:opacity-75">
                    {headerLinkIcon === "edit" ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                      </svg>
                    ) : '--'}
                  </Link>
                ) : ''}
              </h1>
            </div>

            {location && tabs && tabs.length > 1 ? (
              <nav className="-mb-px flex">
                {tabs.map((tab) => (
                  <Link
                    key={tab.name}
                    to={tab.path}
                    className={`whitespace-nowrap pb-4 px-8 border-b-2 border-solid font-semibold text-sm ${tab.path === location.pathname ? 'border-cyan-500 text-cyan-500' : 'border-transparent text-gray-300 hover:text-gray-200 hover:border-gray-200'} ${tab.disabled ? 'opacity-25 pointer-events-none' : ''}`}
                    aria-current={tab.path === location.pathname  ? 'page' : undefined}
                  >
                    {tab.name}
                  </Link>
                ))}
              </nav>
            ) : ''}

          </header>
        ) : ''}
        <div className={`flex-grow relative flex flex-col ${noPadding ? 'p-0 md:h-full' : 'p-4 sm:p-8'}`}>
          { children }
        </div>
      </main>
    </div>
  );
}

export default Layout;

