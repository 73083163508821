import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    if (window.newrelic){
      window.newrelic.noticeError(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{background: 'rgba(231, 76, 60, 0.5)', color: 'white', fontWeight: '500', padding: '10px', textAlign: 'center', borderRadius: '3px'}}>
          Something went wrong. Please <span style={{color: 'white', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer'}}
            onClick={() => window.location = window.location}
          >
            refresh
          </span>.
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;