import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { CheckIcon, XIcon, CalendarIcon } from '@heroicons/react/solid'
// import { ModalRoute, ModalContainer } from 'react-router-modal';

import Layout from "../../components/layout";
import ErrorBoundary from "../../components/common/errorboundary";
import Loader from "../../components/common/loader";

import { authService } from "../../services/auth";
import { trackEvent } from '../../utils/tracking';
import { userOperations } from "../../redux/user"

// import PostTrialSubscriptionModal from "../../components/trial/postTrialSubscriptionModal";
import FormFooter from "./footer";
import SubscriptionSelect from "../../components/account/subscriptionSelect";
// import FAQs from "../../components/account/faqs";
import { localStorageAvailable } from '../../utils/localStorage';

// user comes here when they have hit 50 conversation searches
const Plan = ({history, currentUser, fetchCurrentUser, ...subrouteProps}) => {
  const { nextPage, isLastPage } = subrouteProps;
  // const [checkingOut, setCheckingOut] = useState(null);
  // const [error, setError] = useState(null);

  useEffect(() => {
    // if we have a subscription, skip this page
    if (currentUser && currentUser.subscription){
      history.push('/audiences/');
    }
  }, [])

  const onSubmit = () => {
    history.push(isLastPage ? '/audiences/' : `/welcome/${nextPage}/`);
  }

  // // treat this page like a feature paywall
  const trackingFeature = "Onboarding"

  return (
    <div className="max-w-5xl w-full mx-auto min-h-full flex flex-col">
      <Helmet><title>Welcome | Plan</title></Helmet>

      <div className="inline-block bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle py-6 px-4 sm:p-6">
        <div className="text-center">
          <div className="relative mb-4">
            <hr className="opacity-25" />
            <div className="absolute -top-3 w-full text-center">
              <span className="p-1 text-sm rounded-sm bg-gray-800 text-gray-500 uppercase font-semibold">
                Set up your account
              </span>
            </div>
          </div>

          <p className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            Choose your GummySearch flavor
          </p>

          {/*<p className="mt-4 max-w-xl text-gray-400 mx-auto">
            Pick a paid plan to try, or continue for free.
            Trials have a small fee (to account for AI costs), and you can cancel any time for any reason.
          </p>*/}


          <div className="">
            <SubscriptionSelect currentUser={currentUser} fetchCurrentUser={fetchCurrentUser}
              closeModal={() => {
                history.push(`/account/subscription/`);
              }}
              trackingFeature={trackingFeature}
              showFree={false}
              cancelUrl={window.location.href}
              successUrl={window.location.origin + '/audiences/'}
              onSelectFree={onSubmit}
            />
          </div>

          <div className="mt-4 text-center">
            <div className="underline font-semibold cursor-pointer" onClick={onSubmit}>Continue on free tier</div>
            <div className="text-gray-300 text-sm">(you can upgrade anytime, or buy a day pass if subscriptions aren't your thing)</div>
          </div>
          
        </div>
      </div>

      <FormFooter submit={onSubmit} hideCTA={true} {...subrouteProps} />
    </div>
  );
}

const mapStateToProps = state => {
  // const {
  //   used, total, fetching,
  // } = state.trial;

  return {
    // 'trialTotal': total,
    // 'trialUsed': used,
    // 'trialFetching': fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  // const fetchTrial = (callback, onError) => {
  //   dispatch(trialOperations.fetchTrial(callback, onError))
  // };
  const fetchCurrentUser = (callback) => {
    dispatch(userOperations.fetchCurrentUser(callback))
  };
  return {
    fetchCurrentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
