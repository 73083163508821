import { postRequest, getRequest } from '../utils/axios';
import axios from 'axios';

import { toQueryString } from '../utils/urls.js'


export const productsService = {
    searchProductCategory,
    addProductCategory,
    searchProduct,
    addProduct,
};

function searchProductCategory(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/product-categories/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function addProductCategory(params, onSuccess, onError) {
  return postRequest(
    `/api/v1/product-categories/`, params,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function searchProduct(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/products/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function addProduct(params, onSuccess, onError) {
  return postRequest(
    `/api/v1/products/`, params,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

