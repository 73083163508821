import React, { useState, useEffect } from "react"
// import {
//   Link
// } from "react-router-dom";

// import { getIcon } from '../../utils/icons';

import BlogPost from "./blogPost";

import { resources } from './constants';


const Resources = ({objectiveKey, learningStyleKey, currentUser, showImage}) => {

  const INCREMENT = 6;
  const [countToShow, setCountToShow] = useState(INCREMENT);

  // effects
  useEffect(() => {
    setCountToShow(INCREMENT);
  }, [objectiveKey, learningStyleKey]); // Switching user's options

  // get all ordered resources (sort by objective first, then style)
  const orderedResources = resources
    .sort((a,b) => a.node.frontmatter.style === learningStyleKey && b.node.frontmatter.style !== learningStyleKey ? -1 : a.node.frontmatter.style !== learningStyleKey && b.node.frontmatter.style === learningStyleKey ? 1 : 0)
    .sort((a,b) => a.node.frontmatter.objectives.includes(objectiveKey) && !b.node.frontmatter.objectives.includes(objectiveKey) ? -1 : !a.node.frontmatter.objectives.includes(objectiveKey) && b.node.frontmatter.objectives.includes(objectiveKey) ? 1 : 0)
    .sort((a,b) => a.node.frontmatter.objectives === objectiveKey && b.node.frontmatter.objectives !== objectiveKey ? -1 : a.node.frontmatter.objectives !== objectiveKey && b.node.frontmatter.objectives === objectiveKey ? 1 : 0) // exact match (article written ONLY for this objective)

  const resourcesToShow = orderedResources.filter((r, i) => i < countToShow);

  return (
    <div>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6">
        {resourcesToShow.map(r => (
          <BlogPost key={r.node.frontmatter.slug} node={r.node} currentUser={currentUser} showImage={showImage} />
        ))}
      </div>

      {resourcesToShow.length === 0 ? (
        <div className="opacity-50">No resources specific to this use-case yet</div>
      ) : ''}

      {resourcesToShow.length < orderedResources.length ? (
        <div className="mt-4 text-sm font-semibold">
          <span onClick={() => setCountToShow(countToShow + INCREMENT)} className="opacity-50 hover:opacity-75 cursor-pointer flex items-center">
            <span>Show more</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
            </svg>
          </span>
        </div>
      ) : ''}
      
    </div>
  );
}

export default Resources;
