import React, { useState } from "react";
import {
  Switch,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from 'react-toastify';
import copy from 'copy-to-clipboard';

import PropsRoute from '../../components/common/propsroute.jsx';
import SubredditInfo from './subredditInfo.jsx';
import SubredditTopics from './subredditTopics.jsx';
import SubredditSubmissions from './subredditSubmissions.jsx';
import SubredditStats from './subredditStats.jsx';
import SubredditInfluencers from './subredditInfluencers.jsx';
import SubredditModeration from './subredditModeration.jsx';
import SubredditReach from './subredditReach.jsx';
import ErrorBar from "../common/errorBar";
import NavBar from '../common/navbar';

import { searchesService } from '../../services/searches';
import { trackEvent } from '../../utils/tracking';
import { readableNumber } from '../../utils/text';
import { hasStarter, hasPro } from '../../utils/account';


// modal to view subreddit
const SubredditModal = ({
  collection,
  items,
  currentUser,
  baseUrl,
  location,
  match,
  closeModal
}) => {
  // state
  const { subredditName } = match.params;


  // get subreddit details
  const collectionItem = items.filter(s => s.external_id === subredditName)[0];
  const subreddit = collectionItem && collectionItem.item;
  const subredditBaseUrl = `/audience/${collection.hash}/subs/${subredditName}/`;

  const isEffectiveStarterSubscriber = hasStarter(currentUser);
  const isEffectiveProSubscriber = hasPro(currentUser);


  // dont open modal unless we have a match
  // if (!subreddit) return '';

  const tabs = [
    { name: 'Info', link: `${subredditBaseUrl}`, exact: true },
    { name: 'Topics', link: `${subredditBaseUrl}topics/`},
    { name: 'Posts', link: `${subredditBaseUrl}posts/`},
    { name: 'Performance', link: `${subredditBaseUrl}performance/`},
    // { name: 'Moderation', link: `${subredditBaseUrl}moderation/`},
    { name: 'Stats', link: `${subredditBaseUrl}stats/`},
    { name: 'People', link: `${subredditBaseUrl}people/`},
    // { name: 'Content Advisor', link: `${subredditBaseUrl}content/`, disabled: true },
    // { name: 'Moderation', link: `${subredditBaseUrl}moderation/`, disabled: true },
  ];

  return (
    <Modal widthClass={'max-w-4xl overflow-visible'} close={closeModal}>
      <Helmet><title>Audience | Subreddit</title></Helmet>
      
      {subreddit ? (
        <React.Fragment>
          <div className="flex items-center flex-wrap">
            {subreddit && subreddit.icon ? (
              <img className="h-12 w-12 mr-4 rounded-md" src={subreddit.icon} alt={subreddit.name} />
            ) : (
              <div className="h-12 w-12 mr-4 rounded-md bg-gray-700">
                <svg className="h-6 w-6 m-3 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                  <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                </svg>
              </div>
            )}
          
            <div className="mr-2">
              <h3 className="text-lg leading-6 font-medium">
                r/{subreddit.name}
              </h3>
              <div className="opacity-50 text-xs mt-1">
                {readableNumber(subreddit.count_users)} members since {subreddit.created}
              </div>
            </div>

            <div className="flex ml-auto space-x-4">
              {subreddit.share_url ? (
                <button type="button" className="inline-flex px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 bg-gray-700 hover:bg-gray-600"
                  onClick={() => {
                    copy(subreddit.share_url);
                    toast.success("Copied public link to clipboard")
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="hidden sm:block -ml-1 mr-2 h-5 w-5 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                  </svg>
                  <span className="flex">Share<span className="hidden sm:block ml-1"> Subreddit</span></span>
                </button>
              ) : ''}
              

              <a href={subreddit.link} target="_blank" rel="noreferrer" className="cursor-pointer"
                onClick={() => {
                  trackEvent("ExternalRedirect", {
                    "platform": "Reddit",
                    "type": "Subreddit",
                    "platform_id": subreddit.name,
                  });
                  searchesService.saveRedirect("RSR", subreddit.name, subreddit.name);
                }}
              >
                <button type="button" className="inline-flex px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 bg-gray-700 hover:bg-gray-600">
                  <svg className="hidden sm:block -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" viewBox="0 0 24 24">
                    <path fillRule="evenodd" d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"/>
                  </svg>
                  <span className="flex">View<span className="hidden sm:block ml-1"> on Reddit</span></span>
                </button>
              </a>
            </div>

            <button className="focus:outline-none ml-4" onClick={closeModal}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <div className="py-4">
            <NavBar tabs={tabs} />
          </div>

          <div id="subreddit-content" className="flex-grow overflow-auto flex flex-col"
            // style={{minHeight: 'calc(100% - 40px)'}}
            style={{minHeight: '400px'}}
          >
            <Switch>
              <PropsRoute path={`${subredditBaseUrl}`} exact={true} component={SubredditInfo}
                subreddit={subreddit} currentUser={currentUser} collection={collection} />
              <PropsRoute path={`${subredditBaseUrl}topics/`} component={SubredditTopics}
                subreddit={subreddit} showTerminologyExamples={true} />
              <PropsRoute path={`${subredditBaseUrl}posts/`} component={SubredditSubmissions}
                subreddit={subreddit} showRedirect={true} trackRedirect={true} hasPro={isEffectiveProSubscriber} />
              <PropsRoute path={`${subredditBaseUrl}stats/`} component={SubredditStats}
                subreddit={subreddit} currentUser={currentUser} hasStarter={isEffectiveStarterSubscriber} />
              <PropsRoute path={`${subredditBaseUrl}moderation/`} component={SubredditModeration}
                subreddit={subreddit} currentUser={currentUser} hasPro={isEffectiveProSubscriber} />
              <PropsRoute path={`${subredditBaseUrl}people/`} component={SubredditInfluencers}
                subreddit={subreddit} currentUser={currentUser} hasPro={isEffectiveProSubscriber} />
              <PropsRoute path={`${subredditBaseUrl}performance/`} component={SubredditReach}
                subreddit={subreddit} currentUser={currentUser} hasPro={isEffectiveProSubscriber} />
              {/*<PropsRoute path={`${subredditBaseUrl}topics/`} component={SearchResultsAll}
                savedSearch={savedSearch} currentUser={currentUser} baseUrl={baseUrl} />
              <PropsRoute path={`${subredditBaseUrl}conversations/`} component={SearchResultsConversations}
                savedSearch={savedSearch} currentUser={currentUser} baseUrl={baseUrl} />*/}
            </Switch>
          </div>
        </React.Fragment>
      ) : 'loading...'}

    </Modal>
  );
};

export default withRouter(SubredditModal);

