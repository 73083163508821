import React, { useState } from "react";
import {
  Link,
} from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";

import { collectionsService } from '../../services/collections';
import ErrorBar from "../common/errorBar";


const ConversationsInfoModal = ({ closeModal, history, currentUser }) => {
  
  const isProSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "pro");
  const isEffectiveProSubscriber = isProSubscriber || currentUser.trial.plan === 'pro';

  return (
    <Modal close={closeModal} widthClass={'max-w-5xl'}>
      <Helmet><title>Conversations | Info</title></Helmet>
      
      <div className="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 opacity-50 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
        </svg>

        <h3 className="text-lg leading-6 font-medium">
          Conversations
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="text-md">

        {/*<div className="mt-4">
          <p>
            Welcome to your conversations page. Here you can find posts or comments you've saved to lists for future reference, as well as keywords you are tracking on Reddit.
          </p>
        </div>*/}

        {/*{isEffectiveProSubscriber ? (
          <div className="mt-4">
            <div className="opacity-50 mb-1">Advanced Search for Reddit</div>

            <div className="sm:flex space-x-4">
              <div className="flex-1">
                <p>
                  Use the conversations feature to search Reddit for keywords related to your business. You may search all Reddit submissions/comments, or target an Audience or specific subreddit.
                </p>
                <p className="mt-2">Advanced Search for Reddit is available to GummySearch Pro users only.</p>
              </div>
              <div className="flex-1 mt-2 sm:mt-0">
                <iframe width="100%" height="180px" src="https://www.youtube.com/embed/of1l75X1wys" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        ) : ''}*/}


        <div className="mt-4">
          <div className="opacity-50 mb-1 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
            Tracked Keywords
          </div>

          <div className="sm:flex space-x-4">
            <div className="flex-1">
              <p>
                Tracked keywords will notify you via email of new conversations that match your filters.
              </p>
              <p className="mt-2">
                To set up a new tracked keyword, make a submission/comment search and then press the <span className="px-1.5 py-1 rounded-md relative top-0.5 bg-gray-700 inline-block">
                  <div className="flex items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="rgba(8, 145, 178,0.15)" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg> Track
                  </div>
                </span> button.
              </p>
              <p className="mt-2">
                Press the "Stats" toggle to see the performance & efficiency of each of your tracked keywords, and configure your <Link href="/account/notifications/">notification channel</Link> to send your matches to Slack/Discord.
              </p>
            </div>
            <div className="flex-1 mt-2 sm:mt-0">
              <iframe width="100%" height="180px" src="https://www.youtube.com/embed/nNTxXbAfLYc" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div> 

        <div className="mt-4">
          <div className="opacity-50 mb-1 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
            </svg>
            Bookmarks
          </div>

          <div className="sm:flex space-x-4">
            <div className="flex-1">
              <p className="mt-2">
                Bookmark conversations that interest you for easy access later. To create a new bookmark or save a conversation, make a submission/comment search and then press the <span className="px-1.5 py-1 rounded-md relative top-0.5 bg-gray-700 inline-block">
                  <div className="flex items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                    </svg> <span className="ml-1">Bookmark</span>
                  </div>
                </span> button on the conversation you want to save.
              </p>
              <p className="mt-2">
                You can add multiple bookmarks to a single conversation, or remove a bookmark from the same popover.
              </p>
            </div>
            <div className="flex-1 mt-2 sm:mt-0">
              <iframe width="100%" height="180px" src="https://www.youtube.com/embed/9kyNWODBdxw" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div> 


        {/*<div className="mt-4">
          <div className="opacity-50 mb-1 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
            </svg>
            Saved Conversation Lists
          </div>
          <p>
            Save conversations that interest you for easy access later.
          </p>
          <p className="mt-2">
            To create a new list or save a conversation, make a submission/comment search and then press the <span className="px-1.5 py-1 rounded-md relative top-0.5 bg-gray-700 inline-block">
              <div className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="rgba(8, 145, 178,0.15)" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                </svg> Save
              </div>
            </span> button on any conversations you want to save.
          </p>
        </div> */}

        {/*{isEffectiveProSubscriber ? (
          <div className="mt-4">
            <button className="mt-2 md:mt-0 w-full text-center p-2 px-4 border border-solid border-transparent font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
              onClick={() => {
                closeModal()
              }}
            >
              Make a keyword search
            </button>
          </div>
        ) : ''}*/}
      </div>


    </Modal>
  );
};

export default ConversationsInfoModal;

