import React, { useState } from "react";
import {
  Switch,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";

import PropsRoute from "../../components/common/propsroute";
import NameAudience from "./nameaudience"
import AudienceDiscovery from "./discovery"


// modal to add a new audience, discover communities, and save to that audience
const AudiencesNewModal = ({
  closeModal
}) => {

  const baseUrl = "/audiences/new/"  // might change when adding new communities to existing audience

  return (
    <Modal widthClass={'max-w-5xl'} close={closeModal}>
      <Helmet><title>Audiences | New</title></Helmet>

      <div className="flex items-center w-full mb-4 pb-4 border-b border-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-3 opacity-25" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      
        <h3 className="text-lg leading-6 font-medium">
          New Audience - Select Subreddits
        </h3>

        <button className="ml-auto text-gray-500 border border-transparent rounded-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-500"
          onClick={closeModal} autoFocus="off"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <Switch>
        <PropsRoute path={`${baseUrl}discover/`} component={AudienceDiscovery} />
        <PropsRoute path={`${baseUrl}`} component={NameAudience} showIcons={true}/>
      </Switch>
    </Modal>
  );
};

export default AudiencesNewModal;

