import React, { useState } from "react";
import { connect } from 'react-redux';
// import {
//   withRouter,
// } from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";
import { PencilAltIcon } from '@heroicons/react/outline'

import { collectionsService } from '../../services/collections';
import { audiencesOperations } from "../../redux/audiences"

import ErrorBar from "../common/errorBar";


const AudienceEditModal = ({ collection, refetch, closeModal, history, fetchAudiences }) => {
  // state
  const [collectionName, setCollectionName] = useState(collection.name);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const updateCollection = (data) => {
    if (saving) return;

    setSaving(true)
    setError(null)
    collectionsService.updateCollection(collection.hash, data, (results) => {
      if (results && results.data){
        closeModal();
        setSaving(false);
        if (refetch){
          refetch()
        }
      }
    }, (response, error) => {
      setSaving(false)
      setError(error)
    })
  }


  return (
    <Modal close={closeModal}>
      <Helmet><title>Audience | Edit</title></Helmet>
      
      <div className="flex items-center">
        <PencilAltIcon className="h-6 w-6 opacity-50 mr-2"/>

        <h3 className="text-lg leading-6 font-medium">
          {collection.name}
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="mt-4">
        <h3 className="mb-2 text-xs font-semibold text-gray-400 uppercase tracking-wide">
          Name
        </h3>
        <div>
          <input id="colleciton-name" type="text"
            className="bg-gray-700 px-2 py-3 text-sm border-none rounded-md w-full focus:ring-0"
            placeholder={saving ? "Saving..." : "Collection Name"} autoComplete="off" spellCheck="off"
            disabled={saving} value={collectionName} onChange={e => setCollectionName(e.target.value)}
            onKeyDown={(event) => {
              const value = event.target.value;
              if (event.key === 'Enter') {
                if (value && value !== ''){
                  updateCollection({'name': value})
                }      
              } else {
                setCollectionName(value)
              }
            }}
          />
        </div>
      </div> 

      <div className="mt-4">
        <h3 className="mb-2 text-xs font-semibold text-gray-400 uppercase tracking-wide">
          Danger Zone
        </h3>
        <div>
          <button type="button" className="text-center items-center px-10 py-3 shadow-sm text-xs font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            onClick={() => {
              setError(null)
              if (window.confirm("Are you sure?")){
                collectionsService.deleteCollection(collection.hash, (results) => {
                  fetchAudiences();
                  history.push(`/audiences/`);
                }, (response, error) => {
                  setError(error)
                })
              }
            }}
          >
            Delete
          </button>
        </div>
      </div> 

      {error ? (
        <ErrorBar error={error} setError={setError} />
      ) : ''}  

    </Modal>
  );
};


const mapStateToProps = state => {
  return {
    
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAudiences = (callback, onError) => {
    dispatch(audiencesOperations.fetchAudiences(callback, onError))
  };
  return {
    fetchAudiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceEditModal);



