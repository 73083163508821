import React from "react";

import { styleKeyword } from '../../utils/text';
import { trackEvent } from '../../utils/tracking';
import { searchesService } from '../../services/searches';

import ErrorBar from "../common/errorBar";
// import SubmissionAnalyze from "./submissionanalyze";

// import { timeStampToFullTime } from '../../utils/time';

// returns the raw contents of a submission, be it image iframe video or text
const SubmissionBody = ({submission, keyword, currentUser}) => {
  
  return (
    <div className="mt-4">

      <h2 className="text-md font-sm mb-1 flex items-center">
        <span className="opacity-50">Submission Contents</span>
      </h2>

      <div className="bg-gray-800 text-base p-4 rounded-lg">
        {submission.body_html ? (
          <div className="pb-4 xl:pb-0">
            <div className="prose font-small break-words embedTarget containsKeywords"
              dangerouslySetInnerHTML={{
                __html: styleKeyword(submission.body_html, keyword)
                  .replace(`href=`, `target="_blank" rel="noreferrer" href=`)
              }}></div>
          </div>
        ) : submission.type === 'text' && !submission.body_html ? (
          <div className="py-4 xl:pt-4 xl:pb-0">
            <div className="font-small opacity-25">No submission content</div>
          </div>
        ) : ''}
          
        {submission.embed ? (
          <div className="py-4 xl:pt-4 xl:pb-0">
            <div className="rounded-md overflow-hidden"
              dangerouslySetInnerHTML={{
                __html: submission.embed
                  .replace('&lt;', '<').replace('&gt;', '>') // iframe start + end tags
                  .replace('width=\"356\"', 'width=\"100%\"') // separate iframe widths for some reason
                  .replace('width=\"267\"', 'width=\"100%\"') // TODO: figure out better way of doing this
                }}
            ></div>
          </div>
        ) : ''}

        {['link', 'crosspost'].includes(submission.type) ? (
          <div className="py-4 xl:pt-4 xl:pb-0">
            <a href={submission.url} target="_blank" rel="noreferrer"
              onClick={() => {
                trackEvent("ExternalRedirect", {
                  "platform": "Reddit",
                  "type": "SubmissionSource",
                  "platform_id": submission.id,
                });
                searchesService.saveRedirect("RSU", submission.id, submission.title);
              }}
            >
              {submission.thumbnail && submission.thumbnail !== 'default' ? (
                <div className="relative rounded-md">
                  <img className="w-full h-full rounded-md" src={submission.thumbnail} width="100%" alt={submission.title}/>
                  <div className="absolute top-0 right-0 w-full h-full bg-black opacity-50 rounded-md" aria-hidden="true"></div>
                  <div className="absolute top-2 left-2 right-2 overflow-hidden text-sm flex items-center p-2 bg-white text-gray-900 rounded-md opacity-50">
                    <svg className="flex-shrink-0 h-4 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                    <div className="whitespace-nowrap overflow-ellipsis overflow-hidden">{submission.url}</div>
                  </div>
                </div>
              ) : (
                <div className="relative rounded-md p-4 bg-gray-700 break-all text-white text-sm border border-gray-600 shadow-sm">
                  <svg className="inline-block h-4 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                  </svg>
                  <span>{submission.url}</span>
                </div>
              )}
            </a>
          </div>
        ) : ''}

        {submission.type === 'image' ? (
          <div className="py-4 xl:pt-4 xl:pb-0 flex flex-wrap">
            {submission.media.map(url => (
              <div className="relative rounded-md w-96 mb-4 bg-gray-100" key={url} style={{minHeight: '100px'}}>
                <img className="w-full rounded-md" src={url} width="100%" alt={submission.title}/>
              </div>
            ))}
          </div>
        ) : ''}

        {submission.body_html && submission.body_html.length > 1000 ? (
          <div className="text-center font-semibold opacity-50 hover:opacity-100 cursor-pointer text-sm"
            onClick={() => {
              if (document.getElementById(`submission-drawer`)){
                // drawer is open, scroll to the very top
                document.getElementById(`submission-drawer`).scroll(0, 0)
              } else {
                // drawer not open, so we're in a list view. scroll to this element
                document.getElementById(`submission-${submission.id}`).scrollIntoView()
              }
            }}
          >
            Scroll to top ↑
          </div>
        ) : ''}
      </div>
    </div>
  );
}

export default SubmissionBody;

