import React, { useState } from "react";
// import {
//   withRouter,
// } from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";

import { collectionsService } from '../../services/collections';
import ErrorBar from "../common/errorBar";


const SearchInfoModal = ({ closeModal, history }) => {
  

  return (
    <Modal close={closeModal}>
      <Helmet><title>Search | Info</title></Helmet>
      
      <div className="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 opacity-50 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
        </svg>

        <h3 className="text-lg leading-6 font-medium">
          Search
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="text-md">
        <div className="mt-4">
          <div className="opacity-50 mb-1">Advanced Search for Reddit</div>
          <p>
            Use the Advanced Search feature to browse Reddit for keywords related to your business. You may search all Reddit submissions/comments, or target an Audience or specific subreddit.
          </p>
          <p className="mt-4">
            After making a search, you can save valuable conversations to a list, or track the keyword to be notified of new instances as they occur.
          </p>
          <div  className="mt-4">
            <iframe width="100%" height="180px" src="https://www.youtube.com/embed/of1l75X1wys" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>


    </Modal>
  );
};

export default SearchInfoModal;

