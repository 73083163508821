import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link, useLocation, useHistory
} from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactTooltip from 'react-tooltip';
import { Popover, Transition } from '@headlessui/react'

//import { InformationCircleIcon } from '@heroicons/react/solid'

import ErrorBoundary from "../../components/common/errorboundary";
import LoaderFancy from "../../components/common/loaderfancy";
import ErrorBar from "../../components/common/errorBar";
import KeywordVelocity from "./keywordvelocity";
import AudienceDiscoveryResults from "./discoveryresults";

import { collectionsService } from '../../services/collections';
import { audiencesOperations } from "../../redux/audiences"
import { discoverService } from '../../services/discover';

import { getParamsFromUrl, toQueryString } from '../../utils/urls';
import { trackEvent } from '../../utils/tracking';
import { readableNumber } from '../../utils/text';


// Discover new communiites based on an audience we're creating
const AudienceDiscovery = ({
  currentUser,
  currentCollection,  // set if adding to existing collection, otherwise blank
  currentCollectionItems,  // set if adding to existing collection, otherwise blank
  refetchCurrentCollection,
  fetchAudiences,
}) => {
  // state
  const location = useLocation();
  const history = useHistory();
  const params = getParamsFromUrl();

  const [name, setName] = useState(params.name || currentCollection && currentCollection.name);
  const [keywords, setKeywords] = useState(params.keywords && params.keywords.split(',') || [], [location]);
  const [selectedSubs, setSelectedSubs] = useState([]);  // array of subreddits user has selected

  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  

  // effects
  useEffect(() => {
    if (!name){
      // Always needs a name, otherwise go name this new audience
      history.push('/audiences/new/')
    }
  }, [name]);


  useEffect(() => {
    if (!loading) performSearch(keywords);
  }, [
    keywords,
    // params.keywords,
    // history.location.search,
  ]); // Run when keywords changed

  // actions
  const performSearch = (searchKeywords) => {
    if (!searchKeywords || !searchKeywords.length){
      setResults({'keywords': [], 'communities': []});
      return;
    }

    setLoading(true);
    setError(null);
    trackEvent("PerformCommunitySearch", {
      "type": "Discover",
      "count_keywords": searchKeywords.length,
      "keywords": searchKeywords
    });
    discoverService.performSearch(searchKeywords, (response) => {
      setResults(response.data);
      setLoading(false);

    }, (error, response) => {
      setLoading(false);
      if (error.response && error.response.status === 500){
        setError("Error fetching results.")
      } else {
        setError(response || "Error fetching results.")
      }
    });
  }

  const setNewKeywords = (keywords) => {
    setKeywords(keywords);
    // change url (different if we have a collection)
    if (currentCollection){
      history.push(`/audience/${currentCollection.hash}/add/discover/?name=${name}&keywords=${keywords.join(",")}`);
    } else {
      history.push(`/audiences/new/discover/?name=${name}&keywords=${keywords.join(",")}`);
    }

    if (document.getElementById('results-container')){
      document.getElementById('results-container').scrollTop = 0;
    }
  }

  const clearKeyword = (keyword) => {
    setNewKeywords(keywords.filter(t => t !== keyword))
  }

  const addKeyword = (keyword) => {
    if (!keyword) return;
    if (keywords.includes(keyword)) return;

    if (keyword.includes(',')){
      // adding multiple (comma separated)
      setNewKeywords([...keywords, ...keyword.split(',').map(k => k.trim())])
    } else {
      // just adding one
      setNewKeywords([...keywords, keyword])
    }
  }

  const addToAudience = () => {
    if (!selectedSubs || !selectedSubs.length){
      alert("Missing selected subreddits")
      return;
    }
    if (!name && !currentCollection){
      alert("Missing Audience Name")
      return;
    }

    // if there's an audience, add to it. otherwise create audience and add to it
    if (!currentCollection){
      createCollectionAndAdd();
    } else {
      bulkAdd(currentCollection)
    }
  }

  const createCollectionAndAdd = () => {
    setCreating(true)
    collectionsService.createCollection(name, (results) => {
      setCreating(false)
      if (results && results.data){
        bulkAdd(results.data);
        fetchAudiences()
      } else {
        console.log(error)
        setError("Unknown error")
      }
    }, (response, error) => {
      console.log(error)
      setCreating(false)
      setError(error)
    })
  }

  const bulkAdd = (collection) => {
    setCreating(true)
    // assuming all are subreddits for now
    const items = selectedSubs.map((s) => {return {'external_type': 'RSR', 'external_id': s.name}});
    collectionsService.addCollectionItems(collection.hash, items, (results) => {
      if (results && results.data){
        // go to the audience
        setCreating(false);

        // if we just created, simply go there. otherwise refetch then go there
        if (currentCollection){
          refetchCurrentCollection()
        }
        history.push(`/audience/${collection.hash}/`);
        fetchAudiences();
      }
    }, (response, error) => {
      console.log(error)
      setCreating(false)
      setError(error)
    })
  }


  // get max velocity for a keyword, as well as for a keyword/community combo
  var maxVelocity = 1;
  if (results){
    maxVelocity = Math.max(...results.keywords.map(k => k.velocity));
  }
  
  // get current results, plus any keywords we're currently searching for
  var keywordResults = []
  if (results){
    const searchingKeywords = keywords.filter(k => !results.keywords.map(r => r.phrase).includes(k));
    keywordResults = [
      ...results.keywords,
      ...searchingKeywords.map((k) => {return {'phrase': k, 'instances': [], 'velocity': 0}})
    ]
  }

  return (
    <div className="w-full">
      <Helmet><title>Audiences | New | Discover</title></Helmet>

      <ErrorBoundary>
        {loading && !results ? (
          <React.Fragment>
            <div className="h-96">
              <LoaderFancy />
            </div>
            {/* its a spacer */}
            {currentCollection ? (
              <div className="sm:h-24" />
            ) : (
              <div className="sm:h-40" />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="flex">
              <h3 className="mb-2 text-xs font-semibold text-gray-400 uppercase tracking-wide">
                Search keywords
              </h3>
              <div className="opacity-50 cursor-pointer hover:opacity-75 ml-2" data-for='search-keywords-tooltip'
                data-tip="Try a 2-4 keywords your audience might mention"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
            <ReactTooltip id='search-keywords-tooltip' place="right" effect="solid" backgroundColor="white" textColor="black" />

            <div className="flex flex-wrap">
              {keywordResults.map(keyword => (
                <KeywordVelocity key={keyword.phrase}
                  phrase={keyword.phrase} clear={clearKeyword}
                  instanceType="reddit-submission"  // maybe change to mixed later
                  instances={keyword.instances || []}
                  velocity={keyword.velocity || 0}
                  maxVelocity={maxVelocity} noWrap={true} />
              ))}  

              <div className="rounded-sm text-sm bg-gray-700 text-white flex mb-2 mr-2 items-center p-2">
                <div className="mr-2 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                </div>
                <div className="whitespace-nowrap overflow-ellipsis flex-grow">
                  <input id="new-discover-keyword" type="text" className="bg-transparent text-sm border-none p-0 w-full focus:ring-0"
                    placeholder="New Keyword" autoComplete="off" spellCheck="off"
                    autoFocus={!keywordResults.length}
                    onKeyDown={(event) => {
                      if(event.key === 'Enter') {
                        const value = event.target.value;
                        if (value && value !== ''){
                          addKeyword(value);
                          document.getElementById('new-discover-keyword').value = '';
                        }      
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            {keywordResults && keywordResults.length ? (
              <div className="mt-4">
                <h3 className="mb-2 text-xs font-semibold text-gray-400 uppercase tracking-wide flex items-center">
                  <span className="mr-2">Subreddit Results</span>

                  {loading && results ? (
                    <svg className="animate-spin h-4 w-4 opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  ) : results && !loading ? (
                    <span className="opacity-50 font-medium">
                      {results.communities.filter(c => c.hidden).length === 0 ? (
                        <span>{results.communities.length}</span>
                      ) : (
                        <span>{results.communities.filter(c => !c.hidden).length} / {results.communities.length}</span>
                      )}
                    </span>
                  ) : ''}
                </h3>
                <div id="results-container" className={`${currentCollection ? 'h-80' : 'h-96'} overflow-auto`}>
                  {results ? (
                    <AudienceDiscoveryResults
                      communities={results.communities.filter(c => !c.hidden).filter(c => c.type === 'subreddit')}
                      keywordResults={keywordResults} loading={loading}
                      currentCollectionItems={currentCollectionItems}
                      selectedSubs={selectedSubs} setSelectedSubs={setSelectedSubs}
                    />
                  ) : (
                    <div>No results... Adjust your keywords</div>
                  )}
                </div>
              </div>
            ) : (
              <div className={`${currentCollection ? 'h-80' : 'h-96'} mb-10`}></div>
            )}
            

            <div className="border-t border-solid border-gray-700 w-full flex items-center pt-4">
              <div className="mr-auto">
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={`flex-shrink-0 inline-flex p-2 items-center justify-center focus:outline-none focus:ring-transparent focus:border-none rounded-lg ${open ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700'}`}
                      >
                        <span className="sr-only">Search Tips</span>
                        <span>Tips</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={React.Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-10 bg-white text-gray-900 shadow-lg rounded-lg left-20 bottom-0 p-2 w-screen max-w-xs sm:max-w-md"
                        >
                          <ul className="p-2 list-disc pl-8 text-sm">
                            <li>Pick 2-4 words your target audience might mention</li>
                            <li>Short & broad synonyms for your audience work well</li>
                            <li>Similar words like "Marketing" and "Marketers" are fine</li>
                            <li>The more keywords, the more refined the results get</li>
                            <li>For more info, read the full <a className="underline" href="https://gummysearch.com/how-to/discover-subreddits/" target="_blank">
                              guide on finding subreddits
                            </a></li>
                          </ul>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>

              {selectedSubs.length !== 0 ? (
                <div className="px-4 text-sm">
                  <div>{selectedSubs.length} Subreddit{selectedSubs.length === 1 ? '' : 's'} Selected</div>
                  <div className="text-xs opacity-50">
                    {readableNumber(selectedSubs.map(s => s.count_users).reduce((partial_sum, a) => partial_sum + a, 0))} Members
                  </div>
                </div>
              ) : (
                <div className="px-4 text-sm flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-yellow-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                  <div>None Selected Yet</div>
                </div>
              )}

              <button
                type="submit"
                onClick={() => addToAudience()}
                disabled={selectedSubs.length === 0 || creating}
                className={`text-center px-4 py-2 border border-solid border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-cyan-500 ${selectedSubs.length === 0 ? 'bg-gray-700 hover:bg-gray-700' : 'bg-cyan-600 hover:bg-cyan-700'}`}
              >
                {selectedSubs.length !== 0 ? (currentCollection ? 'Add to Audience' : 'Create Audience') : 'Select Relevant Subreddits To Continue'}
              </button>
            </div>
          </React.Fragment>
        )}

        {error ? (
          <ErrorBar error={error} setError={setError} onRetry={() => performSearch(keywords)}/>
        ) : ''}
      </ErrorBoundary>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAudiences = (callback, onError) => {
    dispatch(audiencesOperations.fetchAudiences(callback, onError))
  };
  return {
    fetchAudiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceDiscovery);

