import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import ConfettiExplosion from 'react-confetti-explosion';
import Layout from "../../components/layout";
import ErrorBoundary from "../../components/common/errorboundary";
import ProgressRing from "../../components/common/progressring";
import Loader from "../../components/common/loader";

// import { QuestionTo } from '@questionto/react';

import {
  InformationCircleIcon
} from '@heroicons/react/outline'

import Knowedgebase from "../../components/guide/knowledgebase";

// import { trialService } from '../../services/trial';
// import { authService } from "../../services/auth";
// import { trialOperations } from "../../redux/trial"


const Help = ({ currentUser }) => {
  
  return (
    <Layout title="Help" currentUser={currentUser}>
      <Helmet><title>Help</title></Helmet>
      
      <div className="flex items-center sm:-mt-3 mb-6 pb-5 border-b  border-solid border-gray-800">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">Help</h2>
        </div>
      </div>

      <ErrorBoundary>

        {/*<div className="mb-2">
          <div className="flex items-center">
            <h2 className="text-lg font-medium">Quick Links</h2>
          </div>
        </div>*/}
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6">

          {/*<QuestionTo projectId="7mx2uar553l52t8" userId={currentUser && currentUser.identity.id}>
            <div className={`text-sm sm:text-base p-2 sm:p-4 sm:h-24 rounded-md bg-gray-800 flex items-center cursor-pointer hover:bg-gray-700`}>
              <div className="mr-4 pointer-events-none">
                <svg className="h-8 w-8 opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288" />
                </svg>
              </div>
              <div className="pointer-events-none">
                <div className="flex items-center">
                  Contact Support
                </div>
                <div className="text-xs sm:text-sm opacity-50">Write a question/problem, or record a video with your question</div>
              </div>
            </div>
          </QuestionTo>*/}

          <a href="mailto:fed@gummysearch.com" className={`text-sm sm:text-base p-2 sm:p-4 sm:h-24 rounded-md bg-gray-800 flex items-center cursor-pointer hover:bg-gray-700`}>
            <div className="mr-4 pointer-events-none">
              <svg className="h-8 w-8 opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288" />
              </svg>
            </div>
            <div className="pointer-events-none">
              <div className="flex items-center">
                Contact Support
              </div>
              <div className="text-xs sm:text-sm opacity-50">Write a question/problem, or record a video with your question</div>
            </div>
          </a>

          <a href={`https://gummysearch.feedbear.com/boards/feature-requests`} target="_blank"
            className={`text-sm sm:text-base p-2 sm:p-4 sm:h-24 rounded-md bg-gray-800 flex items-center cursor-pointer hover:bg-gray-700`}
          >
            <div className="mr-4">
              <svg className="h-8 w-8 opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
              </svg>
            </div>
            <div>
              <div className="flex items-center">
                Feature Request
              </div>
              <div className="text-xs sm:text-sm opacity-50">Submit and vote on product features</div>
            </div>
          </a>

          {currentUser && currentUser.subscription ? (
            <a href={`${currentUser.env.landing_page_url}/releases/`} target="_blank"
              className={`text-sm sm:text-base p-2 sm:p-4 sm:h-24 rounded-md bg-gray-800 flex items-center cursor-pointer hover:bg-gray-700`}
            >
              <div className="mr-4">
                <svg className="h-8 w-8 opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z" />
                </svg>
              </div>
              <div>
                <div className="flex items-center">
                  Release Log
                </div>
                <div className="text-xs sm:text-sm opacity-50">See the latest product developments of GummySearch</div>
              </div>
            </a>
          ) : ''}
          
        </div>

        <div className="mt-6">
          <Knowedgebase currentUser={currentUser} />
        </div>
      </ErrorBoundary>
    </Layout>
  );
}

const mapStateToProps = state => {
  const {
    // currentUser,
  } = state.user;

  return {
    // currentUser,
    // 'trialTotal': total,
    // 'trialUsed': used,
    // 'trialFetching': fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  // const fetchTrial = (callback, onError) => {
  //   dispatch(trialOperations.fetchTrial(callback, onError))
  // };
  return {
    // fetchTrial,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
