import React from "react"
import {
  Link
} from "react-router-dom";


const ProgressRing = ({ radius, stroke, color, progress, dontDimProgress }) => {
  // state
  const normalizedRadius = radius - stroke;
  // const normalizedRadius = radius;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - progress / 100 * circumference;

  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      style={{opacity: dontDimProgress ? 1 : (progress / 100)}}
     >
      <circle
        stroke={color || "white"}
        fill="transparent"
        strokeDasharray={ circumference + ' ' + circumference }
        style={ { strokeDashoffset } }
        strokeWidth={ stroke }
        r={ normalizedRadius }
        cx={ radius }
        cy={ radius }
       />
    </svg>
  );
}

export default ProgressRing;
