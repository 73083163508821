import React, { useState, useEffect } from "react";

import ReactTooltip from 'react-tooltip';

import SubredditPopover from "./subredditpopover";
import AggregateOption from "./aggregateOption";

import { languageService } from '../../services/language';

import { getUserSuggestions } from '../../components/audiences/constants/suggestions';
import { conversationContainsPhrase } from '../../utils/reddit'
import { capitalizeFirst } from '../../utils/text';


const AggregateTopics = ({
  results,
  aggregates,
  filteredResults,
  frontendFilters,
  applyFrontendFilter,
  clearFrontendFilter,
  updating,
  hideSections,
}) => {
  const PHRASE_COUNT = 5;
  const PHRASE_COUNT_INCREMENT = 5;
  const [showNumPhrases, setShowNumPhrases] = useState(PHRASE_COUNT, [results]);
  const FLAIR_COUNT = 5;
  const FLAIR_COUNT_INCREMENT = 5;
  const [showNumFlair, setShowNumFlair] = useState(FLAIR_COUNT, [results]);
  const TOPICS_COUNT = 5;
  const TOPICS_COUNT_INCREMENT = 5;
  const [showNumTopics, setShowNumTopics] = useState(TOPICS_COUNT, [results]);
  const [suggestions, setSuggestions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  // const [showAllSubreddits, setShowAllSubreddits] = useState(false, [results]);

  useEffect(() => {
    setShowNumPhrases(PHRASE_COUNT);
    setShowNumFlair(FLAIR_COUNT);
    fetchSuggestions();
  }, [
    results
  ]);

  // actions
  const fetchSuggestions = () => {
    if (loadingSuggestions || (suggestions && suggestions.length) || (!results || !results.length)) return;

    // fetch user's suggested keywords from the API
    setLoadingSuggestions(true)
    languageService.fetchCategories((results) => {
      if (results && results.data){
        // enrich suggestion categories with the keywords from the API
        setSuggestions(getUserSuggestions(results.data))
        setLoadingSuggestions(false)
      }
    }, (response, error) => {
      setLoadingSuggestions(false)
    })
  }


  // cut out early if we haven't got anything or cleared the search
  if (!results || !results.length) return ''

  const filtersApplied = results.length !== filteredResults.length;

  // get the flairs specifically
  var flairs = [];
  results.filter(r => r._meta.type === 'reddit-submission' && r.flair_label).forEach(r => {
    const name = r.flair_label
    const index = flairs.map(r => r.name).indexOf(name);
    index === -1 ? flairs.push({'name': name, 'count': 1}) : flairs[index]['count'] += 1
  });
  flairs.map(s => {
    s['filteredCount'] = filteredResults.filter(r => r.flair_label === s.name).length
    return s
  })
  const flairsToShow = flairs
    .sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0))
    .filter((s, i) => i < showNumFlair); 


  // get the category keyword matches
  const commonCategories = suggestions.map(s => {
    s['count'] = results.filter(r => r.matching_categories && r.matching_categories.includes(s.slug)).length
    s['filteredCount'] = filteredResults.filter(r => r.matching_categories && r.matching_categories.includes(s.slug)).length
    return s
  }).filter(s => s.count > 0).sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));

  // get the topics
  var commonTopics = [];
  results.filter(r => r._meta.type === 'reddit-submission' && r.matching_topics && r.matching_topics.length).forEach(r => {
    r.matching_topics.map(t => {
      const name = t;
      const index = commonTopics.map(r => r.name).indexOf(name);
      index === -1 ? commonTopics.push({'name': name, 'count': 1}) : commonTopics[index]['count'] += 1
    })
  });
  commonTopics.map(s => {
    s['filteredCount'] = filteredResults.filter(r => r.matching_topics && r.matching_topics.includes(s.name)).length
    return s
  })
  const topicsToShow = commonTopics
    .sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0))
    .filter((s, i) => i < showNumTopics); 

  // const commonTopics = suggestions.map(s => {
  //   s['count'] = results.filter(r => r.matching_categories && r.matching_categories.includes(s.slug)).length
  //   s['filteredCount'] = filteredResults.filter(r => r.matching_categories && r.matching_categories.includes(s.slug)).length
  //   return s
  // }).filter(s => s.count > 0).sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));



  return (
    <React.Fragment>
      
                
      <div className="sm:flex flex-wrap sm:space-x-8" disabled={updating}>

        <div className="flex-1">
          {commonTopics.length >= 1 ? (
            <React.Fragment>
              <div className="text-md font-sm mb-1 mt-4 flex items-center">
                <h2 className="opacity-75">Topics</h2>
                <span className="ml-2 text-xs font-semibold">
                  {frontendFilters && frontendFilters.topics && frontendFilters.topics.length ? (
                    <React.Fragment>
                      <span className="opacity-50">{frontendFilters.topics.length} / {commonTopics.length}</span>
                      <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                        onClick={() => clearFrontendFilter('topics')}>clear</span>
                    </React.Fragment>
                  ) : (
                    <span className="opacity-50">{commonTopics.length}</span>
                  )}
                </span>
                {updating ? (
                  <span className="ml-2 text-xs">
                    <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-50" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                ) : ''}

                <ReactTooltip id='phrases-info' place="left" effect="solid" backgroundColor="white" textColor="black" />
                <span className="opacity-50 cursor-pointer hover:opacity-100 ml-auto" data-for='phrases-info' data-tip="Common phrases found in these conversations.">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>

              </div>

              <div className="">
                {topicsToShow.map(t => (
                  <AggregateOption 
                    key={t.name}
                    onClick={() => applyFrontendFilter('topics', t.name)}
                    // label={k.phrase}
                    label={`${capitalizeFirst(t.name)}`}
                    count={t.count}
                    maxCount={commonTopics[0] && commonTopics[0]['count']}
                    filteredCount={t.filteredCount}
                    filtersApplied={filtersApplied}
                    icon='folder'
                    thisFilterApplied={frontendFilters.topics && frontendFilters.topics.includes(t.name)}
                  />
                ))}

                {commonTopics.length > topicsToShow.length ? (
                  <div className="px-2 py-1 mb-2 text-xs bg-gray-900 text-gray-400 hover:text-gray-200 hover:bg-gray-900 rounded-sm flex space-x-1 cursor-pointer"
                    onClick={() => setShowNumTopics(showNumTopics + TOPICS_COUNT_INCREMENT)}
                  >
                    <span>+ show more</span>
                  </div>
                ) : ''} 
              </div>
            </React.Fragment>
          ) : ''}
        </div>

        {(commonCategories.length > 0) ? (
          <div className="flex-1 max-w-1/2">
            
            <React.Fragment>
              <div className="text-md font-sm mb-1 mt-4 flex items-center">
                <h2 className="opacity-75">Categories</h2>
                <span className="ml-2 text-xs font-semibold">
                  {frontendFilters && frontendFilters.categories && frontendFilters.categories.length ? (
                    <React.Fragment>
                      <span className="opacity-50">{frontendFilters.categories.length} / {commonCategories.length}</span>
                      <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                        onClick={() => clearFrontendFilter('categories')}>clear</span>
                    </React.Fragment>
                  ) : (
                    <span className="opacity-50">{commonCategories.length}</span>
                  )}
                </span>
                {updating ? (
                  <span className="ml-2 text-xs">
                    <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-50" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                ) : ''}

                <ReactTooltip id='categories-info' place="left" effect="solid" backgroundColor="white" textColor="black" />
                <span className="opacity-50 cursor-pointer hover:opacity-100 ml-auto" data-for='categories-info' data-tip="GummySearch-analyzed conversation categories">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </div>

              <div className="block">
                {commonCategories.map(t => (
                  <AggregateOption 
                    key={t.name}
                    onClick={() => {
                      applyFrontendFilter('categories', t.slug)
                      // applyFrontendFilter('categoryKeywords', t.keywords) // this is used to do the keyword highlighting
                    }}
                    label={t.name}
                    count={t.count}
                    maxCount={commonCategories[0] && commonCategories[0]['count']}
                    filteredCount={t.filteredCount}
                    filtersApplied={filtersApplied}
                    thisFilterApplied={frontendFilters.categories && frontendFilters.categories.includes(t.slug)}
                    icon={t.icon_name}
                  />
                ))}
              </div>
            </React.Fragment>
            
          </div>
        ) : ''}
        
        {!hideSections || !hideSections.includes('topics_flair') ? (
          <div className="flex-1 max-w-1/2">
            {flairs.length >= 1 ? (
              <React.Fragment>
                
                <div className="text-md font-sm mb-1 mt-4 flex items-center">
                  <h2 className="opacity-75">Flair</h2>
                  <span className="ml-2 text-xs font-semibold">
                    {frontendFilters && frontendFilters.flair && frontendFilters.flair.length ? (
                      <React.Fragment>
                        <span className="opacity-50">{frontendFilters.flair.length} / {flairs.length}</span>
                        <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                          onClick={() => clearFrontendFilter('flair')}>clear</span>
                      </React.Fragment>
                    ) : (
                      <span className="opacity-50">{flairs.length}</span>
                    )}
                  </span>

                  <ReactTooltip id='flair-info' place="left" effect="solid" backgroundColor="white" textColor="black" />
                  <span className="opacity-50 cursor-pointer hover:opacity-100 ml-auto" data-for='flair-info' data-tip="Flair that users set on their submissions. Each subreddit has unique flair.">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                </div>

                <div className="block">
                  {flairsToShow.map(t => (
                    <AggregateOption 
                      key={t.name}
                      onClick={() => applyFrontendFilter('flair', t.name)}
                      label={t.name}
                      count={t.count}
                      maxCount={flairsToShow[0] && flairsToShow[0]['count']}
                      filteredCount={t.filteredCount}
                      filtersApplied={filtersApplied}
                      thisFilterApplied={frontendFilters.flair && frontendFilters.flair.includes(t.name)}
                      icon='tag'
                    />
                  ))}

                  {flairs.length > flairsToShow.length ? (
                    <div className="px-2 py-1 mb-2 text-xs bg-gray-900 text-gray-400 hover:text-gray-200 hover:bg-gray-900 rounded-sm flex space-x-1 cursor-pointer"
                      onClick={() => setShowNumFlair(showNumFlair + FLAIR_COUNT_INCREMENT)}
                    >
                      <span>+ show more</span>
                    </div>
                  ) : ''} 
                </div>
              </React.Fragment>
            ) : ''}
          </div>
        ) : ''}

      </div>
   </React.Fragment>
  )
};

export default AggregateTopics;

