import { patchRequest, getRequest, postRequest } from '../utils/axios';
import axios from 'axios';


export const emailService = {
    unsubscribeEmails,
};


function unsubscribeEmails(data, onSuccess, onError) {
  return postRequest(
    `/api/v1/email-unsubscribe/`, data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

