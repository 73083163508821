import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link, Switch, Route
} from "react-router-dom";
import { ModalRoute, ModalContainer } from 'react-router-modal';
import { Helmet } from "react-helmet";

import {
  InformationCircleIcon
} from '@heroicons/react/outline'

import { collectionsService } from '../../services/collections';
import { savedSearchesService } from '../../services/savedSearches';
import { savedSearchOperations } from "../../redux/saved"
import { audiencesOperations } from "../../redux/audiences"

import Layout from "../../components/layout";
import ErrorBar from "../../components/common/errorBar";
import ErrorBoundary from "../../components/common/errorboundary";
import LoaderFancy from '../../components/common/loaderfancy.jsx';
// import PropsRoute from '../../components/common/propsroute.jsx';
// import DrawerRoute from "../../components/common/drawerroute";

// import Browse from "../../components/conversations/browse";
import Browse from "../../components/conversations/browse";
import TrackedKeywordsList from "../../components/conversations/trackedkeywordslist";
import TrackedKeywordDrawer from "../../components/conversations/trackedkeyworddrawer";
import ConversationsInfoModal from "../../components/conversations/conversationsInfoModal"
import SavedConversationsList from "../../components/conversations/savedconversationslist";
import SavedConversationsDrawer from "../../components/conversations/savedconversationsdrawer";

/*import AudienceSuggestionDrawer from "../../components/audiences/audiencesuggestiondrawer";
import AudienceBrowseDrawer from "../../components/audiences/audiencebrowsedrawer";*/



const Conversations = ({
  history, location, match,
  currentUser,
  audiences, fetchingAudiences, fetchAudiences,
  savedLists, fetchingSavedLists,
  trackedKeywords, fetchingTrackedKeywords,
  fetchAllSavedSearches,
  updateSavedSearches, updating, updatingHashes,
  clearSavedSearchUnread,
}) => {
  // state
  const [error, setError] = useState(null);

  // effects
  useEffect(() => {
    // just refetch any time we visit this page
    if (!fetchingTrackedKeywords){//} && (!trackedKeywords.length || !savedLists.length)){
      fetchAllSavedSearches();
    }

    // fetch audiences if not in store already
    if (!fetchingAudiences && !audiences.length){
      fetchAudiences();
    }
  }, []); // Init all the things!

  // actions
  const syncPaginated = () => {
    // recursively fetches a certain amount of tracked keywords at a time
    const pageSize = 3;
    var idsToFetch = trackedKeywords.map(s => s.hash);

    const fetchNextPage = () => {
      if (idsToFetch.length > 0){
        const nextIds = idsToFetch.slice(0, pageSize);

        // go fetch update for this page
        updateSavedSearches({'hash__in': nextIds, 'sync': true}, () => {
          // fetch next page if there are more left
          idsToFetch = idsToFetch.filter(i => !nextIds.includes(i));
          fetchNextPage();
        })
      }
    }

    fetchNextPage();
  }

  // display utils
  const isProSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "pro");
  const isEffectiveProSubscriber = isProSubscriber || (currentUser.trial && currentUser.trial.plan === 'pro');

  return (
    <Layout currentUser={currentUser} >
      <Helmet><title>Conversations</title></Helmet>
      
      <ErrorBoundary>
        <div className="">
          <div className="flex items-center flex-wrap sm:-mt-3 mb-6 pb-5 border-b border-solid border-gray-800">
            <div>
              <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">
                  Conversations
                </h2>
              </div>
            </div>
            <div className="ml-auto flex">

              <Link to={`/conversations/info/`}
                type="button"
                className="appearance-none ml-3 inline-flex items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
              >
                <InformationCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Info
              </Link>
            </div>
          </div>

          {/*{true || isEffectiveProSubscriber ? (
            <div className="">
              <div className={`${true ||  isEffectiveProSubscriber ? '' : 'opacity-50 pointer-events-none'}`}>
                <div className="mb-4">
                  <Browse history={history} isEffectiveProSubscriber={isEffectiveProSubscriber}/>
                </div>
              </div>
            </div>
          ) : ''}*/}

          {(fetchingTrackedKeywords && !trackedKeywords.length) ? (
            <div className={isEffectiveProSubscriber ? 'h-64 relative' : 'h-64'}>
              <LoaderFancy />
            </div>
          ) : (
            <div className="w-full my-4 grid lg:grid-cols-3 lg:space-x-8">
              
              <div className="md:col-span-2">

                <div className={``}>
                  <TrackedKeywordsList
                    // savedSearches={trackedKeywords.sort((a,b) => (a.stats.efficiency < b.stats.efficiency) ? 1 : ((b.stats.efficiency < a.stats.efficiency) ? -1 : 0))}
                    savedSearches={trackedKeywords}
                    collections={audiences}
                    refetch={syncPaginated}
                    loading={fetchingTrackedKeywords || updating}
                    updatingHashes={updatingHashes}
                    currentUser={currentUser} history={history}
                  />
                </div>

                {/*{!isEffectiveProSubscriber ? (
                  <Link to="/account/subscription/" className="bg-gray-800 hover:bg-gray-700 p-4 rounded-md mt-8 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
                    <div>Keyword tracking is available in GummySearch Pro</div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-auto h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </Link>
                ) : ''}*/}
              </div>

              <div className="md:col-span-1 mt-4 lg:mt-0">
                <SavedConversationsList savedSearches={savedLists} loading={fetchingSavedLists} />
              </div>
            </div>
          )}

          <ErrorBoundary>
            <ModalRoute component={TrackedKeywordDrawer}
              path={`/conversations/tracked/:savedSearchHash/`}
              parentPath={`/conversations/`}
              props={{
                'currentUser': currentUser,
                'refetchAll': fetchAllSavedSearches, // Does a replace, b/c this is called when someone deletes
                'clearSavedSearchUnread': clearSavedSearchUnread
              }}
            />
            <ModalRoute component={SavedConversationsDrawer}
              path={`/conversations/saved/:savedSearchHash/`}
              parentPath={`/conversations/`}
              props={{
                'currentUser': currentUser,
                'refetchAll': fetchAllSavedSearches, // TODO: updated just lists? But that wouldn't help if they just deleted
              }}
            />
            <ModalContainer />
          </ErrorBoundary>
        </div>
        
        {error ? (
          <ErrorBar error={error} setError={setError} />
        ) : ''}

        <ModalRoute
          path={`/conversations/info/`}
          parentPath={`/conversations/`}
          component={ConversationsInfoModal}
          props={{
            'currentUser': currentUser,
          }}
        />
        <ModalContainer />
      </ErrorBoundary>
    </Layout>
  );
}


const mapStateToProps = state => {
  const {
    fetchingTrackedKeywords,
    trackedKeywords,
    fetchingSavedLists,
    savedLists,
    updating,
    updatingHashes,
  } = state.savedSearch;
  const {
    audiences,
    fetchingAudiences
  } = state.audiences;

  return {
    fetchingTrackedKeywords,
    trackedKeywords,
    fetchingSavedLists,
    savedLists,
    updating,
    updatingHashes,

    audiences,
    fetchingAudiences,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAudiences = (callback, onError) => {
    dispatch(audiencesOperations.fetchAudiences(callback, onError))
  };
  const fetchAllSavedSearches = (callback, onError) => {
    dispatch(savedSearchOperations.fetchAllSavedSearches(callback, onError))
  };
  const updateSavedSearches = (params, callback, onError) => {
    dispatch(savedSearchOperations.updateSavedSearches(params, callback, onError))
  };
  const clearSavedSearchUnread = (hash) => {
    dispatch(savedSearchOperations.clearSavedSearchUnread(hash))
  };

  return {
    fetchAudiences,
    fetchAllSavedSearches,
    updateSavedSearches,
    clearSavedSearchUnread,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversations);
