import React from "react";
import ReactTooltip from 'react-tooltip';

const SubredditTags = ({
  subreddit,
  tags,
  toolTipPlace,
  tooltipPrefix,
  tagClassName,
  style, // either "link" or not set, in that case it's a button
}) => {

  return (
    <div className="flex flex-wrap items-center">
      <ReactTooltip id={`${tooltipPrefix}subreddit-tags-${subreddit.name}`} place={toolTipPlace || "top"} effect="solid" backgroundColor="white" textColor="black" />
                
      {tags.map((t) => (
        <div key={t.key} 
          className={`${style === "link" ? 'text-sm border-b border-dashed mr-2 border-gray-500 hover:border-gray-400 cursor-pointer' : tagClassName || 'text-xs py-1 px-2 text-xs mr-2 mb-1 bg-gray-600 rounded-sm cursor-pointer '}`}
          data-for={`${tooltipPrefix}subreddit-tags-${subreddit.name}`} data-tip={t.description}
        >
          {t.name}
        </div>
      ))}
    </div>
  );
}

export default SubredditTags;

