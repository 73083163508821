import React, { useState, useEffect, Fragment } from "react"
import { connect } from 'react-redux';

import {
  Switch,
  Route,
  Link
} from "react-router-dom";
import { ModalRoute, ModalContainer } from 'react-router-modal';
import { Helmet } from "react-helmet";
import {
  PlusIcon, InformationCircleIcon, ViewListIcon, ViewBoardsIcon,
} from '@heroicons/react/outline'
import { Transition } from '@headlessui/react'


import Layout from "../../components/layout";
import LoaderFancy from "../../components/common/loaderfancy";
import ErrorBar from "../../components/common/errorBar";
import ErrorBoundary from "../../components/common/errorboundary";
import SelectPopover from "../../components/common/selectPopover";

import AudienceCard from "../../components/audiences/audiencecard"
import NameAudience from "../../components/audiences/nameaudience"
// import AudiencesNewModal from "../../components/audiences/audiencesNewModal"
// import AudiencesInfoModal from "../../components/audiences/audiencesInfoModal"

import { audiencesOperations } from "../../redux/audiences"
import { uiOperations } from "../../redux/ui"
import { collectionsService } from '../../services/collections';

import { classNames } from '../../utils/tailwind';
import { localStorageAvailable } from '../../utils/localStorage';



const Audiences = ({
   history, 
   location, 
   match,  
   currentUser, 
   audiences,  
   audienceItems,
   fetchingAudiences, 
   fetchAudiences,
   fetchAudienceItems,
   fetchingAudienceItems,
   hasFetchedAudienceItems,
   audienceDisplayType,
   audienceSort,
   setUiState,
}) => {
  //state
  const [error, setError] = useState(null);
  const [reordering, setReordering] = useState(false);
  const sortOptions = ['Recent', 'Largest', 'Growth'];
  const sortOption = audienceSort;
  // const displayType = audienceDisplayType; // not showing the toggle anymore
  const displayType = 'card';
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!fetchingAudiences && !audiences.length){
      fetchAudiences();
    }
    if (!fetchingAudienceItems && !audienceItems.length){
      fetchAudienceItems([]); // function sig takes hashes, but we're going to get all of them instead
    }
  }, []); // Load up lists of all users audiences

  // useEffect(() => {
  //   if (audiences && audiences.length && !audienceItems.length){
  //     fetchAudienceItems(audiences.map(a => a.hash))
  //   }
  // }, [audiences]); // Get all audience items if we dont have them yet

  // actions
  const deleteAudience = (audience) => {
    setError(null)
    if (window.confirm("Are you sure?")){
      collectionsService.deleteCollection(audience.hash, (results) => {
        fetchAudiences();
        // history.push(`/audiences/`);
      }, (response, error) => {
        setError(error)
      })
    }
  }

  // sort the audiences
  var sortedAudiences = audiences || [];
  const loading = fetchingAudiences;
  if (sortOption === "Largest"){
    sortedAudiences = sortedAudiences.sort((a,b) => (a.calculated.current_members < b.calculated.current_members) ? 1 : ((b.calculated.current_members < a.calculated.current_members) ? -1 : 0));
  } else if (sortOption === "Growth"){
    // first sort by daily growth, then weekly, then monthly
    sortedAudiences = sortedAudiences.sort((a,b) => (a.calculated.growth_members_daily < b.calculated.growth_members_daily) ? 1 : ((b.calculated.growth_members_daily < a.calculated.growth_members_daily) ? -1 : 0))
    sortedAudiences = sortedAudiences.sort((a,b) => (a.calculated.growth_members_weekly < b.calculated.growth_members_weekly) ? 1 : ((b.calculated.growth_members_weekly < a.calculated.growth_members_weekly) ? -1 : 0))
    sortedAudiences = sortedAudiences.sort((a,b) => (a.calculated.growth_members_monthly < b.calculated.growth_members_monthly) ? 1 : ((b.calculated.growth_members_monthly < a.calculated.growth_members_monthly) ? -1 : 0))
  
  } else if (sortOption === "Recent"){
    // sort by recently added via API
    sortedAudiences = sortedAudiences.sort((a,b) => (a.id < b.id) ? 1 : ((b.id < a.id) ? -1 : 0));

    // now use localstorage state to see recently visited
    const key = 'audience-view-order'
    if (localStorageAvailable() && localStorage.getItem(key)){
      const order = localStorage.getItem(key);
      const getHashOrder = (hash) => {
        if (order.includes(hash)){
          return order.indexOf(hash)
        }
        return 1000000; // just a high value
      }
      sortedAudiences = sortedAudiences.sort((a,b) => getHashOrder(a.hash) > getHashOrder(b.hash) ? 1 : ((getHashOrder(b.hash) > getHashOrder(a.hash)) ? -1 : 0));
    }
  }

  // filter if we have a audience/subreddit name in there (save IDs only)
  var filteredAudienceIds = sortedAudiences.map(s => s.id)
  if (searchTerm){
    filteredAudienceIds = sortedAudiences.filter((a) => {
      return a.name.toLowerCase().includes(searchTerm.toLowerCase()) || a.subreddit_names.join(' ').toLowerCase().includes(searchTerm.toLowerCase())
    }).map(s => s.id)
  };

  return (
    <div>
      <Helmet><title>Audiences</title></Helmet>
      
      <ErrorBoundary>
        {audiences && audiences.length ? (
          <div className={`${reordering ? 'opacity-50' : ''}`}>

            <div className="flex items-center mb-2 flex-wrap">
              <h2 className="text-lg font-medium flex items-center flex-wrap">
                <span>Your Saved Audiences</span>

                <p className="ml-2 text-gray-500 truncate">
                  {sortedAudiences.length !== audiences.length ? (
                    <span>{sortedAudiences.length} / {audiences.length}</span>
                  ) : (
                    <span>{audiences.length}</span>
                  )}
                </p>
              </h2>


              <div className="ml-auto flex">

                {audiences.length >= 2 ? (
                  <div className="ml-4 flex items-center">
                    <div className="text-xs opacity-50 hidden sm:block">Sort</div>
                    <div className="ml-2 flex items-center">
                      <SelectPopover
                        options={sortOptions}
                        currentOption={sortOption}
                        setCurrentOption={(s) => setUiState('audienceSort', s)}
                        labelField={undefined}
                      />
                    </div>
                  </div>
                ) : ''}

                {/*{audiences.length >= 2 ? (
                  <div className="ml-4 flex items-center">
                    <div className="text-xs opacity-50 hidden sm:block">Display</div>
                    <div className="ml-2 flex items-center space-x-1">
                      <ViewBoardsIcon
                        className={`cursor-pointer h-5 w-5 ${displayType === 'card' ? 'text-gray-200' : 'text-gray-500 hover:text-gray-300'}`} 
                        onClick={() => setUiState('audienceDisplayType', 'card')} />
                      <ViewListIcon
                        className={`cursor-pointer h-5 w-5 ${displayType === 'row' ? 'text-gray-200' : 'text-gray-500 hover:text-gray-300'}`} 
                        onClick={() => setUiState('audienceDisplayType', 'row')} />
                    </div>
                  </div>
                ) : ''}*/}

                {audiences.length >= 5 ? (
                  <div className="ml-4 flex items-center">
                    <div className="text-xs opacity-50 hidden sm:block">Search</div>
                    <input
                      type="text"
                      name="name"
                      autoComplete="off"
                      className={`ml-2 bg-gray-800 focus:bg-gray-700 border-transparent focus:border-gray-700 placeholder-gray-500 text-white block text-black w-full text-xs rounded-sm p-1 px-2 focus:ring-cyan-500 focus:border-cyan-500`}
                      placeholder={`Audience/Subreddit`}
                      style={{minWidth: '50px'}}
                      value={searchTerm} onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    />
                  </div>
                ) : ''}

                
              </div>
            </div>

            <Transition show={true} as={Fragment}
              enter="transition transform ease-in duration-100"
              enterFrom="opacity-50 translate-y-1"
              enterTo="opacity-100 translate-y-0"
            >
              <div className={`grid grid-cols-1 ${displayType === 'card' ? 'md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6' : 'gap-2'}`}>
                {sortedAudiences.map(audience => (
                  <AudienceCard
                    key={audience.hash} audience={audience}
                    hidden={!filteredAudienceIds.includes(audience.id)}
                    displayType={displayType}
                    bulkFetchedItems={audienceItems.filter(i => i.collection_hash === audience.hash)}
                    fetchingAudienceItems={!hasFetchedAudienceItems || fetchingAudienceItems}
                    deleteAudience={deleteAudience}
                  />
                ))}  

                
                <Link to="/audiences/new/" className={`${displayType === 'card' ? 'py-8' : 'py-4'} rounded-md shadow-lg border-2 border-dashed text-gray-400 border-gray-800 hover:border-gray-700 hover:text-gray-200 text-white flex flex-col text-center justify-center`}>
                  Make a new audience  
                </Link>
                
              </div>
            </Transition>

            {audiences.length && (sortedAudiences.length === 0) ? (
              <div className="p-20 text-center opacity-25">No results for this search term</div>
            ) : ''}
          </div>
        ) : loading ? (
          <div className="block mt-36">
            <LoaderFancy />
          </div>
        ) : (
          <div className="w-full mx-auto sm:max-w-5xl">
            <NameAudience showHeader={true} noPublicScroll={true} showIcons={true} />
          </div>
        )}

        {error ? (
          <ErrorBar error={error} setError={setError} />
        ) : ''}
      </ErrorBoundary>

      {/*<ModalRoute
        path={`/audiences/new/`}
        parentPath={`/audiences/`}
        component={AudiencesNewModal} />
      <ModalRoute
        path={`/audiences/info/`}
        parentPath={`/audiences/`}
        component={AudiencesInfoModal} />*/}
      {/*<ModalContainer />*/}
    </div>
  );
}

const mapStateToProps = state => {
  const {
    audiences,
    fetchingAudiences,
    audienceItems,
    fetchingAudienceItems,
    hasFetchedAudienceItems,
  } = state.audiences;
  const {
    audienceDisplayType,
    audienceSort,
  } = state.ui;

  return {
    audiences,
    audienceItems,
    fetchingAudiences,
    fetchingAudienceItems,
    hasFetchedAudienceItems,

    audienceDisplayType,
    audienceSort,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAudiences = (callback, onError) => {
    dispatch(audiencesOperations.fetchAudiences(callback, onError))
  };
  const fetchAudienceItems = (hashes, callback, onError) => {
    dispatch(audiencesOperations.fetchAudienceItems(hashes, callback, onError))
  };
  const setUiState = (key, value) => {
    dispatch(uiOperations.setUiState(key, value))
  };
  return {
    fetchAudiences,
    fetchAudienceItems,
    setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Audiences);
