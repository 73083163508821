import React, { useState, useEffect } from "react";
import ReactTooltip from 'react-tooltip';
import { SparklesIcon } from '@heroicons/react/outline'

import { searchesService } from '../../services/searches';
import { redditService } from '../../services/reddit';

import SubmissionTypeIcon from "./submissiontypeicon";
import SubmissionBody from "./submissionbody";
import SubmissionComments from "./submissioncomments";
import SubmissionCommentAggregates from "./submissioncommentaggregates";
import SubmissionAnalyze from "./submissionanalyze";
// import SubmissionAnalyze from "./submissionanalyze";
import ErrorBar from "../common/errorBar";

import SubredditPopover from "./subredditpopover";
import RedditorPopover from "./redditorpopover";
// import BookmarkConversationModal from "../old_saved/bookmarkconversationmodal";
import ConversationListPopover from '../conversations/conversationlistpopover'

import { styleKeyword, textContainsKeyword } from '../../utils/text';
import { timeStampToFullTime } from '../../utils/time';
import { trackEvent } from '../../utils/tracking';
import { splitKeywords } from '../../utils/reddit';


const SubmissionContents = ({
  submission,
  close,
  keyword,
  hideClose,
  onRedirect,
  bgClass,
  topBorder,
  hideHeader,
  currentUser,
}) => {
  const redirected = false; // TODO: make dynamic?
  const [viewed, setViewed] = useState(submission.viewed || false);

  // since user can save convo from this component, we have an updated state here
  const [savedListsUpdated, setSavedListsUpdated] = useState(false);
  const [updatedSavedLists, setUpdatedSavedLists] = useState([]);
  const savedLists = savedListsUpdated ? updatedSavedLists : submission.saved_conversations || [];

  // state here b/c it's used by both comments + aggregate view
  const [comments, setComments] = useState([]);
  const [topLevelComments, setTopLevelComments] = useState([]);
  const [commentAggregates, setCommentAggregates] = useState({});
  const [fetchingComments, setFetchingComments] = useState(false);
  const [error, setError] = useState(null);

  // effects
  useEffect(() => {
    // record view if we haven't already
    if (submission && !submission.viewed){
      searchesService.saveView({conversation_type: "RSU", platform_id: submission.id, platform_name: submission.title});
      setViewed(true)
    }

     // reset if we just switched submissions
    setComments([]);
    setTopLevelComments([]);
  }, [submission && submission.id]);

  // actions
  const fetchComments = (callback) => {
    // fetch replies to the submission
    if (!submission || !submission.num_comments) return;

    setFetchingComments(true)
    setError(null)
    redditService.fetchReplies({'submission_id': submission.id}, (response) => {
      constructCommentTree(response.data.results); // do this one before the setComments below
      setComments(response.data.results);
      setCommentAggregates(response.data.aggregates)
      // TODO: need to handle new response if we have > 100 total nested!
      setFetchingComments(false)

      if (callback){
        callback(response.data.results)
      }
    }, (error, response) => {
      console.error(response)
      setError(response || 'server error fetching comments')
    });
  }

  // constructs nested comment tree
  const constructCommentTree = (results) => {
    // flat comments (at any level in the hierarchy)
    const flatComments = results;

    // get a map of each parent id's comments
    var childrenDict = {}
    flatComments.forEach((c) => {
      // put into dict if it has a parent
      if (c.parent_id){
        if (childrenDict[c.parent_id]){
          childrenDict[c.parent_id].push(c);
        } else {
          childrenDict[c.parent_id] = [c];
        }
      }
    })

    const getCommentReplies = (id) => {
      // recursively get the comment's replies
      if (childrenDict[id]){
        var replies = childrenDict[id];
        replies.forEach(r => {
          r.replies = getCommentReplies(r.id);
        })
      } else {
        var replies = []
      } 
      return replies
    }

    // now we construct the top-level comments, and add in the replies (and their replies)
    var topLevelComments = flatComments.filter(c => !c.parent_id).map(c => {
      c.replies = getCommentReplies(c.id)  // recursive
      return c
    })

    setTopLevelComments(topLevelComments)
  }



  // display utils

  // tabs for the submission (not using urls on this one b/c we could have query params)
  var tabs = [
    {'slug': 'submission', name: 'Submission'},
    {'slug': 'comments', name: `${submission ? `${submission.num_comments} ` : ''}Comment${submission && submission.num_comments === 1 ? '' : 's'}`, disabled: (!submission || !submission.num_comments)},
    // {'slug': 'aggregates', name: 'Comment Aggregate', disabled: (!submission || !submission.num_comments)},
    {'slug': 'analyze', name: 'Summary', 'icon': SparklesIcon, iconColor: 'text-gray-600'},
  ]
  // if (currentUser && currentUser.features.analyze && currentUser.features.analyze_submission){
  //   tabs.push({'slug': 'analyze', name: 'Summarize'})
  // }
  const [currentTab, setCurrentTab] = useState(tabs[0]); 

  // when nothing loaded yet
  if (!submission) return ''

  const link = `https://www.reddit.com${submission.link}`;

  // find matching keyword (if exists, also handle list)
  // TODO: match more than one keyword?
  // TODO confirm we should be matching on body_html and not body
  var matchingKeyword = null;
  if ((submission.title || submission.body_html) && keyword){
    splitKeywords(keyword).forEach(k => {
      if (!matchingKeyword && (textContainsKeyword(submission.title, k) || textContainsKeyword(submission.body_html, k))){
        matchingKeyword = k;
      }
    })
  }
	
  return (
    <div id={`submission-content`} className={`${bgClass || ''}`} style={{minHeight: '300px'}}>

      {!hideHeader ? (
        <div className="items-center justify-between space-x-4 mb-2">
          <div className="min-w-0 space-y-3">
            <div className="flex items-top">
              {!hideClose ? (
                <div className="flex items-center ml-auto text-smh-6">
                  <button onClick={close} className="rounded-md text-gray-400 hover:text-gray-300 focus:outline-none flex items-center">
                    <span className="sr-only">Close panel</span>
                    <svg className="h-5 w-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    {/*Close*/}
                  </button>
                </div>
              ) : ''}

              <div className="block pl-4 max-w-full w-full">
                <h2 className="text-xl font-bold break-words">
                  <div className="flex items-top">

                    <span className={`containsKeywords leading-6 tripleLineClip ${submission.is_removed || submission.is_deleted ? 'opacity-50' : ''}`}
                      dangerouslySetInnerHTML={{__html: styleKeyword(submission.title, matchingKeyword)}}
                    ></span>

                    {submission.over_18 ? (
                      <span className="h-6 inline-flex items-center flex-grow-0 ml-2 px-1.5 py-0.5 rounded-md text-xs font-medium bg-gray-700 text-gray-100">
                        18+
                      </span>
                    ) : ''}

                    {submission.is_removed ? (
                      <span className="h-6 inline-flex items-center flex-grow-0 ml-2 px-1.5 py-0.5 rounded-md text-xs font-medium bg-gray-700 text-gray-100">
                        Removed
                      </span>
                    ) : ''}

                    {submission.is_deleted ? (
                      <span className="h-6 inline-flex items-center flex-grow-0 ml-2 px-1.5 py-0.5 rounded-md text-xs font-medium bg-gray-700 text-gray-100">
                        Deleted
                      </span>
                    ) : ''}
                  </div>
                </h2>
              </div>
            </div>

            <div className="flex items-center flex-wrap">
              <div className="relative flex items-center space-x-2 -ml-2 text-gray-600">
                    
                <ReactTooltip id={`content-tooltip`} effect="solid" place="right" backgroundColor="white" textColor="black"/>

                <ConversationListPopover
                  // onSelect={(s) => console.log("selected", s)}
                  selectedIds={[submission.id]} selectedType={'submission'}
                  currentLists={savedLists}
                  // getPopoverParentElement={() => parentListElement || document.getElementById(`submission-${submission.id}`)}
                  getPopoverParentElement={() => document.getElementById(`submission-content`)}
                  positions={['bottom', 'right', 'top', 'left']}
                  onAdd={(list) => {
                    setUpdatedSavedLists([...savedLists, list])
                    setSavedListsUpdated(true)
                  }}
                  onRemove={(list) => {
                    setUpdatedSavedLists(savedLists.filter(s => s !== list))
                    setSavedListsUpdated(true)
                  }}
                >
                  {savedLists.length === 0 ? (
                    <span data-for={`content-tooltip`} data-tip="Bookmark this conversation" className="relative text-gray-500 hover:text-gray-200">
                      <SubmissionTypeIcon type={'bookmark'} className="mx-auto self-center h-6 w-6 mx-auto cursor-pointer" fill={'rgba(8, 145, 178,0.15)'}/>
                      <div className="hidden absolute w-6 h-6 top-0.5 text-center font-semibold text-xs text-gray-200">
                        +
                      </div>
                    </span>
                  ) : (
                    <div className="flex flex-col gap-y-2">
                      {savedLists.map(s => (
                        <span className="h-6" key={submission.hash} data-for={`content-tooltip`} data-tip={`Bookmarked: ${s.saved_search_name}`}>
                          <SubmissionTypeIcon type={'bookmark'} stroke={s.saved_search_color} fill={`${s.saved_search_color}75`} className="mx-auto self-center h-6 w-6 mx-auto cursor-pointer text-gray-400 hover:text-gray-200" />
                        </span>
                      ))}
                    </div>
                  )}
                </ConversationListPopover>

                <span aria-hidden="true">&middot;</span>

                {submission.is_removed || submission.is_deleted ? (
                  <SubmissionTypeIcon type={'removed'} />
                ) : (
                  <SubmissionTypeIcon type={submission.type} className="self-center flex-shrink-0 h-5 w-5 text-gray-500" />
                )}
                <span aria-hidden="true">&middot;</span>

                
                <span className="text-sm text-gray-500 font-medium flex">
                  <svg className="self-center flex-shrink-0 h-4 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                  </svg>{submission.score}
                </span>
                <span aria-hidden="true">&middot;</span>
                <span className="text-sm text-gray-500 font-medium flex">
                  
                  <svg className="self-center flex-shrink-0 h-4 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                  </svg>{submission.num_comments}
                </span>
                <span aria-hidden="true">&middot;</span>
                
                <SubredditPopover name={submission.subreddit_name} openOnClick={true} openOnHover={true} getPopoverParentElement={() => document.getElementById(`submission-content`)}>
                  <span className="text-sm text-gray-500 hover:text-white font-medium truncate">
                    {submission.subreddit_name}
                  </span>
                </SubredditPopover>

                {redirected || submission.redirected ? (
                  <React.Fragment>
                    <ReactTooltip id={`redirected-${submission.id}`} effect="solid" place="right" backgroundColor="white" textColor="black" />
                    <span aria-hidden="true">&middot;</span>
                    <span className="text-sm text-gray-400 font-medium flex"
                       data-for={`redirected-${submission.id}`} data-tip="You've clicked out to this submission on Reddit"
                    >
                      <SubmissionTypeIcon type={'clicked'} className="self-center text-gray-500 h-6 w-5 mx-auto opacity-80 hover:opacity-100"/>
                    </span>
                    
                  </React.Fragment>
                ) : viewed || submission.viewed ? (
                  <React.Fragment>
                    <ReactTooltip id={`viewed-${submission.id}`} effect="solid" place="right" backgroundColor="white" textColor="black" />
                    <span aria-hidden="true">&middot;</span>
                    <span className="text-sm text-gray-500 font-medium flex"
                       data-for={`viewed-${submission.id}`} data-tip="You've viewed this submission on GummySearch"
                    >
                      <SubmissionTypeIcon type={'viewed'} className="self-center text-gray-500 h-6 w-5 mx-auto opacity-80 hover:opacity-100"/>
                    </span>
                    
                  </React.Fragment>
                ) : ''}
              </div>
              <div className="relative ml-auto flex-col flex-shrink-0 items-end space-y-3">
                <div className="flex text-gray-500 text-sm space-x-2">
                  <RedditorPopover name={submission.author} openOnClick={true} openOnHover={true} getPopoverParentElement={() => document.getElementById(`submission-content`)}>
                    <span className="hidden sm:block text-gray-500 hover:text-white">{submission.author}</span>
                  </RedditorPopover>
                  <span className="hidden sm:block" aria-hidden="true">&middot;</span>
                  <span>{timeStampToFullTime(submission.timestamp_utc)}</span>
                  {/*<span aria-hidden="true">&middot;</span>
                  <span>United states</span>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : ''}

      <div className="flex items-center border-b border-gray-800">
        <nav className="-mb-px flex space-x-2 max-w-full overflow-x-auto no-scrollbar" x-descriptions="Tab component">
          {tabs.map((tab) => (
            <div
              key={tab.name}
              // to={tab.link}
              disabled={tab.disabled}
              onClick={() => setCurrentTab(tab)}
              className={`whitespace-nowrap cursor-pointer py-4 px-2 flex items-center border-solid border-b-2 font-medium focus:outline-none focus:ring-none text-sm ${currentTab.name === tab.name ? 'border-gray-500 text-white' : 'border-transparent text-gray-400 hover:text-gray-300 hover:border-gray-500'} ${tab.disabled ? 'text-gray-700' : ''}`}
            >
              <span>{tab.prefix !== undefined ? `${tab.prefix} ` : ''}{tab.name}</span>

              {false && tab.disabled ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              ) : ''}

              {tab.icon ? (
                <tab.icon className={`${tab.iconColor} ml-1 h-4 w-4`} />
              ) : ''}
            </div>
          ))}
        </nav>

        <div className="ml-auto flex items-center space-x-4">

          <a href={link} target={"_blank"} rel="noreferrer"
            onClick={() => {
              trackEvent("ExternalRedirect", {
                "platform": "Reddit",
                "type": "Submission",
                "platform_id": submission.id,
              });
              searchesService.saveRedirect("RSU", submission.id, submission.title);
              if (onRedirect){
                onRedirect(submission.id)
              }
            }}
          >
            <button style={{backgroundColor: '#FF4500'}} type="button" className="inline-flex px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md opacity-90 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
              <svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"/>
              </svg>
              <span>Reply <span className="hidden lg:inline">on Reddit</span></span>
            </button>
          </a>
        </div>
      </div>

      <div id="submission-subnav-content" className={`bg-gray-900 rounded-xs pb-6`}>
        {currentTab.slug === 'submission' ? (
          <SubmissionBody submission={submission} keyword={matchingKeyword} currentUser={currentUser} />
        ) : currentTab.slug === 'comments' ? (
          <SubmissionComments submission={submission} keyword={matchingKeyword} currentUser={currentUser}
            comments={comments} topLevelComments={topLevelComments}
            fetchComments={fetchComments} loading={fetchingComments}/>
        ) : currentTab.slug === 'aggregates' ? (
          <SubmissionCommentAggregates submission={submission} currentUser={currentUser}
            comments={comments} topLevelComments={topLevelComments}
            aggregates={commentAggregates}
            fetchComments={fetchComments} loading={fetchingComments}/>
        ) : currentTab.slug === 'analyze' ? (
          <SubmissionAnalyze submission={submission} keyword={matchingKeyword} currentUser={currentUser}
            comments={comments} topLevelComments={topLevelComments}
            fetchComments={fetchComments} loadingComments={fetchingComments}
          />
        ) : ''}
      </div>

      {error ? (
        <ErrorBar error={error} setError={setError} onRetry={fetchComments}/>
      ) : ''}    

    </div>
  );
}

export default SubmissionContents;

