// query MyQuery {
//   allMdx(
//     filter: {
//       frontmatter: {
//         type: {in: ["insight", "how-to", "demo"]},
//         style: {in: ["BP", "VI"]},
//         published: {ne: ""},
//         // objectives: {ne: ""},
//     }},
//     sort: { order: ASC, fields: [frontmatter___published] }
//   ) {
//     edges {
//       node {
//         timeToRead
//         frontmatter {
//           slug
//           published
//           author
//           title
//           type
//           description
//           imageUrl
//           objectives
//           style
//         }
//       }
//     }
//     totalCount
//   }
// }

// see above query for resources. pull "nodes" and display
const resources = [
        {
          "node": {
            "timeToRead": 7,
            "frontmatter": {
              "slug": "/insights/idea-validation/",
              "published": "2021-09-23",
              "author": "Fed",
              "title": "How to validate your product idea",
              "type": "insight",
              "description": "6 simple steps to ensure your product idea is necessary, marketable, and primed for success",
              "imageUrl": "https://unsplash.com/photos/7e2pe9wjL9M/download?h=200",
              "objectives": "VS,FP",
              "style": "BP"
            }
          }
        },
        {
          "node": {
            "timeToRead": 5,
            "frontmatter": {
              "slug": "/insights/reddit-startups/",
              "published": "2021-09-24",
              "author": "Fed",
              "title": "A startup founder's guide to Reddit",
              "type": "insight",
              "description": "Here are 10 lessons I've learned about Reddit over the past 10 years that startup founders can take advantage of.",
              "imageUrl": "../../images/graphics-reddit.jpeg",
              "objectives": "MR",
              "style": "BP"
            }
          }
        },
        {
          "node": {
            "timeToRead": 6,
            "frontmatter": {
              "slug": "/insights/online-communities/",
              "published": "2021-09-28",
              "author": "Fed",
              "title": "Online communities at every stage of the startup journey",
              "type": "insight",
              "description": "Product makers can tap into online communities in 5 ways to advance their business.",
              "imageUrl": "https://unsplash.com/photos/RVZ5mT-HvN4/download?h=200\"",
              "objectives": "MR",
              "style": "BP"
            }
          }
        },
        {
          "node": {
            "timeToRead": 5,
            "frontmatter": {
              "slug": "/how-to/discover-subreddits/",
              "published": "2021-10-13",
              "author": "Fed",
              "title": "How to discover Subreddits using GummySearch",
              "type": "how-to",
              "description": "GummySearch make it easy to discover Subreddits full of your target audience, so that you can ideate/validate/grow your business.",
              "imageUrl": "https://www.slashgear.com/wp-content/uploads/2020/07/redddit-Main-1280x720.jpg",
              "objectives": "",
              "style": "BP"
            }
          }
        },
        {
          "node": {
            "timeToRead": 6,
            "frontmatter": {
              "slug": "/how-to/find-problems-to-solve/",
              "published": "2021-11-14",
              "author": "Fed",
              "title": "How to find problems to solve on Reddit",
              "type": "how-to",
              "description": "Online communities are a treasure trove of inspiration for product and business ideas.",
              "imageUrl": "https://unsplash.com/photos/lPX6-ee-1zU/download?h=200",
              "objectives": "FP,CO,MR",
              "style": "BP"
            }
          }
        },
        {
          "node": {
            "timeToRead": 4,
            "frontmatter": {
              "slug": "/insights/social-listening/",
              "published": "2021-11-15",
              "author": "Fed",
              "title": "Social Listening for Startups",
              "type": "insight",
              "description": "An introduction to social listening and why it's an incredibly useful tool for early-stage startups.",
              "imageUrl": "https://unsplash.com/photos/mS20cgMWaPw/download?h=200\"",
              "objectives": "CU,BR",
              "style": "BP"
            }
          }
        },
        {
          "node": {
            "timeToRead": 5,
            "frontmatter": {
              "slug": "/how-to/validate-your-idea/",
              "published": "2021-11-18",
              "author": "Fed",
              "title": "How to land validation interviews with GummySearch",
              "type": "how-to",
              "description": "How to find potential customers to talk to in order to validate your business idea",
              "imageUrl": "https://unsplash.com/photos/Y_LgXwQEx2c/download?h=200",
              "objectives": "VS",
              "style": "BP"
            }
          }
        },
        {
          "node": {
            "timeToRead": 3,
            "frontmatter": {
              "slug": "/insights/keywords-to-track-on-reddit/",
              "published": "2021-11-28",
              "author": "Fed",
              "title": "Keywords you should be tracking on Reddit",
              "type": "insight",
              "description": "Learn what to track on Reddit to discover customer acquisition opportunities.",
              "imageUrl": "https://unsplash.com/photos/oHoBIbDj7lo/download?h=200",
              "objectives": "CU",
              "style": "BP"
            }
          }
        },
        {
          "node": {
            "timeToRead": 6,
            "frontmatter": {
              "slug": "/how-to/find-customers-with-social-listening/",
              "published": "2021-11-28",
              "author": "Fed",
              "title": "How to find customers on Reddit with social listening",
              "type": "how-to",
              "description": "Learn how to use GummySearch to find customers for your business on Reddit.",
              "imageUrl": "https://unsplash.com/photos/V5vqWC9gyEU/download?h=200",
              "objectives": "CU",
              "style": "BP"
            }
          }
        },
        {
          "node": {
            "timeToRead": 1,
            "frontmatter": {
              "slug": "/demos/how-to-search-subreddits/",
              "published": "2022-07-07",
              "author": "Fed",
              "title": "How to search for Subreddits",
              "type": "demo",
              "description": "60-second video demo of discovering Subreddits on Reddit using GummySearch",
              "imageUrl": "https://img.youtube.com/vi/phN1HSgmTO4/maxresdefault.jpg",
              "objectives": "",
              "style": "VI"
            }
          }
        },
        {
          "node": {
            "timeToRead": 1,
            "frontmatter": {
              "slug": "/demos/customer-discovery/",
              "published": "2022-07-07",
              "author": "Fed",
              "title": "Customer Discovery using Reddit",
              "type": "demo",
              "description": "60-second video demo of the GummySearch customer discovery features",
              "imageUrl": "https://img.youtube.com/vi/bxLdW8-WaAE/maxresdefault.jpg",
              "objectives": "FP",
              "style": "VI"
            }
          }
        },
        {
          "node": {
            "timeToRead": 1,
            "frontmatter": {
              "slug": "/demos/subreddit-info/",
              "published": "2022-07-07",
              "author": "Fed",
              "title": "Subreddit Stats & Details",
              "type": "demo",
              "description": "60-second video demo of the GummySearch Subreddit detail view",
              "imageUrl": "https://img.youtube.com/vi/-JJcNTcaFBM/maxresdefault.jpg",
              "objectives": "MR",
              "style": "VI"
            }
          }
        },
        {
          "node": {
            "timeToRead": 1,
            "frontmatter": {
              "slug": "/demos/search-keywords-on-reddit/",
              "published": "2022-07-07",
              "author": "Fed",
              "title": "How to search keywords on Reddit",
              "type": "demo",
              "description": "60-second video demo of the GummySearch keyword search feature",
              "imageUrl": "https://img.youtube.com/vi/MQxl2Q_bwJk/maxresdefault.jpg",
              "objectives": "CU,VS,BR",
              "style": "VI"
            }
          }
        },
        {
          "node": {
            "timeToRead": 2,
            "frontmatter": {
              "slug": "/demos/tracking-keywords-on-reddit/",
              "published": "2022-07-07",
              "author": "Fed",
              "title": "How to track keywords on Reddit",
              "type": "demo",
              "description": "Video demo of the GummySearch advanced keyword tracking features",
              "imageUrl": "https://img.youtube.com/vi/nNTxXbAfLYc/maxresdefault.jpg",
              "objectives": "CU,BR",
              "style": "VI"
            }
          }
        },
        {
          "node": {
            "timeToRead": 2,
            "frontmatter": {
              "slug": "/demos/advanced-reddit-search/",
              "published": "2022-07-07",
              "author": "Fed",
              "title": "Advanced search for Reddit",
              "type": "demo",
              "description": "60-second video demo of the GummySearch advanced search for Reddit",
              "imageUrl": "https://img.youtube.com/vi/of1l75X1wys/maxresdefault.jpg",
              "objectives": "MR",
              "style": "VI"
            }
          }
        },
        {
          "node": {
            "timeToRead": 1,
            "frontmatter": {
              "slug": "/demos/growing-subreddits/",
              "published": "2022-07-07",
              "author": "Fed",
              "title": "How to find fast-growing Subreddits",
              "type": "demo",
              "description": "60-second video demo of the GummySearch trending subreddits feature",
              "imageUrl": "https://img.youtube.com/vi/TUDM6gcoC28/maxresdefault.jpg",
              "objectives": "CO,MR",
              "style": "VI"
            }
          }
        },
        {
          "node": {
            "timeToRead": 1,
            "frontmatter": {
              "slug": "/demos/save-reddit-posts/",
              "published": "2022-10-14",
              "author": "Fed",
              "title": "How to save Reddit posts",
              "type": "demo",
              "description": "Demo video of the bookmark feature and when to use it",
              "imageUrl": "https://img.youtube.com/vi/9kyNWODBdxw/maxresdefault.jpg",
              "objectives": "VS,FP",
              "style": "VI"
            }
          }
        },
        {
          "node": {
            "timeToRead": 1,
            "frontmatter": {
              "slug": "/demos/subreddit-performance/",
              "published": "2022-12-28",
              "author": "Fed",
              "title": "How to post to Reddit for maximum visibility",
              "type": "demo",
              "description": "Demo video of the subreddit performance report via GummySearch",
              "imageUrl": "https://img.youtube.com/vi/1o8bX5eS2Bg/maxresdefault.jpg",
              "objectives": "CO",
              "style": "VI"
            }
          }
        },
        {
          "node": {
            "timeToRead": 1,
            "frontmatter": {
              "slug": "/demos/analyze-reddit-patterns/",
              "published": "2023-02-13",
              "author": "Fed",
              "title": "How to analyze Reddit for common trends and patterns",
              "type": "demo",
              "description": "Demo video of the GummySearch pattern finder feature",
              "imageUrl": "https://img.youtube.com/vi/2Jxs5MsHCtY/maxresdefault.jpg",
              "objectives": "FP,CO,MR",
              "style": "VI"
            }
          }
        }
      ];

export {
    resources
}