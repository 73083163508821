import React, { useState, PureComponent } from "react";
import ReactTooltip from 'react-tooltip';

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


// import SubredditPopover from "./subredditpopover";
// import SubmissionTypeIcon from "./submissiontypeicon";

import { stDev, toHistogram, median } from '../../utils/numbers';


// custom tooltip used for the charts!
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const bin = payload[0]
    const data = bin.payload
    return (
      <div className="p-2 bg-gray-800 text-sm text-white rounded-md">
        <div className="font-semibold">
          {data.label}
        </div>
        <div>
          <span className="font-semibold mr-1">{bin.name}:</span>
          {data.filteredCount === data.count ? (
            <span>{data.count}</span>
          ) : (
            <React.Fragment>
              <span>{data.filteredCount}</span>
              <span> / {data.count}</span>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  return null;
};

const AggregateScores = ({
  results,
  filteredResults,
  frontendFilters,
  applyFrontendFilter,
  clearFrontendFilter,
  updating,
}) => {
  const MAX_COLUMNS_TO_SHOW = 10;

  // cut out early if we haven't got anything or cleared the search
  if (!results || !results.length) return ''

  // get the count of items with each upvote score
  const scores = results.map(r => r.score);
  const maxUpvotes = Math.max(...scores);
  const minUpvotes = Math.min(...scores);
  var interval = Math.abs(parseInt((Math.max(minUpvotes + 1, 0) + stDev(scores)) / MAX_COLUMNS_TO_SHOW));
  if (interval > median(scores)){
    interval = parseInt(median(scores)) // adjust if there's a huge outlier
  }
  // console.log("stdev", stDev(scores), interval, median(scores))
  const scoresHistogram = toHistogram(scores, MAX_COLUMNS_TO_SHOW, interval);
  var numUpvotes = scoresHistogram.map((c) => {
    return {
      'label': c.isLast ? `${c.min}+ Upvotes` : (c.min === c.max - 1) ? `${c.min} Upvote${c.min === 1 ? '' : 's'}` : `${c.min} to ${c.max - 1} Upvote${(c.max - 1) === 1 ? '' : 's'}`,
      'min': c.min,
      'max': c.max,
      'count': results.filter(r => r.score >= c.min && r.score < c.max).length,
      'filteredCount': filteredResults.filter(r => r.score >= c.min && r.score < c.max).length,
      'inFilters': frontendFilters && frontendFilters.score && frontendFilters.score.map(s => s.min).includes(c.min),
    }
  });
  numUpvotes = numUpvotes.map(c => {c.unfilteredCount = c.count - c.filteredCount; return c;})

  // get the count of items with each number of comments
  var numComments = []
  if (results.filter(r => r.num_comments != undefined).length){
    const comments = results.filter(r => r.num_comments != undefined).map(r => r.num_comments);
    const maxComments = Math.max(...comments);
    const minComments = Math.min(...comments);
    var interval = Math.abs(parseInt((Math.max(minComments + 1, 0) + stDev(comments)) / MAX_COLUMNS_TO_SHOW));
    if (interval > median(comments)){
      interval = parseInt(median(comments)) // adjust if there's a huge outlier
    }
    const scoresHistogram = toHistogram(comments, MAX_COLUMNS_TO_SHOW, interval);
    numComments = scoresHistogram.map((c) => {
      return {
        'label': c.isLast ? `${c.min}+ Comments` : (c.min === c.max - 1) ? `${c.min} Comment${c.min === 1 ? '' : 's'}` : `${c.min} - ${c.max - 1} Comment${c.min === 1 ? '' : 's'}`,
        'min': c.min,
        'max': c.max,
        'count': results.filter(r => r.num_comments >= c.min && r.num_comments < c.max).length,
        'filteredCount': filteredResults.filter(r => r.num_comments >= c.min && r.num_comments < c.max).length,
        'inFilters': frontendFilters && frontendFilters.comments && frontendFilters.comments.map(s => s.min).includes(c.min),
      }
    });
  }
  numComments = numComments.map(c => {c.unfilteredCount = c.count - c.filteredCount; return c;});

  // console.log("frontend filters", frontendFilters)
  return (
    <React.Fragment>
      <ReactTooltip id='aggregate-scores' place="right" effect="solid" backgroundColor="white" textColor="black" />
      
      <div className="sm:flex sm:space-x-12" disabled={updating}>

        <div className="flex-1 max-w-1/2">
          <div className="text-md font-sm mb-1 mt-4 flex items-center">
            <h2 className="opacity-75">Upvotes</h2>
            {updating ? (
              <span className="ml-2 text-xs">
                <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-50" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </span>
            ) : ''}
            <span className="ml-2 text-xs font-semibold">
              {frontendFilters && frontendFilters.score && frontendFilters.score.length ? (
                <React.Fragment>
                  <span className="opacity-50">{frontendFilters.score.length} / {numUpvotes.length}</span>
                  <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                    onClick={() => clearFrontendFilter('score')}>clear</span>
                </React.Fragment>
              ) : ''}
            </span>
          </div>

          <div className="h-36 text-black relative mb-4">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                // width={500}
                // height={300}
                margin={0}
                data={numUpvotes}
                // stroke={(a) => console.log("a", a)}
                onClick={(data) => {
                  if (data && data.activePayload && data.activePayload.length > 0) {
                    const payload = data.activePayload[0].payload;
                    applyFrontendFilter('score', {'min': payload.min, 'max': payload.max});
                  }
                }}
              >
                {/*<XAxis dataKey="min" tick={{ fontSize: 10 }} />*/}
                <Tooltip content={<CustomTooltip />} cursor={{ stroke: "rgb(75 85 99)", strokeWidth: 1, fill: "rgb(31 41 55)" }} />
                {filteredResults.length !== results.length ? (
                  <Bar dataKey="filteredCount" stackId="a" fill="#374251">
                    {numUpvotes.map((entry, index) => (
                      <Cell key={index} stroke={entry.inFilters ? '#35d399' : '' }/>
                    ))}
                  </Bar>
                ) : ''}
                <Bar dataKey={(filteredResults.length === results.length) ? "count" : "unfilteredCount"} stackId="a" fill={(filteredResults.length === results.length) ? "#374251" : "rgb(31 41 55)"}>
                  {numUpvotes.map((entry, index) => (
                    <Cell key={index} stroke={entry.inFilters ? '#35d399' : '' }/>
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            <div className="text-white flex items-center text-xs border-t border-gray-700 pb-4">
              <div className="text-gray-500">{numUpvotes[0].min} Upvote{numUpvotes[0].min === 1 ? '' : 's'}</div>
              <div className="text-gray-500 ml-auto">
                {numUpvotes[numUpvotes.length - 1].label}
              </div>
            </div>
          </div>
        </div>
        

        
        <div className="flex-1 max-w-1/2">
          {numComments.length > 0 ? (
            <React.Fragment>
              <div className="text-md font-sm mb-1 mt-4 flex items-center">
                <h2 className="opacity-75">Comments</h2>
                {updating ? (
                  <span className="ml-2 text-xs">
                    <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-50" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  </span>
                ) : ''}
                <span className="ml-2 text-xs font-semibold">
                  {frontendFilters && frontendFilters.comments && frontendFilters.comments.length ? (
                    <React.Fragment>
                      <span className="opacity-50">{frontendFilters.comments.length} / {numComments.length}</span>
                      <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                        onClick={() => clearFrontendFilter('comments')}>clear</span>
                    </React.Fragment>
                  ) : ''}
                </span>
              </div>

              <div className="h-36 text-black relative mb-4">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    // width={500}
                    // height={300}
                    margin={0}
                    data={numComments}
                    onClick={(data) => {
                      if (data && data.activePayload && data.activePayload.length > 0) {
                        const payload = data.activePayload[0].payload;
                        applyFrontendFilter('comments', {'min': payload.min, 'max': payload.max});
                      }
                    }}
                  > 
                    {/*<XAxis dataKey="min" tick={{ fontSize: 10 }} />*/}
                    <Tooltip content={<CustomTooltip />} cursor={{ stroke: "rgb(75 85 99)", strokeWidth: 1, fill: "rgb(31 41 55)" }}/>
                    {filteredResults.length !== results.length ? (
                      <Bar dataKey="filteredCount" stackId="a" fill="#374251">
                        {numComments.map((entry, index) => (
                          <Cell key={index} stroke={entry.inFilters ? '#35d399' : '' }/>
                        ))}
                      </Bar>
                    ) : ''}
                    <Bar dataKey={(filteredResults.length === results.length) ? "count" : "unfilteredCount"} stackId="a" fill={(filteredResults.length === results.length) ? "#374251" : "rgb(31 41 55)"}>
                      {numComments.map((entry, index) => (
                        <Cell key={index} stroke={entry.inFilters ? '#35d399' : '' }/>
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                <div className="text-white flex items-center text-xs border-t border-gray-700 pb-4">
                  <div className="text-gray-500">{numComments[0].min} Comment{numComments[0].min === 1 ? '' : 's'}</div>
                  <div className="text-gray-500 ml-auto">
                    {numComments[numComments.length - 1].label}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : ''}
        </div>
       

      </div>
   </React.Fragment>
  )
};

export default AggregateScores;

