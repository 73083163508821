import { postRequest, getRequest } from '../utils/axios';
import axios from 'axios';


export const betaService = {
    confirmBetaLink,
    // addCreditBundle,
};

function confirmBetaLink(betaHash, onSuccess, onError) {
  return getRequest(
    `/api/v1/beta-invite/${betaHash}/confirm/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

