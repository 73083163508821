import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import {
  Link,
} from "react-router-dom";
import { withRouter } from "react-router";
import { toast, ToastContainer } from 'react-toastify';
import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon, CheckIcon, SelectorIcon
} from '@heroicons/react/outline';

import { Popover, Transition, Listbox } from '@headlessui/react'
import ReactTooltip from 'react-tooltip';

import SubredditTags from "../reddit/subreddittags";
import SubredditPopover from "../reddit/subredditpopover";

import ErrorBoundary from "../common/errorboundary";

import { readableNumber } from '../../utils/text';
import { classNames } from '../../utils/tailwind';

import { collectionsService } from '../../services/collections';
import { audiencesOperations } from "../../redux/audiences"


// renders a subreddit to a collection detail screen
const TopSubredditCard = ({
  subreddit,
  displayType,
  list,
  timeframes,
  currentMetric,
  maxValues,
  audiences,
  fetchAudiences,
  history,
}) => {
  // state
  // const views = ['details', 'topics', 'submissions'];
  const [showDescription, setShowDescription] = useState(false);

  // for adding to new audience
  const NEW_AUDIENCE_KEY = 'new';
  const NEW_AUDIENCE_LABEL = 'Create New Audience'
  const [selectedAudienceHash, setSelectedAudienceHash] = useState(NEW_AUDIENCE_KEY);  // will be hash
  const selectedAudience = audiences.filter(a => a.hash === selectedAudienceHash)[0]
  const [newAudienceName, setNewAudienceName] = useState('');
  const [addingToAudience, setAddingToAudience] = useState(false);
  const [addedToAudience, setAddedToAudience] = useState(null);
  const [error, setError] = useState(null);
  const [selectedAdditionalSubs, setSelectedAdditionalSubs] = useState([]);
  const isMobile = window.screen.width < 600;

  // actions
  const saveToAudience = (e, close) => {
    e.preventDefault();

    if (!selectedAudienceHash) return;

    // collection items to add
    var items = [
       {'external_type': 'RSR', 'external_id': subreddit.name}
    ]
    selectedAdditionalSubs.forEach(s => {
      items.push({'external_type': 'RSR', 'external_id': s})
    })

    // create new audience if needed, then add items
    setAddingToAudience(true)
    if ((selectedAudienceHash === NEW_AUDIENCE_KEY) && newAudienceName){
      collectionsService.createCollection(newAudienceName, (results) => {
        if (results && results.data){
          bulkAdd(results.data, items, close);
        } else {
          alert("Failed to create new audience")
          setAddingToAudience(false)
        }
      }, (response, error) => {
        alert(error)
        setAddingToAudience(false)
      })
    } else {
      // audience already exists, just add the items
      bulkAdd(selectedAudience, items, close);
    }
  }

  const bulkAdd = (collection, items, close) => {
    // given a collection, adds to it
    setAddingToAudience(true)
    collectionsService.addCollectionItems(collection.hash, items, (results) => {
      if (results && results.data){
        setAddingToAudience(false);
        setAddedToAudience(collection);
        setSelectedAdditionalSubs([])
        close()

        const CustomToastWithLink = () => (
          <div>
            Added to your saved audience. <Link className="underline" to={`/audience/${collection.hash}/`}>Explore Now</Link>
          </div>
        );


        toast.success(CustomToastWithLink);
        fetchAudiences(); // update so we have new audience data
      }
    }, (response, error) => {
      setAddingToAudience(false)
      alert(error)
    })
  }

  return (
    <div key={subreddit.name} className={`rounded-md shadow-lg bg-gray-800 text-white flex ${displayType === 'card' ? 'flex-col p-4' : 'p-2'}`}>
      <ErrorBoundary>
       <div className={displayType === 'card' ? 'flex items-center' : 'flex items-center flex-1'}>
          {subreddit.icon ? (
            <img className="h-12 w-12 mr-4 rounded-md" src={subreddit.icon} alt={subreddit.name} />
          ) : (
            <div className="h-12 w-12 mr-4 rounded-md bg-gray-700">
              <svg className="h-6 w-6 m-3 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
              </svg>
            </div>
          )}

          <h2 className="text-xl font-semibold">
            {/*<a href={subreddit.link} target="_blank" rel="noreferrer" className="cursor-pointer hover:underline"
              onClick={() => {
                trackEvent("ExternalRedirect", {
                  "platform": "Reddit",
                  "type": "Subreddit",
                  "platform_id": subreddit.name,
                });
                searchesService.saveRedirect("RSR", subreddit.name, subreddit.name);
              }}
            >
              r/{ subreddit.name }
            </a>*/}
            <div className="flex items-center">
              <SubredditPopover name={subreddit.name}
                addToAudienceButtonId={`add-to-audience-${subreddit.name}`}
                addToAudienceNameFieldId={`add-to-audience-name-${subreddit.name}`}
              >
                <span className="cursor-pointer hover:underline">r/{ subreddit.name }</span>
              </SubredditPopover>
              {subreddit.collections && subreddit.collections.length ? (
                <span className="ml-1 font-normal">
                  <ReactTooltip id={`tracking-audience-${subreddit.name}`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                    
                  <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-4 w-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                    data-for={`tracking-audience-${subreddit.name}`} data-tip={`This community is in your audience${subreddit.collections.length > 1 ? 's' : ''}: ${subreddit.collections.map(c => c.name).join(', ')}`}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </span>
              ) : addedToAudience ? (
                <span className="ml-1 font-normal">
                  <ReactTooltip id={`tracking-audience-${subreddit.name}`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                    
                  <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-4 w-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                    data-for={`tracking-audience-${subreddit.name}`} data-tip={`This community is in your audience: ${addedToAudience.name}`}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </span>
              ) : ''}
            </div>
            <div className="flex text-xs font-medium mt-1 ">
              <div className="opacity-50">
                {readableNumber(subreddit.count_users)} members
              </div>
            </div>
          </h2>
        </div>

        {/*<nav className="flex mb-4">
          {views.map((v) => (
            <div key={v} onClick={() => setView(v)}
              className={`whitespace-nowrap flex-1 py-4 border-b-2 cursor-pointer text-center text-sm ${v === view ? 'border-cyan-500 text-cyan-500' : 'border-transparent text-gray-300 hover:text-gray-200 hover:border-gray-200'}`}
            >
              {v.split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')}
            </div>
          ))}
        </nav>*/}

        <div className={displayType === 'card' ? 'mt-2' : 'flex-1'}>
          {/*<dt className="text-xs mb-1 font-normal opacity-50">
            Metrics
          </dt>*/}
          {!list.useTimeframes ? (
            <div className="flex">
              {list.metrics.map(metric => (
                <div key={metric.key} className="flex-1">
                  <dt className="text-xs mb-1 font-normal opacity-50">
                    {metric.title}
                  </dt>
                  {/*<dd className="flex" disabled={!subreddit.calculated[metric.key]}>
                    {subreddit.calculated[metric.key] ? readableNumber(subreddit.calculated[metric.key].toFixed(2)) : '--'}
                    {metric.suffix || ''}
                  </dd>*/}
                  <div className="w-24 max-w-full bg-gray-600 rounded-sm relative flex-shrink-0" disabled={!subreddit.calculated[metric.key]}>
                    <div className="relative p-0.5 z-10 text-white text-xs">
                      {subreddit.calculated[metric.key] ? readableNumber(subreddit.calculated[metric.key].toFixed(2)) : '--'}
                      {subreddit.calculated[metric.key] && metric.suffix || ''}
                    </div>
                    <div className="absolute z-0 top-0 bg-cyan-600 rounded-sm p-0.5 h-full" style={{width: (100 * subreddit.calculated[metric.key] / maxValues[metric.key])  + '%'}}></div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex">
              {timeframes.map(timeframe => (
                <div key={timeframe.key} className="flex-1">
                  <dt className="text-xs mb-1 font-normal opacity-50">
                    {timeframe.title}
                  </dt>
                  {/*<dd className="flex" disabled={!subreddit.calculated[currentMetric.key + '_' + timeframe.key]}>
                     {subreddit.calculated[currentMetric.key + '_' + timeframe.key] ? readableNumber(subreddit.calculated[currentMetric.key + '_' + timeframe.key].toFixed(2)) : '--'}
                     {currentMetric.suffix || ''}
                  </dd>*/}
                  <div className="w-24 max-w-full bg-gray-600 rounded-sm relative flex-shrink-0" disabled={!subreddit.calculated[currentMetric.key + '_' + timeframe.key]}>
                    <div className="relative p-0.5 z-10 text-white text-xs">
                      {subreddit.calculated[currentMetric.key + '_' + timeframe.key] ? readableNumber(subreddit.calculated[currentMetric.key + '_' + timeframe.key].toFixed(2)) : '--'}
                      {subreddit.calculated[currentMetric.key + '_' + timeframe.key] && currentMetric.suffix || ''}
                    </div>
                    <div className="absolute z-0 top-0 bg-cyan-600 rounded-sm p-0.5 h-full" style={{width: (100 * subreddit.calculated[currentMetric.key + '_' + timeframe.key] / maxValues[currentMetric.key + '_' + timeframe.key])  + '%'}}></div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={displayType === 'card' ? 'mt-2' : 'flex-1'}>
          <dt className={`text-xs mb-1 font-normal ${subreddit.tags.length ? 'opacity-50' : 'opacity-0'}`}>
            Tags
          </dt>
          <dd>
            <SubredditTags subreddit={subreddit} tags={subreddit.tags} />
          </dd>
        </div>

        {displayType === 'card' ? (
          <div className={'mt-2'}>
            <dt className={`text-xs mb-1 font-normal ${subreddit.description ? 'opacity-50' : 'opacity-0'}`}>
              Description
            </dt>
            <dd className="">
              <p className={`text-sm opacity-100 overflow-ellipsis ${showDescription ? '' : 'cursor-pointer line-clamp-3'}`}
                onClick={() => setShowDescription(true)}
                // style={{minHeight: '70px'}}
              >
                { subreddit.description }
              </p>
            </dd>
          </div>
        ) : ''}

        <div className={displayType === 'card' ? 'mt-2' : 'my-auto mx-2'}>
          <div className={displayType === 'card' ? '' : 'hidden sm:block ml-auto'}>

            <Popover className="relative">
              {({ open, close }) => (
                <>
                  <Popover.Button disabled={addedToAudience}
                    className={`w-36 text-center flex items-center ${displayType === 'card' ? 'w-full' : 'ml-auto'} justify-center py-2 px-2 shadow-sm text-xs rounded-sm text-white hover:bg-cyan-600 focus:outline-none bg-cyan-700`}
                  >
                    {addedToAudience ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    )}
                    <span id={`add-to-audience-${subreddit.name}`}>{addedToAudience ? 'In Audience' : 'Add to Audience'}</span>
                  </Popover.Button>

                  <Transition
                    show={open}
                    as={React.Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      static
                      className={`absolute z-10 ${isMobile ? '' : 'right-full top-0 mr-2'} px-2 w-screen max-w-xs sm:px-0`}
                    >
                      <div className="rounded-lg shadow-lg ring-opacity-5">
                        <form className="relative bg-white text-gray-800 text-sm rounded-md p-4" onSubmit={(e) => saveToAudience(e, close)}>
                          
                          <div>
                            <label className="text-xs uppercase font-semibold mb-1 block opacity-50">Select Audience</label>
                            <Listbox value={selectedAudienceHash} onChange={(s) => {
                              setSelectedAudienceHash(s)
                            }}>
                              <div className="relative">
                                <Listbox.Button className="relative w-full cursor-pointer border border-solid border-gray-300 rounded-md shadow-sm pl-3 pr-6 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500">
                                  <span className="block truncate">{selectedAudience && selectedAudience.name || NEW_AUDIENCE_LABEL}</span>
                                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>

                                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                  <Listbox.Option
                                    key={NEW_AUDIENCE_KEY}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'text-white bg-cyan-600' : 'text-gray-900',
                                        'cursor-default select-none relative py-2 pl-8 pr-4'
                                      )
                                    }
                                    value={NEW_AUDIENCE_KEY}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                          {NEW_AUDIENCE_LABEL}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active ? 'text-white' : 'text-cyan-600',
                                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                            )}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>

                                  {audiences.map((a) => (
                                    <Listbox.Option
                                      key={a.hash}
                                      className={({ active }) =>
                                        classNames(
                                          active ? 'text-white bg-cyan-600' : 'text-gray-900',
                                          'cursor-default select-none relative py-2 pl-8 pr-4'
                                        )
                                      }
                                      value={a.hash}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                            {a.name}
                                          </span>

                                          {selected ? (
                                            <span
                                              className={classNames(
                                                active ? 'text-white' : 'text-cyan-600',
                                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                              )}
                                            >
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}

                                  {audiences.length === 0 ? (
                                    <Listbox.Option
                                      key={'none'}
                                      className={'text-gray-900 cursor-default select-none relative py-2 pl-8 pr-4'}
                                      value={'none'}
                                      disabled={true}
                                    >
                                      <span className={classNames(false ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                        No other Audiences yet...
                                      </span>
                                    </Listbox.Option>
                                  ) : ''}
                                </Listbox.Options>
                              </div>
                            </Listbox>
                          </div>

                          {selectedAudienceHash === NEW_AUDIENCE_KEY ? (
                            <div className="mt-4">
                              <label className="text-xs uppercase font-semibold mb-1 block opacity-50">Name Audience</label>
                              <input
                                type="text" id={`add-to-audience-name-${subreddit.name}`}
                                name="keyword"
                                autoComplete="off" //autoFocus={isEffectiveProSubscriber ? "on" : ""}
                                className={`w-full text-sm cursor-pointer border border-solid border-gray-300 rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500`}
                                // placeholder={`Name this audience`}
                                value={newAudienceName}
                                onChange={(e) => {
                                  setNewAudienceName(e.target.value);
                                }}
                              />
                            </div>
                          ) : ''}

                          {subreddit.related_subreddits.length ? (
                            <div className="mt-4">
                              <label className="text-xs uppercase font-semibold mb-1 block opacity-50">Include Similar Subreddits?</label>
                              {subreddit.related_subreddits.map(s => (
                                <div key={s} className="flex items-center mb-1">
                                  <input type="checkbox" checked={selectedAdditionalSubs.includes(s)} 
                                    
                                    onChange={() => {
                                      if (selectedAdditionalSubs.includes(s)){
                                        setSelectedAdditionalSubs(selectedAdditionalSubs.filter(as => as !== s))
                                      } else {
                                        setSelectedAdditionalSubs([...selectedAdditionalSubs, s])
                                      }
                                    }}
                                  />
                                  <div className="ml-2">r/{s}</div>
                                </div>
                              ))}
                            </div>
                          ) : ''}
                          
                          <button
                            type="submit" disabled={addingToAudience || !selectedAudienceHash || ((selectedAudienceHash) === NEW_AUDIENCE_KEY && !newAudienceName)}
                            className="mt-4 mx-0 w-full text-sm text-center p-2 border border-solid border-transparent font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
                          >
                            {addingToAudience ? 'Adding' : 'Add'} to Audience
                          </button>
                        </form>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
        
      </ErrorBoundary>
    </div>
  )
}

const mapStateToProps = state => {
  const {
    audiences,
    // fetchingAudiences,
  } = state.audiences;

  return {
    audiences,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAudiences = (callback, onError) => {
    dispatch(audiencesOperations.fetchAudiences(callback, onError))
  };
  return {
    fetchAudiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopSubredditCard);

