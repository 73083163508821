import { patchRequest, getRequest, postRequest } from '../utils/axios';
import axios from 'axios';


export const profileService = {
    updateProfile,
    getObjectiveChecklist,
    getLimits,
};

function updateProfile(id, data, onSuccess, onError) {
  return patchRequest(
    `/api/v1/profile/${id}/`,
    data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getObjectiveChecklist(id, onSuccess, onError) {
  return getRequest(
    `/api/v1/profile/objective/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getLimits(onSuccess, onError) {
  return getRequest(
    `/api/v1/profile/limits/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}
