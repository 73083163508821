import React from "react"
import {
  Link
} from "react-router-dom";

import { getIcon } from '../../utils/icons';


const Checklist = ({checklist, className}) => {

  const nextIncompleteStep = checklist && checklist.steps.filter(s => !s.complete)[0];
  const nextIncompleteStepTitle = nextIncompleteStep && nextIncompleteStep.title;

  if (!checklist) return (
    <div className="opacity-50">No checklist available for this objective, yet...</div>
  )

  return (
    <div>
      {checklist.steps.map(step => (
        <Link to={step.url || '/home/'} key={step.title}
          className={`py-2 px-3 rounded-md ${className || 'hover:bg-gray-700 bg-gray-800 border-solid border-gray-800'} border shadow-sm flex items-center mt-1 ${(step.complete ) ? '' : ''}`}
          // disabled={step.complete || step.title !== nextIncompleteStepTitle}
        >
          <div className="mr-4 opacity-50">
            { getIcon(step.icon, 'h-8 w-8') }
          </div>
          <div className="flex-grow">
            <div>{step.title}</div>
            <div className="text-sm opacity-80">{step.subtitle}</div>
          </div>
          {step.complete ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          ) : step.title === nextIncompleteStepTitle ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 opacity-50 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          ) : ''}
          
        </Link>
      ))}
    </div>
  );
}

export default Checklist;
