// types.js
const FETCH_SAVED_SEARCHES = 'saved-searches/fetch';
const FETCH_SAVED_SEARCHES_SUCCESS = 'saved-searches/fetch-success';
const FETCH_SAVED_SEARCHES_FAIL = 'saved-searches/fetch-fail';

const UPDATE_SAVED_SEARCHES = 'saved-searches/update';
const UPDATE_SAVED_SEARCHES_SUCCESS = 'saved-searches/update-success';
const UPDATE_SAVED_SEARCHES_FAIL = 'saved-searches/update-fail';

const CLEAR_UNREAD_COUNT = 'saved-search/unread-count/clear';

export default {
  FETCH_SAVED_SEARCHES,
  FETCH_SAVED_SEARCHES_SUCCESS,
  FETCH_SAVED_SEARCHES_FAIL,

  UPDATE_SAVED_SEARCHES,
  UPDATE_SAVED_SEARCHES_SUCCESS,
  UPDATE_SAVED_SEARCHES_FAIL,

  CLEAR_UNREAD_COUNT,
}