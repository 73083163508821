import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {
  InformationCircleIcon
} from '@heroicons/react/outline'

// import { FeedbackFish } from '@feedback-fish/react'

import { authService } from "../services/auth";
import { savedSearchOperations } from "../redux/saved"
import { statusOperations } from "../redux/status"


const MobileHeader = ({
  location,
  hideNav,
  title,
  currentUser,
  trackedKeywordUnreadCount, fetchedUnreadCounts, fetchingTrackedKeywords, fetchAllSavedSearches,
  status, fetchingStatus, fetchStatus
}) => {
  const [open, setOpen] = useState(false);

  // should we move this out of this component and into something more obvious? probably
  useEffect(() => {
    // fetch unread counts if we havent already
    if (!fetchedUnreadCounts && !fetchingTrackedKeywords){
      fetchAllSavedSearches();
    }

    if (currentUser && currentUser.features.status && (status === null) && !fetchingStatus){
      // fetch only when status is null b/c the sidebar is re-rendered on every main route change
      fetchStatus()
    }
  }, []); // When sidebar loads, if we have a trial go fetch the counts. also fetch tracked searches counts

  // service status
  var statusColor = null;
  var showStatusAlert = false
  if (status && !status.backend_primary){
    statusColor = 'rgb(239, 68, 68)'
    showStatusAlert = true
  } else if (status && status.backend_primary && !status.backend_secondary){
    statusColor = 'rgb(245, 158, 11)'
  }

  return (
    <header className="block md:hidden flex-shrink-0 bg-gray-800 relative h-16 flex items-center">
      <div className="ml-2">
        <Link to="/audiences/" className="flex items-center justify-center h-16 w-16 focus:outline-none focus:ring-2 focus:ring-inset md:w-20">
          <img className="h-8 w-auto" src="/images/branding-logo-head-gray.png" alt="GummySearch logo" />
        </Link>
      </div>

      <div className="ml-1 mr-auto text-md leading-5 -mt-1">
        { title }
      </div>

      {/* Menu button area */}
      {!hideNav ? (

        <div className="mr-6 flex items-center md:hidden">

          <Link to="/audiences/" className={`block cursor-pointer rounded-md text-xs mr-1 p-2 ${location.pathname.startsWith('/audiences/') || location.pathname.startsWith('/audience/') ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 sm:h-4 sm:w-4 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            <div className="hidden sm:block">Audiences</div>
          </Link>

          <Link to="/search/" className={`block cursor-pointer rounded-md text-xs mr-1 p-2 ${location.pathname.startsWith('/search/') || location.pathname.startsWith('/reddit/') ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
            <svg className="h-6 w-6 sm:h-4 sm:w-4 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
            </svg>
            <div className="hidden sm:block">Search</div>
          </Link>

          {/*<Link to="/search/" className={`block cursor-pointer rounded-md text-xs mr-1 p-2 ${location.pathname.startsWith('/search/') || location.pathname.startsWith('/reddit/') ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 sm:h-4 sm:w-4 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
            </svg>
            <div className="hidden sm:block">Search</div>
          </Link>*/}
          
          <Link to="/conversations/" className={`block cursor-pointer relative rounded-md text-xs mr-1 p-2 ${location.pathname.startsWith('/conversations/') ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 sm:h-4 sm:w-4 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
            </svg>
            <div className="hidden sm:block">Conversations</div>

            {trackedKeywordUnreadCount > 0 ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 absolute top-1 right-1 text-green-400" fill="#34d399" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            ) : ''}
          </Link>

          <Link to="/help/" className={`block cursor-pointer rounded-md text-xs mr-1 p-2 ${location.pathname.startsWith('/help/') ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-700'}`}>
            <InformationCircleIcon className="h-6 w-6 sm:h-4 sm:w-4 mx-auto" aria-hidden="true" />
            <div className="hidden sm:block">Help</div>
          </Link>


          {/* Mobile menu button */}
          <button type="button" className="block p-2 rounded-md text-gray-400 text-xs hover:bg-gray-700 focus:outline-none focus:ring-none focus:ring-inset focus:ring-none"
            onClick={() => setOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="h-6 w-6 sm:h-4 sm:w-4 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <div className="hidden sm:block">Menu</div>

            {statusColor && showStatusAlert ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 absolute top-3 right-6 text-gray-400" style={{color: statusColor}} fill={statusColor} viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            ) : ''}
          </button>
        </div>
      ) : ''}

      {open ? (
        <div className="fixed inset-0 z-40 md:hidden" role="dialog" aria-modal="true">
          <div className="block fixed inset-0 bg-gray-600 bg-opacity-75 cursor-pointer" aria-hidden="true" onClick={() => setOpen(false)}></div>

          <nav className="fixed overflow-y-auto z-40 inset-0 h-full w-full text-sm bg-white inset-y-0 left-auto right-0 max-w-sm w-full shadow-lg" aria-label="Global">
            <div className="h-16 flex items-center justify-between px-4">
              <Link to="/audiences/">
                <img className="h-8 w-auto" src="/images/branding-logo-head-gray.png" alt="GummySearch logo" />
              </Link>
              <button type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close main menu</span>
                {/* Heroicon name: outline/x */}
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <div className="max-w-8xl mx-auto py-3 px-2 sm:px-4">
              
              <Link to="/search/" className="block cursor-pointer rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-100"
                onClick={() => setOpen(false)}
              >
                Search
              </Link>

              <Link to="/audiences/" className="block cursor-pointer rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-100"
                onClick={() => setOpen(false)}
              >
                Audiences
              </Link>

              <Link to="/conversations/" className="block cursor-pointer rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-100"
                onClick={() => setOpen(false)}
              >
                Conversations
              </Link>

              <Link to="/help/" className="block cursor-pointer rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-100"
                onClick={() => setOpen(false)}
              >
                Help
              </Link>
            </div>
            
            <div className="border-t border-solid border-gray-200 pt-4 pb-3">
              {/*
              <div className="max-w-8xl mx-auto px-4 flex items-center sm:px-6">
                <div className="flex-shrink-0">
                  <img className="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixqx=i0he7T9Qq2&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                </div>
                <div className="ml-3 min-w-0 flex-1">
                  <div className= font-medium text-gray-800 truncate">Whitney Francis</div>
                  <div className="text-sm font-medium text-gray-500 truncate">whitneyfrancis@example.com</div>
                </div>
                <a href="#" className="ml-auto flex-shrink-0 bg-white p-2 text-gray-400 hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                  </svg>
                </a>
              </div>
              */}
              <div className="mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4">
                <Link to="/account/" className="block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                  Your Account
                </Link>

                {/*<Link to="/welcome/" className="block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                  Welcome Guide
                </Link>*/}

                {currentUser ? (
                  <React.Fragment>
                    {/*<a href={`${currentUser.env.landing_page_url}/roadmap/`} target="_blank"
                      className="block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                      Product Roadmap
                    </a>*/}
                    <a href={`${currentUser.env.landing_page_url}/blog/`} target="_blank"
                      className="block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                      Blog & Examples
                    </a>

                    <a href={`https://gummysearch.feedbear.com/boards/feature-requests`} target="_blank"
                      className="block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                      Feature Request
                    </a>

                    {currentUser.subscription && currentUser.subscription.key ? (
                      <React.Fragment>
                        {false ? (
                          <a href={`${currentUser.env.landing_page_url}/roadmap/`} target="_blank"
                            className="block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                            Product Roadmap
                          </a>
                        ) : ''}
                        <a href={`${currentUser.env.landing_page_url}/release/`} target="_blank"
                          className="block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                          Release Log
                        </a>
                      </React.Fragment>
                    ) : ''}
                  </React.Fragment>
                ) : ''}

                {currentUser.features && currentUser.features.status ? (
                  <Link to="/status/" className="flex items-center rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                    Service Status

                    {statusColor ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-2 text-gray-400" style={{color: statusColor}} fill={statusColor} viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    ) : ''}
                  </Link>
                ) : ''}

                {currentUser.identity.is_staff ? (
                  <a href={`${currentUser.env.api_root_url}/admin/`} target="_blank"
                    className="block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                    Admin Panel
                  </a>
                ) : ''}

                {/*{false && currentUser && currentUser.features.trial_credit ? (
                  <Link to="/trial/" className="block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                    Free Trial <span className="text-cyan-500 text-xs">{trialUsed} / {trialTotal}</span>
                  </Link>
                ) : ''}*/}

                {/*<FeedbackFish projectId="e4495e942265b5">
                  <div className={`block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50`}>
                    Feedback
                  </div>
                </FeedbackFish>*/}
              </div>
            </div>
            <div className="border-t border-solid border-gray-200 pt-4 pb-3">
              <div className="mt-3 max-w-8xl mx-auto px-2 space-y-1 sm:px-4">

                {currentUser ? (
                  <React.Fragment>
                    <a href={`${currentUser.env.landing_page_url}/terms-and-conditions/`} target="_blank"
                      className="block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                      Terms of Use
                    </a>
                    <a href={`${currentUser.env.landing_page_url}/privacy-policy/`} target="_blank"
                      className="block rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50">
                      Privacy Policy
                    </a>
                  </React.Fragment>
                ) : ''}

                <div className="block cursor-pointer rounded-md py-2 px-3 font-medium text-gray-900 hover:bg-gray-50"
                  onClick={() => {
                    // setOpen(false);
                    authService.logout();
                  }}
                >
                  Log out →
                </div>
              </div>
            </div>
          </nav>
        </div>
      ) : ''}
    </header>
  )
};

const mapStateToProps = state => {
  const {
    fetchedUnreadCounts,
    trackedKeywordUnreadCount,
    fetchingTrackedKeywords,
  } = state.savedSearch;
  const {
    status, fetching,
  } = state.status;

  return {
    fetchedUnreadCounts,
    trackedKeywordUnreadCount,
    fetchingTrackedKeywords,

    'status': status,
    'fetchingStatus': fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAllSavedSearches = (callback, onError) => {
    dispatch(savedSearchOperations.fetchAllSavedSearches(callback, onError))
  };
  const fetchStatus = (callback) => {
    dispatch(statusOperations.fetchStatus(callback))
  };
  return {
    fetchAllSavedSearches,
    fetchStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MobileHeader));

