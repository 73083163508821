import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { CheckIcon, XIcon, CalendarIcon } from '@heroicons/react/solid'
// import { ModalRoute, ModalContainer } from 'react-router-modal';

import Layout from "../../components/layout";
import ErrorBoundary from "../../components/common/errorboundary";
import Loader from "../../components/common/loader";

import { authService } from "../../services/auth";
import { trackEvent } from '../../utils/tracking';
import { userOperations } from "../../redux/user"

// import PostTrialSubscriptionModal from "../../components/trial/postTrialSubscriptionModal";
import SubscriptionSelect from "../../components/account/subscriptionSelect";
import FAQs from "../../components/account/faqs";
import { localStorageAvailable } from '../../utils/localStorage';

// user comes here when they have hit 50 conversation searches
const LimitReached = ({currentUser, fetchCurrentUser, history}) => {
  // treat this page like a feature paywall
  const trackingFeature = "Searches Limit"

  useEffect(() => {
    if (currentUser && currentUser.subscription) return;

    // record that user sees the paywall
    const eligibleForTrialSubscription = currentUser.trial.eligible_for_trial_subscription || false;
    trackEvent("PaywallView", {
      'feature': trackingFeature,
      'plan': "none",
      'currentPlan': currentUser.subscription ? currentUser.subscription.key : "none",
      "isNew": currentUser.identity.is_new ? "yes" : "no",
      "loginDays": currentUser.identity.login_days,
      "trial": eligibleForTrialSubscription,
    });

    if (localStorageAvailable()){
      localStorage.setItem('last_feature_paywall', trackingFeature);
    }
  }, [])

  const allPlanBenefits = [
    {'title': 'Save Time', 'description': 'No need to waste your precious time reading Reddit posts, GummySearch will show you the important stuff so you can go back to building your business.'},
    {'title': 'Be Confident', 'description': "Make informed descisions backed up by conversations happening between real people on Reddit. Confidently dive into conversational insights of any target group."},
    {'title': 'Make Money', 'description': 'No matter if you are ideating, validating, or looking for sales leads, the potential gains that will come from using Reddit right will far outweigh $29/month.'},
  ]

  // if (!currentUser) return '';
  if (currentUser && currentUser.subscription) return (
    <Layout title="Free tier limit reached" currentUser={currentUser} >
      <div className="p-4 text-white">You have an active subscription. You must be lost?</div>
    </Layout>
  );

  return (
    <Layout title="Free tier limit reached" currentUser={currentUser} >
      <Helmet><title>Free tier limit reached</title></Helmet>
      
      <div className="flex items-center sm:-mt-3 mb-6 pb-5 border-b  border-solid border-gray-800">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">Free tier limit reached</h2>
        </div>
      </div>

      <ErrorBoundary>

        <div className="p-4 w-full lg:max-w-3xl mx-auto">
          
          <h2 className="text-2xl font-extrabold text-center">You've used up all of your free searches!</h2>
            <div className="mt-2 text-lg opacity-80 mx-auto text-center">
              To continue searching Reddit, please choose a subscription plan or buy a day pass.
            </div>

            <div className="mt-8">
              <SubscriptionSelect currentUser={currentUser} fetchCurrentUser={fetchCurrentUser}
                closeModal={() => {
                  history.push(`/account/subscription/`);
                }}
                trackingFeature={trackingFeature}
                showFree={true}
                // showTeam={true}
                onSelectFree={() => alert("Please select a Starter/Pro plan if you wish to make more keyword searches.")}
              />
            </div>

            {/*<div className="mt-12">
              <h2 className="text-2xl font-extrabold text-center">Why buy a subscription?</h2>
              <div className="mt-4">
                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
                  {allPlanBenefits.map((b) => (
                    <div key={b.title} className="space-y-2">
                      <div className="flex items-center">
                        <CheckIcon className="w-8 h-8 text-green-500" aria-hidden="true" />
                        <dt className="ml-2 text-lg leading-6 font-medium">{b.title}</dt>
                      </div>
                      <dd className="text-base text-gray-400">{b.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>*/}

            {currentUser.features.account_subscription_day_pass ? (
              <div className="mt-12">
                <h2 className="text-2xl font-extrabold text-center">Don't like subscriptions?</h2>
                <div className="mt-2 text-base opacity-80 mx-auto text-center">
                  That's OK! <Link className="text-cyan-400 underline semi-bold" to="/account/subscription/day-pass/">Buy a day pass</Link> to use GummySearch when you need it.
                </div>
              </div>
            ) : ''}
            
            <div className="mt-12">
              <h2 className="text-2xl font-extrabold text-center">Questions?</h2>
              <div className="mt-2 text-base opacity-80 mx-auto text-center">
                Reach out via email to <a className="underline semibold" href="mailto:fed@gummysearch.com">fed@gummysearch.com</a> or Twitter <a className="underline semibold" href="https://twitter.com/foliofed" target="_blank">@foliofed</a> and I'm happy to help.
              </div>
            </div>

            {/*<div className="mt-12">
              <h2 className="text-2xl font-extrabold text-center">Don't intend to purchase?</h2>
              <div className="mt-2 text-lg opacity-80 mx-auto text-center">
                <Link to={`/trial-feedback/?trial_hash=${trial.hash}`}
                  className="mt-4 px-4 py-2  border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm"
                >
                  Leave some feedback 🙏
                </Link>
              </div>
            </div>*/}

            {/*<div className="mt-8">
              <FAQs/>
            </div>*/}

        </div>

      </ErrorBoundary>
    </Layout>
  );
}

const mapStateToProps = state => {
  // const {
  //   used, total, fetching,
  // } = state.trial;

  return {
    // 'trialTotal': total,
    // 'trialUsed': used,
    // 'trialFetching': fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  // const fetchTrial = (callback, onError) => {
  //   dispatch(trialOperations.fetchTrial(callback, onError))
  // };
  const fetchCurrentUser = (callback) => {
    dispatch(userOperations.fetchCurrentUser(callback))
  };
  return {
    fetchCurrentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LimitReached);
