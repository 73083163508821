import React, { useState, useEffect } from "react";
import {
  Link,
} from "react-router-dom";
import { withRouter } from "react-router";
import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon
} from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';

import ErrorBoundary from "../common/errorboundary";
import SubredditTags from "../reddit/subreddittags";

import { readableNumber } from '../../utils/text';
// import { getParamsFromUrl, toQueryString } from '../../utils/urls';
import { trackEvent } from '../../utils/tracking';

import { searchesService } from '../../services/searches';
import { redditService } from '../../services/reddit';
// import { collectionsService } from '../../services/collections';


// renders similar subreddit for a collection
const SimilarSubredditCard = ({
  subreddit,
  collection,
  addCommunity, setNotRelevant
}) => {
  // state
  const [showDescription, setShowDescription] = useState(false);
  // const [added, setAdded] = useState(false);
  const displayType = 'card'

  if (!subreddit) return ''

  return (
    <div key={subreddit.name} className={`rounded-md shadow-lg bg-gray-800 text-white flex ${displayType === 'card' ? 'flex-col p-4' : 'p-2'}`}>
      <ErrorBoundary>
        <div className="flex items-center flex-1">
          {subreddit && subreddit.icon ? (
            <img className="h-12 w-12 mr-4 rounded-md" src={subreddit.icon} alt={subreddit.name} />
          ) : (
            <div className="h-12 w-12 mr-4 rounded-md bg-gray-700">
              <svg className="h-6 w-6 m-3 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
              </svg>
            </div>
          )}

          
          <h2 className="text-xl font-semibold">
            {/*<a href={subreddit.link} target="_blank" rel="noreferrer" className="cursor-pointer hover:underline"
              onClick={() => {
                trackEvent("ExternalRedirect", {
                  "platform": "Reddit",
                  "type": "Subreddit",
                  "platform_id": subreddit.name,
                });
                searchesService.saveRedirect("RSR", subreddit.name, subreddit.name);
              }}
            >
              r/{ subreddit.name }
            </a>*/}
            <div //to={`/audience/${collection.hash}/sub/${subreddit.name}/`}
              className="">
              r/{ subreddit.name }
            </div>
            <div className="flex text-xs font-medium mt-1 ">
              <div className="opacity-50">
                {readableNumber(subreddit.count_users)} members
              </div>

              <ReactTooltip id={`subreddit-stats-${subreddit.name}`} place="right" effect="solid" backgroundColor="white" textColor="black" />
              {subreddit.calculated && subreddit.calculated.growth_members_weekly != null ? (
                <div className={`ml-2 flex ${subreddit.calculated.growth_members_weekly > 0 ? 'text-green-400' : subreddit.calculated.growth_members_weekly === 0 ? 'text-gray-400' : 'text-red-400'}`}>
                  <TrendingUpIcon className="h-4 w-4 mr-1" />
                  <div>{subreddit.calculated.growth_members_weekly.toFixed(2)}% / week</div>
                </div>
              ) : subreddit.calculated && subreddit.calculated.growth_members_daily != null? (
                <div className="text-gray-400 ml-2 flex">
                  <TrendingUpIcon className="h-4 w-4 mr-1" />
                  <div>{subreddit.calculated.growth_members_daily.toFixed(2)}% / day</div>
                  <InformationCircleIcon className="h-4 w-4 ml-1 text-gray-500" 
                    data-for={`subreddit-stats-${subreddit.name}`} data-tip="No weekly growth data yet. Showing growth over the past day."
                  />
                </div>
              ) : ''}
            </div>
          </h2>
        </div>

        <div className={`flex ${displayType === 'card' ? 'h-44 flex-col overflow-auto pt-2' : 'flex-wrap items-center sm:flex-1'} `}>

          {subreddit && subreddit.tags ? (
            <div className={displayType === 'card' ? '' : 'hidden sm:block'}>
              {/*<dt className="text-xs mb-1 font-normal opacity-50">
                In Comparison
              </dt>*/}
              <dd className="block">
                <SubredditTags subreddit={subreddit} tags={subreddit.tags} style="link"/>
              </dd>
            </div>
          ) : ''}

          {displayType === 'card' && subreddit && subreddit.description ? (
            <React.Fragment>
              <dt className="mt-4 text-xs font-semibold text-gray-400 uppercase tracking-wide">
                Description
              </dt>
              <dd className="mb-2">
                <p className={`text-sm opacity-100 overflow-ellipsis ${showDescription ? '' : 'cursor-pointer line-clamp-3'}`}
                  onClick={() => setShowDescription(true)}
                  // style={{minHeight: '70px'}}
                >
                  { subreddit && subreddit.description }
                </p>
              </dd>
            </React.Fragment>
          ) : ''}

          <div className={displayType === 'card' ? 'mt-auto pt-2' : 'sm:flex-1 ml-auto flex items-center'}>
            {/*{displayType === 'row' && subreddit ? (
              <div
                className={`mr-2 opacity-100 sm:opacity-90 hover:opacity-100 cursor-pointer ml-auto text-gray-600 hover:text-gray-400`}
                
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </div>
            ) : ''}*/}

            <div className={displayType === 'card' ? '' : 'hidden sm:block'}
              // to={`/audience/${collectionItem.collection_hash}/sub/${subreddit.name}/`}
            >
              <div className="flex space-x-2">
                <button type="button" className={`text-center flex items-center ${displayType === 'card' ? 'w-full' : 'ml-auto'} justify-center py-2 px-2 shadow-sm text-xs rounded-sm text-white hover:bg-cyan-500 focus:outline-none bg-cyan-600`}
                  onClick={() => addCommunity(subreddit.name)}
                >
                  Add to Audience
                </button>

                <button type="button" className={`text-center flex items-center ${displayType === 'card' ? 'w-full' : 'ml-auto'} justify-center py-2 px-2 shadow-sm text-xs rounded-sm text-white hover:bg-gray-600 focus:outline-none bg-gray-700`}
                  onClick={() => setNotRelevant(subreddit.name)}
                >
                  Not Relevant
                </button>
              </div>
            </div>
          </div>

        </div>
      </ErrorBoundary>
    </div>
  )
}

export default withRouter(SimilarSubredditCard);

