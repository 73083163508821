import React, { useState, useEffect } from "react";
import ReactTooltip from 'react-tooltip';

import SubredditPopover from "../../components/reddit/subredditpopover";

import { collectionsService } from '../../services/collections';
import { searchesService } from '../../services/searches';


// has suggestions for where to go to conversations to 
const AudienceAddSimilar = ({
  collection,
  refetch
}) => {
  const [similar, setSimilar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [hidden, setHidden] = useState([]);  // names of added/deleted communities

  // effects
  useEffect(() => {
    fetchSimilarCommunities();
  }, []);

  // acions
  const fetchSimilarCommunities = () => {
    setLoading(true)
    collectionsService.getSimilar(collection.hash, (results) => {
      if (results && results.data){
        setSimilar(results.data)
        setLoading(false)
      }
    }, (response, error) => {
      setLoading(false)
      setError(error || 'Error fetching similar communtiies');
    })
  }


  const addCommunity = (name) => {
    // add a single communtiy to the collection
    const items = [{'external_type': 'RSR', 'external_id': name}];
    collectionsService.addCollectionItems(collection.hash, items, (results) => {
      refetch();
    }, (response, error) => {
      console.log(error)
      error("Error adding community to collection")
    })
  }

  const hideCommunity = (name) => {
    // right now just adds to the user's hidden communities
    // take feedback as to where we hide it from
    searchesService.addHiddenCommunity("RSR", name, name, {
      'type': 'collection',
      'type_id': collection.id,
    });
  }

  const communitiesToShow = similar ? similar.filter(sub => !hidden.includes(sub.name)) : [];
  const hasCommunities = similar && similar.length > 0;
  const hasCommunitiesToShow = communitiesToShow.length > 0;        

  return (
    <div className="">
    
      <h3 className="mb-1 text-xs font-semibold text-gray-400 uppercase tracking-wide">
        Similar Communities
      </h3>
      <div className="mb-2 text-sm text-gray-200">
        Recommended based on communities that members of your audience also participate in.
      </div>
      <div className="w-full">
        {loading ? (
          <div className="opacity-25 h-24 mb-3">Loading, this may take up to a minute...</div>
        ) : error ? (
          <div className="opacity-25">{error}</div>
        ) : hasCommunitiesToShow ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-1">
            {similar.filter(sub => !hidden.includes(sub.name)).map(sub => (
                <SubredditPopover key={sub.name} openOnClick={false} openOnHover={true} name={sub.name}>
                  <div 
                    className={`flex-1 mr-2 mt-2 p-2 text-xs text-center text-white bg-gray-700 rounded-md`}
                    key={sub.name} 
                  >
                    <div className="text-xs truncate"><span className="opacity-50">r/</span>{sub.name}</div>

                    <div className="flex items-center mt-2">
                      <div className="flex-1 p-1 rounded-sm opacity-75 hover:opacity-100 hover:bg-gray-600 cursor-pointer text-xs"
                        onClick={() => {
                          addCommunity(sub.name)
                          setHidden([...hidden, sub.name])
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <div className="ml-1 flex-1 p-1 rounded-sm opacity-75 hover:opacity-100 cursor-pointer text-xs hover:bg-gray-600"
                        onClick={() => {
                          hideCommunity(sub.name)
                          setHidden([...hidden, sub.name])
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14H5.236a2 2 0 01-1.789-2.894l3.5-7A2 2 0 018.736 3h4.018a2 2 0 01.485.06l3.76.94m-7 10v5a2 2 0 002 2h.096c.5 0 .905-.405.905-.904 0-.715.211-1.413.608-2.008L17 13V4m-7 10h2m5-10h2a2 2 0 012 2v6a2 2 0 01-2 2h-2.5" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </SubredditPopover>
              ))}
          </div>
        ) : (
          <div className="opacity-25 h-24 mb-3">No more suggestions at this time</div>
        )}
        
      </div>
    </div>
  );
};

export default AudienceAddSimilar;

