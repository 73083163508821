import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import {
  Link, Switch, Route
} from "react-router-dom";
import { ModalRoute } from 'react-router-modal';
import {
  CheckIcon, InformationCircleIcon, ViewListIcon, ViewBoardsIcon, LightBulbIcon,
} from '@heroicons/react/outline'

import { searchesService } from '../../services/searches';
import ErrorBoundary from "../common/errorboundary";

import FeaturePaywallWrapper from "../account/featurePaywallWrapper"
import ResultsAnalyzeQuestion from "../../components/language/questionAnswer";

const AudienceAskQuestion = ({
  collection,
  topics,
  suggestions,
  subreddits,
  currentUser,
  history,
  location,
  match,
}) => {
  // state
  const [question, setQuestion] = useState('');
  const [submittedQuestion, setSubmittedQuestion] = useState('');
  const [recentSearches, setRecentSearches] = useState([]);
  const [loadingRecentSearches, setLoadingRecentSearches] = useState(false);

  // effects
  useEffect(() => {
    // fetch recent searches in this audience
    fetchRecentSearches();
  }, []);

  const fetchRecentSearches = () => {
    setLoadingRecentSearches(true)
    searchesService.recentAnalysisSearches({'reports_bulk': ['ask'], 'collection': collection.hash}, (results) => {
      if (results && results.data){
        setRecentSearches(Array.from(new Set(results.data.map(s => s.params_analysis.ask_question)))) // just save unique questions
        setLoadingRecentSearches(false)
      }
    }, () => {
      setLoadingRecentSearches(false)
    })
  }


  return (
    <ErrorBoundary>
      <Helmet><title>Audience | Ask</title></Helmet>
      
      <div className="mt-4">
        <FeaturePaywallWrapper featureKey={"ask"} className={'max-w-3xl mx-auto mt-8'} currentUser={currentUser}>
          <ResultsAnalyzeQuestion
            currentUser={currentUser}
            results={[]}
            filterParams={Object.assign({}, {
              'collection': collection.hash,
              'introspect-fields': ['keyword'],
            })}
            placeholder="Ask this audience a question..."
            recentSearches={recentSearches} refetchSearches={fetchRecentSearches}
            // limitOptions={[100, 200, 500]}
            // history={history} location={location} baseUrl={`/audience/${collection.hash}/ask/`}
            // emptyMessage={results && results.length < 100 ? `If <100 AI results for ${suggestion.name} found, try "More > Keyword Search".` : ''}
          />
        </FeaturePaywallWrapper>
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  return {
    currentUser,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceAskQuestion);

