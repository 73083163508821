// reducers.js
import { combineReducers } from 'redux';

import audiencesReducer  from './redux/audiences';
import trialReducer  from './redux/trial';
import savedSearchReducer  from './redux/saved';
import uiReducer  from './redux/ui';
import statusReducer  from './redux/status';
import userReducer  from './redux/user';

export default combineReducers({
 audiences: audiencesReducer,
 savedSearch: savedSearchReducer,
 status: statusReducer,
 trial: trialReducer,
 ui: uiReducer,
 user: userReducer,
});