import { postRequest, getRequest, deleteRequest, patchRequest } from '../utils/axios';
import axios from 'axios';


export const sharedService = {
    getReport,
    getReportResults,
    getUserReports,
    getUserReport,
    createUserReport,
    updateUserReport,
};

function getReport(hash, onSuccess, onError) {
  return getRequest(
    `/api/v1/shared-reports/${hash}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getReportResults(hash, onSuccess, onError) {
  return getRequest(
    `/api/v1/shared-reports/${hash}/results/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getUserReports(onSuccess, onError) {
  return getRequest(
    `/api/v1/user-shared-reports/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getUserReport(hash, onSuccess, onError) {
  return getRequest(
    `/api/v1/user-shared-reports/${hash}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function createUserReport(params, onSuccess, onError) {
  return postRequest(
    `/api/v1/user-shared-reports/`,
    params,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function updateUserReport(hash, params, onSuccess, onError) {
  return patchRequest(
    `/api/v1/user-shared-reports/${hash}/`,
    params,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// function deleteUserReport(hash, onSuccess, onError) {
//   return deleteRequest(
//     `/api/v1/hidden-communities/${id}/`,
//     (response) => {
//       if (onSuccess){
//         return onSuccess(response)
//       }
//     },
//     (error, response) => {
//       if (onError){
//         return onError(error, response)
//       }
//     }
//   )
// }

