import React, { useState } from "react";
// import {
//   withRouter,
// } from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";

// import { collectionsService } from '../../services/collections';
import ErrorBar from "../common/errorBar";
import Filters from "../reddit/filters";

import { defaultParams } from '../reddit/constants'
import { toQueryString } from '../../utils/urls';


const SearchQueryModal = ({ closeModal, history, currentUser }) => {
  
  // initial params
  const [filters] = useState({
    'type': 'submissions',
    'limit': 100,
    'sort': defaultParams.sort,
    'backend': currentUser.env.default_reddit_backend,//currentUser.env.default_reddit_backend,
  }, []);
  console.log(history)

  const applyNewFilters = (newFilters) => {
    var totalFilters = Object.assign(filters, newFilters) // get updated filters
    
    // get rid of any empty filters
    for (var key in totalFilters) {
      if (totalFilters[key] === null || totalFilters[key] === undefined || totalFilters[key] === '') {
        delete totalFilters[key];
      }
    }

    // go to the new url (which should fetch new results)
    history.push(`/reddit/${toQueryString(totalFilters)}`);
  }

  return (
    <Modal close={closeModal} widthClass="max-w-3xl">
      <Helmet><title>Search | Query</title></Helmet>
      
      {/*<div className="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 opacity-50 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
        </svg>

        <h3 className="text-lg leading-6 font-medium">
          Search
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>*/}

      <div>
        <Filters
          filters={filters}
          apply={applyNewFilters}
          // close={closeModal} // dont include, no need
          clearFilters={() => console.error("not implemented")}
          currentUser={currentUser}
        />
      </div>


    </Modal>
  );
};

export default SearchQueryModal;

