import React, { useState } from "react"
import { Link } from "react-router-dom";

import ErrorBoundary from "../common/errorboundary";
import SubmissionTypeIcon from "../reddit/submissiontypeicon";


const SavedConversationsList = ({ savedSearches, loading }) => {
  
  return (
    <ErrorBoundary>
      <div className="flex items-center mb-2">
        <h2 className="text-lg font-medium">Bookmarks</h2>

        <div className="ml-2 text-gray-500 truncate">
          {loading ? (
            <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : (
            <span>{savedSearches.length}</span>
          )}
        </div>
      </div>

      
      {savedSearches && savedSearches.length ? (
        <div className="gap-y-4">
          {savedSearches.map(search => (
            <Link to={`/conversations/saved/${search.hash}/all/`} key={search.hash} className="rounded-md shadow-lg bg-gray-800 hover:bg-gray-700 text-white flex p-2 py-3 flex-col mb-2">
              <ErrorBoundary>
                <div className="flex items-center text-sm space-x-2 relative">

                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 flex-shrink-0" viewBox="0 0 24 24"
                    stroke={search.color} fill={`${search.color}75`}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                  </svg>

                  <div>
                    <div className="font-semibold truncate text-sm">{search.name}</div>
                    <div className="text-xs opacity-50">{search.count_conversations} conversation{search.count_conversations === 1 ? '' : 's'}</div>
                  </div>
                </div>
              </ErrorBoundary>
            </Link>
          ))}

          <Link to="/conversations/info/" className={`py-4 text-sm rounded-md shadow-lg border-2 border-dashed text-gray-400 border-gray-800 hover:border-gray-700 hover:text-gray-200 text-white flex flex-col text-center justify-center`}>
            New bookmark
          </Link>
        </div>
      ) : (
        <Link className="opacity-75 hover:opacity-100 cursor-pointer border-2 border-dashed border-gray-700 p-4 h-32 w-full rounded-lg flex flex-col items-center justify-center"
          // onClick={() => document.getElementById('keyword-search').focus()}
          to="/conversations/info/"
        >
          <h3>No bookmarks yet</h3>
          <div className="text-sm opacity-75 mt-2 text-center">
            Make a search and save useful conversations you find
          </div>
        </Link>
      )}
    </ErrorBoundary>
  );
}

export default SavedConversationsList;
