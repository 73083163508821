import React, { useState, useEffect } from "react";

import ReactTooltip from 'react-tooltip';
import { PieChart, Pie, Sector, ResponsiveContainer, Tooltip } from 'recharts';

import AggregateOption from "./aggregateOption";
import SubredditPopover from "./subredditpopover";



const AggregateSubreddits = ({
  results,
  filteredResults,
  frontendFilters,
  applyFrontendFilter,
  clearFrontendFilter,
}) => {


  // state
  const INITIAL_SUBREDDIT_COUNT = 5;
  const SUBREDDIT_COUNT_INCREMENT = 5;
  const [countSubredditsToShow, setCountSubredditsToShow] = useState(INITIAL_SUBREDDIT_COUNT, [results]);
  const [activeSubredditIndex, setActiveSubredditIndex] = useState(0);
  const demoUrl = '';
  // const [showAllSubreddits, setShowAllSubreddits] = useState(false, [results]);

  useEffect(() => {
    setCountSubredditsToShow(INITIAL_SUBREDDIT_COUNT)
  }, [
    results
  ]);

  // cut out early if we haven't got anything or cleared the search
  if (!results || !results.length) return ''
  const filtersApplied = results.length !== filteredResults.length;

  // utils

  // renders the pie chart shape
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name, count } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const isSelected = frontendFilters.subreddits && frontendFilters.subreddits.includes(payload.name);

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={'#fff'}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={isSelected ? 'rgb(75, 85, 99)' : 'rgb(31 41 55)'}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={'rgb(6, 182, 212)'}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={'rgb(6, 182, 212)'} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#fff">
          {`${percent}%`}
        </text>
        
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#fff">{name}</text>
      </g>
    );
  };

  // get the top subreddits
  var allSubreddits = [];
  results.filter(s => !s.subreddit_name.startsWith('u/') && !s.subreddit_name.startsWith('r/u_')).forEach(r => {
    const name = r.subreddit_name.replace('r/', '')
    const index = allSubreddits.map(r => r.name).indexOf(name);
    index === -1 ? allSubreddits.push({'name': name, 'count': 1}) : allSubreddits[index]['count'] += 1
  });
  allSubreddits.map(s => {
    s['filteredCount'] = filteredResults.filter(r => r.subreddit_name.replace('r/', '') === s.name).length;
    s['percent'] = parseInt(s.count * 100 / results.length);
    return s
  })
  allSubreddits = allSubreddits
    // .filter(s => results.length < 50 || s.count > 1) // if we have plenty of results, only show more than 1 hit
    .sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));   
  const subredditsToShow = allSubreddits.filter((s, i) => i < countSubredditsToShow);
  const hiddenSubreddits = allSubreddits.filter(s => !subredditsToShow.map(s => s.name).includes(s.name));
  const hiddenSubredditAggregate = {'name': 'Other', 'count': hiddenSubreddits.length, 'percent': parseInt(hiddenSubreddits.length * 100 / results.length)}

  if (allSubreddits.length <= 1) return ''

  return (
    <React.Fragment>
      <ReactTooltip id='aggregate-subreddits' place="left" effect="solid" backgroundColor="white" textColor="black" />
      
      <div className="flex flex-wrap space-x-4 sm:space-x-8">
        <div className="flex-1">

          <div className="text-md font-sm mb-1 mt-4 flex items-center">
            <h2 className="opacity-75">Subreddits</h2>
            <span className="ml-2 text-xs font-semibold">
              {frontendFilters && frontendFilters.subreddits && frontendFilters.subreddits.length ? (
                <React.Fragment>
                  <span className="opacity-50">{frontendFilters.subreddits.length} / {allSubreddits.length}</span>
                  <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                    onClick={() => clearFrontendFilter('subreddits')}>clear</span>
                </React.Fragment>
              ) : (
                <span className="opacity-50">{allSubreddits.length}</span>
              )}
            </span>

            {/*<span className="opacity-50 cursor-pointer hover:opacity-100 ml-auto" data-for='aggregate-subreddits' data-tip="Select multiple to quickly filter for relevant subreddits">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>*/}
          </div>

          <div className="">
            {subredditsToShow.filter(c => !c.details || !c.details.over_18).map(sub => (
              <SubredditPopover key={sub.name} name={sub.name}
                openOnClick={false} openOnHover={true}
                openDelay={1000} dontOpenOnClick={true}
                positions={['left', 'right', 'bottom', 'top']}
              >
                <AggregateOption 
                  key={sub.name}
                  onClick={() => applyFrontendFilter('subreddits', sub.name)}
                  label={sub.name}
                  count={sub.count}
                  maxCount={subredditsToShow[0] && subredditsToShow[0]['count']}
                  filteredCount={sub.filteredCount}
                  filtersApplied={filtersApplied}
                  thisFilterApplied={frontendFilters.subreddits && frontendFilters.subreddits.includes(sub.name)}
                />
              </SubredditPopover>
            ))}

            {allSubreddits.length > subredditsToShow.length ? (
              <div className="px-2 py-1 mb-2 text-xs bg-gray-900 text-gray-400 hover:text-gray-200 hover:bg-gray-900 rounded-sm flex space-x-1 cursor-pointer"
                onClick={() => setCountSubredditsToShow(countSubredditsToShow + SUBREDDIT_COUNT_INCREMENT)}
              >
                {/*<span>+ {Math.min(SUBREDDIT_COUNT_INCREMENT, allSubreddits.length - subredditsToShow.length)} more</span>*/}
                <span>+ show more</span>
              </div>
            ) : ''} 
          </div>
        </div>

        {false ? (
          <div className="flex-1">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={[...subredditsToShow, hiddenSubredditAggregate]}
                  dataKey="percent" cx="50%" cy="50%"
                  innerRadius={40} outerRadius={60}
                  // fill="rgb(6, 182, 212)"
                  fill="rgb(31 41 55)" stroke="rgba(75, 85, 99, 0.25)"
                  onMouseEnter={(_, index) => {
                    setActiveSubredditIndex(index)
                  }}
                  activeIndex={activeSubredditIndex}
                  activeShape={renderActiveShape}
                />
                
              </PieChart>
            </ResponsiveContainer>
          </div>
        ) : ''}
        
      </div>
   </React.Fragment>
  )
};

export default AggregateSubreddits;

