// reducers.js
import { combineReducers } from 'redux';

import types from './types';

const INITIAL_STATE = {
  audiences: [],
  audienceItems: [],
  fetchingAudiences: false,
  fetchingAudienceItems: false,
  hasFetchedAudienceItems: false,
  audienceSubreddits: {}, // key is subreddit name
  fetchingAudienceSubreddits: {}, // key is subreddit name, value is boolean
}

const audiencesReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {

    case types.FETCH_AUDIENCES: {
      return {
        ...state,
        fetchingAudiences: true,
      }
    }

    case types.FETCH_AUDIENCES_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        audiences: data,
        fetchingAudiences: false,
      }
    }

    case types.FETCH_AUDIENCES_FAIL: {
      const { error } = action;
      return {
        ...state,
        fetchingAudiences: false,
      }
    }

    case types.FETCH_AUDIENCE_ITEMS: {
      return {
        ...state,
        fetchingAudienceItems: true,
      }
    }

    case types.FETCH_AUDIENCE_ITEMS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        audienceItems: data,
        fetchingAudienceItems: false,
        hasFetchedAudienceItems: true,
      }
    }

    case types.FETCH_AUDIENCE_ITEMS_FAIL: {
      const { error } = action;
      return {
        ...state,
        fetchingAudienceItems: false,
      }
    }

    // case types.FETCH_AUDIENCE_SUBREDDITS: {
    //   const { names } = action;
    //   const nameDict = names.reduce((a, v) => ({ ...a, [v]: true}), {});
    //   return {
    //     ...state,
    //     fetchingAudienceSubreddits: {...state.fetchingAudienceSubreddits, ...nameDict},
    //     // clientWorkstreams: {...state.clientWorkstreams, ...{[clientId]: null}},
    //   }
    // }

    // case types.FETCH_AUDIENCE_SUBREDDITS_SUCCESS: {
    //   const { data } = action;
    //   console.log("data", data)
    //   const names = [];
    //   return {
    //     ...state,
    //     // fetchingClientWorkstreams: {...state.fetchingClientWorkstreams, ...{[clientId]: false}},
    //     // clientWorkstreams: {...state.clientWorkstreams, ...{[clientId]: data.results}},
    //   }
    // }

    // case types.FETCH_AUDIENCE_SUBREDDITS_FAIL: {
    //   const { names } = action;
    //   return {
    //     ...state,
    //     // fetchingClientWorkstreams: {...state.fetchingClientWorkstreams, ...{[clientId]: false}},
    //     // clientWorkstreams: {...state.clientWorkstreams, ...{[clientId]: null}},
    //   }
    // }

    default: return state;
  }
}

export default audiencesReducer;