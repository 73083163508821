import React from "react"
import {
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import FormFooter from "./footer";

const objectives = {
  'FP': {
    'title': 'Finding problems to solve on GummySearch',
    'next': '/audiences/',
    'description': 'You can use GummySearch to target a particular niche category and determine what the members of those communities are complaining about or asking for.',
    'steps': [
      {'index': 1, 'icon': 'discover', 'title': 'Discover Subreddits', 'description': 'Use GummySearch Discover to find some communities for a particular niche.'},
      {'index': 2, 'icon': 'collection', 'title': 'Add Subreddits to Collection', 'description': 'From the suggestions we give you, add relevant communities to a collection to keep track of your research.'},
      {'index': 3, 'icon': 'discussion', 'title': 'Browse Pain Points', 'description': 'Your collection contains links to conversations with pain points, solution requests, and requests for resources.'},
    ]
  },
  'VS': {
    'title': 'Validating your idea with GummySearch',
    'next': '/audiences/',
    'description': 'You have an idea for a business and want to validate it.',
    'steps': [
      {'index': 1, 'icon': 'discussion', 'title': 'Search Conversations', 'description': 'Search Reddit for keywords related to your problem/solution to see what the community thinks on the subject.'},
      {'index': 2, 'icon': 'bookmark', 'title': 'Save Conversations', 'description': 'Save relevant conversations to refer back to easily. Make a list of people to reach out to for user interviews.'},
      {'index': 3, 'icon': 'bell', 'title': 'Track Keyword Mentions', 'description': "Set up notifications for new conversations with your keywords, so you always stay in the loop."},
      {'index': 4, 'icon': 'speakerphone', 'title': 'Request for Interviews', 'description': 'Use the discover feature to find relevant communities, and make a post asking for some interviews.'},
    ]
  },
  'CU': {
    'title': 'Discover customers on GummySearch',
    'next': '/audiences/',
    'description': 'You have a solution and want to find people who have a problem you can solve',
    'steps': [
      {'index': 1, 'icon': 'discussion', 'title': 'Search Conversations', 'description': "Search for keywords related to the problem you're solving, and respond with your expert advice or suggestions."},
      {'index': 2, 'icon': 'bell', 'title': 'Track Keyword Mentions', 'description': "Set up notifications for new conversations with your keywords, so you always be in the right place at the right time."},
      {'index': 3, 'icon': 'collection', 'title': 'Niche Down', 'description': 'If your keywords are too broad, make a collection of subreddits to search within relevant communities only.'},
    ]
  },
  'CO': {
    'title': 'Distribute content with GummySearch intelligence',
    'next': '/audiences/',
    'description': 'Grow as a thought leader within your customer niche',
    'steps': [
      {'index': 1, 'icon': 'discover', 'title': 'Discover Communities', 'description': 'Use GummySearch Discover to find all of the relevant communities in your niche.'},
      {'index': 2, 'icon': 'sort', 'title': 'Browse Top Content', 'description': 'Add subreddits to a collection and see the kind of content that performs well across your niche.'},
      {'index': 3, 'icon': 'discussion', 'title': 'Search Discussions', 'description': 'Search conversations that are asking for resources, to inspire you for your own writing.'},
      {'index': 4, 'icon': 'speakerphone', 'title': 'Post Content', 'description': 'Post your own helpful content within relevant communities to grow exposure to your brand.'},
      
    ]
  },
  'OT': {
    'title': 'Other use-cases of GummySearch',
    'next': '/audiences/',
    'description': "If your main objective does not match one of the pre-determined ones on the previous screen, you can still use GummySearch to discover communities and conversations related to your business. If you need any advice for your specific use-case, feel free to reach out to @foliofed on Twitter.",
    'steps': [
      {'index': 1, 'icon': 'discover', 'title': 'Discover Subreddits', 'description': 'Use GummySearch Discover to find some communities for a particular niche.'},
      {'index': 2, 'icon': 'discussion', 'title': 'Search Conversations', 'description': "Search for keywords related to the problem you're solving, and respond with your expert advice or suggestions."},
      {'index': 3, 'icon': 'bell', 'title': 'Track Keyword Mentions', 'description': "Set up notifications for new conversations with your keywords, so you always be in the right place at the right time."},
      {'index': 4, 'icon': 'collection', 'title': 'Niche Down', 'description': 'If your keywords are too broad, make a collection of subreddits to search within relevant communities only.'},
    ]
  },
};

const getIcon = (key) => {
    if (key === 'discover'){
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      )
    } else if (key === 'discussion'){
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
        </svg>
      )
    } else if (key === 'sort'){
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4" />
        </svg>
      )
    } else if (key === 'interview'){
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
        </svg>
      )
    } else if (key === 'collection'){
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
        </svg>
      )
    } else if (key === 'bookmark'){
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
        </svg>
      )
    } else if (key === 'bell'){
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
        </svg>
      )
    } else if (key === 'speakerphone'){
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
        </svg>
      )
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    )
  }


const Steps = ({history, currentUser, ...subrouteProps}) => {

  const { nextPage, isLastPage } = subrouteProps;

  const onSubmit = () => {
    const destination = objectives[currentUser.profile.objective]['next'];
    history.push(isLastPage ? destination : `/welcome/${nextPage}/`);
  }

  // various options from the previous screen
  const currentObjective = objectives[currentUser.profile.objective];

  return (
    <div className="max-w-5xl w-full mx-auto min-h-full flex flex-col">
      <Helmet><title>Welcome | Steps</title></Helmet>

      <div className="inline-block bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle p-6">
        <div className="text-center">
          <div className="relative mb-4">
            <hr className="opacity-25" />
            <div className="absolute -top-3 w-full text-center">
              <span className="p-1 text-sm rounded-sm bg-gray-800 text-gray-500 uppercase font-semibold">
                Tips for you
              </span>
            </div>
          </div>

          {currentObjective ? (
            <React.Fragment>
              <p className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
                {currentObjective.title}
              </p>
              <p className="mt-4 max-w-2xl text-gray-400 mx-auto">
                {currentObjective.description}
              </p>

              <div className="text-left mt-4">
                {currentObjective.steps.map(step => (
                  <div key={step.title} className="mb-4 flex-1 flex flex-col sm:p-4">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
                        { getIcon(step.icon) }
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium">{ step.index }. { step.title }</p>
                    </dt>
                    <dd className="mt-1 ml-16 text-md text-gray-400">
                      { step.description }
                    </dd>
                  </div>
                ))}
                
              </div>
            </React.Fragment>
          ) : ''}
          
          
        </div>
      </div>

      <FormFooter submit={onSubmit} {...subrouteProps} />
    </div>
  );
}

export default Steps;
