import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Modal from "../common/modal";
import AnalysisClusters from "../language/analysisClusters";
import AnalysisQuestionAnswerResults from "../language/questionAnswerResults";

// serves a simple plan selection modal with the subscriptions component
const ReportModal = ({ 
    reports,
    history,
    match,
    closeModal,
    getReportTitle,
    getReportType,
    getReportResultsTitle,
 }) => {
  const reportHash = match.params.hash;
  const report = reports.find(r => r.hash === reportHash);
  if (!report) return '';
  
  return (
    <Modal close={closeModal} widthClass={`max-w-5xl bg-gray-900 ${getReportType(report) === 'Ask' ? 'max-w-7xl' : 'max-w-5xl'}`}>
      <Helmet><title>Account | Report</title></Helmet>

      <div className="flex items-center">
          
          <h3 className="text-lg leading-6 font-medium">
            {getReportType(report)}: {getReportTitle(report)}

            {report.params_results.subreddits ? (
              <div className="text-gray-500 mt-1 text-sm">
                In {report.params_results.subreddits.split(',').map(sub => `r/${sub}`).join(', ')}
              </div>
            ) : ''}
          </h3>

          <button className="focus:outline-none ml-auto" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="mt-4">
          {getReportType(report) === 'Patterns' ? <AnalysisClusters analysis={report} /> : ''}
          {getReportType(report) === 'Ask' ? <AnalysisQuestionAnswerResults analysis={report}  /> : ''}
        </div>
    </Modal>
  );
};

export default ReportModal;

