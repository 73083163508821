import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import {
  Link, Switch, Route
} from "react-router-dom";
import { ModalRoute } from 'react-router-modal';
import Toggle from 'react-toggle'
import {
  CheckIcon, InformationCircleIcon, ViewListIcon, ViewBoardsIcon,
} from '@heroicons/react/outline'

import { collectionsService } from '../../services/collections';
import { searchesService } from '../../services/searches';
import { uiOperations } from "../../redux/ui"
// import { hasStarter } from "../../utils/account"

import DrawerRoute from "../common/drawerroute";
import ErrorBoundary from "../common/errorboundary";
import PropsRoute from "../common/propsroute";
import SelectPopover from "../common/selectPopover";
import AudienceTopicCard from "./audienceTopicCard";
import AudienceTopic from "./audienceTopic";
// import AudienceSearch from "./audienceSearch";


const AudienceTopics = ({
  collection,
  topics,
  suggestions,
  subreddits,
  loadingTopics,
  fetchTopics,
  setUiState,
  currentUser,
  topicSort,
  history,
  match,
}) => {
  // state
  var sortOptions = ['Velocity', 'Growth']; // not doing Volume
  const sortOption = topicSort || sortOptions[0];
  const displayType = 'card';
  const currentTopic = history.location.pathname && history.location.pathname.split('/topics/')[1] && history.location.pathname.split('/topics/')[1].replace('/', '')
  const isMobile = window.screen.width < 800;  // on mobile, show detail view in drawer
  const [keyword, setKeyword] = useState('');

  // default t0 "Velocity" sort
  var sortedItems = JSON.parse(JSON.stringify(topics)); // this clones for new reference
  if (sortOption === "Velocity"){
    sortedItems = sortedItems.filter(t => typeof t.stats.velocity_current === "number").sort((a,b) => (a.stats.velocity_current < b.stats.velocity_current) ? 1 : ((b.stats.velocity_current < a.stats.velocity_current) ? -1 : 0));
  } else if (sortOption === "Growth"){
    sortedItems = sortedItems.filter(t => typeof t.stats.growth_past === "number").sort((a,b) => (a.stats.growth_past < b.stats.growth_past) ? 1 : ((b.stats.growth_past < a.stats.growth_past) ? -1 : 0));
    // sortedItems = [...sortedItems, ...topics.filter(t => typeof t.stats.growth_year !== "number")] // put ones without the stat at end
  } else if (sortOption === "Volume"){
    sortedItems = sortedItems.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));
  }

  useEffect(() => {
    if (sortedItems && sortedItems.length && !isMobile){
      // only redirect to first if there's no current topic on the page (and we're not on mobile)
      if (history.location.pathname && !currentTopic){
        history.push(`/audience/${collection.hash}/topics/${encodeURIComponent(sortedItems[0].topic)}/`)
      }
    }
  }, [topics])

  // if have a keyword search
  if (keyword){
    sortedItems = sortedItems.filter(t => t.topic.toLowerCase().includes(keyword.toLowerCase()))
  }

  // to make width
  var maxVelocity = 0.01; // switch from 
  if (topics && topics.length){
    // maxCount = Math.max(...[...terminology.popular.map(k => k.count), ...terminology.recent.map(k => k.count)]);
    maxVelocity = Math.max(...topics.filter(k => k.stats.velocity_current).map(k => k.stats.velocity_current));
  }

  var maxCount = 1;
  if (topics && topics.length){
     maxCount = Math.max(...topics.map(k => k.count));
  }

  return (
    <ErrorBoundary>
      <Helmet><title>Audience | Topics</title></Helmet>
      
      {/*<div className="mt-4">
        <AudienceSearch collection={collection} />
      </div>*/}

      <div className="mt-4 flex space-x-8 relative">
        <div className="flex-1">
          
          {topics.length !== 0 ? (
            <div className="relative text-gray-400 focus-within:text-white mb-4">
              <form onSubmit={(e) => {e.preventDefault()}}>
                <input id="keyword-search" type="text" placeholder="Search topics" autoComplete="off" spellCheck="off" autoFocus="on"
                  className="text-sm sm:text-base block bg-gray-800 w-full border-transparent p-4 pl-12 placeholder-gray-500 rounded-md focus:border-transparent focus:ring-0"
                  // disabled={!collection}
                  value={keyword} onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                  </svg>
                </div>
                {keyword ? (
                  <div className="absolute inset-y-0 right-0 flex items-center justify-center pr-4 opacity-50 hover:opacity-75 cursor-pointer"
                    onClick={() => setKeyword('')}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                ) : ''}
                
              </form>
            </div>
          ) : ''}

          <div className="flex items-center mb-2">
            <h2 className="text-lg font-medium flex items-center flex-wrap">
              <span>Popular Topics</span>

              <p className="ml-2 text-gray-500 truncate">
                {loadingTopics ? (
                  <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : keyword ? (
                  <span>{sortedItems.length} / {topics.length}</span>
                ) : (
                  <span>{topics.length}</span>
                )}
              </p>
            </h2>
            <div className="ml-auto flex">
              {topics.length >= 2 ? (
                <div className="ml-4 flex items-center">
                  <div className="text-xs opacity-50 hidden sm:block">Sort by</div>
                  <div className="ml-2 flex items-center">
                    <SelectPopover
                      options={sortOptions}
                      currentOption={sortOption}
                      setCurrentOption={(s) => setUiState('topicSort', s)}
                      labelField={undefined}
                    />
                  </div>
                </div>
              ) : ''}
            </div>
          </div>
          
          <div className={`grid grid-cols-1 bg-gray-800 rounded-md overflow-hidden`}>

            {/*{topics.length !== 0 ? (
              <div className="relative text-gray-400 focus-within:text-white border-b border-gray-600">
                <form onSubmit={(e) => {e.preventDefault()}}>
                  <input id="keyword-search" type="text" placeholder="Search topics" autoComplete="off" spellCheck="off" //autoFocus="off"
                    className="text-sm sm:text-base block bg-gray-800 w-full border-transparent p-4 pl-12 placeholder-gray-500 rounded-md focus:border-transparent focus:ring-0"
                    // disabled={!collection}
                    value={keyword} onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                    </svg>
                  </div>
                  {keyword ? (
                    <div className="absolute inset-y-0 right-0 flex items-center justify-center pr-4 opacity-50 hover:opacity-75 cursor-pointer"
                      onClick={() => setKeyword('')}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                  ) : ''}
                  
                </form>
              </div>
            ) : ''}*/}

            {sortedItems.map(topic => (
              <AudienceTopicCard key={topic.topic}
                topic={topic}
                collection={collection}
                maxVelocity={maxVelocity} maxCount={maxCount}
                // showYearlyGrowth={sortOption !== "Growth (Monthly)"}
                highlighted={currentTopic === topic.topic ? true : false}
              />
            ))}

            {topics.length === 0 && !loadingTopics ? (
              <div className="p-4 opacity-50">
                No popular topics for this audience yet.

                <span className="underline ml-2 cursor-pointer" onClick={fetchTopics}>Refetch</span>
              </div>
            ) : topics.length > 0 && sortedItems.length === 0 ? (
              <div className="p-4 text-center">
                <div className="text-semibold text-gray-200">No posts tagged with "{keyword}"</div>
                <div className="mt-4">
                  <Link
                    to={`/audience/${collection.hash}/search?keyword=title:"${keyword}"`}
                    // to={`/reddit/?type=submissions&keyword=${topicDetails.submission_ids.join(",")}`}
                    type="button"
                    className="ml-auto cursor-pointer inline-flex items-center px-3 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-700 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                  >
                    <svg className="w-4 h-4 mr-2 opacity-50" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                    </svg>
                    Try a keyword search
                  </Link>
                </div>
              </div>
            ) : ''}
          </div>
        </div>

        {!isMobile ? (
          <div id="topics-detail" className="flex-1">
            <PropsRoute path={`/audience/${collection.hash}/topics/:name/`} component={AudienceTopic}
              collection={collection} topics={topics} loadingTopics={loadingTopics}
              suggestions={suggestions || []} subreddits={subreddits || []}
              // suggestions={suggestions} loadingSuggestions={loadingSuggestions}
            />
          </div>
        ) : (
          <Switch>
            <Route path={`/audience/${collection.hash}/topics/:name/`}
              render={(props) => {
                if (loadingTopics) return ''; // just while loading
                return (
                  <DrawerRoute closeUrl={`/audience/${collection.hash}/topics/`} {...props}>
                    <div id="topic-drawer" className="h-full flex flex-col bg-gray-900 text-white shadow-xl overflow-auto">
                      <div className="p-4 sm:p-6">
                        <AudienceTopic
                          collection={collection} topics={topics} loadingTopics={loadingTopics}
                          suggestions={suggestions || []} subreddits={subreddits || []}
                          match={props.match} history={history} onClose={() => history.push(`/audience/${collection.hash}/topics/`)}
                        />
                      </div>
                    </div>
                  </DrawerRoute>
                );
              }}
            />
          </Switch>
        )}

        
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  const {
    topicSort,
  } = state.ui;

  return {
    currentUser,
    topicSort,
  }
};

const mapDispatchToProps = (dispatch) => {
  const setUiState = (key, value) => {
    dispatch(uiOperations.setUiState(key, value))
  };
  return {
    setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceTopics);

