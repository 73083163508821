import { postRequest, getRequest } from '../utils/axios';
import axios from 'axios';


export const languageService = {
    fetchCategories,
    setCategoryKeywords,
};

function fetchCategories(onSuccess, onError) {
  return getRequest(
    `/api/v1/language-categories/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function setCategoryKeywords(data, onSuccess, onError) {
  return postRequest(
    `/api/v1/language-categories/`, data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}
