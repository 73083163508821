import React, { useState, useEffect } from "react";
import {
  Link,
} from "react-router-dom";
import { withRouter } from "react-router";
import {
  TrendingUpIcon, TrendingDownIcon, InformationCircleIcon, QuestionMarkCircleIcon
} from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
// import { Popover, Transition } from '@headlessui/react'

import ErrorBoundary from "../common/errorboundary";

import { velocityToString, capitalizeFirst } from '../../utils/text';
// import { getParamsFromUrl, toQueryString } from '../../utils/urls';
// import { trackEvent } from '../../utils/tracking';

// import { searchesService } from '../../services/searches';
// import { redditService } from '../../services/reddit';
// import { collectionsService } from '../../services/collections';


// renders a subreddit to a collection detail screen
const AudienceTopicCard = ({
  topic,
  collection,
  // maxCount,
  maxVelocity,
  highlighted,
  showYearlyGrowth, // if false, show monthly
}) => {

  const subredditsToShow = 2

  return (
    <Link
      to={`/audience/${collection.hash}/topics/${encodeURIComponent(topic.topic)}/`}
      // to={`/audience/${collection.hash}/topics/${topic.topic}/`}
      key={topic.topic} className={`group border-b border-gray-700 text-white p-3 sm:p-4 ${highlighted ? 'bg-gray-700' : 'bg-gray-800 hover:bg-gray-700'}`}>
      <ErrorBoundary>
        <div className="lg:flex w-full items-center">
          <div className="flex-1">
            <h2 className="text-xl font-semibold flex items-center mb-0">
              {capitalizeFirst(topic.topic)}

              {typeof topic.stats.growth_past === "number" ? (
                <span className={`ml-auto mr-4 text-xs flex items-center ${topic.stats.growth_past > 0 ? 'text-green-400' : topic.stats.growth_past === 0 ? 'text-gray-400' : 'text-red-400'}`}
                  data-for={`topic-stats-${topic.topic}`} data-tip={`${topic.stats.growth_past}% ${topic.stats.growth_past > 0 ? 'increase' : 'decrease'} in mentions recently`}
                >
                  {topic.stats.growth_past > 0 ? (
                    <TrendingUpIcon className="h-4 w-4 mr-1" />
                  ) : (
                    <TrendingDownIcon className="h-4 w-4 mr-1" />
                  )}
                  <div>{topic.stats.growth_past}%</div>
                </span>
              ) : ''}
            </h2>

            {/*<div className="text-xs text-gray-500">
              <span className="text-gray-400"><b>{topic.count}</b></span> post{topic.count === 1 ? '' : 's'} in{' '}
              {Object.keys(topic.subreddits).filter((s, i) => i < subredditsToShow).map((s, i) => (
                <span key={s}>
                  <span className="text-gray-400">r/{s}</span>
                  {i + 1 !== Object.keys(topic.subreddits).length ? (
                    <span>, </span>
                  ) : ''}
                  {i === (subredditsToShow - 1) && Object.keys(topic.subreddits).length > subredditsToShow ? (
                    <span> and {Object.keys(topic.subreddits).length - subredditsToShow} other{Object.keys(topic.subreddits).length - subredditsToShow === 1 ? '' : 's'}</span>
                  ) : ''}
                </span>
              ))}
            </div>*/}
          </div>

          <div className="flex-1 mt-1 lb:mt-0">
            <div className="mt-auto w-full text-xs font-medium items-center">
              <div className="flex-grow bg-transparent rounded-sm relative flex-shrink-0 h-6 mr-4">
                <div className="absolute bg-cyan-700 group-hover:bg-cyan-600 rounded-sm p-1 h-full" style={{width: parseInt((100 * topic.stats.velocity_current) / maxVelocity) + '%'}}></div>
                <div className="absolute p-1 px-2 text-gray-300 max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                  
                  <span className="text-gray-100 mr-1"><b>{velocityToString(topic.stats.velocity_current)}</b></span> in {` `}
                  {/*<span className="text-gray-100 mr-1"><b>{topic.count}</b></span> post{topic.count === 1 ? '' : 's'} in {` `}*/}
                  {Object.keys(topic.subreddits).filter((s, i) => i < subredditsToShow).map((s, i) => (
                    <span className="ml-1" key={s}>
                      <span className="text-gray-100">r/{s}</span>
                      {i + 1 !== Object.keys(topic.subreddits).length ? (
                        <span>, </span>
                      ) : ''}
                      {i === (subredditsToShow - 1) && Object.keys(topic.subreddits).length > subredditsToShow ? (
                        <span> and {Object.keys(topic.subreddits).length - subredditsToShow} other{Object.keys(topic.subreddits).length - subredditsToShow === 1 ? '' : 's'}</span>
                      ) : ''}
                    </span>
                  ))}
                </div>
              </div>
              {/*<div className="opacity-50 mr-auto">
                {velocityToString(topic.stats.velocity_current)}
              </div>*/}

              <ReactTooltip id={`topic-stats-${topic.topic}`} place="top" effect="solid" backgroundColor="white" textColor="black" />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </Link>
  )
}

export default withRouter(AudienceTopicCard);

