// operations.js
// import fetch from 'cross-fetch';
// import axios from 'axios';
import types from './types.js';

// generic ui state util
const setUiState = (key, value) => {
  return dispatch => {
    dispatch({type: types.SET_UI_STATE, key, value});
  }
}


export default {
  setUiState,
}