import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';

import {
  Link, Switch
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { ModalRoute, ModalContainer } from 'react-router-modal';
import Toggle from 'react-toggle'
import {
  CheckIcon, InformationCircleIcon, ViewListIcon, ViewBoardsIcon,
} from '@heroicons/react/outline'
import { Transition } from '@headlessui/react'

import { redditService } from '../../services/reddit';
import { audiencesOperations } from "../../redux/audiences"
import { uiOperations } from "../../redux/ui"

import ErrorBar from "../common/errorBar";
import ErrorBoundary from "../common/errorboundary";
import SelectPopover from "../common/selectPopover";

import SubredditPopover from "../reddit/subredditpopover";
import TopSubredditCard from "./topsubredditcard.jsx"
// import AudienceSubredditCard from "./audiencesubredditcard";


const Top40List = ({
  lists,
  timeframes,
  sizes,
  currentUser,
  subredditDisplayType,
  // subredditSort,
  setUiState,
  match, 
  history,
  // audiences, fetchingAudiences, fetchAudiences,
  // isEffectiveProSubscriber,
}) => {
  
  // // state
  const { listKey } = match.params;
  const isMobile = window.screen.width < 600;
  // const displayType = subredditDisplayType;
  // const displayType = isMobile ? 'card' : subredditDisplayType; // force card for mobile
  const displayType = isMobile ? 'card' : 'list'; // force display type based on screen type
  const list = lists.filter(l => l.key === listKey)[0]
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);
  const [metric, setMetric] = useState(null);
  const [timeframe, setTimeframe] = useState(null);
  const [size, setSize] = useState(sizes[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedTerm, setSearchedTerm] = useState('');
  const countToShowIncrement = 50;
  const [countToShow, setCountToShow] = useState(countToShowIncrement); // start with 50, show more later

  // effects
  useEffect(() => {
    if (!list) return;
    setItems([]);
    setMetric(list.metrics && list.metrics[0] && list.metrics[0])
    setTimeframe(list.useTimeframes ? timeframes[2] : null) // defaults to monthly timeframe
    setSize(sizes[0]);
    setCountToShow(countToShowIncrement);
  }, [list.key]); // Set default params when list changes

  useEffect(() => {
    if (!metric) return;
    fetchItems();
  }, [metric, timeframe, size, searchedTerm, countToShow]); // Fetch items when params change

  // useEffect(() => {
  //   if (!fetchingAudiences && !audiences.length){
  //     fetchAudiences()
  //   }
  // }, []);

  // const sortOptions = ['Recent', 'Largest', 'Growth'];
  // const sortOption = subredditSort;
  // const [includeStats, setIncludeStats] = useState(false);
  // const displayType = subredditDisplayType;
  // // const [displayType, setDisplayType] = useState('card');
  

  // // actions
  const fetchItems = () => {
    if (!list || loading || !metric) return;

    // assemble params based on the metric selection & list config
    const apiMetric = `${metric.key}${timeframe ? `_${timeframe.key}` : ''}` // constructs metric based on timeframe
    var params = {
      'metric': apiMetric,
      'search': searchedTerm,
      'limit': countToShow,
    }
    if (size && size.key !== 'all'){
      params['tags'] = [size.key] // can do multiple later
    }

    setError(null)
    setLoading(true)
    // setItems([])

    redditService.fetchTopSubreddits(params, (response) => {
      setItems(response.data.results)
      setLoading(false)
    }, (response, error) => {
      console.log(error)
      setError(error)
      setLoading(false)
    })
  };

  if (!list){
    return 'Invalid top 40 list'
  }

  // based on the metrics tracking, get the top value so we can do percentile 
  var maxValues = {}
  if (list && items.length){
    // const velocityPercentWidth = Math.min(100 * (velocity / maxVelocity), 100);
    if (list.useTimeframes){
      list.metrics.forEach(m => {
        timeframes.forEach(t => {
          const key = `${m.key}_${t.key}`;
          maxValues[key] = Math.max(...items.filter(i => i.calculated[key]).map(i => i.calculated[key])) || 1
        });
      })
    } else {
      list.metrics.forEach(m => {
        const key = m.key;
        maxValues[key] = Math.max(...items.filter(i => i.calculated[key]).map(i => i.calculated[key])) || 1
      })
    } 
  }

  return (
    <ErrorBoundary>
      <Helmet><title>Top Subreddits | {list.title}</title></Helmet>
      <div className="">
        <div className="mb-2">
          <div className="flex flex-wrap items-center">
            <h2 className="text-lg font-medium flex items-center flex-wrap">
              <div className="flex items-center flex-wrap">
                <div className="hidden sm:block">Top {list.name} Subreddits</div>
                <div className="block sm:hidden">Top Subreddits</div>

                <div className="mx-auto flex items-center px-1">
                  {lists.map(l => (
                    <Link key={l.key} className={`px-1 sm:px-2 py-1 mx-1 text-sm rounded-sm ${l.key === list.key  ? 'bg-gray-600 text-white' : 'bg-gray-800 cursor-pointer hover:bg-gray-700'}`}
                      // onClick={() => setBillingCycle(b)}
                      to={`/audiences/trending/${l.key}/`}
                    >
                      {l.title}
                    </Link>
                  ))}
                </div>
              </div>

              <p className="hidden sm:block ml-2 text-gray-500 truncate">
                {loading ? (
                  <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  <span>{/*{items.length}*/}</span>
                )}
              </p>
            </h2>

            <div className="ml-auto flex mt-1 sm:mt-0">

              

              {list.metrics && list.metrics.length ? (
                <div className="ml-4 flex items-center">
                  <div className="text-xs opacity-50 hidden sm:block">Sort by</div>
                  <div className="ml-2 flex items-center">
                    <SelectPopover
                      options={list.metrics}
                      currentOption={metric}
                      setCurrentOption={setMetric}
                      labelField={'title'}
                    />
                  </div>
                </div>
              ) : ''}

      

              {list.metrics && list.useTimeframes ? (
                <div className="ml-4 flex items-center">
                  <div className="text-xs opacity-50 hidden sm:block">Timeframe</div>
                  <div className="ml-2 flex items-center">
                    <SelectPopover
                      options={timeframes}
                      currentOption={timeframe}
                      setCurrentOption={setTimeframe}
                      labelField={'title'}
                    />
                  </div>
                </div>
              ) : ''}

              {sizes ? (
                <div className="ml-4 flex items-center">
                  <div className="text-xs opacity-50 hidden sm:block">Size</div>
                  <div className="ml-2 flex items-center">
                    <SelectPopover
                      options={sizes}
                      currentOption={size}
                      setCurrentOption={setSize}
                      labelField={'subtitle'}
                    />
                  </div>
                </div>
              ) : ''}

              <div className="ml-4 flex items-center">
                <div className="text-xs opacity-50 hidden sm:block">Search</div>
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  className={`ml-2 bg-gray-800 focus:bg-gray-700 border-transparent focus:border-gray-700 placeholder-gray-500 text-white block text-black w-full text-xs rounded-sm p-1 px-2 focus:ring-cyan-500 focus:border-cyan-500`}
                  placeholder={`Topic/Keyword`}
                  style={{minWidth: '50px'}}
                  value={searchTerm} onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      setSearchedTerm(e.target.value);
                    }
                  }}
                />
              </div>


              {false && !isMobile ? (
                <div className="ml-4 flex items-center">
                  <div className="text-xs opacity-50 hidden sm:block">Display</div>
                  <div className="ml-1 flex items-center space-x-1">
                    <ViewBoardsIcon
                      className={`cursor-pointer h-5 w-5 ${displayType === 'card' ? 'text-gray-200' : 'text-gray-500 hover:text-gray-300'}`} 
                      onClick={() => setUiState('subredditDisplayType', 'card')} />
                    <ViewListIcon
                      className={`cursor-pointer h-5 w-5 ${displayType === 'row' ? 'text-gray-200' : 'text-gray-500 hover:text-gray-300'}`} 
                      onClick={() => setUiState('subredditDisplayType', 'row')} />
                  </div>
                </div>
              ) : ''}
              
            </div>
          </div>
          {/*<p className="text-gray-500 truncate text-sm mt-1">
            Select subreddit to create audience and explore further
          </p>*/}
        </div>
          
        <Transition show={!loading || (items.length ? true : false)} as={React.Fragment}
          enter="transition transform ease-in duration-100"
          enterFrom="opacity-50 translate-y-1"
          enterTo="opacity-100 translate-y-0"
        >
          <div>
            <div className={`grid grid-cols-1 ${displayType === 'card' ? 'md:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-4' : 'gap-2'}`}>
              {items.map(item => (
                <TopSubredditCard key={item.name} subreddit={item}
                  displayType={displayType} list={list}
                  currentMetric={metric} timeframes={timeframes} maxValues={maxValues}
                  //audiences={audiences} //fetchAudiences={fetchAudiences}
                  history={history}
                />
              ))}
            </div>

            {false && !loading && items.length === 0 ? (
              <div className="py-20 px-4 text-center text-2xl text-center font-semibold text-gray-600">
                No tracked communities that meet these filters
              </div>
            ) : ''}

            {!loading && items.length && (items.length === countToShow) ? (
              <div className="py-12 px-4 text-center text-xl text-center font-semibold text-gray-600 cursor-pointer"
                onClick={() => {
                  setCountToShow(countToShow + countToShowIncrement); // will refetch for more results
                }}
              >
                More Subreddits Please!
              </div>
            ) : (
               <div className="py-16 px-4">{/* Just a spacer */}</div>
            )}
          </div>
        </Transition>


        

        {/*<div>
          {items.map(item => (
            <div>
              <SubredditPopover name={item.name}>r/{item.name}</SubredditPopover>
            </div>
          ))}
        </div>*/}

        {error ? (
          <ErrorBar error={error} setError={setError} onRetry={fetchItems}/>
        ) : ''}
      </div>
    </ErrorBoundary>
  );
}

const mapStateToProps = state => {
  const {
    subredditDisplayType,
    // subredditSort,
  } = state.ui;
  // const {
  //   audiences,
  //   fetchingAudiences,
  // } = state.audiences;

  return {
    subredditDisplayType,
    // subredditSort,
    // audiences,
    // fetchingAudiences,
  }
};

const mapDispatchToProps = (dispatch) => {
  const setUiState = (key, value) => {
    dispatch(uiOperations.setUiState(key, value))
  };
  // const fetchAudiences = (callback, onError) => {
  //   dispatch(audiencesOperations.fetchAudiences(callback, onError))
  // };
  return {
    setUiState,
    // fetchAudiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Top40List);

