import React from "react"
import {
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  SparklesIcon, TrendingUpIcon
} from '@heroicons/react/outline'

import FormFooter from "./footer";


const Intro = ({history, ...subrouteProps}) => {

  const { nextPage, isLastPage } = subrouteProps;

  const onSubmit = () => {
    history.push(isLastPage ? '/audiences/' : `/welcome/${nextPage}/`);
  }
  
  return (
    <div className="max-w-5xl w-full mx-auto min-h-full flex flex-col">
      <Helmet><title>Welcome | Intro</title></Helmet>

      <div className="inline-block bg-gray-800 rounded-lg overflow-hidden shadow-xl transform transition-all my-8 align-middle py-6 px-4 sm:p-6">
            
        <div className="text-center">
          <div className="relative mb-4">
            <hr className="opacity-25" />
            <div className="absolute -top-3 w-full text-center">
              <span className="p-1 text-sm rounded-sm bg-gray-800 text-gray-500 uppercase font-semibold">
                Welcome!
              </span>
            </div>
          </div>

          
          <p className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            Intro to GummySearch
          </p>
          <div className="text-left">
            <p className="mt-6 max-w-2xl text-gray-400 mx-auto text-base">
              Welcome! I built GummySearch because Reddit is a treasure trove of information, but not always easy to navigate.
              With this audience research toolkit, I hope you can unlock the full potential of conversational insights, within any niche that you are interested in. Enjoy!
            </p>
            <p className="mt-2 max-w-2xl text-gray-400 mx-auto text-base flex">
              <div className="text-left ml-auto">
                - <a href="https://twitter.com/foliofed" target="_blank" className="font-semibold border-dashed border-b border-gray-600 hover:border-gray-400">Fed, GummySearch Founder</a><br/>
                
              </div>
            </p>
          </div>
          {/*<p className="mt-4 max-w-2xl text-gray-400 mx-auto">
            GummySearch is your tool to discover niche online communities full of your customers, and engage them in conversation when it relates to your business.
          </p>*/}
          {/*<p className="mt-4 max-w-2xl text-gray-400 mx-auto">
            The available features can be used separately, but are best paired together.
          </p>*/}
        </div>

        <div className="mt-8 text-left">
          <dl className="block md:flex flex-wrap">

            <div className="flex-1 flex flex-col sm:p-4">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium">Find Communities</p>
              </dt>
              <dd className="mt-1 ml-16 text-md text-gray-400">
                Discover Subreddits full of your target customers. Quickly identify the active, new, or growing communities to pay attention to.
              </dd>
            </div>

            <div className="flex-1 flex flex-col mt-4 sm:mt-0 sm:p-4">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
                  
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium">Search Reddit (Better)</p>
              </dt>
              <dd className="mt-1 ml-16 text-md text-gray-400">
                Search Reddit posts & comments for any keyword. Zoom in with advanced query parameters, or zoom out for trends & insights.
              </dd>
            </div>
          </dl>

          <dl className="block md:flex flex-wrap">
            <div className="flex-1 flex flex-col mt-4 sm:mt-0 sm:p-4">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
                  
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium">Explore Personas</p>
              </dt>
              <dd className="mt-1 ml-16 text-md text-gray-400">
                Quickly explore pain points, solution requests, open questions, and spending patterns for people in any set of communities.
              </dd>
            </div>

            <div className="flex-1 flex flex-col mt-4 sm:mt-0 sm:p-4">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium">Track Keywords</p>
              </dt>
              <dd className="mt-1 ml-16 text-md text-gray-400">
                Stay up to date with new threads related to your business. Set up email/slack/discord alerts and be the first to join the conversation.
              </dd>
            </div>

          </dl>

          <dl className="block md:flex flex-wrap">
            

            <div className="flex-1 flex flex-col mt-4 sm:mt-0 sm:p-4">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
                  <TrendingUpIcon className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium">Discover Community Trends</p>
              </dt>
              <dd className="mt-1 ml-16 text-md text-gray-400">
                See the top subreddits by activity, size, growth, and engagement. Filter to find new breakout communities & niches.
              </dd>
            </div>

            <div className="flex-1 flex flex-col mt-4 sm:mt-0 sm:p-4">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
                  <SparklesIcon className="h-6 w-6" />
                </div>
                <p className="ml-16 text-lg leading-6 font-medium">Summarize with AI</p>
              </dt>
              <dd className="mt-1 ml-16 text-md text-gray-400">
                Use AI to summarize Reddit posts in seconds. Find patterns within hundreds of posts/comments without having to read them yourself.
              </dd>
            </div>

          </dl>
        </div>
        
      </div>

      <FormFooter submit={onSubmit} {...subrouteProps} />
    </div>
  );
}

export default Intro;
