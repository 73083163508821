import { postRequest, getRequest } from '../utils/axios';
import axios from 'axios';

import { trackEvent } from '../utils/tracking';
import { authService } from './auth';


export const paymentService = {
    initiateCheckout,
    modifyPlan,
    downgradeToFreeTrial,
    getPaymentPortalUrl,
    activateDaypass,
    updateLimitPack,
    initiateLtdConvertCheckout,
    checkChurnkeyScreens,
    paywallViewed,
};

function initiateCheckout(data, onSuccess, onError) {

  // fire analytics event upon starting a checkout
  trackEvent("InitiateCheckout", data);

  return postRequest(
    `/api/v1/payments/checkout/`,
    data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function modifyPlan(data, onSuccess, onError) {
  return postRequest(
    `/api/v1/payments/modify/`,
    data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function downgradeToFreeTrial(data, onSuccess, onError) {
  return postRequest(
    `/api/v1/payments/downgrade/`,
    data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getPaymentPortalUrl(data, onSuccess, onError) {
  return postRequest(
    `/api/v1/payments/payment-portal-url/`, data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function activateDaypass(onSuccess, onError) {
  return postRequest(
    `/api/v1/payments/activate-pass/`, {},
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// add/update/delete limit packs
function updateLimitPack(data, onSuccess, onError) {
  return postRequest(
    `/api/v1/payments/update-limit/`,
    data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function initiateLtdConvertCheckout(data, onSuccess, onError) {

  // fire analytics event upon starting a checkout
  // trackEvent("InitiateCheckout", data);

  return postRequest(
    `/api/v1/payments/checkout-ltd-convert/`,
    data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function checkChurnkeyScreens(currentUser) {

  // dont show paywalls if on account page
  if (window.location.pathname.includes('/account/')) return;

  if (currentUser && currentUser.subscription && typeof(window.churnkey) !== "undefined" && currentUser.subscription.hmac_hash){
    // check pause
    window.churnkey && window.churnkey.check && window.churnkey.check('pause', {
      subscriptionId: currentUser.subscription.stripe_subscription_id, // optional
      customerId: currentUser.subscription.stripe_customer_id, // required
      authHash: currentUser.subscription.hmac_hash, // required
      appId: 'wq8d877hj', // required
      mode: currentUser.env.name === 'production' ? 'live' : 'test', // set to 'test' to hit test billing provider environment
      provider: 'stripe', // set to 'stripe', 'chargebee', 'braintree', 'paddle'
      softWall: window.location.pathname.includes('/account/') ? true : false, // set to true to allow exiting wall
      forceCheck: false, // recommended to leave this to false to avoid redundant checks
      handleLogout() {
        // optional, if defined, a "Logout" button will show
        // authService.logout()

        // go to subscription so people can cancel if they want.
        window.location.href = "/account/subscription/"
      },
      onPauseWallActivated(){
        // optional, called when wall is activated
        trackEvent("ViewPausePaymentWall", {});
      },
      onCancel(customer) {
        window.location.href = "/account/subscription/"
      }, // optional
      onResume(customer) {
        window.location.href = "/account/subscription/"
      }, // optional 
      onPauseWallClose() { }, // optional
      onError(error, type) {
        // types below
      }
    }) 

    // check failed payments
    window.churnkey && window.churnkey.check && window.churnkey.check('failed-payment', {
      subscriptionId: currentUser.subscription.stripe_subscription_id, // optional
      customerId: currentUser.subscription.stripe_customer_id, // required
      authHash: currentUser.subscription.hmac_hash, // required
      appId: 'wq8d877hj', // required
      mode: currentUser.env.name === 'production' ? 'live' : 'test', // set to 'test' to hit test billing provider environment
      provider: 'stripe', // set to 'stripe', 'chargebee', 'braintree', 'paddle'
      softWall: window.location.pathname.includes('/account/') ? true : false, // set to true to allow exiting wall
      forceCheck: false, // recommended to leave this to false to avoid redundant checks
      ignoreInvoicesWithoutAttempt: false, // set to true to ignore invoices without a failed charge
      handleLogout() {
        // optional, if defined, a "Logout" button will show
        // authService.logout()
        
        // go to subscription so people can cancel if they want.
        window.location.href = "/account/subscription/"
      },
      handleSupportRequest() {
        // optional, if defined, a "Contact Support" button will show
        alert("Please email fed@gummysearch.com with your request.")
      },
      // handleCancel(customer) {
      //   // optional, if defined, a "Cancel Subscription" button will show
      // },
      // onUpdatePaymentInformation(customer) {
      //   // optional, called after customer updates payment information and overdue invoice is charged
      // },
      onFailedPaymentWallActivated(){
        // optional, called when wall is activated
        trackEvent("ViewFailedPaymentWall", {});
      },
      onFailedPaymentWallClose() { }, // optional
      // onCancel(customer) {
      //   window.location.reload()
      // }, // optional
      onError(error, type) {
         // types below
      }
    }) 

  }
}

function paywallViewed(data, onSuccess, onError) {
  return postRequest(
    `/api/v1/payments/paywall-view/`,
    data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}