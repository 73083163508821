// operations.js
// import fetch from 'cross-fetch';
import types from './types.js';
import axios from 'axios';

import { collectionsService } from "../../services/collections";

const fetchAudiences = (callback, onError) => {
  return dispatch => {
    dispatch({type: types.FETCH_AUDIENCES});
    return collectionsService.getCollections((response) => {
      dispatch({type: types.FETCH_AUDIENCES_SUCCESS, data: response.data});
      if (callback){
        callback(response.data)
      }
    }, (error, response) => {
      dispatch({type: types.FETCH_AUDIENCES_FAIL});
      if (onError){
        onError()
      }
    })
  }
}

const fetchAudienceItems = (hashes, callback, onError) => {
  return dispatch => {
    dispatch({type: types.FETCH_AUDIENCE_ITEMS});
    return collectionsService.getCollectionItemsBulk(hashes, (response) => {
      dispatch({type: types.FETCH_AUDIENCE_ITEMS_SUCCESS, data: response.data});
      if (callback){
        callback(response.data)
      }
    }, (error, response) => {
      dispatch({type: types.FETCH_AUDIENCE_ITEMS_FAIL});
      if (onError){
        onError()
      }
    })
  }
}

const fetchAudienceSubreddits = (names, callback, onError) => {
  // return dispatch => {
  //   dispatch({type: types.FETCH_AUDIENCES});
  //   return collectionsService.getCollections((response) => {
  //     dispatch({type: types.FETCH_AUDIENCES_SUCCESS, data: response.data});
  //     if (callback){
  //       callback(response.data)
  //     }
  //   }, (error, response) => {
  //     dispatch({type: types.FETCH_AUDIENCES_FAIL});
  //     if (onError){
  //       onError()
  //     }
  //   })
  // }
}

export default {
  fetchAudiences,
  fetchAudienceItems,
}