import { isExpired } from "react-jwt";
import { postRequest, getRequest } from '../utils/axios';
import { FullStoryAPI } from 'react-fullstory';
// import axios from 'axios';

import { deidentifyUser } from '../utils/tracking'
// import { userOperations } from '../redux/user'


export const authService = {
    login,
    logout,
    register,
    fetchUser,
    tokenNeedsRefresh,
    refreshToken,
    forgotPassword,
    forgotPasswordConfirm,
    checkPasswordTokenValidity,
};

function login(username, password, onSuccess, onError) {
  return postRequest(
    '/api/token/',
    {'username': username, 'password': password},
    (response) => {
      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      // fetch user config (moved to Login.jsx)
      // fetchUser()

      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function register(data, onSuccess, onError) {
  return postRequest(
    '/api/v1/register/',
    data,
    (response) => {
      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      // fetch user config (moved to Register.jsx)
      // fetchUser()

      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function tokenNeedsRefresh(token) {
  return isExpired(token);
}

function refreshToken(onSuccess, onError) {
  localStorage.setItem('refreshingToken', true);
  return postRequest(
    '/api/token/refresh/',
    {'refresh': localStorage.getItem('refreshToken')},
    (response) => {
      const accessToken = response.data.access;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshingToken', false);
      if (onSuccess){
        return onSuccess(accessToken)
      }
    },
    (error, response) => {
      // log the user out if their refresh failed
      localStorage.setItem('refreshingToken', false);
      authService.logout()
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function fetchUser(onSuccess, onError) {
  return getRequest(
    '/api/v1/user-config/',
    (response) => {
      const user = JSON.stringify(response.data)
      localStorage.setItem('currentUser', user); // set to local storage
      // TODO: set to redux state as well. Can do by calling userOperations.fetchCurrentUser instead.
      if (onSuccess){
        onSuccess(response.data)
      }
    },
    (error, response) => {
      if (onError){
        onError(error, response)
      }
    }
  )
}

function forgotPassword(email, onSuccess, onError) {
  return postRequest(
    '/api/v1/password_reset/reset_password/',
    {'email' : email},
    (response) => {
      if (onSuccess){
        onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        onError(error, response)
      }
    }
  )
}

function forgotPasswordConfirm(token, password, onSuccess, onError) {
  return postRequest(
    '/api/v1/password_reset/confirm/',
    {'token': token, 'password': password},
    (response) => {
      if (onSuccess){
        onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        onError(error, response)
      }
    }
  )
}

function checkPasswordTokenValidity(token, onSuccess, onError) {
  return postRequest(
    '/api/v1/password_reset/validate_token/',
    {'token': token},
    (response) => {
      if (onSuccess){
        onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        onError(error, response)
      }
    }
  )
}

function logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('refreshingToken');

    deidentifyUser();

    // window.location = `/login/`
    window.location = `/login/?next=${window.location.href}`
}