import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import ErrorBar from "../common/errorBar";
import LoaderFancy from "../common/loaderfancy";
import ResultsAnalyzePatterns from "../../components/language/patterns";
import FeaturePaywallWrapper from "../../components/account/featurePaywallWrapper"


// analyzes filtered results for suggestion
const AudienceSuggestionAnalyze = ({
  currentUser,
  results,
  filterParams,
  fetching,
  suggestion,
}) => {

  return (
    <div className="p-4 overflow-y-auto h-full">
      <Helmet><title>Audience | Theme | {suggestion.name} | Patterns</title></Helmet>
      <FeaturePaywallWrapper featureKey={"pattern-finder"} className={'max-w-full'} currentUser={currentUser}>
        {results && results.length ? (
          <ResultsAnalyzePatterns
            currentUser={currentUser}
            results={results}
            filterParams={filterParams}
            limitOptions={[100, 200, 500]}
            filterParams={Object.assign(filterParams, {
              'limit': suggestion && suggestion.count > 100 ? 200 : 100, // bump up the number of results if there are above 100
            })}
            emptyMessage={results && results.length < 100 ? `If <100 AI results for ${suggestion.name} found, try "More > Keyword Search".` : ''}
          />
        ) : fetching ? (
          <div className="p-4 overflow-y-auto h-full">
            <div className="h-48 relative">
              <LoaderFancy />
            </div>
          </div>
        ) : (
          <div className="p-6 bg-gray-800 rounded-lg border border-solid border-gray-700 text-white">
            <div className="">
              <h3 className="text-lg leading-6 font-medium">
                No results at this time...
              </h3>
            </div>
          </div>
        )}
      </FeaturePaywallWrapper>
      
    </div>
  );
}

export default AudienceSuggestionAnalyze;

