import React, { useState } from "react";

import ReactTooltip from 'react-tooltip';

// import SubredditPopover from "./subredditpopover";
import AggregateOption from "./aggregateOption";
import SubmissionTypeIcon from "./submissiontypeicon";


const QuickFilters = ({
  results,
  filteredResults,
  frontendFilters,
  applyFrontendFilter,
  clearFrontendFilter,
}) => {
  // cut out early if we haven't got anything or cleared the search
  if (!results || !results.length) return ''

  const filters = [
    // {'key': 'most-upvoted', 'name': 'Most Upvoted', 'icon': 'arrow-up'},
    // {'key': 'most-discussed', 'name': 'Most Discussed', 'icon': 'comment'},
    // {'key': 'not-removed', 'name': 'Not Removed', 'icon': 'live'},
    {'key': 'no-duplicates', 'name': 'No Duplicates', 'icon': 'arrows-in'},
    {'key': 'text-posts-only', 'name': 'Text Posts Only', 'icon': 'text'},
    {'key': 'not-viewed', 'name': 'Not Viewed Yet', 'icon': 'new'},
  ];

  // no quick filters for comments yet
  const type = results && results.length && results[0]._meta.type;
  if (type === 'reddit-comment') return '';

  return (
    <React.Fragment>
      <ReactTooltip id='quick-filters' place="right" effect="solid" backgroundColor="white" textColor="black" />
      
      <div className="text-md font-sm mb-1 mt-4 items-center flex">
          <h2 className="opacity-75">Quick Filters</h2>
          <span className="ml-1 text-xs font-semibold">
            {frontendFilters && frontendFilters.quick && frontendFilters.quick.length ? (
              <React.Fragment>
                <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                  onClick={() => clearFrontendFilter('quick')}>clear</span>
              </React.Fragment>
            ) : ''}
          </span> 
      </div>
      <div className="flex items-center flex-wrap">
        {filters.map(f => (
          <div key={f.key} className={`p-1 px-2 mr-2 mb-2 font-medium text-xs flex items-center cursor-pointer rounded-sm ${frontendFilters.quick && frontendFilters.quick.includes(f.key) ? 'bg-gray-700 text-white' : 'bg-gray-800 hover:bg-gray-700'}`}
            onClick={() => applyFrontendFilter('quick', f.key)}
          >
            <div>
              {frontendFilters.quick && frontendFilters.quick.includes(f.key) ? (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-2 h-4 w-4 flex-shrink-0 text-green-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
              ) : (
                <SubmissionTypeIcon type={f.icon} className="mr-2 h-4 w-4 opacity-50 flex-shrink-0" />
              )}
              
            </div>
            <div>{f.name}</div>
          </div>
        ))}
      </div>
   </React.Fragment>
  )
};

export default QuickFilters;

