import React from "react";
// import { Link } from "react-router-dom";

// import { withRouter } from "react-router";
// import ReactTooltip from 'react-tooltip';

import AggregateSubreddits from "./aggregateSubreddits";
import AggregateTypes from "./aggregateTypes";
import AggregateTopics from "./aggregateTopics";
import AggregateScores from "./aggregateScores";
import AggregateVelocity from "./aggregateVelocity";
import QuickFilters from "./quickFilters";

// import { getParamsFromUrl, toQueryString } from '../../utils/urls';
// import { velocityToString } from '../../utils/text';

const Aggregates = ({
  results,
  aggregates,
  filteredResults,
  frontendFilters,
  applyFrontendFilter,
  clearFrontendFilter,
  clearFrontendFilters,
  fetching,
  fetchingExtra,
  fetchingAggregates,
  history,
  hideTitle,
  hideSections=[],
}) => {

  if (!results || !results.length) return ''

  // see if there are frontend filters
  const filtersApplied = filteredResults.length !== results.length;
  
  return (
    <div className={`${fetching ? 'opacity-50' : ''}`}>
      
      {/*<div className="">
        <h2 className="flex-1 flex text-lg font-medium items-center">
          <span>
            Filters
          </span>
          <p className="ml-2 mt-1 text-xs text-gray-500 truncate">
            {clearFrontendFilters && filtersApplied ? (
              <span className="ml-2 text-cyan-500 font-semibold cursor-pointer opacity-80 hover:opacity-100"
                onClick={clearFrontendFilters}
              >
                clear filters
              </span>
            ) : ''}
          </p>
        </h2>
        <div className="text-sm text-gray-400">
          {filtersApplied ? (
            <div>Filters applied to results, now showing {filteredResults.length}/{results.length}.</div>
          ) : (
            <div>Click any of the filters/charts below to target specific results.</div>
          )}
        </div>
      </div>*/}
      
      {!hideSections || !hideSections.includes('quick') ? (
        <QuickFilters results={results} filteredResults={filteredResults} frontendFilters={frontendFilters} applyFrontendFilter={applyFrontendFilter} clearFrontendFilter={clearFrontendFilter} hideSections={hideSections} />
      ) : ''}
      {!hideSections || !hideSections.includes('velocity') ? (
        <AggregateVelocity results={results} filteredResults={filteredResults} frontendFilters={frontendFilters} applyFrontendFilter={applyFrontendFilter} clearFrontendFilter={clearFrontendFilter} hideSections={hideSections} aggregates={aggregates} updating={fetchingExtra}/>
      ) : ''}
      {!hideSections || !hideSections.includes('subreddits') ? (
        <AggregateSubreddits results={results} filteredResults={filteredResults} frontendFilters={frontendFilters} applyFrontendFilter={applyFrontendFilter} clearFrontendFilter={clearFrontendFilter} hideSections={hideSections} />
      ) : ''}
      {!hideSections || !hideSections.includes('scores') ? (
        <AggregateScores results={results} filteredResults={filteredResults} frontendFilters={frontendFilters} applyFrontendFilter={applyFrontendFilter} clearFrontendFilter={clearFrontendFilter} hideSections={hideSections} updating={fetchingExtra} />
      ) : ''}
      {!hideSections || !hideSections.includes('topics') ? (
        <AggregateTopics results={results} filteredResults={filteredResults} frontendFilters={frontendFilters} applyFrontendFilter={applyFrontendFilter} clearFrontendFilter={clearFrontendFilter} hideSections={hideSections} aggregates={aggregates} updating={fetchingExtra}/>
      ) : ''}
      {!hideSections || !hideSections.includes('types') ? (
        <AggregateTypes results={results} filteredResults={filteredResults} frontendFilters={frontendFilters} applyFrontendFilter={applyFrontendFilter} clearFrontendFilter={clearFrontendFilter} hideSections={hideSections} />
      ) : ''}
      

      <br />
    </div>
  )
};

export default Aggregates;

