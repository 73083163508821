

const hasPro = (currentUser) => {
    // either has mega, pro, or has a current trial
    const isMegaSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "mega");
    const isProSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "pro");
    const isEffectiveProSubscriber = isMegaSubscriber || isProSubscriber || currentUser.trial.plan === 'pro';
    return isEffectiveProSubscriber;
}

const hasStarter = (currentUser) => {
    // either has mega, pro, starter, or a current trial
    const isMegaSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "mega");
    const isProSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "pro");
    const isStarterSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "starter");
    const isEffectiveProSubscriber = isMegaSubscriber || isProSubscriber || currentUser.trial.plan === 'pro';
    const isEffectiveStarterSubscriber = isStarterSubscriber || isEffectiveProSubscriber;
    return isEffectiveStarterSubscriber;
}


export {
    hasStarter,
    hasPro,
}

