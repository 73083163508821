import { localStorageAvailable } from './localStorage';

const canAskToSaveKeyword = (keyword) => {
    // make sure we're allowed to ask
    if (!keyword) return; // needs to have a keyword
    if (!keyword.split(',').length > 1) return; // can't be multiple (like suggestion categories)
    if (!localStorageAvailable()) return; // don't risk it if it's not available
    if (hasAskedToNotAskAgain()) return; // make sure user didn't request to turn off for all
    if (hasAskedToSaveKeyword(keyword)) return; // don't ask if already asked for this keyword
    
    // good to go!
    return true;
}

const recordAskedToSaveKeyword = (keyword) => {
    const key = `tip-save-keyword-asked-${keyword}`
    localStorage.setItem(key, 'true');
}

const hasAskedToSaveKeyword = (keyword) => {
    const key = `tip-save-keyword-asked-${keyword}`
    return localStorage.getItem(key) ? true : false;
}

const recordDontAskAgain = (keyword) => {
    const key = `tip-save-keyword-dont-ask-again`
    localStorage.setItem(key, 'true');
}

const hasAskedToNotAskAgain = () => {
    const key = `tip-save-keyword-dont-ask-again`
    return localStorage.getItem(key) ? true : false;
}


export {
    canAskToSaveKeyword,
    recordAskedToSaveKeyword,
    recordDontAskAgain,
}

