import React, { useState, useEffect, useRef } from "react";
import {
  Link, useHistory
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Popover } from 'react-tiny-popover'

import SubredditTags from "../reddit/subreddittags";
import FeaturePaywallWrapper from "../account/featurePaywallWrapper"

import { redditService } from '../../services/reddit';
import { velocityToString } from '../../utils/text';
import { trackEvent } from '../../utils/tracking';
import { toQueryString } from '../../utils/urls';


const SubredditModeration = ({ subreddit, currentUser, hasPro }) => {
  const [loading, setLoading] = useState(false);
  const [moderation, setModeration] = useState(null);
  
  useEffect(() => {
    if (!hasPro) return;

    setLoading(true)
    redditService.fetchSubredditModeration(subreddit.name, (response) => {
      setModeration(response.data)
      setLoading(false)
    }, (error, response) => {
      console.error(response)
      setLoading(false)
    });
  }, []);



  if (loading){
    return (
      <div className="flex items-center text-center h-96 justify-center">
        <svg className="animate-spin h-6 block mx-auto w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    )
  }

  return (
    <FeaturePaywallWrapper featureKey={"subreddit-moderation"} className={'max-w-full py-0'} currentUser={currentUser}>
      <div id="subreddit-influencers" className="text-base pr-2">
        <div>
          <dt className="flex items-center mb-2 flex-wrap">
            <div className="flex items-center">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                Moderation Level
              </h3>
              {/*<ReactTooltip id={`influencers-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
              <span className="cursor-pointer ml-2" data-for='influencers-info' data-tip="Biggest contributors to this subreddit in the past month">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>*/}
            </div>
          </dt>

          {(subreddit.tags || []).filter(t => t.category === "Moderation").length ? (
            <div>
              <SubredditTags subreddit={subreddit} tags={subreddit.tags.filter(t => t.category === "Moderation")} toolTipPlace="right" tooltipPrefix={'modal-'}/>
            </div>
          ) : ''}
          <div>
            <b>{moderation && moderation.posts_removed_percent}%</b> of new posts are removed by this Subreddit's moderators or Reddit's spam filters.
          </div>
          {subreddit.calculated && subreddit.calculated.percentile_moderation ? (
            <div>This is more than <b>{subreddit.calculated.percentile_moderation}%</b> of all communities on Reddit.</div>
          ) : ''}
        </div>


       
        <div className="mt-4">
          <dt className="flex items-center mb-2 flex-wrap">
            <div className="flex items-center">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                AutoModerator Activity
              </h3>
              {moderation && moderation.posts_removed_automoderator_comments && moderation.posts_removed_automoderator_comments.length ? (
                <Link className="text-xs text-cyan-500 ml-2"
                  to={`/reddit/?type=comments&usernames=${`AutoModerator`}&subreddits=${subreddit.name}`}
                >
                  Browse
                </Link>
              ) : ''}
              <ReactTooltip id={`automoderator-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
              <span className="cursor-pointer ml-2" data-for='automoderator-info' data-tip="AutoModerator is a bot that comments on and removes posts automatically based on rules set by subreddit admins.">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
            </div>
          </dt>
          {moderation && moderation.posts_removed_automoderator_comments && moderation.posts_removed_automoderator_comments.length ? (
            <div>
              {moderation.posts_removed_automoderator_comments.map((c, i) => (
                <div key={i} className="text-gray-200 text-base flex items-center mb-2">
                  <div className="opacity-50 flex items-center mr-2">
                    <div>{c.count}</div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mx-2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                    </svg>
                  </div>
                  <div>{c.comment}</div>
                </div>
              ))}
            </div>
          ) : (
            <div>No automoderator activity in this community</div>
          )}
        </div>
        
      </div>
    </FeaturePaywallWrapper>
  );
}

export default SubredditModeration;
