import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { Popover } from 'react-tiny-popover'
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon
} from '@heroicons/react/outline';

import SubredditTags from "../reddit/subreddittags";

import { getRequest } from '../../utils/axios';
import { readableNumber } from '../../utils/text';
import { getParamsFromUrl, toQueryString } from '../../utils/urls';
import { trackEvent } from '../../utils/tracking';
import { searchesService } from '../../services/searches';


// component that wraps another component, which represents a subreddit name
// always opens on hover on web. can open on click optionally.
const SubredditPopover = ({
  name, positions, children, history,
  getPopoverParentElement, audiences, openDelay, dontOpenOnClick,
  addToAudienceButtonId, addToAudienceNameFieldId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subreddit, setSubreddit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelaying, setIsDelaying] = useState(false);
  const [error, setError] = useState(null);
  const [showDescription, setShowDescription] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const isValidSub = name && name.substr(0, 2) !== 'u/' && name.substr(0, 4) !== 'r/u_';
  const strippedName = (name.substr(0, 2) === 'r/') ? name.slice(2) : name; // strip out the subreddit part
  const isMobile = window.screen.width < 600;  // no hovering on mobile, just clicks
  const openOnClick = isMobile && !dontOpenOnClick;
  const openOnHover = !isMobile;

  // map subreddit names to audiences
  var subredditAudienceDict = {};
  (audiences || []).forEach((a) => {
    a.subreddit_names.forEach((s) => {
      if (subredditAudienceDict[s]){
        subredditAudienceDict[s] += a.name
      } else {
        subredditAudienceDict[s] = [a.name]
      }
    })
  })

  // effects
  useEffect(() => {
    if (!isLoading && strippedName && isValidSub){
      if (!subreddit || subreddit.name !== strippedName){
        if (openOnClick && isOpen){
          fetchSubreddit(strippedName)
        } else if (openOnHover && isHovering){
          fetchSubreddit(strippedName)
        }
      }
    }
  }, [
    isOpen,
    isHovering,
    isValidSub,
  ]); // when opening, go fetch subreddit info

  // useEffect(() => {
  //   if (isDelaying && isHovering && !subreddit){
  //     setTimeout(() => {
  //       setIsDelaying(false)
  //     }, 1000)
  //   }
  // }, [
  //   isDelaying,
  //   // isHovering,
  // ]); // delay showing for a bit

  // actions
  const fetchSubreddit = (name) => {
    if (!name) return;
    if (!isValidSub) return;

    // make request and set results
    setIsLoading(true);
    setError(false);
    getRequest(`/api/v1/reddit/subreddits/${name}/`,
      (response) => {
        setSubreddit(response.data);
        setIsLoading(false);
      }, (error) => {
        console.log("error fetching", error);
        setIsLoading(false);
        setError("Error fetching subreddit")
      });
  }

  const setDelay = () => {
    // delay showing the popover for a bit
    if (isDelaying) return;

    setIsDelaying(true);
    setTimeout(() => {
      setIsDelaying(false)
    }, openDelay || 500)
  }

  // actions
  // const goToKeyword = (value) => {
  //   var params = getParamsFromUrl()
  //   params = Object.assign(params, {'keyword': value})
  //   if ('example' in params) delete params['example'];
  //   const searchQuery = toQueryString(params)
  //   history.push(`/reddit/${searchQuery}`)
  // }

  // content for popover
  const getContent = () => {

    // if (isLoading){
    //   return '';  // dont show anything on load now, as we have a fast cache
    // }


    return (
      <div className="z-40 w-96 rounded-md overflow-hidden shadow-lg bg-white" role="menu" aria-orientation="vertical" aria-labelledby="options-menu"
        onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
        onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
      >
        {subreddit && subreddit.over_18 ? (
          <div className="flex-1 bg-white p-6 text-center text-red-500">
            This subreddit contains adult content.<br/>
            That's not really what GummySearch is for.<br/>
            If you'd like to see it, go to Reddit directly.
          </div>
        ) : subreddit ? (
          <React.Fragment>
            {subreddit.banner ? (
              <div className="flex-shrink-0 relative">
                <img className="h-24 w-full object-cover" src={subreddit.banner} alt={subreddit.title} />
                {openOnClick && isOpen ? (
                  <span className="text-white opacity-75 hover:opacity-100 absolute cursor-pointer p-2 right-1 top-1 cursor-pointer" onClick={() => setIsOpen(false) && setIsHovering(false)}>
                    <svg className="h-5 w-5 bg-black bg-opacity-10 font-bold rounded-sm" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </span>
                ) : ''}
              </div>
            ) : ''}
            <div className="flex-1 bg-white p-4 flex flex-col justify-between">
              <div className="flex items-center">
                {subreddit.icon ? (
                  <img className="h-12 w-12 mr-4 rounded-md" src={subreddit.icon} alt={subreddit.name} />
                ) : ''}
                <div className="flex-grow">
                  <h2 className="text-xl font-semibold text-gray-900 flex items-center">
                    <div>r/{ subreddit.name }</div>

                    {subreddit.share_url ? (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 ml-auto mr-2 cursor-pointer"
                        data-for={`subreddit-stats-${subreddit.name}`} data-tip="Share Public Link"
                        onClick={() => {
                          copy(subreddit.share_url);
                          toast.success("Copied public link to clipboard")
                        }}
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                      </svg>
                    ) : ''}

                    <a href={subreddit.link} target="_blank" rel="noreferrer" className="cursor-pointer"
                      data-for={`subreddit-stats-${subreddit.name}`} data-tip="View on Reddit"
                      onClick={() => {
                        trackEvent("ExternalRedirect", {
                          "platform": "Reddit",
                          "type": "Subreddit",
                          "platform_id": subreddit.name,
                          "link": subreddit.share_url || subreddit.link,
                          "external": subreddit.share_url ? true : false,
                        });
                        searchesService.saveRedirect("RSR", subreddit.name, subreddit.name);
                      }}
                    >
                      <svg className="h-5 w-5 " xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"/>
                      </svg>
                    </a>
                  </h2>

                  <div className="flex text-xs font-medium mt-0">
                    <div className="text-gray-700">
                      {readableNumber(subreddit.count_users)} members
                    </div>

                    <ReactTooltip id={`subreddit-stats-${subreddit.name}`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                    {subreddit.calculated && subreddit.calculated.growth_members_monthly != null ? (
                      <div className={`ml-2 flex ${subreddit.calculated.growth_members_monthly > 0 ? 'text-green-500' : subreddit.calculated.growth_members_monthly === 0 ? 'text-gray-400' : 'text-red-400'}`}>
                        <TrendingUpIcon className="h-4 w-4 mr-1" />
                        <div>{subreddit.calculated.growth_members_monthly.toFixed(2)}% / month</div>
                      </div>
                    ) : subreddit.calculated && subreddit.calculated.growth_members_daily != null? (
                      <div className="text-gray-500 ml-2 flex">
                        <TrendingUpIcon className="h-4 w-4 mr-1" />
                        <div>{subreddit.calculated.growth_members_daily.toFixed(2)}% / day</div>
                        <InformationCircleIcon className="h-4 w-4 ml-1 text-gray-500" 
                          data-for={`subreddit-stats-${subreddit.name}`} data-tip="No weekly growth data yet. Showing growth over the past day."
                        />
                      </div>
                    ) : ''}
                  </div>
                </div>
                {!subreddit.banner && openOnClick && isOpen ? (
                  <div className="text-gray-400 ml-auto cursor-pointer hover:text-gray-500" onClick={() => setIsOpen(false) && setIsHovering(false)}>
                    <svg className="flex-shrink-0 h-5 w-5 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                ) : ''}
              </div>

              {subreddit && subreddit.tags && subreddit.tags.length ? (
                <div className="block mt-2">
                  <SubredditTags subreddit={subreddit} tags={subreddit.tags} tagClassName="bg-gray-200 text-gray-900 text-xs py-1 px-2 text-xs mr-2 mb-1 rounded-sm"/>
                </div>
              ) : ''}
              
              <div className="flex-1">
                <p className={`mt-3 text-gray-900 text-sm overflow-ellipsis ${showDescription ? '' : 'cursor-pointer line-clamp-3'}`}
                  onClick={() => setShowDescription(true)}
                >
                  { subreddit.description }
                </p>
              </div>

              {subreddit && subreddit.related_subreddits && subreddit.related_subreddits.length ? (
                <div className="flex-1 mt-3">
                  <dt className="text-base font-normal text-gray-900">
                    Similar Subreddits
                  </dt>
                  <dd className="block mt-0 flex flex-wrap">
                    {subreddit.related_subreddits.map(s => (
                      <div key={s} className="px-2 py-1 flex space-x-1 items-center text-xs mr-1 mt-1 bg-gray-200 text-gray-800 rounded-sm flex items-center">
                        <span>r/{s.replace('r/', '')}</span>
                        {subredditAudienceDict[s.replace('r/', '')] ? (
                          <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-4 w-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        ) : ''}

                      </div>
                    ))}
                  </dd>
                </div>
              ) : ''}

              {subreddit && subreddit.collections && subreddit.collections.length ? (
                <div className="flex-1 mt-3">
                  <dt className="text-base font-normal text-gray-900">
                    In Audience
                  </dt>
                  <dd className="block mt-0">
                    {subreddit.collections.map((c) => (
                      <Link to={`/audience/${c.hash}/`} key={c.hash} 
                        className={`cursor-pointer text-xs py-1 px-2 text-xs mr-2 mb-1 bg-gray-200 text-gray-800 hover:bg-gray-300 rounded-sm`}
                      >
                        {c.name}
                      </Link>
                    ))}
                  </dd>
                </div>
              ) : (
                <div className="flex-1 mt-3">
                  <dt className="text-base font-normal text-gray-900">
                    Not In Audience
                  </dt>
                  <dd className="block mt-0 text-gray-900 text-sm">
                    Not in any of your audiences yet. Add this subreddit to analyze it futher.
                  </dd>
                  {addToAudienceButtonId && (
                    <button
                      className="mt-2 bg-cyan-600 hover:bg-cyan-700 text-white text-sm font-semibold py-2 px-4 rounded"
                      onClick={() => {
                        const addToAudienceButton = document.getElementById(addToAudienceButtonId);
                        if (addToAudienceButton) {
                          addToAudienceButton.click();
                          setIsOpen(false);

                          // focus on name field if it was passed in
                          setTimeout(() => {
                            const addToAudienceNameField = document.getElementById(addToAudienceNameFieldId);
                            if (addToAudienceNameField) {
                              addToAudienceNameField.focus();
                            }
                          }, 250)
                        }
                      }}
                    >
                      Add to Audience
                    </button>
                  )}
                </div>
              )}
              
              
            </div>
          </React.Fragment>
        ) : isDelaying ? (
          <div className="flex-1 bg-white p-6 text-center text-red-400">...</div>
        ) : isLoading ? (
          <div className="flex-1 bg-white p-6 text-center text-gray-400">loading...</div>
        ) : error ? (
          <div className="flex-1 bg-white p-6 text-center text-red-500">{error}</div>
        ) : (
          <div className="flex-1 bg-white p-6 text-center text-red-500">unknown sub</div>
        )}
      </div>
    )
  }

  var parentElement = window.document.body;
  if (getPopoverParentElement && getPopoverParentElement()){
    parentElement = getPopoverParentElement()
  }

  return (
    <Popover
      isOpen={isValidSub && (isOpen || isHovering) && !isDelaying}
      positions={positions || ['bottom', 'top', 'right', 'left']}
      align="start"
      content={getContent()}
      parentElement={parentElement}
      // onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
      // onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
    >
      <span className={isValidSub && openOnClick ? 'cursor-pointer': ''}
        onClick={() => isValidSub && openOnClick && setIsOpen(!isOpen)}
        onMouseEnter={() => {
          if (openOnHover){
            setDelay(); // delay if first time
            setIsHovering(true)
          }
        }}
        onMouseLeave={() => {
          if (openOnHover){
            setIsDelaying(false)
            setIsHovering(false)
          }
        }}
      >{ children }</span>
    </Popover>
  )
}

const mapStateToProps = state => {
  const {
    audiences,
  } = state.audiences;

  return {
    audiences,
  }
};

const mapDispatchToProps = (dispatch) => {
  // const fetchAudiences = (callback, onError) => {
  //   dispatch(audiencesOperations.fetchAudiences(callback, onError))
  // };
  return {
    // fetchAudiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubredditPopover);


