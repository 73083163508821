import React, { useState, useEffect } from "react";
// import {
//   withRouter,
// } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import copy from 'copy-to-clipboard';
import Modal from "../common/modal";
import { Helmet } from "react-helmet";
import {
  LinkIcon, DuplicateIcon, ExternalLinkIcon
} from '@heroicons/react/outline'
import { Switch } from '@headlessui/react'



import { collectionsService } from '../../services/collections';
import { sharedService } from '../../services/shared';
import { classNames } from '../../utils/tailwind';

import ErrorBar from "../common/errorBar";
import LoaderFancy from '../common/loaderfancy.jsx';


// TODO: for sharing analysis clusters
// in analysis & past analysis api views, serialize the ID (and maybe other things like params?)
// use that in the analysis JSX
// pass through to share modal so we have an ID to use to create report
// also serialize if an analysis is shared

const AnalysisAskShareModal = ({
  analysis, refetch, closeModal, history,
  sharedReportHash, setSharedReportHash
}) => {
  // state
  const [error, setError] = useState(null);
  const [enabled, setEnabled] = useState(null)
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [sharedReport, setSharedReport] = useState(null);

  // effects
  useEffect(() => {
    if (analysis && sharedReportHash){
      fetchSharedReport(sharedReportHash);
    } else {
      setLoading(false); // no shared report yet, no need to load
    }
  }, []);

  useEffect(() => {
    if (enabled !== null && sharedReport && sharedReport.enabled !== enabled){
      updateReport(sharedReport.hash, {'enabled': enabled});
    }
  }, [enabled, sharedReport]);


  const fetchSharedReport = (hash) => {
    setLoading(true)
    sharedService.getUserReport(hash, (results) => {
      if (results && results.data){
        setSharedReport(results.data)
        setEnabled(results.data.enabled)
        setLoading(false)
      }
    }, (response, error) => {
      setLoading(false)
      setError(error);
    })
  }

  const createReport = () => {
    setLoading(true)
    sharedService.createUserReport({
      'report_type': 'AI',
      'enabled': true,
      // 'title': 'placeholder', // TODO
      'analysis': analysis.hash
    }, (results) => {
      if (results && results.data){
        // refetch() // refetch analysis so we know we have a report??
        setSharedReport(results.data)
        setEnabled(results.data.enabled);
        setSharedReportHash(results.data.hash);
        setLoading(false)
      }
    }, (response, error) => {
      setLoading(false)
      setError(error);
    })
  }

  const updateReport = (hash, params) => {
    if (updating || enabled === null) return;

    setUpdating(true)
    sharedService.updateUserReport(hash, params, (results) => {
      if (results && results.data){
        setSharedReport(results.data)
      }
      setUpdating(false)
    }, (response, error) => {
      setError(error);
      setUpdating(false)
    })
  }

  const copyUrl = (url) => {
    if (!url) return;

    copy(url);
    toast.success("Copied link to clipboard")
  }

  var sharedReportUrl = sharedReport && sharedReport.url;

  return (
    <Modal close={closeModal}>
      <Helmet><title>Ask | Share</title></Helmet>
      
      <div className="flex items-center">
        <LinkIcon className="h-6 w-6 opacity-50 mr-2" />

        <h3 className="text-lg leading-6 font-medium">
          Share Q&A Report
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      {loading ? (
        <div className="h-48 relative"><LoaderFancy /></div>
      ) : !sharedReport ? (
        <div className="h-48 flex flex-col items-center justify-center">
          <div className="opacity-50">Create a link to share this analysis with others</div>
          <div className="opacity-50 text-xs mb-2">
            
          </div>
          <button onClick={createReport}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-600"
          >
            <LinkIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Create public link
          </button>
        </div>
      ) : (
        <div className="h-48 pt-4">
          <div className="">
            <h3 className="mb-2 text-xs font-semibold text-gray-400 uppercase tracking-wide">
              Availablity
            </h3>
            <div>
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? 'bg-cyan-600' : 'bg-gray-700',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                  <span className="text-sm font-medium text-gray-400">Public Link {enabled ? 'Available' : 'Disabled'} </span>
                </Switch.Label>
              </Switch.Group>
            </div>
          </div> 

          <div className={`mt-4 ${enabled ? '' : 'opacity-25 pointer-events-none'}`}>
            <h3 className="mb-2 text-xs font-semibold text-gray-400 uppercase tracking-wide">
              Share Link
            </h3>
            <div className="flex space-x-1 md:space-x-2 text-md items-center text-sm">
              <div className="w-full flex-grow flex items-center p-2 rounded-sm bg-gray-700 border-gray-900">
                <div className="truncate">{sharedReportUrl}</div>
              </div>
              
              <a disabled={!sharedReportUrl} href={sharedReportUrl} target="_blank"
                className="p-2 rounded-sm shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
              >
                <ExternalLinkIcon className="w-4"/>
              </a>

              <button disabled={!sharedReportUrl} onClick={() => copyUrl(sharedReportUrl)}
                className="p-2 rounded-sm shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
              >
                <DuplicateIcon className="w-4"/>
              </button>
            </div>
          </div> 

          {/*<div className="mt-4">
            <div className="opacity-50 mb-2">Analytics</div>
            <div></div>
          </div> */}
        </div>
      )}


      {error ? (
        <ErrorBar error={error} setError={setError} />
      ) : ''}  

    </Modal>
  );
};

export default AnalysisAskShareModal;

