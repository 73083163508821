import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { CSVLink } from "react-csv";
import { Transition, Popover } from '@headlessui/react'

import {
  Link
} from "react-router-dom";
import { collectionsService } from '../../services/collections';
import { uiOperations } from "../../redux/ui"

import ErrorBoundary from "../common/errorboundary";
import SelectPopover from "../common/selectPopover";
import LoaderFancy from '../common/loaderfancy.jsx';
import ErrorBar from "../common/errorBar";
import FeaturePaywallWrapper from "../account/featurePaywallWrapper"

import MakeReviews from './productMakeReviews';

import { capitalizeFirst, slugify } from '../../utils/text';
import { organizeProductMakes } from '../../utils/products';


const AudienceProductCategory = ({
  collection,
  match,
  history,
  categories,
  subreddits,
  currentUser,
  onClose, // mobile view only
}) => {
  const { id } = match.params;
  const category = categories.filter(t => t.id === parseInt(id))[0];
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [error, setError] = useState(null);
  const isMobile = window.screen.width < 800;  // on mobile, show detail view in drawer
  const hasProductsTab = collection && currentUser && currentUser.features.audience_products; // actual content behind paywall
  const countToShow = 10;
  const [showAllProducts, setShowAllProducts] = useState(false); // otherwise limit to those with 2+ reviews

  // effects
  useEffect(() => {
    if (hasProductsTab && (!categoryDetails || categoryDetails.id !== id)){
      setCategoryDetails(null);
      fetchCollectionCategory(id);
    }
    setShowAllProducts(false)
  }, [id]);

  const fetchCollectionCategory = (t) => {
    if (id === 'new') return; // don't run on the /new/ subroute
    setCategoryDetails(null)
    setError(null)
    collectionsService.getCollectionProductCategory(collection.hash, id, (results) => {
      if (results && results.data){
        setCategoryDetails(results.data)
        setLoadingCategory(false)
      }
    }, (response, error) => {
      setError(error);
    })

    if (!isMobile && document.getElementById('main').scrollTop > 250){
      document.getElementById("category-detail") && document.getElementById("category-detail").scrollIntoView({behavior: 'smooth'});
    }
  }

  if (!category) return '' // haven't loaded category list yet

  // organize reviews by make (with nested models)
  const productMakes = categoryDetails ? organizeProductMakes(categoryDetails) : [];

  const getReviewsCsvDict = () => {
    var reviews = [];
    try {
      productMakes.forEach(make => {
        make.reviews.forEach(review => {
          reviews.push({
            'product': make.make,
            'model': review.model,
            'rating': review.one_to_five_rating,
            'highlight': review.highlight,
            'subreddit': review.subreddit_name,
            'post_title': review.reddit_submission_data.title.replace(/"/g, '""').replace(/,/g, '\,').replace(/'/g, '\''),
            'post_url': review.reddit_submission_data.url,
            'comment': review.reddit_comment_data.body.replace(/"/g, '""').replace(/,/g, '\,').replace(/'/g, '\''),
            'comment_url': review.reddit_comment_data.url,
          })
        })
      })
    } catch (error) {console.error(error)}
    return reviews
  }

  const getProductsCsvDict = () => {
    var products = [];
    try {
      productMakes.forEach(make => {
        products.push({
          'product': make.make,
          'stars_average': make.stars_average,
          'reviews_count': make.reviews.length,
          'models': make.products.map(product => product.model).join("\n").replace(/"/g, '""').replace(/,/g, '\,').replace(/'/g, '\''),
          'highlights': make.reviews.map(r => r.highlight).join("\n").replace(/"/g, '""').replace(/,/g, '\,').replace(/'/g, '\''),
        })
      })
    } catch (error) {console.error(error)}
    return products
  }

  return (
    <ErrorBoundary>
      <Helmet><title>Audience | Product Category</title></Helmet>
      
      <div className="">
        {onClose ? (
          <div className="mr-2 mb-2 opacity-50 hover:opacity-100"
            onClick={onClose}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>

          </div>
        ) : ''}          
        
        <FeaturePaywallWrapper featureKey="products" currentUser={currentUser}>
          {!categoryDetails ? (
            <div className="h-96 relative">
              <LoaderFancy height={300}/>
            </div>
          ) : (
            <div className={``}>
              {/*<div className="mt-4 mb-2 flex items-center">
                <h3 className="flex-1 flex text-sm items-center">
                  <span className="text-gray-300 font-semibold">About {capitalizeFirst(category.name)}</span>
                </h3>
              </div>

              <div className="p-6 bg-gray-800 rounded-lg text-white">

                <div className="flex border-b border-gray-500 pb-4 mb-4">
                  <h2 className="text-xl font-medium flex items-center flex-wrap mr-2">
                    <span>{capitalizeFirst(category.name)}</span>
                  </h2>
                </div>
                
                <div>
                  <div className="text-lg whitespace-pre-wrap">summary goes here</div>
                </div>

                <div className="mt-4 flex">
                  <Link
                    // to={`/reddit/?type=submissions&collection=${collection.hash}&topic=${name}&sort=top`}
                    to={`/audience/${collection.hash}/invalidurl/`}
                    type="button"
                    className="ml-auto cursor-pointer inline-flex items-center px-3 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-700 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 opacity-50 hidden sm:block">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                    </svg>
                    Browse all posts
                  </Link>
                </div>
              </div>*/}
            </div>
          )}

          {categoryDetails ? (
            <div className="">
              <h3 className="flex text-sm items-center w-full">
                <div className="text-gray-300 font-semibold flex items-center w-full">
                  {capitalizeFirst(category.name)} options
                  <p className="ml-2 text-gray-500 truncate">
                    <span>{productMakes.length}</span>
                  </p>

                  <Popover className="relative ml-auto">
                    {({ open, close }) => (
                      <>
                        <Popover.Button className={`ml-auto rounded-md text-xs text-cyan-500 hover:text-cyan-600 focus:outline-none flex items-center`}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 h-5 w-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                          </svg>
                        </Popover.Button>
                        <Transition
                          show={open}
                          as={React.Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel static className="absolute z-10 top-full right-0 mt-2 px-1 w-screen max-w-xs sm:px-0 text-sm">
                            <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                              <div className="relative grid bg-white text-gray-900 p-2">
                                <CSVLink
                                  data={getProductsCsvDict()}
                                  filename={`products-${slugify(category.name)}.csv`}
                                  className="font-semibold flex items-center p-4 hover:bg-gray-100 cursor-pointer rounded-md"
                                  target="_blank"
                                  onClick={close}
                                >
                                  Download Products
                                </CSVLink>
                                <CSVLink
                                  data={getReviewsCsvDict()}
                                  filename={`reviews-${slugify(category.name)}.csv`}
                                  className="font-semibold flex items-center p-4 hover:bg-gray-100 cursor-pointer rounded-md"
                                  target="_blank"
                                  onClick={close}
                                >
                                  Download Reviews
                                </CSVLink>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              </h3>
              <div className="mt-2 space-y-2">
                {productMakes.filter((m, i) => showAllProducts || i < countToShow).map((m , i) => (
                  <MakeReviews key={m.make} make={m} index={i}/>
                ))}
                {!showAllProducts && (productMakes.length > countToShow) && (
                  <div className="py-2 text-center">
                    <span className="pl-2 cursor-pointer text-gray-500 hover:text-gray-200" onClick={() => setShowAllProducts(true)}>
                      + Show {productMakes.length - countToShow} more
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : ''}
        </FeaturePaywallWrapper>
      </div>

      {/*<Switch>
        {topic ? (
          <ModalRoute component={AudienceTopicResultsDrawer}
            path={`/audience/${collection.hash}/topics/${name}/results/`}
            parentPath={`/audience/${collection.hash}/topics/${name}/`}
            props={{
              'collection': collection,
              'currentUser': currentUser,
              'baseUrl': `/audience/${collection.hash}/topics/${name}/`,
              'topic': topic,
            }}
          />
        ) : ''}
      </Switch>*/}

      {error ? (
        <ErrorBar error={error} setError={setError} onRetry={() => {fetchCollectionCategory(id)}}/>
      ) : ''}
    </ErrorBoundary>
  );
}

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  const {
    // topicSort,
  } = state.ui;

  return {
    currentUser,
    // topicSort,
  }
};

const mapDispatchToProps = (dispatch) => {
  const setUiState = (key, value) => {
    dispatch(uiOperations.setUiState(key, value))
  };
  return {
    setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceProductCategory);

