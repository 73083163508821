import React, { useState } from "react"
import {
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

import {
  SparklesIcon, TrendingUpIcon
} from '@heroicons/react/outline'

import FormFooter from "./footer";


const FreePlanInfo = ({history, currentUser, fetchCurrentUser, ...subrouteProps}) => {
  const { nextPage, isLastPage } = subrouteProps;

  const onSubmit = () => {
    history.push(isLastPage ? '/audiences/' : `/welcome/${nextPage}/`);
  }

  return (
    <div className="max-w-5xl w-full mx-auto min-h-full flex flex-col">
      <Helmet><title>Welcome | Free Plan</title></Helmet>

      <div className="inline-block bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle py-6 px-4 sm:p-6">
        <div className="text-center">
          <div className="relative mb-4">
            <hr className="opacity-25" />
            <div className="absolute -top-3 w-full text-center">
              <span className="p-1 text-sm rounded-sm bg-gray-800 text-gray-500 uppercase font-semibold">
                Free plan info
              </span>
            </div>
          </div>

          <p className="mt-2 text-xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            Included in the Free tier
          </p>

          <p className="mt-4 max-w-xl text-gray-400 mx-auto">
            50 searches on the free tier gets you pretty far, make sure to use them!<br/>
            When you need the premium features, upgrading & downgrading is a breeze.
          </p>

          <div className="mt-8 text-left md:flex space-x-8">
            <dl className="flex-1">

              <div className="flex-1 flex flex-col sm:p-4">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium">Community Discovery</p>
                </dt>
                <dd className="mt-1 ml-16 text-md text-gray-400">
                  Find subreddits and organize them into audiences, search and analyze them in bulk
                </dd>
              </div>

              <div className="flex-1 flex flex-col sm:p-4">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium">Keyword Search (50 searches)</p>
                </dt>
                <dd className="mt-1 ml-16 text-md text-gray-400">
                  Search any keyword in your audience to find posts on Reddit relevant to your business
                </dd>
              </div>

              <div className="flex-1 flex flex-col sm:p-4">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium">Bookmark Posts</p>
                </dt>
                <dd className="mt-1 ml-16 text-md text-gray-400">
                  Bookmark & organize posts to read later or share with your team, great for validation and lead lists
                </dd>
              </div>

              <div className="flex-1 flex flex-col sm:p-4">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                    </svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium">Audience Topics</p>
                </dt>
                <dd className="mt-1 ml-16 text-md text-gray-400">
                  Browse the most popular & growing topics discussed in your audiences
                </dd>
              </div>
            </dl>
            <dl className="flex-1">

              <div className="flex-1 flex flex-col sm:p-4">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>

                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium">Keyword Tracking (Starter Plan)</p>
                </dt>
                <dd className="mt-1 ml-16 text-md text-gray-400">
                  Set up keyword alerts to your email/slack/discord to be notified of conversations as they happen
                </dd>
              </div>

              <div className="flex-1 flex flex-col sm:p-4">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>

                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium">Trending Subreddits (Starter Plan)</p>
                </dt>
                <dd className="mt-1 ml-16 text-md text-gray-400">
                  Find the most active & fastest-growing subreddits, across all of Reddit or within a particular niche
                </dd>
              </div>

              <div className="flex-1 flex flex-col sm:p-4">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium">Common Themes (Starter Plan)</p>
                </dt>
                <dd className="mt-1 ml-16 text-md text-gray-400">
                  GummySearch identifies pain points and solution requests in any subreddit
                </dd>
              </div>

              <div className="flex-1 flex flex-col sm:p-4">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium">AI Features (Pro plan)</p>
                </dt>
                <dd className="mt-1 ml-16 text-md text-gray-400">
                  Summarize hundreds of posts in seconds to find common trends, or ask your audience a question in an LLM-like experience (with citations)
                </dd>
              </div>
            </dl>
          </div>
          
        </div>
      </div>

      <FormFooter submit={onSubmit} {...subrouteProps} />
    </div>
  );
}

const mapStateToProps = state => {
  // const {
  //   used, total, fetching,
  // } = state.trial;

  return {
    // 'trialTotal': total,
    // 'trialUsed': used,
    // 'trialFetching': fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  // const fetchCurrentUser = (callback) => {
  //   dispatch(userOperations.fetchCurrentUser(callback))
  // };
  return {
    // fetchCurrentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreePlanInfo);
