import React, { useState, useEffect } from "react";
import { useJwt } from "react-jwt";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { authService } from "../../services/auth";
import { localStorageAvailable } from "../../utils/localStorage";
import { userOperations } from "../../redux/user";


// high level component, wraps most of app
// passes auth params to children components
// upon state changes, re-assesses expiration of access token
const Authentication = ({children, location, history, fetchCurrentUser, currentUser}) => {

  // state
  const [ accessToken, setAccessToken ] = useState(localStorageAvailable() && localStorage.getItem('accessToken'));
  const [ isRefreshing, setIsRefreshing ] = useState(localStorageAvailable() && localStorage.getItem('refreshingToken'));
  const { isExpired } = useJwt(localStorageAvailable() && localStorage.getItem('accessToken'));

  // effects
  useEffect(() => {
    if (accessToken && isExpired){
      authService.refreshToken((newToken) => {
        setAccessToken(newToken)
      }, () => {
        // this means the refresh token is expired. take user to /login/ to re-authenticate
        console.error("couldnt refresh token")
        window.location = `/login/?next=${window.location.href}`
      });
    }
  }, [
    accessToken,
    isExpired,
  ]); // If current token is expired, go refresh it

  useEffect(() => {
    if (accessToken && !isExpired){
      // authService.fetchUser();
      fetchCurrentUser();
    }
  }, [
    accessToken,
    isExpired,
  ]); // Refetches user config when a new access token is set

  
  // clean slate of props we return to all components
  var authState = {
    'authenticated': false,
    'accessToken': null,
    'refreshToken': null,
    'refreshingToken': false,
    'currentUser': null,
  }

  // determine returning properties based on state of current token
  if (accessToken && !isExpired){
    // we're ready to rock!
    authState = Object.assign(authState, {
      'authenticated': true,
      'accessToken': localStorageAvailable() && localStorage.getItem('accessToken'),
      'refreshToken': localStorageAvailable() && localStorage.getItem('refreshToken'),
      // 'currentUser': JSON.parse(localStorageAvailable() && localStorage.getItem('currentUser')),
      'currentUser': currentUser, // from redux store (initialized with local storage if its there)
    })
  } else if (accessToken && isExpired) {
    // it's expired. the effect in this file will get a new one
    authState = Object.assign(authState, {
      'refreshingToken': true,
    })
    return '';  // show a blank screen temporarily
  } else {
    // not authenticated. this is an OK state depending on which route it is
    // screens like /account/ + /trial/ will kick out to login,
    // while screens like /login/ + /register/ are fine 
  }


  return (
    <React.Fragment>
      {children({
        ...authState,
      })}
    </React.Fragment>
  )
};

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  
  return {
    currentUser,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchCurrentUser = (key, value) => {
    dispatch(userOperations.fetchCurrentUser())
  };
  return {
    fetchCurrentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Authentication));


