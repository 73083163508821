import React, { useState, useEffect } from "react"
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";

import LayoutAuth from "../../components/auth/layout";

import { authService } from "../../services/auth";
import { getParameterByName } from "../../utils/urls";


const PasswordResetConfirm = ({history, location, match, authenticated }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
  const [token] = useState(getParameterByName('token'));
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [sentResetEmail, setSentResetEmail] = useState(null);

  // makes sure token is valid
  useEffect(() => {
    if (!token){
      setError('Invalid password reset link');
      return 
    }

    setLoading(true);
    authService.checkPasswordTokenValidity(
      token,
      (response) => {
        setLoading(false);
        setTokenIsValid(true);
      },
      (error, response) => {
        setError('This link is invalid or has expired. Please request a new one.');
        setLoading(false);
        setTokenIsValid(false);
      }
    )
  }, [
    token,
  ]); // Refetches user config when the access token is set


  // actions
	const submitForm = (e) => {
		e.preventDefault();  // to not post to the current url
		setLoading(true);
    setError(null);

    const password = e.target.elements['password'].value;

		authService.forgotPasswordConfirm(
			token, password,
			(response) => {
				setError(null);
        window.location = '/login/?successMessage=Password reset. Please log in.'
        // setLoading(false);  // no need, wait for redirect
			},
			(error, response) => {
				setError((response && response.password && response.password[0]) || 'could not reset password');
				setLoading(false);
			}
		)
	}

  return (
    <LayoutAuth title="Reset your password">
      <Helmet><title>Password Reset Confirm</title></Helmet>
      <form className="space-y-6" action="#" onSubmit={submitForm}>

      	{error ? (
          <div className="bg-red-500 p-2 px-3 font-medium rounded-xs">
            {error}
          </div>
        ) : ''}

        {tokenIsValid ? (
          <React.Fragment>
            <div>
              <label htmlFor="password" className="block uppercase text-sm opacity-75 font-medium">
                New Password
              </label>
              <div className="mt-1">
                <input id="password" name="password" type="password" autoFocus placeholder="Password" autoComplete="new-password" required minLength="8"
                  className="appearance-none bg-gray-700 block w-full p-3 rounded-sm border-solid border-transparent shadow-sm focus:outline-none focus:ring-transparent focus:border-cyan-500"
                />
              </div>
            </div>

            <div className="sm:flex items-center justify-between sm:flex-row-reverse">
              <button type="submit" className="cursor-pointer w-full justify-center tracking-wider p-3 font-semibold rounded-sm shadow-sm bg-cyan-500 focus:outline-none"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
            </div>

          </React.Fragment>
        ) : ''}

	    </form>
    </LayoutAuth>
  );
}

export default PasswordResetConfirm;
