import React, { useState } from "react";
// import {
//   withRouter,
// } from "react-router-dom";
import Modal from "../common/modal";
import FeaturePaywallWrapper from "./featurePaywallWrapper";
import { Helmet } from "react-helmet";


const NotificationsSlackModal = ({ 
  closeModal,
  history,
  digestSettings,
  // updateSettings,
  currentUser,
  createNotificationChannel,
}) => {
  const currentEmail = digestSettings && digestSettings.notification_channel && digestSettings.notification_channel.channel_type === 'SE' && digestSettings.notification_channel.params.email || '';  
  const [email, setEmail] = useState(currentEmail);
  const [saving, setSaving] = useState(false);

  const save = (e) => {
    e.preventDefault()

    if (!email) return;
    if (saving) return;
    if (!email.includes('.slack.com')){
      return alert("Invalid slack email")
    }

    setSaving(true);
    const nickname = `Slack: ${email}`
    createNotificationChannel({'channel_type': 'SE', 'params': {'email': email}, nickname: nickname}, () => {
      setSaving(false);
      closeModal();
    }, () => {
      setSaving(false);
    });
  }
  
  return (
    <Modal close={closeModal} widthClass={'max-w-xl'}>
      <Helmet><title>Account | Notifications | Slack</title></Helmet>
      
      <FeaturePaywallWrapper featureKey={"notification-channels"} className={'max-w-full py-0'} currentUser={currentUser}>
        <div className="flex items-center">
          
          <h3 className="text-lg leading-6 font-medium">
            Configure Slack Notifications
          </h3>

          <button className="focus:outline-none ml-auto" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="mt-4">
          <div className="text-gray-200 space-y-4">
            <p>
              To configure Slack notifications, you will need an email address for the slack channel you want to send digests to.
            </p>
            <p>
              To get this email, it takes less than a minute.
            </p>
            <p>
              See <a className="underline cursor-pointer" href="https://slack.com/help/articles/206819278-Send-emails-to-Slack#h_01F4WDZG8RTCTNAMR4KJ7D419V" target="_blank">this guide</a> or just go to "Get Channel Details > Integrations > Send Emails to this Channel", then press "Copy" and paste the email here.
            </p>
          </div>

          <form className="mt-4 flex flex-wrap space-x-1 sm:space-x-2 space-y-2 md:space-y-0 text-xs md:text-base items-center w-full md:w-auto"
            onSubmit={save}
          >
            <input
              type="text" id="slack-email"
              name="slack-email"
              autoComplete="off" //autoFocus={isEffectiveProSubscriber ? "on" : ""}
              className={`text-xs md:text-base flex-grow bg-gray-800 cursor-pointer border border-solid border-gray-600 rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500`}
              placeholder={`channelname-channelhash@yourslackorg.slack.com`}
              value={email} onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <button
              type="submit" disabled={saving}
              className="mt-2 md:mt-0 w-full md:w-auto md:ml-2 text-center p-2 px-4 border border-solid border-transparent font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            >
              Save
            </button>
          </form>
        </div>
      </FeaturePaywallWrapper>
    </Modal>
  );
};

export default NotificationsSlackModal;

