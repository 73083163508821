import React, { Fragment, useState, useEffect, useRef } from "react";

import { Dialog, Transition } from '@headlessui/react'


// to pass props to children. see if this makes any weird glitches. if so, just render children without copying
// we have this really just to have an animated close drawer
const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}


export default function Drawer({children, close, widthClass}) {
  // const [open, setOpen] = useState(true)

  const [open, setOpen] = useState(false);

  // effects
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 1)
  }, []); // Animate open when we get to this route

  const closeDrawer = () => {
    setOpen(false)
    setTimeout(() => {
      // history.push(closeUrl);
      close();
    }, 300)
  }


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden darkStyle" open={open} onClose={closeDrawer}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-75 transition-opacity cursor-pointer" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 max-w-full flex old-pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={`${widthClass || 'w-screen max-w-4xl'} bg-gray-900 text-white shadow-lg h-full`}>
                { React.cloneElement(children, { closeDrawer }) } 
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

