import React, { useState } from "react";

import ReactTooltip from 'react-tooltip';

// import SubredditPopover from "./subredditpopover";
import SubmissionTypeIcon from "./submissiontypeicon";


const AggregateOption = ({
  label,
  icon,
  count,
  maxCount,
  filteredCount,
  filtersApplied,
  thisFilterApplied,
  onClick,
}) => {
  
  return (
    <div onClick={onClick} className="flex items-center mb-1 text-sm group cursor-pointer">

      <div className="flex-1 bg-transparent rounded-sm relative flex-shrink-0 h-7">
        <div className="absolute bg-gray-800 group-hover:bg-gray-700 rounded-sm p-1 h-full" style={{width: (100 * count / maxCount) + '%'}}></div>
        {filteredCount && filtersApplied ? (
          <div className="absolute bg-gray-700 rounded-sm p-1 h-full" style={{width: (100 * filteredCount / maxCount) + '%'}}></div>
        ) : ''}
        <div className="absolute p-1 px-2 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
          {icon ? (
            <SubmissionTypeIcon type={icon} className="mr-2 h-4 w-4 opacity-50 flex-shrink-0" />
          ) : ''}
          {label}
        </div>
      </div>
      <div className={`ml-2 w-4 text-center ${thisFilterApplied ? 'text-green-500' : 'text-transparent'}`}>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
      </div>
      <div className={`ml-0 w-5 text-right ${thisFilterApplied ? 'text-gray-300 font-semibold' : 'text-gray-500'}`}>
        {count}
      </div>
    </div>
  )
};

export default AggregateOption;

