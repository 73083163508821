import React, { useState } from "react";
import {
  Link, useHistory
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';

import ErrorBoundary from '../common/errorboundary.jsx';

import { styleKeyword, findKeywordsAndTruncate, textContainsKeyword, cleanHtml } from '../../utils/text';
import { splitKeywords } from '../../utils/reddit';
import { timeStampToEasyTime } from '../../utils/time';
import { trackEvent } from '../../utils/tracking';

import SubredditPopover from "./subredditpopover";
import RedditorPopover from "./redditorpopover";
import SubmissionTypeIcon from "./submissiontypeicon";
import { searchesService } from '../../services/searches';

// import BookmarkConversationModal from "../old_saved/bookmarkconversationmodal";
import ConversationListPopover from '../conversations/conversationlistpopover'


const ResultRowComment = ({
  item,
  location,
  keyword,
  backend,
  commentsUrl,
  inNested,
  redirected,
  isSelected, toggleSelected,
  parentListElement, className,
}) => {
  const history = useHistory();
  const [bookmarkModalOpen, setBookMarkModalOpen] = useState(false);
  const [viewed, setViewed] = useState(item.viewed);
  const isLoading = !Object.keys(item).includes('redirected');
  const hideDetails = false; // no need to be dynamic?

  // since user can save convo from this component, we have an updated state here
  const [savedListsUpdated, setSavedListsUpdated] = useState(false);
  const [updatedSavedLists, setUpdatedSavedLists] = useState([]);

  const rootUrl = commentsUrl || '/reddit/comment/'; // mostly shown on reddit page, but potentially on saved search as well
  const isCurrent = location && location.pathname === `${rootUrl}${item.id}/`;

  // find matching keyword (if exists, also handle list)
  var matchingKeyword = null;
  if (item.body && keyword){
    splitKeywords(keyword).forEach(k => {
      if (!matchingKeyword && textContainsKeyword(item.body, k)){
        matchingKeyword = k;
      }
    })
  }

  // either populated from the parent, or updated here via the modal
  const savedLists = savedListsUpdated ? updatedSavedLists : item.saved_conversations || [];

  const scrollIntoView = () => {
    // after clicking on item, scroll to it (only if in a nested view)
    if (!inNested) return;

    // TODO: it used to be fine without having to do this. when you hit a nested item, it would just open (and closing one above it didn't affect scroll)
    setTimeout(() => {
      document.getElementById(`comment-${item.id}`).scrollIntoView({
          behavior: 'smooth'
      })
    }, 100)
  }

  const toggleOpen = (item) => {
    // if open, closes. if closed, navigate to open
    const newUrl = isCurrent ? rootUrl : `${rootUrl}${item.id}/${location.search}`;
    history.push(newUrl);
    // setViewed(true);
    scrollIntoView();
  }

  return (
    <li id={`comment-${item.id}`}
      className={`${className || ''} group relative -ml-2 pl-2 py-4 border-l-4 ${isCurrent ? 'border-gray-200' : 'border-transparent'} hover:${isCurrent ? 'border-gray-200' : 'border-not-gray-700'}`}
    >
      <ErrorBoundary>
        <div className="flex space-x-4">
          {/* left sidebar of user actions */}
          <div className="text-center flex flex-col gap-y-2 h-auto justify-between">
            <ReactTooltip id={`action-${item.id}`} effect="solid" place="right" backgroundColor="white" textColor="black"/>
                
            {/* checkbox if we're allowing for a select tobble */}
            {toggleSelected ? (
              <div className="w-6 flex-shrink-0 text-gray-400 text-center">
                <div className={`block mx-auto relative`}>
                  <input type="checkbox" value={isSelected} checked={isSelected} onChange={(e) => {
                      toggleSelected();
                    }}
                    className="h-5 w-5 ml-0 rounded-sm cursor-pointer border bg-gray-700 border-gray-600 focus:outline-none focus:ring-0 checked:bg-not-green-500 active:bg-not-green-500"
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}
                    className={`${isSelected ? 'hidden' : ''} hidden group-hover:block absolute top-1.5 left-1 h-4 w-4 cursor-pointer`}
                    onClick={() => toggleSelected()}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
            ) : ''}

            {isLoading ? (
              <svg className="animate-spin h-6 w-6 text-white opacity-25" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              <React.Fragment>
                <ConversationListPopover
                  // onSelect={(s) => console.log("selected", s)}
                  selectedIds={[item.id]} selectedType={'comment'}
                  currentLists={savedLists}
                  getPopoverParentElement={() => parentListElement || document.getElementById(`comment-${item.id}`)}
                  positions={['bottom', 'right', 'top', 'left']}
                  onAdd={(list) => {
                    setUpdatedSavedLists([...savedLists, list])
                    setSavedListsUpdated(true)
                  }}
                  onRemove={(list) => {
                    setUpdatedSavedLists(savedLists.filter(s => s !== list))
                    setSavedListsUpdated(true)
                  }}
                  className={`w-6 relative ${savedLists && savedLists.length > 1 ? '' : 'h-6'}`}
                >
                  {savedLists.length === 0 ? (
                    <span data-for={`icon-${item.id}`} data-tip="Bookmark this conversation" className="relative text-gray-500 hover:text-gray-200">
                      <SubmissionTypeIcon type={'bookmark'} className="mx-auto self-center h-6 w-6 mx-auto cursor-pointer" />
                      <div className="hidden absolute w-6 h-6 top-0.5 text-center font-semibold text-xs text-gray-200">
                        +
                      </div>
                    </span>
                  ) : (
                    <div className="flex flex-col gap-y-2">
                      {savedLists.map(s => (
                        <span className="h-6" key={s.hash} data-for={`icon-${item.id}-${s.hash}`} data-tip={`Bookmarked: ${s.saved_search_name}`}>
                          <SubmissionTypeIcon type={'bookmark'} stroke={s.saved_search_color} fill={`${s.saved_search_color}75`} className="mx-auto self-center h-6 w-6 mx-auto cursor-pointer text-gray-400 hover:text-gray-200" />
                        </span>
                      ))}
                    </div>
                  )}
                </ConversationListPopover>
              </React.Fragment>
            )}

            {/* expand/contract always at bottom. not showing to keep clean*/}
            {/*{inNested ? (
              <div className="mt-auto w-6 text-center flex-shrink-0 text-gray-400 hover:text-gray-200 text-center cursor-pointer"
                onClick={() => toggleOpen(item)}
                data-for={`action-${item.id}`}
                data-tip={!isCurrent ? "Expand" : ''}
              >
                {isCurrent ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-5 mx-auto">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-5 mx-auto">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                )}
              </div>
            ) : ''}*/}
          </div>

          {/* main contents */}
          <div className="min-w-0 space-y-3 flex flex-col h-auto w-full">
            <div className="block max-w-full w-full mb-auto">
              <Link className="" to={isCurrent ? rootUrl : `${rootUrl}${item.id}/${location.search}`}
                onClick={() => {
                  scrollIntoView();
                  setViewed(true);
                }}
              >
                {/*<span className="absolute inset-0" aria-hidden="true"></span>*/}
                {matchingKeyword ? (
                  <div className="text-xs text-gray-400 containsKeywords doubleLineClip"
                    dangerouslySetInnerHTML={{__html: cleanHtml(styleKeyword(
                      findKeywordsAndTruncate(item.body, matchingKeyword),
                      matchingKeyword
                    ))}}>
                  </div>
                ) : item.body ? (
                  <div className="text-sm text-gray-400 containsKeywords doubleLineClip"
                    dangerouslySetInnerHTML={{__html: cleanHtml(item.body)}}>
                  </div>
                ) : (
                  <div className="text-xs text-gray-400 items-center">View Comment</div>
                )}
              </Link>
            </div>  

            {/* info about this comment specifically */}
            {!hideDetails ? (
              <div className="flex items-center flex-wrap w-full">
                <div className="relative flex items-center space-x-1 sm:space-x-2 text-gray-600 text-center ">
                  <span className="text-sm text-gray-500 font-medium flex"
                    data-for={`action-${item.id}`}
                    data-tip={`${item.is_removed ? 'Removed ' : item.is_deleted ? 'Deleted ' : 'Reddit '} comment`}
                  >
                    {item.is_removed || item.is_deleted ? (
                      <SubmissionTypeIcon type={'removed'} className="self-center flex-shrink-0 h-4 w-5"/>
                    ) : (
                      <SubmissionTypeIcon type={'comment'} className="h-5 w-5 mx-auto"/>
                    )}
                  </span>
                  <span aria-hidden="true">&middot;</span>

                  
                  <span className="text-sm text-gray-500 font-medium flex">
                    <svg className="self-center flex-shrink-0 h-4 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                    </svg>{item.score}
                  </span>
                  <span aria-hidden="true">&middot;</span>
                  
                  
                  <SubredditPopover name={item.subreddit_name} openOnClick={true} openOnHover={true}
                    getPopoverParentElement={() => parentListElement || document.getElementById(`submission-${item.id}`)}
                    positions={['right', 'left', 'top', 'bottom']}
                  >
                    <span className="text-sm text-gray-500 hover:text-white font-medium truncate">
                      {item.subreddit_name}
                    </span>
                  </SubredditPopover>

                  {redirected || item.redirected || viewed || item.viewed ? (
                    <span aria-hidden="true">&middot;</span>
                  ) : ''}

                  {/* set of icons/actions related to a user's activity on submission */}
                  <ReactTooltip id={`icon-${item.id}`} effect="solid" place="right" backgroundColor="white" textColor="black"/>
              

                  {redirected || item.redirected ? (
                    <span className="w-5 mx-auto text-gray-500 font-medium flex"
                     data-for={`icon-${item.id}`} data-tip="You've clicked out to this submission on Reddit"
                    >
                      <SubmissionTypeIcon type={'clicked'} className="self-center h-6 w-5 mx-auto opacity-80 hover:opacity-100"/>
                    </span>
                  ) : viewed || item.viewed ? (
                    <span className="w-5 mx-auto text-gray-500 font-medium flex"
                      data-for={`icon-${item.id}`} data-tip="You've viewed this submission on GummySearch"
                    >
                      <SubmissionTypeIcon type={'viewed'} className="self-center flex-shrink-0 h-6 w-5 opacity-80 hover:opacity-100"/>
                    </span>
                  ) : ''}
                </div>
                <div className="relative ml-auto flex-col flex-shrink-0 items-end space-y-3">
                  <div className="flex text-gray-500 text-sm space-x-2">
                    <RedditorPopover name={item.author} openOnClick={true} openOnHover={true}
                      getPopoverParentElement={() => parentListElement || document.getElementById(`submission-${item.id}`)}
                      positions={['left', 'right', 'top', 'bottom']}
                    >
                      <span className="hidden sm:block text-gray-500 hover:text-white">{item.author}</span>
                    </RedditorPopover>
                    <span className="hidden sm:block" aria-hidden="true">&middot;</span>
                    <span>{timeStampToEasyTime(item.timestamp_utc)}</span>
                  </div>
                </div>
              </div>
            ) : ''}
          </div>
        </div>
        
      </ErrorBoundary>
    </li>
  );
}

export default ResultRowComment;

