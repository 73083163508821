import React, { useState } from "react"
import { connect } from 'react-redux';
// import {
//   Link
// } from "react-router-dom";
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { CSVLink } from "react-csv";
import Markdown from 'markdown-to-jsx'
import copy from 'copy-to-clipboard';

import { uiOperations } from "../../redux/ui"

import { timeStampToEasyTime } from '../../utils/time';

import SubredditPopover from "../reddit/subredditpopover";
import RedditorPopover from "../reddit/redditorpopover";
import SubmissionTypeIcon from "../reddit/submissiontypeicon";
import NestedResults from "../reddit/nestedresults";
import AnalysisAskShareModal from "../language/analysisAskShare";


// import AnalysisQuestionAnswerResultsShareModal from "../language/questionAnswerResultsShare";

// analysis for set of conversations
const AnalysisQuestionAnswerResults = ({
  analysis,
  reset,
  limitsConfigurable,
  emptyMessage,
  viewOption,
  currentUser,
  columnView,
  // history, location, baseUrl,
}) => {
  // console.log("analysis", analysis)
  const askAnalysis = analysis.results.all.ask;
  const citations = askAnalysis.citations;
  const posts = askAnalysis.closest_posts;
  const [shareOpen, setShareOpen] = useState(false);
  const [sharedReportHash, setSharedReportHash] = useState(analysis.shared_report_hash); // set to local state in case they just shared
  const [expandedCitations, setExpandedCitations] = useState([]);


  // TODO: filter citations to be unique


  const InlineCitation = ({type, id}) => {
    // console.log("citation", post_id, comment_id)
    var citation = null;
    if (type === "comment"){
      citation = citations.filter(c => c.type === "comment" && c.id === id)[0]
    }
    if (type === "post"){
      citation = citations.filter(c => c.type === "post" && c.id === id)[0]
    }

    if (citation){
      return <span className="text-gray-500 font-bold cursor-pointer hover:text-gray-400"
        onClick={(e) => {
          e.preventDefault()
          if (!expandedCitations.includes(id)) {
            setExpandedCitations([...expandedCitations, id])

            // scroll to the citation
            var element = document.getElementById(`citation-${id}`);
            element.scrollIntoView({behavior: "smooth"});
          } else {
            setExpandedCitations(expandedCitations.filter(i => i !== id))
          }
        }}>
        [{citation.index}]
      </span>
    }
    return <span className="text-red-500 font-bold">[?]</span>
  }

  const renderPostContents = (c) => {
    return (
      <div>
        {['link', 'crosspost'].includes(c.post.type) ? (
          <div className="py-2 xl:pt-4 xl:pb-0">
            <a href={c.post.url} target="_blank" rel="noreferrer"
              onClick={() => {
                // trackEvent("ExternalRedirect", {
                //   "platform": "Reddit",
                //   "type": "SubmissionSource",
                //   "platform_id": submission.id,
                // });
                // searchesService.saveRedirect("RSU", submission.id, submission.title);
              }}
            >
              {c.post.thumbnail && c.post.thumbnail !== 'default' ? (
                <div className="relative rounded-md">
                  <img className="w-full h-full rounded-md" src={c.post.thumbnail} width="100%" alt={c.post.title}/>
                  <div className="absolute top-0 right-0 w-full h-full bg-black opacity-50 rounded-md" aria-hidden="true"></div>
                  <div className="absolute top-2 left-2 right-2 overflow-hidden text-sm flex items-center p-2 bg-white text-gray-900 rounded-md opacity-50">
                    <svg className="flex-shrink-0 h-4 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                    </svg>
                    <div className="whitespace-nowrap overflow-ellipsis overflow-hidden">{c.post.url}</div>
                  </div>
                </div>
              ) : (
                <div className="relative rounded-md p-4 bg-gray-700 break-all text-white text-sm border border-gray-600 shadow-sm">
                  <svg className="inline-block h-4 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                  </svg>
                  <span>{c.post.url}</span>
                </div>
              )}
            </a>
          </div>
        ) : ''}

        {c.post.type === 'image' ? (
          <div className="py-4 xl:pt-4 xl:pb-0 flex flex-wrap">
            {c.post.media.map(url => (
              <div className="relative rounded-md w-96 mb-4 bg-gray-100" key={url} style={{minHeight: '100px'}}>
                <img className="w-full rounded-md" src={url} width="100%" alt={c.post.title}/>
              </div>
            ))}
          </div>
        ) : ''}
      </div>
    );
  }

  return (
    <div id="ask-analysis" className={!columnView ? `md:flex md:space-x-8` : ''}>

      <div className={!columnView ? `md:flex-1 md:flex-shrink-0 md:w-1/2` : ''}>

        <div className="mt-4">
          <div className="p-6 bg-gray-800 rounded-lg text-white">

            <div className="flex border-b border-gray-600 pb-4 mb-4 w-full">
              <h2 className="text-xl font-medium flex items-center flex-wrap">
                <div className="text-gray-100 font-semibold">{askAnalysis.question}</div>

                {/*<ReactTooltip id={`action-icons`} place="top" effect="solid" backgroundColor="white" textColor="black" />
                <div className="ml-4 flex items-center space-x-2 text-gray-400">
                  {reset ? (
                    <div className="hover:text-gray-200 cursor-pointer"
                      onClick={reset}
                      data-for={`action-icons`} data-tip={"Reset"}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                      </svg>
                    </div>
                  ) : ''}
                </div>*/}
              </h2>
              <div className="ml-auto flex-shrink-0">
                <button type="button" className="bg-cyan-500 flex inline-block px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md opacity-100 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  onClick={() => setShareOpen(true)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-1 mr-2 h-5 w-5 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
                  </svg>
                  <span>Share</span>
                </button>
              </div>
            </div>

            <div className="text-lg text-gray-200">
              <Markdown options={{
                wrapper: 'article',
                overrides: {
                   InlineCitation: {
                    component: InlineCitation,
                  },
                  p: {
                    props: {
                      className: 'mb-4',
                    },
                  },
                  ol: {
                    props: {
                      className: 'list-decimal pl-6',
                    },
                  },
                },
              }}>
                {askAnalysis.answer}
              </Markdown>
            </div>
          </div>
        </div>
      </div>

      <div className={!columnView ? `md:flex-1 md:flex-shrink-0 md:w-1/2 mt-4` : 'mt-8'}>
        <div className={!columnView ? `space-y-8` : 'space-y-4'}>
          {citations.map(c => (
            <div key={`${c.type}-${c.id}-${c.index}`} id={`citation-${c.id}`} className="text-gray-400 p-4 rounded-md bg-gray-800 cusror-pointer"
              className={`text-base p-4 rounded-md bg-gray-800 ${expandedCitations.includes(c.id) ? '' : 'cursor-pointer hover:bg-gray-700'}`}
              onClick={() => {
                if (!expandedCitations.includes(c.id)){
                  setExpandedCitations([...expandedCitations, c.id])
                  // scrollIntoView(c);
                }
              }}
            >
              <div className="flex items-top">
                <div className="mr-3 flex items-center cursor-pointer opacity-50 hover:opacity-75 cursor-pointer font-bold" onClick={(e) => {
                  e.preventDefault()
                  if (expandedCitations.includes(c.id)) {
                    setExpandedCitations(expandedCitations.filter(id => id !== c.id))
                  }
                }}>
                  <div className="mr-1">{c.index}</div>
                  {!expandedCitations.includes(c.id) ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                  )}
                </div>
                <div>
                  <span className="font-semibold">{c.post.title}</span>
                </div>
              </div>
              <div className="pl-11 mt-2">
                {c.type === "comment" ? (
                  <div className="">
                    <div className={`text-base text-gray-400 ${expandedCitations.includes(c.id) ? 'tripleLineClip' : 'singleLineClip'} mb-1`}>{c.post.body}</div>

                    {expandedCitations.includes(c.id) ? (
                      <React.Fragment>{renderPostContents(c)}</React.Fragment>
                    ) : ''}

                    <div className={`text-base text-white ${expandedCitations.includes(c.id) ? '' : 'doubleLineClip'}`}>{c.comment.body}</div>

                    
                    <div className="flex mt-4 items-center text-gray-400">
                      <svg className="mr-1 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                      </svg>
                      <div className="text-sm text-gray-500">
                        Comment by <RedditorPopover name={c.comment.author} openOnClick={true} openOnHover={true}
                        getPopoverParentElement={() => document.getElementById(`citation-${c.id}`)}
                        positions={['left', 'right', 'top', 'bottom']}
                      >
                        <span className="hover:text-white text-gray-400 ">{c.comment.author}</span>
                      </RedditorPopover> in <SubredditPopover name={c.post.subreddit_name} openOnClick={true} openOnHover={true}
                        getPopoverParentElement={() => document.getElementById(`citation-${c.id}`)}
                        positions={['right', 'left', 'top', 'bottom']}
                      >
                        <span className="hover:text-white text-gray-400 ">
                          {c.post.subreddit_name}
                        </span>
                      </SubredditPopover> on {timeStampToEasyTime(c.comment.timestamp_utc)}
                      </div>
                      {expandedCitations.includes(c.id) ? (
                        <div className="ml-auto">
                          <a href={`https://reddit.com${c.comment.link}`} target={"_blank"} rel="noreferrer"
                          >
                            <button  type="button" className="bg-cyan-500 inline-flex px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md opacity-100 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                              <svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"/>
                              </svg>
                              <span>View</span>
                            </button>
                          </a>
                        </div>
                      ) : ''}
                    </div>
                    
                  </div>
                ) : c.type === "post" ? (
                  <div className="w-5/6 relative">
                    {expandedCitations.includes(c.id) ? (
                      <React.Fragment>{renderPostContents(c)}</React.Fragment>
                    ) : ''}

                    <div className={`text-base text-white pre-wrap ${expandedCitations.includes(c.id) ? '' : 'tripleLineClip text-gray-400'}`}>{c.post.body}</div>
                    {expandedCitations.includes(c.id) ? (
                       <ul className="mt-4 space-y-1 w-full">
                        {c.post.replies.map(comment => (
                          <li key={comment.id} className="text-base flex items-top text-gray-400  w-full">
                            <div className="w-14 flex-shrink-0 text-center flex items-center">
                              <SubmissionTypeIcon type="arrow-up" className="self-center flex-shrink-0 h-4 w-5" />
                              <span className="mr-4">{comment.score}</span>
                            </div>
                            <span className="singleLineClip text-gray-200 text-base w-96">{comment.body}</span>
                          </li>
                        ))}
                      </ul>
                    ) : ''}

                    
                    <div className="flex mt-4 items-center text-gray-400">
                      <SubmissionTypeIcon type={c.post.type} className="mr-1 flex-shrink-0 h-4 w-4"/>
                      <div className="text-sm text-gray-400">
                        Post by {c.post.author} in {c.post.subreddit_name} on {timeStampToEasyTime(c.post.timestamp_utc)}
                      </div>
                      {expandedCitations.includes(c.id) ? (
                        <div className="ml-auto">
                          <a href={`https://reddit.com${c.post.link}`} target={"_blank"} rel="noreferrer"
                            // onClick={() => {
                            //   trackEvent("ExternalRedirect", {
                            //     "platform": "Reddit",
                            //     "type": "Submission",
                            //     "platform_id": submission.id,
                            //   });
                            //   searchesService.saveRedirect("RSU", submission.id, submission.title);
                            //   if (onRedirect){
                            //     onRedirect(submission.id)
                            //   }
                            // }}
                          >
                            <button  type="button" className="bg-cyan-500 inline-flex px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md opacity-100 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                              <svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"/>
                              </svg>
                              <span>View</span>
                            </button>
                          </a>
                        </div>
                      ) : ''}
                    </div>
                    
                  </div>
                ) : 'Invalid Type'}
              </div>
            </div>
          ))}
        </div>


        {posts && posts.length ? (
          <div className='space-y-1 mt-8'>
            <div className="flex items-center space-x-2 text-gray-200">
              <div>Relevant Posts</div>
              {/*<div className="opacity-50 text-sm">{posts.length}</div>*/}

              <svg xmlns="http://www.w3.org/2000/svg" className="ml-auto h-6 w-6 flex-shrink-0 opacity-50 hover:opacity-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}
                onClick={() => {
                  copy(posts.map(p => `https://reddit.com${p.link}`).join(' \n'));
                  toast.success(`Links copied`);
                }}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
              </svg>
            </div>
            {posts.map(p => (
              <div
                // key={`post-${p.id}`} id={`citation-${c.id}`}
                // className="text-gray-400"
                // className={`text-base p-4 rounded-md bg-gray-800 ${expandedCitations.includes(c.id) ? '' : 'cursor-pointer hover:bg-gray-700'}`}
                // onClick={() => {
                //   if (!expandedCitations.includes(c.id)){
                //     setExpandedCitations([...expandedCitations, c.id])
                //     // scrollIntoView(c);
                //   }
                // }}
                className="text-gray-400 flex items-center"
              >
                <SubmissionTypeIcon type={p.type} className="flex-shrink-0 h-4 w-4 mr-2"/>
                <a href={`https://reddit.com${p.link}`} target={"_blank"} rel="noreferrer"
                  className="hover:underline hover:text-gray-200 flex items-center"
                >
                  <span>{p.title}</span>
                </a>
                <span className="ml-auto text-sm text-gray-500 font-medium flex">
                  {p.num_comments}
                  <svg className="self-center flex-shrink-0 h-4 w-5 ml-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                  </svg>
                </span>

              </div>
            ))}
          </div>
        ) : ''}
        
      </div>


      {shareOpen ? (
        <AnalysisAskShareModal
          analysis={analysis}
          sharedReportHash={sharedReportHash} setSharedReportHash={setSharedReportHash} // keep local state b/c no refetch after share
          closeModal={() => setShareOpen(false)}
        />
      ) : ''}
    </div>
  );
}

const mapStateToProps = state => {
  // const {
  //   patternDisplayStyle,
  // } = state.ui;

  // const viewOption = patternDisplayStyle || 'preview';
  return {
    // viewOption
  }
};

const mapDispatchToProps = (dispatch) => {
  // const setUiState = (key, value) => {
  //   dispatch(uiOperations.setUiState(key, value))
  // };
  return {
    // setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisQuestionAnswerResults);
