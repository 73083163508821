const dayOfWeekMap = (i) => {
	return {
		0: 'Sun',
		1: 'Mon',
		2: 'Tue',
		3: 'Wed',
		4: 'Thu',
		5: 'Fri',
		6: 'Sat',
	}[i]
}

const dayOfWeek = (timestamp) => {
	return dayOfWeekMap(new Date(timestamp * 1000).getDay())
}

const fullDayOfWeekMap = (i) => {
	return {
		0: 'Sunday',
		1: 'Monday',
		2: 'Tuesday',
		3: 'Wednesday',
		4: 'Thursday',
		5: 'Friday',
		6: 'Saturday',
	}[i]
}

// const defaultLocale = 'en-US';
const DEFAULT_LOCALE = undefined; // try not setting a default to see if browser picks it up

const timeStampToEasyTime = (timestamp) => {
	var datetime = new Date(timestamp * 1000);

	// separate vars just to check
	const today = new Date().setHours(0, 0, 0, 0);
	const thatDay = new Date(timestamp * 1000).setHours(0, 0, 0, 0);
	
	if (today === thatDay){
		// just return time like 5:39 PM
		const time = datetime.toLocaleTimeString(DEFAULT_LOCALE)
		return `${time.split(':').slice(0, 2).join(":")} ${time.split(' ')[1] ? time.split(' ')[1] : ''}`
	}
	// return day (prefixed with day of week), and remove time
	return `${dayOfWeek(timestamp)} ${datetime.toLocaleDateString(DEFAULT_LOCALE).split(',')[0]}`;
}

const timeStampToFullTime = (timestamp) => {
	var datetime = new Date(timestamp * 1000);
	return `${dayOfWeek(timestamp)} ${datetime.toLocaleString(DEFAULT_LOCALE)}`;
}

export {
    timeStampToEasyTime,
    timeStampToFullTime,
    dayOfWeek,
    dayOfWeekMap,
    fullDayOfWeekMap,
}

