import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link
} from "react-router-dom";

import { Listbox, Transition } from '@headlessui/react'
import {
  UserGroupIcon, CheckIcon, SelectorIcon, PlusCircleIcon
} from '@heroicons/react/outline'

import ErrorBoundary from "../common/errorboundary";

import { audiencesOperations } from "../../redux/audiences"
import { localStorageAvailable } from '../../utils/localStorage';
import { searchesService } from '../../services/searches';

import { classNames } from '../../utils/tailwind';


const Browse = ({
  history,
  // isEffectiveProSubscriber,
  audiences, fetchingAudiences, fetchAudiences,
}) => {
  const [audienceKeyword, setAudienceKeyword] = useState('');
  const [keyword, setKeyword] = useState('');
  const ANYONE_KEY = 'anyone';
  const ANYONE_LABEL = 'Anyone'
  const [selectedCollectionHash, setSelectedCollectionHash] = useState(ANYONE_KEY);  // will be hash
  const selectedCollection = audiences.filter(c => c.hash === selectedCollectionHash)[0];
  const [recentSearches, setRecentSearches] = useState([]);
  const [loadingRecentSearches, setLoadingRecentSearches] = useState(false);

  const searchTypes = [
    {'title': 'a post', 'key': 'submissions'},
    {'title': 'a comment', 'key': 'comments'},
  ]
  const [selectedSearchTypeKey, setSelectedSearchTypeKey] = useState(searchTypes[0].key);
  const selectedSearchType = searchTypes.filter(c => c.key === selectedSearchTypeKey)[0];

  // get most recent audiences
  var recentAudiences = [];
  const key = 'audience-view-order';
  if (localStorageAvailable() && localStorage.getItem(key)){
    const order = localStorage.getItem(key);
    const getHashOrder = (hash) => {
      return order.includes(hash) ? order.indexOf(hash) : 1000000;
    }
    recentAudiences = audiences.sort((a,b) => getHashOrder(a.hash) > getHashOrder(b.hash) ? 1 : ((getHashOrder(b.hash) > getHashOrder(a.hash)) ? -1 : 0));
    recentAudiences = recentAudiences.slice(0, 3);
  }

  // effects
  useEffect(() => {
    // fetch audiences if not in store already
    if (!fetchingAudiences && !audiences.length){
      fetchAudiences();
    }

    // load recent searches
    setLoadingRecentSearches(true)
    searchesService.recentSearches({"search_type": "RS,RC"}, (results) => {
      if (results && results.data){
        setRecentSearches(results.data)
        setLoadingRecentSearches(false)
      }
    }, () => {
      setLoadingRecentSearches(false)
    })
  }, []); // Init all the things!


  const performSearch = (e) => {
    e.preventDefault()

    if (!keyword && !selectedCollection) return;

    var url = `/reddit/?keyword=${encodeURIComponent(keyword)}`;
    if (selectedCollection){
      url += `&collection=${selectedCollection.hash}`
    }
    if (selectedSearchType){
      url += `&type=${selectedSearchType.key}`
    }

    history.push(url)
  }

  return (
    <ErrorBoundary>
      <div className="mb-8 flex flex-col items-center justify-center text-md md:text-lg">
        
        <div className="w-full text-left">
          {/*<div className="hidden sm:inline-block mx-auto p-5 rounded-md bg-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
            </svg>
          </div>*/}
          {/*<h2 className="mt-2 text-lg font-medium text-left">Advanced Search for Reddit</h2>*/}
        </div>

        {/*<div className="text-sm font-medium mb-1 opacity-50 transform-uppercase flex items-center">
          Search {selectedCollection && selectedCollection.name || ANYONE_LABEL} for {selectedSearchType.title} that mention {keyword || 'anything'}
        </div>*/}

        <form className="w-full mx-auto sm:flex sm:space-x-4 justify-center" onSubmit={performSearch}>
          {/* Audience/subreddit selection */}
          <div className="flex-1">
            <label className="text-lg font-medium mb-2">
              Audience to search
            </label>
            <div className="mt-2 ">

              <Listbox id="audience-select" value={selectedCollectionHash} onChange={(s) => {
                setSelectedCollectionHash(s)
                setTimeout(() => {
                  document.getElementById('keyword-search').focus()
                }, 100)
              }}>
                <div className="block relative">
                  <Listbox.Button
                    className="w-full text-left relative text-gray-400 focus-within:text-white border-transparent focus:border-transparent focus:ring-0">
                    <span className="block bg-gray-800 w-full border-transparent p-4 pr-12 pl-12 placeholder-gray-500 rounded-md text-sm focus:ring-0">
                      {selectedCollection && selectedCollection.name || ANYONE_LABEL}
                    </span>
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
                      {selectedCollection && selectedCollection.name ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                      ) : (
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64" />
                        </svg>
                      )}
                    </div>

                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center justify-center pr-4">
                      <SelectorIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                  </Listbox.Button>

                  <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-sm sm:text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    <Listbox.Option
                      key={ANYONE_KEY}
                      className={({ active }) => `cursor-default text-xs sm:text-sm select-none relative py-2 pl-4 pr-4 ${active ? 'text-white bg-cyan-600' : 'text-gray-900'}`}
                      value={ANYONE_KEY}
                    >
                      {({ selected, active }) => (
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {ANYONE_LABEL}
                        </span>
                      )}
                    </Listbox.Option>

                    {audiences.map((c) => (
                      <Listbox.Option
                        key={c.hash}
                        className={({ active }) => `cursor-default text-xs sm:text-sm select-none relative py-2 pl-4 pr-4 ${active ? 'text-white bg-cyan-600' : 'text-gray-900'}`}
                        value={c.hash}
                      >
                        {({ selected, active }) => (
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {c.name}
                          </span>
                        )}
                      </Listbox.Option>
                    ))}

                    {audiences.length === 0 ? (
                      <Listbox.Option
                        key={'none'}
                        className={'text-gray-900 cursor-default select-none relative py-2 pl-4 pr-4'}
                        value={'none'}
                        disabled={true}
                      >
                        <span className={classNames(false ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          No saved audiences yet...
                        </span>
                      </Listbox.Option>
                    ) : ''}
                  </Listbox.Options>
                </div>
              </Listbox>

              {/* Add listbox selector here with search results here */}
            </div>

            <div className="flex flex-wrap">
              <div 
                className={`mt-2 p-1 mr-2 text-xs text-white border ${selectedCollectionHash === ANYONE_KEY ? 'bg-gray-700 border-gray-600' : 'hover:bg-gray-800 border-gray-700'} cursor-pointer rounded-md flex items-center`}
                onClick={() => {
                  setSelectedCollectionHash(ANYONE_KEY)
                  setTimeout(() => {
                    document.getElementById('keyword-search').focus()
                  }, 100)
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 mr-1 opacity-25">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64" />
                </svg>

                <div>{ANYONE_LABEL}</div>
              </div>
              {recentAudiences.map(a => (
                <div key={a.hash}
                  className={`mt-2 mr-2 p-1 text-xs text-white border ${selectedCollectionHash === a.hash ? 'bg-gray-700 border-gray-600' : 'hover:bg-gray-800 border-gray-700'} cursor-pointer rounded-md flex items-center`}
                  onClick={() => {
                    setSelectedCollectionHash(a.hash)
                    setTimeout(() => {
                      document.getElementById('keyword-search').focus()
                    }, 100)
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1 opacity-25" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  <div>{a.name}</div>
                </div>
              ))}

              {true || (recentAudiences.length === 0) ? (
                <Link to="/audiences/new/"
                  className={`mt-2 mr-2 p-1 text-xs text-white border hover:bg-gray-800 border-gray-700 cursor-pointer rounded-md flex items-center`}
                >
                  <PlusCircleIcon className="h-4 w-4 mr-1 opacity-25" aria-hidden="true" />
                  <div>New</div>
                </Link>
              ) : ''}
            </div>
          </div>

          {/* Keyword selection */}
          <div className="flex-1 mt-4 sm:mt-0">
            <label className="text-lg font-medium">
              Keyword to look for
            </label>

            <div className="mt-2 relative text-gray-400 focus-within:text-white">
              <input id="keyword-search" type="text" placeholder="Any keyword" autoComplete="off" spellCheck="off" //autoFocus="off"
                className="block bg-gray-800 w-full border-transparent p-4 pl-12 placeholder-gray-500 rounded-md focus:border-transparent sm:text-sm focus:ring-0"
                value={keyword} onChange={(e) => {
                  setKeyword(e.target.value);
                }}
              />
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                </svg>
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className={`mt-2 mr-2 p-1 text-xs text-white border ${keyword === '' ? 'bg-gray-700 border-gray-600' : 'hover:bg-gray-800 border-gray-700'} cursor-pointer rounded-md flex items-center`}
                onClick={() => {
                  setKeyword('')
                  setTimeout(() => {
                    document.getElementById('submit-button').focus()
                  }, 100)
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 mr-1 opacity-25">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64" />
                </svg>
                <div>Anything</div>
              </div>
              {Array.from(new Set(recentSearches.filter(s => s.params.keyword && !s.params.keyword.includes(',')).map(s => s.params.keyword))).map(k => (
                <div key={k}
                  className={`mt-2 mr-2 p-1 text-xs text-white border ${keyword === k ? 'bg-gray-700 border-gray-600' : 'hover:bg-gray-800 border-gray-700'} cursor-pointer rounded-md flex items-center`}
                  onClick={() => {
                    setKeyword(k)
                    setTimeout(() => {
                      document.getElementById('submit-button').focus()
                    }, 100)
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 mr-1 opacity-25">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>

                  <div>{k}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Enter button */}
          <div className="mt-3 text-center">
            <label className="h-6 block"> </label>
            <button
              id="submit-button"
              type="submit"
              // disabled={!keyword && !selectedCollection}
              onClick={(e) => {
                if (!keyword && !selectedCollection){
                  e.preventDefault();
                  alert("Please add either a keyword or select an audience.")
                }
              }}
              className="block w-full border-transparent p-4 rounded-md focus:border-transparent sm:text-sm bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            >
              Perform Advanced Search
            </button>

            <div className="flex mt-3">
              <Link to={`/search/query/`}
                // type="button"
                className="mx-auto inline-flex items-center text-sm font-medium text-gray-400 hover:text-gray-200 hover:underline"
              >
                <svg className="h-4 w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                </svg>
                More filters
              </Link>
            </div>
          </div>
        </form>

      </div>
      <div className="h-4"></div>
    </ErrorBoundary>
  );
}


const mapStateToProps = state => {
  const {
    audiences,
    fetchingAudiences
  } = state.audiences;

  return {
    audiences,
    fetchingAudiences,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAudiences = (callback, onError) => {
    dispatch(audiencesOperations.fetchAudiences(callback, onError))
  };
  return {
    fetchAudiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Browse);

