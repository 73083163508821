import React, { useState } from "react";
// import {
//   withRouter,
// } from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";


const NotificationsEmailModal = ({
  closeModal,
  history,
  digestSettings,
  // updateSettings,
  currentUser,
  createNotificationChannel,
}) => {
  const currentEmail = digestSettings && digestSettings.notification_channel && digestSettings.notification_channel.channel_type === 'EM' && digestSettings.notification_channel.params.email || '';  
  const [email, setEmail] = useState(currentEmail);
  const [saving, setSaving] = useState(false);

  const save = (e) => {
    // fine to have email be blank (will send to default on account)

    e.preventDefault()
    if (saving) return;

    const validateEmail = (email) => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (email && !validateEmail(email)){
      return alert("Invalid email address")
    }

    setSaving(true);
    const nickname = `Email: ${email || 'default'}`
    createNotificationChannel({'channel_type': 'EM', 'params': {'email': email}, 'nickname': nickname}, () => {
      setSaving(false);
      closeModal();
    }, () => {
      setSaving(false);
    });
  }
  
  return (
    <Modal close={closeModal} widthClass={'max-w-xl'}>
      <Helmet><title>Account | Notifications | Email</title></Helmet>
      
      <div className="flex items-center">
        
        <h3 className="text-lg leading-6 font-medium">
          Configure Email Notifications
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="mt-4">
        <div className="text-gray-200 space-y-4">
          <p>
            Configure email address to send tracked keyword digests to.
          </p>
          <p>
            Leave blank to default to your account email address.
          </p>
        </div>

        <form className="mt-4 flex flex-wrap space-x-1 sm:space-x-2 space-y-2 md:space-y-0 text-xs md:text-base items-center w-full md:w-auto"
          onSubmit={save}
        >
          <input
            type="text" id="slack-email"
            name="slack-email"
            autoComplete="off" //autoFocus={isEffectiveProSubscriber ? "on" : ""}
            className={`text-xs md:text-base flex-grow bg-gray-800 cursor-pointer border border-solid border-gray-600 rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500`}
            placeholder={currentUser.identity.email}
            value={email} onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <button
            type="submit" disabled={saving}
            className="mt-2 md:mt-0 w-full md:w-auto md:ml-2 text-center p-2 px-4 border border-solid border-transparent font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
          >
            Save
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default NotificationsEmailModal;

