const range = (start, end) => {
    const size = (end - start) + 1;
    return [...Array(size).keys()].map(i => i + start);
}

const buckets = (min, max, bucketsNumber) => {
    // for a beginning & end of array, determine the min/max of each bucket (of which there are num N)
    return Array.from({length: bucketsNumber}).map((el, idx, arr, step = (max - min) / bucketsNumber) => [min + idx * step, min + (idx + 1) * step])
}

const stDev = (array) => {
    // standard deviation of array
    const n = array.length
    const mean = array.reduce((a, b) => a + b) / n
    return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}


const toHistogram = (arr, numBins, interval) => {
    //inclusive of the first number  
    var max = Math.max(...arr) + 1;
    var min = Math.min(...arr);
    const MIN_BUCKET = -2
    const intervalToUse = interval || 1;  // needs to be at least 1
    if (min > 0 && min < 10){
      min = 0; // if it's a small number, start at 0
    } else if (min < MIN_BUCKET){
      min = MIN_BUCKET; // if there's a big downvote, start min at -2 (edit range below)
    }
    var len = max - min + 1;
    // var numberOfBins = Math.ceil(len / interval);
    var bins = new Array(numBins).fill(0).map((v, i) => {
      return {
        'count': 0,
        'min': min + (intervalToUse * i),
        'max': min + (intervalToUse * (i + 1)),
      }
    })

    // make last bin be overflow if the math doesn't add up for max and interval
    if (max > bins[bins.length - 1].max){
      bins[bins.length - 1].max = max
      bins[bins.length - 1].isLast = true
    }

    // if first bin is hitting the min bucket, modify it
    if (bins[0].min === MIN_BUCKET){
      bins[0].min = Math.min(...arr)
      bins[0].max = bins[1].min;
      // bins[1].min = 0;
    }

    // put into right bucket
    arr.forEach((x) => {
      var binIndex = Math.floor((x-min) / intervalToUse);
      if (binIndex > (bins.length - 1)){
        binIndex = bins.length - 1 // if in overflow bin
      }
      // TODO: figure out when this happens. it's comments, maybe negatives?
      // if (binIndex >= 0){
      //   bins[binIndex]['count']++
      // }
    });
    return bins;
}

const contains = (arr, elem) => {
  // safer method than Array.includes, as that one doesn't match arrays or objects
  return arr.some(e =>{
    return JSON.stringify(e) === JSON.stringify(elem);
  });
} 


// calculate the median
const median = (arr) => {
  arr.sort(function(a, b){ return a - b; });
  var i = arr.length / 2;
  return i % 1 == 0 ? (arr[i - 1] + arr[i]) / 2 : arr[Math.floor(i)];
}

const average = (arr) => {
  const n = arr.length
  return arr.reduce((a, b) => a + b) / n
}


export {
    range,
    buckets,
    stDev,
    toHistogram,
    contains,
    median,
    average,
}

