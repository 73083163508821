// operations.js
// import fetch from 'cross-fetch';
import types from './types.js';
import axios from 'axios';

import { statusService } from "../../services/status";

const fetchStatus = (callback, onError) => {

  return dispatch => {
    dispatch({type: types.FETCH_STATUS});
    return statusService.fetchStatus((response) => {
      dispatch({type: types.FETCH_STATUS_SUCCESS, data: response.data});
      if (callback){
        callback(response.data)
      }
    }, (error, response) => {
      dispatch({type: types.FETCH_STATUS_FAIL});
      if (onError){
        onError()
      }
    })
  }
}

export default {
  fetchStatus,
}