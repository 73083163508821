import React, { useState, useEffect } from "react";

import VisibilitySensor from "react-visibility-sensor";

import { redditService } from '../../services/reddit';
import { collectionsService } from '../../services/collections';
import { readableNumber } from '../../utils/text';
// import { trackEvent } from '../../utils/tracking';

import ErrorBoundary from "../common/errorboundary";
import AudienceDiscoverySubreddit from "./discoverysubreddit";

// shows community results from subreddit
// communities show discover info, still need to get subreddit details 
const AudienceDiscoveryResults = ({
  communities,
  keywordResults,
  loading,
  selectedSubs,
  setSelectedSubs,
  currentCollectionItems,
}) => {
  // state
  const initialCount = 12;
  const countIncrement = 6;

  const [subredditDetails, setSubredditDetails] = useState([]);  // array of subreddits
  const [countToShow, setCountToShow] = useState(initialCount);
  const [fetchingSubreddits, setFetchingSubreddits] = useState(false);
  
  const [showHidden, setShowHidden] = useState(false);
  
  // effects
  useEffect(() => {
    // get the communities that need details fetched when we increase the count to show
    const fetchNames = getCommunitiesToShow().filter(c => c.loading).map(c => c.name);
    fetchSubredditDetails(fetchNames)
    // console.log("fetch", fetchNames)
  }, [
    countToShow,
    keywordResults,
    showHidden,
  ]); // Run when we add new ones to show

  useEffect(() => {
    setCountToShow(initialCount)
  }, [
    keywordResults.length,
  ]); // when we get new keywords, go back do initial count to show

  // useEffect(() => {
  //   console.log("complete refresh?")
  // }, []); // when we get new keywords, go back do initial count to show

  // actions
  

  const fetchSubredditDetails = (names) => {
    if (fetchingSubreddits || !names || !names.length){
      // console.log("not going to fetch", fetchingSubreddits, names)
      return;
    }

    // pass in [subredditname] and get the cached data about it in a bulk API
    setFetchingSubreddits(true);
    redditService.fetchSubreddits(names, (response) => {
      setSubredditDetails([...subredditDetails, ...response.data]);
      setFetchingSubreddits(false);
    }, (error) => {
      console.error(error);
      setFetchingSubreddits(false);
    });
  }

  // adding a subreddit to a collection in a single operation
  const fetchSingleSubredditDetails = (name) => {
    // set the subreddit to be loading while we fetch it
    setSubredditDetails(subredditDetails.map((s) => {
      if (s.name === name){
        s.loading = true;
      }
      return s;
    }));

    // fetch single subreddit, and update it in the subreddits list
    redditService.fetchSubreddits([name], (response) => {
      const thisSubreddit = response.data[0];
      setSubredditDetails(subredditDetails.map((s) => {
        if (s.name === name){
          return thisSubreddit
        }
        return s;
      }));
    }, (error) => {
      console.error(error);
    });
  }

  const fetchMore = () => {
    // increment amount to show. go fetch details on any that don't have em
    // console.log("set to", countToShow + countIncrement)
    setCountToShow(countToShow + countIncrement);
  }

  // when we scroll to bottom, go fetch next page
  const handleNextPageInview = (visible) => {
    // console.log("v", visible)
    if (visible){
      fetchMore()
    }
  }

  // more of a util than an action. used in render and in effect
  const getCommunitiesToShow = () => {
    // filter out hidden ones, then get first N
    return communities.filter(c => showHidden || !c.hidden).filter((c, i) => i < countToShow).map((c) => {
      // attach details if we have em
      const subredditDetail = subredditDetails.filter(s => s.name === c.name)[0];
      if (subredditDetail){
        c.details = subredditDetail;
        c.loading = false
      } else {
        c.loading = true;
      }
      return c;
    });
  }

  // TODO: get the ones to show. have loading state on it. make sure we're fetching details when we expand loading state

  // get communities to show. those that don't have details yet will be show as loading
  const communitiesToShow = getCommunitiesToShow();
  const totalCommunitiesCount = communities.filter(c => showHidden || !c.hidden).length;
  const hiddenCommunitiesCount = communities.filter(c => c.hidden).length;

  // get max velocity for a keyword/community combo
  var maxCommunityVelocity = 1;
  var communityKeywords = []
  communitiesToShow.forEach(community => community.keywords.forEach(k => communityKeywords.push(k)));
  maxCommunityVelocity = Math.max(...communityKeywords.map(k => k.velocity));

  return (
    <ErrorBoundary> 
      <div disabled={loading}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pr-2">
          {communitiesToShow.filter(c => !c.details || !c.details.over_18).map(community => (
            <AudienceDiscoverySubreddit
              key={community.name} name={community.name}
              subreddit={community.details} loading={community.loading}
              keywords={community.keywords} allKeywords={keywordResults.map(k => k.phrase)}
              maxVelocity={maxCommunityVelocity}
              select={(s) => s ? setSelectedSubs([...selectedSubs, s]) : null}
              unselect={(s) => setSelectedSubs(selectedSubs.filter(sub => sub !== s))}
              selected={selectedSubs.map(s => s.name).includes(community.name)}
              refetch={() => fetchSingleSubredditDetails(community.name)}
              isHiddenCommunity={community.hidden}
              isInCollection={currentCollectionItems && currentCollectionItems.map(c => c.external_id).includes(community.name)}
            />
          ))}
        </div>

        {communitiesToShow.length < totalCommunitiesCount ? (
          <div className="p-10 text-center">
            {fetchingSubreddits ? (
              <svg className="animate-spin h-4 w-4 mx-auto opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              <VisibilitySensor onChange={handleNextPageInview}>
                <span className="cursor-pointer opacity-50" onClick={() => fetchMore()}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                  </svg>
                </span>
              </VisibilitySensor>
            )}
          </div>
        ) : keywordResults.length > 0 ? (
          <div className="pt-4 pb-4 text-center text-sm text-gray-400">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <div>No more results for now</div>
            )}

            <div className="pt-2">Need more communities? <span
             className="text-white font-medium hover:underline cursor-pointer"
             onClick={() => {
              document.getElementById('new-discover-keyword').focus()
            }}>Add to your keywords</span></div>
          </div>
        ) : (
          <div className="pt-4 pb-4 text-center text-sm text-gray-400">
            <div>Add some keywords for your target audience to get community results</div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default AudienceDiscoveryResults;

