import React, { useState } from "react";
import {
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout";
import PropsRoute from '../../components/common/propsroute.jsx';

import WelcomePage from "./page";


// welcome page, contains components based on 
const Welcome = ({ history, location, match, currentUser }) => {

  return (
    <Layout title="Welcome" currentUser={currentUser} hideSidebar={true}>
      <Helmet><title>Welcome</title></Helmet>
      <div className="flex-grow justify-center pt-0 text-center block h-full">
        <Switch>
          <PropsRoute path="/welcome/:page/" component={WelcomePage} currentUser={currentUser} />
          <Redirect from="/welcome/" to="/welcome/intro/" />
        </Switch>
      </div>
    </Layout>
  );
};

export default withRouter(Welcome);

