const objectiveOptions = [
  {"key": "FP", "icon": "idea", "label": "Find a problem to solve", "name": "Carter"},
  {"key": "VS", "icon": "search", "label": "Validate a solution", "name": "Angelica"},
  {"key": "CU", "icon": "money", "label": "Discover customers", "name": "Robbie"},
  {"key": "CO", "icon": "speak", "label": "Ideate/distribute content", "name": "Stacy"},
  // {"key": "RE", "label": "Do research for content opportunities", "name": "Peter"}, // don't need
  {"key": "BR", "icon": "bell", "label": "Monitor my brand", "name": "Stephanie"}, // to add story?
  {"key": "MR", "icon": "book", "label": "General market research", "name": "Peter"}, // to add story?
  {"key": "OT", "icon": "other", "label": "Something else", "name": "Bobson"},
]
const roleOptions = [
  {"key": "FO", "label": "Startup Founder"},
  {"key": "SO", "label": "Solopreneur"},
  {"key": "CO", "label": "Copywriter"},
  {"key": "AO", "label": "Agency Owner"},
  {"key": "AG", "label": "Work for an Agency"},
  {"key": "ST", "label": "Work for a Startup Studio"},
  {"key": "EA", "label": "Work at an early stage startup"},
  // {"key": "MI", "label": "Work at a mid-size company"},
  {"key": "LA", "label": "Work at a medium/large company"},
  {"key": "OT", "label": "Other"},
]
const redditKnowledgeOptions = [
  {"key": "LO", "label": "Low - Have never tried Reddit"},
  {"key": "ME", "label": "Medium - Familiar with Reddit"},
  {"key": "HI", "label": "High - I'm a Reddit pro"},
]
const learningStyleOptions = [
  {"key": "BP", "icon": "submission", "label": "Blog Posts"},
  {"key": "VI", "icon": "video", "label": "Videos"},
  {"key": "ST", "icon": "book", "label": "Stories"},
  {"key": "CH", "icon": "clipboard", "label": "Checklists"},
]

export {
    objectiveOptions,
    roleOptions,
    redditKnowledgeOptions,
    learningStyleOptions,
}