import React, { Fragment, useState, useEffect, useRef } from "react";

import { Dialog, Transition } from '@headlessui/react'

import { withRouter } from 'react-router-dom';


// note that this needs to have a focusable element in "children" otherwise there's a weird FocusTrap error
export default function Modal({children, close, widthClass}) {
  // const [open, setOpen] = useState(true)

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto mx-2 darkStyle"
        open={true}
        onClose={() => close()}  // don't need to close. controling modal open state in parent
      >
        <div className="flex items-end justify-center min-h-screen p2-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`inline-block bg-gray-800 text-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-4 align-middle w-full p-4 ${widthClass || 'max-w-lg'}`}>
              { children }
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

