import React, { useState, useEffect, Fragment } from "react"
import { connect } from 'react-redux';

import {
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { ModalRoute, ModalContainer } from 'react-router-modal';
import { Helmet } from "react-helmet";
import {
  PlusIcon, InformationCircleIcon, ViewListIcon, ViewBoardsIcon, PlusCircleIcon,
} from '@heroicons/react/outline'


import PropsRoute from "../../components/common/propsroute";
import NavBar from "../../components/common/navbar";
import Layout from "../../components/layout";
import LoaderFancy from "../../components/common/loaderfancy";
import ErrorBar from "../../components/common/errorBar";
import ErrorBoundary from "../../components/common/errorboundary";

import NameAudience from "../../components/audiences/nameaudience"
import AudiencesNewModal from "../../components/audiences/audiencesNewModal"
import AudiencesInfoModal from "../../components/audiences/audiencesInfoModal"

import Audiences from "./audiences"
import AudiencesTrending from "./trending"
import AudiencesCurated from "./curated"

import { audiencesOperations } from "../../redux/audiences"

// import { audiencesOperations } from "../../redux/audiences"
// import { uiOperations } from "../../redux/ui"
// import { collectionsService } from '../../services/collections';



// audiences navigation page
const AudiencesRoot = ({
   history, location, currentUser,
   fetchingAudiences, audiences, fetchAudiences,
}) => {

  useEffect(() => {
    if (!fetchingAudiences && !audiences.length){
      fetchAudiences();
    }
  }, []); // Load up lists of all users audiences
  
  const tabs = [
    {'name': 'Saved', 'link': '/audiences/', exact: true},
    {'name': 'Curated', 'link': '/audiences/curated/'},
    {'name': 'Trending', 'link': '/audiences/trending/'},
  ]

  // const isStarterSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "starter");
  // const isProSubscriber = currentUser && currentUser.subscription && (currentUser.subscription.key === "pro");
  // const isEffectiveStarterSubscriber = isStarterSubscriber || isProSubscriber || currentUser.trial.plan === 'pro';

  return (
    <Layout currentUser={currentUser} >
      <Helmet><title>Audiences</title></Helmet>
      
      <ErrorBoundary>
        <div>
          <div className="flex items-center sm:-mt-3 pb-2">
            <div className="flex-1 min-w-0">
              <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">Audiences</h2>
            </div>
            <div className="ml-auto flex lg:mt-0">
              
              {true || audiences && audiences.length ? (
                <React.Fragment>
                  <Link to="/audiences/info/"
                    type="button"
                    className="appearance-none inline-flex items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                  >
                    <InformationCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    Info
                  </Link>

                  <Link to="/audiences/new/"
                    type="button"
                    className="appearance-none ml-3 inline-flex items-center px-4 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-500 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                  >
                    <PlusCircleIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    New
                  </Link>
                </React.Fragment>
              ) : ''}
            </div>
          </div>

          <div className="border-b border-solid border-gray-800">
            <NavBar tabs={tabs}/>
          </div>
          <div className="pt-8">
            <Switch>
              <PropsRoute path="/audiences/curated/" component={AudiencesCurated} currentUser={currentUser} audiences={audiences}/>
              <PropsRoute path="/audiences/trending/" component={AudiencesTrending} currentUser={currentUser} />
              <PropsRoute path="/audiences/" component={Audiences} currentUser={currentUser} />
              {/*<Redirect exact path="/account/" to="/account/subscription/" />*/}
            </Switch>
          </div>

        </div>

        <ModalRoute
          path={`/audiences/new/`}
          parentPath={`/audiences/`}
          component={AudiencesNewModal} />
        <ModalRoute
          path={`/audiences/info/`}
          parentPath={`/audiences/`}
          component={AudiencesInfoModal} />
        <ModalContainer />

      </ErrorBoundary>
    </Layout>
  );
}

const mapStateToProps = state => {
  const {
    audiences,
    fetchingAudiences,
  } = state.audiences;

  return {
    audiences,
    fetchingAudiences,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchAudiences = (callback, onError) => {
    dispatch(audiencesOperations.fetchAudiences(callback, onError))
  };
  // const setUiState = (key, value) => {
  //   dispatch(uiOperations.setUiState(key, value))
  // };
  return {
    fetchAudiences,
    // setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudiencesRoot);

