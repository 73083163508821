import React, { useState, useEffect } from "react";
import {
  Link, useHistory
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Popover } from 'react-tiny-popover'

import { redditService } from '../../services/reddit';
import { velocityToString } from '../../utils/text';
import { trackEvent } from '../../utils/tracking';
import { toQueryString } from '../../utils/urls';

import PreviewRedditSubmission from "../reddit/submissionpreview";


const SubredditSubmissions = ({ subreddit, hasPro, showRedirect, trackRedirect }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [submissions, setSubmissions] = useState(null);
  const [showCount, setShowCount] = useState(7);

  useEffect(() => {
    setLoading(true)
    redditService.fetchSubredditSubmissions(subreddit.name, (response) => {
      setSubmissions(response.data.results)
      setLoading(false)
    }, (error, response) => {
      console.error(response)
      setLoading(false)
    });
  }, []);

  if (loading || !submissions){
    return (
      <div className="flex items-center text-center h-96 justify-center">
        <svg className="animate-spin h-6 block mx-auto w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    )
  }

  return (
    <div className="text-sm pr-2">
      {loading ? (
        <div className="flex items-center text-center h-full justify-center">
          <svg className="animate-spin h-6 block mx-auto w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      ) : (
        <div>

          <div className="mb-4">
            <dt className="flex items-center">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide flex items-center">
                Search Keyword

                {!hasPro ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 h-5 w-5 text-yellow-400">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                  </svg>
                ) : ''}
              </h3>
            </dt>
            <div>
              <input id="subreddit-keyword-search" type="text" placeholder="Advanced search in this subreddit" autoComplete="off" spellCheck="off"
                className="bg-gray-700 focus:bg-gray-600 mt-1 w-full text-sm border-transparent px-2 py-1 placeholder-gray-500 rounded-sm focus:border-transparent focus:ring-0"
                onKeyDown={(event) => {
                  if(event.key === 'Enter') {
                    const value = event.target.value;
                    if (value && value !== ''){

                      const querystring = toQueryString({
                        'type': 'submissions',
                        'keyword': encodeURIComponent(value.trim()),
                        'subreddits': subreddit.name,
                      })
                      const url = `/reddit/${querystring}`; 

                      // go to the url
                      history.push(url)

                      // callback to track the action
                      trackEvent("Transition", {
                        "from": "Collection Subreddit",
                        "to": "Reddit Submissions",
                        "keyword": value ? value.trim() : '',
                        "from_suggestion": "no",
                        "suggestion_type": '',
                        "count_subreddits": 1,
                      }); 
                    }      
                  }
                }}
              />
            </div>
          </div>

          <dt className="flex items-center">
            <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
              Popular Posts
            </h3>
            <ReactTooltip id={`submissions-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
            <span className="cursor-pointer ml-2" data-for='submissions-info' data-tip="Popular conversations in this community from the past month">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
          </dt>
          <ul className="divide-y divide-gray-800">
            {submissions.filter((s, i) => i < showCount).map(item => (
              <React.Fragment key={item.id}>
                <PreviewRedditSubmission item={item} showMetadata={true} showRedirect={showRedirect} trackRedirect={trackRedirect} />
              </React.Fragment>
            ))}
          </ul>

          {submissions.length > showCount ? (
            <div className="py-1 mb-2 text-xs text-gray-400 hover:text-gray-200 rounded-sm flex space-x-1 cursor-pointer"
              onClick={() => setShowCount(submissions.length)}
            >
              {/*<span>+ {Math.min(SUBREDDIT_COUNT_INCREMENT, allSubreddits.length - subredditsToShow.length)} more</span>*/}
              <span>+ show more</span>
            </div>
          ) : ''} 

          {submissions.length === 0 ? (
            <div className="opacity-25">
              None found...<br/>
              This subreddit is probably inactive
            </div>
          ) : ''}
        </div>
      )}
    </div>
  );
}

export default SubredditSubmissions;
