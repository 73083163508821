import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { ModalRoute, ModalContainer } from 'react-router-modal';
import { toast, ToastContainer } from 'react-toastify';
import copy from 'copy-to-clipboard';

import Loader from "../../components/common/loader";
import ReportModal from '../../components/account/ReportModal';

import { SUGGESTIONS } from '../../components/audiences/constants/suggestions';
import { searchesService } from '../../services/searches';
import { capitalizeFirst } from '../../utils/text';


const ReportsPage = ({history, match, currentUser}) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const limitIncrement = 10;
  const [limit, setLimit] = useState(limitIncrement);
  const [selectedReport, setSelectedReport] = useState(null);

  // useEffect(() => {
  //   fetchReports();
  // }, []);

  useEffect(() => {
    fetchReports();
  }, [limit]);

  const fetchReports = () => {
    setLoading(true);
    const params = { 'limit': limit };
    searchesService.recentAnalysisSearches(params, (response) => {
      setLoading(false);
      setReports(response.data)
    }, (error) => {
      console.error(error)
      setLoading(false)
    });
  }

  const handleViewReport = (report) => {
    setSelectedReport(report);
  };

  const handleShareReport = (reportId) => {
    const shareLink = `${window.location.origin}/report/${reportId}`;
    navigator.clipboard.writeText(shareLink);
    alert('Report link copied to clipboard!');
  };

  const getReportTitle = (report) => {
    const type = getReportType(report);
    if (type === 'Patterns') {
      var title = ""
      if (report.params_results.suggestion) {
        const suggestion = SUGGESTIONS.find(s => s.slug === report.params_results.suggestion);
        title += suggestion.name
      } else if (report.params_results.topic) {
        title += `${capitalizeFirst(report.params_results.topic)}`
      } else if (report.params_results.keyword) {
        title += `${report.params_results.keyword}`
      } else if (report.params_results.sort) {
        title += report.params_results.sort
      }
      return title;
    }
    if (type === 'Ask') {
      return `"${report.params_analysis.ask_question}"`;
    }
    return `Unknown Title`;
  }

  const getReportType = (report) => {
    if (report.params_analysis && report.params_analysis.reports_bulk && report.params_analysis.reports_bulk.includes('clusters')) {
      return 'Patterns';
    }
    if (report.params_analysis && report.params_analysis.reports_bulk && report.params_analysis.reports_bulk.includes('ask')) {
      return 'Ask';
    }
    if (report.params_analysis && report.params_analysis.reports_bulk && report.params_analysis.reports_bulk.includes('summary')) {
      return 'Summary';
    }
    return 'Unknown';
  }

  const getReportResultsTitle = (report) => {
    const type = getReportType(report);
    if (type === 'Patterns') {
      return report.results.all.clusters.clusters.length + ' Pattern' + (report.results.all.clusters.clusters.length === 1 ? '' : 's');
    }
    if (type === 'Ask') {
      return report.results.all.ask.citations.length + ' Citation' + (report.results.all.ask.citations.length === 1 ? '' : 's');
    }
    if (type === 'Summary') {
      return 'Summary';
    }
    return '?';
  }

  return (
    <div className="my-8 pb-8">
      <Helmet><title>Account | Reports</title></Helmet>
      
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6">Reports</h3>
              <p className="mt-2 text-sm text-gray-400">
                This is where you can view all the reports you have run. Each report contains Patterns/Ask analysis results.
              </p>
            </div>
          </div>
          <div className=" md:mt-0 md:col-span-2">
            <div className="shadow rounded-md overflow-hidden">
              <div className="p-4 bg-gray-800 space-y-6 sm:p-6">
                <div className="flex">
                  {loading && !reports.length ? (
                    <div><Loader /></div>
                  ) : reports.length ? (
                    <div className="overflow-x-auto w-full">
                      <ReactTooltip id={`reports-tooltip`} place="top" effect="solid" backgroundColor="white" textColor="black" />
                      <table className="divide-y divide-gray-700">
                        <thead>
                          <tr>
                            <th className="pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Report</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View Results</th>
                            <th className="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Share Link</th>
                          </tr>
                        </thead>
                        <tbody className="bg-gray-800 divide-y divide-gray-700">
                          {reports.map((report) => (
                            <tr key={report.hash}>
                              <td className="pr-6 py-4 whitespace-nowrap text-sm text-gray-500 flex items-center">
                                {getReportType(report) === 'Patterns' && (
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mr-1" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                                  </svg>
                                )}
                                {getReportType(report) === 'Ask' && (
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mr-1" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                                  </svg>
                                )}
                                {getReportType(report) === 'Summary' && (
                                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block mr-1" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                                    <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd" />
                                  </svg>
                                )}
                                <span className="text-gray-300">{getReportType(report)}</span>
                              </td>
                              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-300 max-w-md truncate overflow-hidden text-ellipsis"
                                // data-for='reports-tooltip' data-tip={getReportTitle(report)}
                              >
                                <h3 className="text-gray-300 truncate overflow-hidden text-ellipsis">{getReportTitle(report)}</h3>
                                {report.params_results.subreddits ? (
                                  <div className="text-gray-500 truncate overflow-hidden text-ellipsis"
                                    data-for='reports-tooltip' data-tip={report.params_results.subreddits && report.params_results.subreddits.split(',').length > 3 && report.params_results.subreddits.split(',').map(sub => `r/${sub}`).join(', ') || ''}
                                  >
                                    {`${report.params_results.subreddits.split(',').slice(0, 3).map(sub => `r/${sub}`).join(', ')}`}
                                    {report.params_results.subreddits.split(',').length > 3 && 
                                      ` + ${report.params_results.subreddits.split(',').length - 3}`
                                    }
                                  </div>
                                ) : ''}
                              </td>
                              
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">{report.create_date}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                <Link to={`/account/reports/${report.hash}/`} className="text-sm font-semibold py-1 px-2 rounded-md hover:opacity-90 border text-white bg-cyan-600 border-transparent">
                                  {getReportResultsTitle(report)}
                                </Link>
                              </td>
                              <td className="pl-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {report.shared_report_enabled && report.shared_report_hash ? (
                                  <button onClick={() => {
                                    copy(`${currentUser.env.share_page_url}/report/${report.shared_report_hash}/`);
                                    toast.success('Link copied to clipboard');
                                  }} className="flex items-center  text-cyan-400 hover:text-cyan-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 mr-2">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                                    </svg>
                                    Copy Link
                                  </button>
                                ) : (
                                  <Link to={`/account/reports/${report.hash}/?share=1`} className="flex items-center text-gray-500 hover:text-gray-400 hover:underline">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 mr-2">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244" />
                                    </svg>
                                    Share
                                  </Link>
                                )}
                              </td>
                              
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {loading || (reports.length === limit) ? (
                        <div className="flex">
                          <button disabled={loading} onClick={() => {
                            setLimit(limit + limitIncrement);
                          }} className="text-gray-400 hover:text-gray-300 text-sm hover:underline mx-auto">
                            {loading ? 'Loading...' : 'Load More'}
                          </button>
                        </div>
                      ) : ''}

                      <ModalRoute
                        path={`/account/reports/:hash/`}
                        parentPath={`/account/reports/`}
                        component={ReportModal}
                        props={{
                          reports,
                          getReportTitle,
                          getReportType,
                          getReportResultsTitle,
                          closeModal: () => history.push('/account/reports/')
                        }}
                      />
                      <ModalContainer />
                    </div>
                  ) : (
                    <div>No reports yet...</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ReportsPage;