import React, { useState, useEffect, Fragment } from "react"
import { connect } from 'react-redux';

import {
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
// import { ModalRoute, ModalContainer } from 'react-router-modal';
import { Helmet } from "react-helmet";
// import {
//   PlusIcon, InformationCircleIcon, ViewListIcon, ViewBoardsIcon,
// } from '@heroicons/react/outline'


import PropsRoute from "../../components/common/propsroute";
import NavBar from "../../components/common/navbar";
import Layout from "../../components/layout";
import LoaderFancy from "../../components/common/loaderfancy";
import ErrorBar from "../../components/common/errorBar";
import ErrorBoundary from "../../components/common/errorboundary";

import PublicAudiences from "../../components/audiences/publicaudiences"
// import NameAudience from "../../components/audiences/nameaudience"
// import AudiencesNewModal from "../../components/audiences/audiencesNewModal"
// import Top40InfoModal from "../../components/top/topInfoModal"
// import Top40List from "../../components/top/list"

// import { audiencesOperations } from "../../redux/audiences"

// import { audiencesOperations } from "../../redux/audiences"
// import { uiOperations } from "../../redux/ui"
// import { collectionsService } from '../../services/collections';



// top subreddits page
const AudiencesCurated = ({
   history, location, currentUser, audiences
}) => {
 
  return (
    <div>
      <Helmet><title>Audiences | Curated</title></Helmet>
      <div className="mb-2 flex-wrap">
        <h2 className="text-lg font-medium flex items-center flex-wrap">
          <span>Curated Audiences</span>
        </h2>
        {/*<p className="text-gray-500 truncate text-sm">
          Select to save and start exploring
        </p>*/}
      </div>
      <PublicAudiences showIcons={true} noPublicScroll={true} userAudiences={audiences}
        gridClass="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6" cardClass="bg-gray-800 hover:bg-gray-700"
      />
    </div>
  );
}


export default AudiencesCurated;
