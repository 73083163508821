import React, { useState, useEffect } from "react";

import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import Markdown from 'markdown-to-jsx'
import { Dialog, Transition, Menu, Popover } from '@headlessui/react'

import LoaderFancy from "../common/loaderfancy";
import Loader from "../common/loader";
// import SelectPopover from "../common/selectPopover";
import ErrorBar from "../common/errorBar";
import ProgressRing from "../common/progressring";

import Keyword from "../language/keyword";
import Sentiment from "../language/sentiment";
import Topic from "../language/topic";
import Emotion from "../language/emotion";
import Highlight from "../language/highlight";
import AnalysisConversation from "../language/analysisConversation"
import FeaturePaywallWrapper from "../account/featurePaywallWrapper"
import AiMinutePopover from "../language/aiminutepopover"


import { redditService } from '../../services/reddit';
import { profileService } from '../../services/profile';
import { wordsToMinutes } from '../../utils/language';

// import { styleKeyword } from '../../utils/text';
// import { conversationContainsPhrase } from '../../utils/reddit';
// import { timeStampToFullTime } from '../../utils/time';

// analyzes submission (and its comments)
const SubmissionAnalyze = ({
  submission, currentUser, //keyword
  // comments, topLevelComments, fetchComments, loading

  comments, 
  topLevelComments, 
  fetchComments, 
  loadingComments,
}) => {
  const [estimating, setEstimating] = useState(false);
  const [estimate, setEstimate] = useState(null);
  const [analyzing, setAnalyzing] = useState(false);
  const [analysis, setAnalysis] = useState(null);
  const [error, setError] = useState(null);
  const [loadingLimits, setLoadingLimits] = useState(false); 
  const [limits, setLimits] = useState(null); 
  const [showSubmission, setShowSubmission] = useState(false);
  const tooShortToAnalyze = estimate && estimate.token_count < 50;
  const analysisParams = {
    'reports_bulk': ['summary'],
    'reports': [
      // 'summary',
      // 'topics',

      // 'sentiments',
      // 'keywords',
      // 'entities',

      // 'highlights',
      // 'emotions',
    ],
    // 'summary_include_comments': true,
  };

  const showSettings = true;
  const countCommentsOptions = [10, 20, 50, 100]; // how many top comments we include
  const [countCommentsToAnalyze, setCountCommentsToAnalyze] = useState(countCommentsOptions[1]); // start with 20 
  const commentsToAnalyze = comments
    .sort((a, b) => b.score - a.score)
    .filter(c => c.author !== 'u/AutoModerator') // remove automoderator
    .filter(c => c.author !== submission.author) // remove replies from the author
    .filter(c => c.score >= 1) // remove downvoted ones
    .slice(0, countCommentsToAnalyze);
  const resultsParams = {"type": "reddit-all", ids: [
    submission.id,
    ...commentsToAnalyze.map(c => c.id)
  ]}

  // const sortOptions = ['Upvotes', 'Recent', 'Replies'];
  // const [sortOption, setSortOption] = useState(sortOptions[0]);

  useEffect(() => {
    // fetch comments if the post has them and we haven't fetched yet
    if (!comments.length && !loadingComments && submission && submission.num_comments){
      fetchComments()
    }
  }, [comments])

  useEffect(() => {
    // fetch estimate if we have comments, or if post has no comments
    if (submission && !estimate && (comments.length || !submission.num_comments)){
      fetchEstimate()
    }
  }, [submission && submission.id, comments]);

  useEffect(() => {
    if (estimate && !estimating){
      fetchEstimate()
    }
  }, [countCommentsToAnalyze]); // refetch estimate due to comment limit change

  // actions
  const fetchEstimate = () => {
    if (estimating) return;

    // fetch estimate for the analysis
    setEstimating(true)
    setAnalyzing(false)
    setEstimate(null)
    setAnalysis(null)
    setError(null)
    redditService.analyzeConversationsEstimate(resultsParams, analysisParams, (response) => {
      setEstimating(false)
      setEstimate(response.data)
      getSubscriptionLimits();
    }, (error, response) => {
      setEstimating(false)
      setError('Error estimating analysis')
    });
  }

  const fetchAnalysis = () => {
    if (analyzing) return;

    // summary length is based on how long the original post is
    // const maxSummaryLength = Math.max(10, parseInt(estimate.token_count / 3));

    // fetch estimate for the analysis
    setAnalyzing(true)
    setAnalysis(null)
    setError(null)
    redditService.analyzeConversations(resultsParams, Object.assign(analysisParams, {'estimate_hash': estimate.estimate_hash}), (response) => {
      setAnalyzing(false)
      setAnalysis(response.data.results['all'])
    }, (error, response) => {
      console.error(response)
      setAnalyzing(false)
      setError('Error running analysis')
    });
  }

  const fetchPastAnalysis = (hash) => {
    if (analyzing) return;

    // fetch estimate for the analysis
    setAnalyzing(true)
    setAnalysis(null)
    setError(null)
    redditService.getPastAnalysis(hash, (response) => {
      setAnalyzing(false)
      setAnalysis(response.data.results['all'])
    }, (error, response) => {
      console.error(response)
      setAnalyzing(false)
      setError('Error fetching past analysis')
    });
  }


  const getSubscriptionLimits = () => {
    if (loadingLimits) return;

    // fetch the limits & current usage of the account
    setLoadingLimits(true)
    profileService.getLimits((response) => {
      setLimits(Object.assign(
        response.data,
        {
          'percent_ai_minutes_used': Math.min(100 * response.data.ai_minutes_used / response.data.ai_minutes_allowed, 100)
        }
      ));
      setLoadingLimits(false)
    })
  }

  const renderCost = () => {
    // render cost vis & popover (used twice)
    if (!estimate || !limits) return '';

    return (
      <AiMinutePopover limits={limits} minutes={wordsToMinutes(estimate.token_count)} >
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-0.5 mr-1 opacity-50">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <div className="mr-1 text-xs opacity-50">AI Minutes: </div>
          <div className="mr-1 relative rounded-full bg-gray-800 border-2 border-gray-700">
            {limits ? (
              <ProgressRing
                radius={14} stroke={2}
                color={limits.percent_ai_minutes_used >= 100 ? '#f59e0c' : '#0a91b1'}
                progress={limits.percent_ai_minutes_used}
              />
            ) : ''}
            
            <span className="text-xs w-full justify-center absolute top-2 flex items-center">
              <span>{wordsToMinutes(estimate.token_count)}</span>
            </span>
          </div>
        </div>
      </AiMinutePopover>
    )
  }

  const reset = () => {
    setEstimate(null);
    setAnalysis(null);
    fetchEstimate();
  }

  return (
    <div className="pt-4 mr-2">
      <FeaturePaywallWrapper featureKey={"submission-summary"} className={'mx-auto'} currentUser={currentUser}>
        {loadingComments ? (
          <div className="h-96 relative p-4 rounded-lg">
            <LoaderFancy />
          </div>
        ) : estimating ? (
          <div className="w-full flex p-4 bg-gray-800 rounded-lg">
            <div>
              <span className="mr-2">Summarize post & top {commentsToAnalyze.length} comments</span>
              <span className="hidden sm:block opacity-50 text-sm">
                Estimating...
              </span>
            </div>
            <div className="ml-auto p-2"><Loader /></div>
          </div>
        ) : analyzing ? (
          <div className="h-96 relative p-4 rounded-lg">
            <div className="h-48 relative">
              <LoaderFancy message={estimate && estimate.loading_time ? `Hang tight, this should take 5-10 seconds` : ''}
                special={true}
              />
            </div>
          </div>
        ) : estimate && !analysis ? (
          <div className="w-full p-4 bg-gray-800 rounded-lg">
            <div className="flex items-center">
              <div>
                <div className="mr-2 flex items-center relative">
                  <div>Summarize post & top {commentsToAnalyze.length} comments</div>

                  {showSettings ? (
                    <Popover className="relative h-5 left-1">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`flex-shrink-0 inline-flex items-center justify-center ml-1 focus:outline-none focus:ring-transparent focus:border-none rounded-lg text-gray-500 hover:text-gray-300`}
                          >
                            <span className="sr-only">Settings</span>
                            <svg  className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>

                          </Popover.Button>

                          <Transition
                            show={open}
                            as={React.Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel static className={`absolute left-10 -top-2 z-50 w-72 bg-white text-black p-4 rounded-md overflow-hidden shadow-lg bg-white flex flex-col space-y-4`}>
                              {countCommentsOptions ? (
                                <div>
                                  <div className="text-sm opacity-50 mb-1">
                                    <div className="uppercase font-semibold ">Count Comments</div>
                                    <div className="text-xs">Increase to get more context in the summary. The following are excluded in comment context:</div>
                                    <ul className="mt-2 text-xs list-disc pl-4">
                                      <li>Auto comments by u/AutoModerator</li>
                                      <li>Low-quality comments with {`<1`} karma</li>
                                      <li>Comment replies by OP</li>
                                    </ul>
                                  </div>
                                  
                                  <div className="flex">
                                    {countCommentsOptions.map(s => (
                                      <div key={s} className="relative py-2 flex items-center ml-2 mr-4">
                                        <div className="flex items-center h-5">
                                          <input id={`limit-${s}`} name="limit" type="radio"
                                            className="focus:ring-cyan-500 h-4 w-4 text-cyan-600 cursor-pointer border-gray-800 bg-gray-600"
                                            checked={parseInt(countCommentsToAnalyze) === s} onChange={(e) => setCountCommentsToAnalyze(s)}
                                          />
                                        </div>
                                        <label htmlFor={`limit-${s}`} className="ml-2 cursor-pointer">
                                          <span className="block text-sm font-medium flex items-center">{s}</span>
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ) : ''}
                              
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  ) : ''}
                </div>
                <span className="hidden sm:block opacity-50 text-sm">
                  Save {parseInt(estimate.token_count / 240) || 1} min by not reading {estimate.token_count} words
                </span>
              </div>
              <div className="ml-auto">
                
                {!estimate.past_analysis ? (
                  <div className="flex items-center space-x-2">

                    { renderCost() }

                    <button type="button" className="inline-flex bg-green-500 px-3 py-3 shadow-sm text-sm text-white font-medium rounded-md opacity-90 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                      onClick={fetchAnalysis}
                      disabled={tooShortToAnalyze}
                    >
                      {/*<span>{wordsToMinutes(estimate.token_count)}</span>*/}
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mx-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                      </svg>
                      {tooShortToAnalyze ? (
                        <span className="ml-2">Too Short</span>
                      ) : (
                        <span className="ml-2">Summarize</span>
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center space-x-2">
                    { renderCost() }

                    <button type="button" className="flex items-center bg-gray-700 px-3 py-3 shadow-sm text-sm text-white font-medium rounded-md opacity-90 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                      onClick={fetchAnalysis}
                      disabled={tooShortToAnalyze}
                    >
                      {/*<span>{wordsToMinutes(estimate.token_count)}</span>*/}
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mx-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                      </svg>

                      <span className="ml-2">Re-calculate</span>
                    </button>

                    <button type="button" className="inline-flex bg-cyan-500 px-3 py-3 shadow-sm text-sm text-white font-medium rounded-md opacity-90 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                      onClick={() => fetchPastAnalysis(estimate.past_analysis.hash)}
                      // disabled={tooShortToAnalyze}
                    >
                      {/*<svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>*/}
                      <svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                      </svg>

                      <span>View Result</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : !analyzing && analysis ? (
          <div className="rounded-lg pr-2">

            <h2 className="text-md font-sm mb-3 flex items-center">
              <span className="opacity-50">Summary</span>

              <div className="flex items-right w-full space-x-2">
                {reset ? (
                  <button type="button" className="ml-auto bg-gray-700 flex inline-block px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md opacity-100 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={reset}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-1 mr-2 h-5 w-5 text-white">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                    </svg>
                    <span>Reset</span>
                  </button>
                ) : ''}

                {analysis.summary ? (
                  <button type="button" className="bg-gray-700 flex inline-block px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md opacity-100 hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    onClick={() => {
                      copy(analysis.summary);
                      toast.success(`Summary copied`)
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 mr-2 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                    <span>Copy</span>
                  </button>
                ) : ''}
              </div>
            </h2>

            <div className={`bg-gray-800 text-lg rounded-lg p-4`}>
              

              {analysis.summary ? (
                <div className="text-lg flex">
                  <Markdown options={{
                    wrapper: 'article',
                    overrides: {
                      //  InlineCitation: {
                      //   component: InlineCitation,
                      // },
                      p: {
                        props: {
                          className: 'mb-4',
                        },
                      },
                      ol: {
                        props: {
                          className: 'list-decimal pl-6',
                        },
                      },
                    },
                  }}>
                    {analysis.summary}
                  </Markdown>
                </div>
              ) : (
                <div className="text-lg flex">
                  <div>No results...</div>
                </div>
              )}
            </div>

          </div>
        ) : ''}

        {error ? (
          <ErrorBar error={error} setError={setError}
            onRetry={estimate ? fetchAnalysis : fetchEstimate} // retry one that failed
          />
        ) : ''}  
      </FeaturePaywallWrapper>
    </div>
  );
}

export default SubmissionAnalyze;

