import React, { useState, useEffect } from "react"
import {
  Link
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Helmet } from "react-helmet";

import {
  CheckIcon, SelectorIcon
} from '@heroicons/react/outline';
import { Listbox } from '@headlessui/react'

import LayoutAuth from "../../components/auth/layout";

import { trialService } from "../../services/trial";

import { getParameterByName } from "../../utils/urls";
import { classNames } from '../../utils/tailwind';
import { trackEvent } from '../../utils/tracking';


// provides feedback to a trial
const TrialFeedback = ({history, location, match }) => {
  const [trial_hash] = useState(getParameterByName('trial_hash'));
  const [reason] = useState(getParameterByName('reason'));
  const [submittingInitial, setSubmittingInitial] = useState(false); 
  const [submittingEventual, setSubmittingEventual] = useState(false); 
  const [error, setError] = useState(null); 
  const [success, setSuccess] = useState(null); 
  const [clarification, setClarification] = useState(''); 
  const [anythingElse, setAnythingElse] = useState(''); 

  // effects
  useEffect(() => {
    if (!trial_hash){
      setError('Missing trial hash in url');
      return 
    }

    if (!reason){
      // no reason in URL, so no need to submit automatically. just exit out here
      // setError('Missing feedback in url');
      return 
    }

    if (submittingInitial) return;

    // go submit the feedback right away (to collect the initial reason)
    setSubmittingInitial(true);
    trialService.submitFeedback(
      {'reason': reason, 'trial_hash': trial_hash},
      (response) => {
        setSubmittingInitial(false);
        // setSuccess('Feedback submitted, thank you!');

        // this event is just for getting to the page (see "LeftTrialFeedback" for full feedback form)
        trackEvent("ClickedTrialFeedback", {
          "reason": reason,
          // "reason_display": selectedReason.label,
          // "added_clarification": clarification ? "yes" : "no",
          // "added_anything_else": anythingElse ? "yes" : "no"
        });
      },
      (error, response) => {
        setSubmittingInitial(false);
        setError(response);
        // setLoading(false);
      }
    )
  }, []);  // submit feedback directly when they click the link

  // actions

  // this is the action to submit ALL feedback
  const submitFeedback = (e) => {
    e.preventDefault();

    if (submittingEventual || !selectedReason) return;

    setSubmittingEventual(true);
    trialService.submitFeedback(
      {
        'reason': selectedReason.key,
        'trial_hash': trial_hash,
        'clarification': clarification,
        'anything_else': anythingElse,
      },
      (response) => {
        setSubmittingEventual(false);
        setSuccess('Feedback submitted, thank you!');
        trackEvent("LeftTrialFeedback", {
          "reason": selectedReason.key,
          "reason_display": selectedReason.label,
          "added_clarification": clarification ? "yes" : "no",
          "added_anything_else": anythingElse ? "yes" : "no"
        });
      },
      (error, response) => {
        setSubmittingEventual(false);
        setError(response);
        // setLoading(false);
      }
    )
  }

  // constants
  const allReasons = [
    {'key': 'EX', 'label': "I wasn't intenting to purchase, just browsing", 'clarification': "That's OK! What did you think? What was your favorite part?"},
    // {'key': 'UN', 'label': "I don't understand how to use it", 'clarification': "I'm sorry. What do you think could make it less confusing?"},
    {'key': 'MA', 'label': "My target audience isn't on Reddit", 'clarification': "Who are you looking for? If I find some relevant communities, would you want me to let you know?"},
    {'key': 'VA', 'label': "I didn't get enough value to justify the cost", 'clarification': "What kind of value would you need to have received in order for the price to be fair?"},
    {'key': 'MI', 'label': "Missing features I expected", 'clarification': "What do you think is missing?"},
    {'key': 'TE', 'label': "Too expensive for me", 'clarification': "What kind of value would you need to have received in order for the price to be fair?"},
    {'key': 'BT', 'label': "Bad timing, might consider later", 'clarification': "What needs to happen for the timing to be better for you?"},
    {'key': 'SH', 'label': "The trial was too short", 'clarification': "What would you have liked to achieve with more time?"},
    {'key': 'OT', 'label': "Other", 'clarification': 'Please tell me more...'},
  ]
  const [selectedReason, setSelectedReason] = useState(allReasons.filter(r => r.key === reason)[0]); 


  return (
    <LayoutAuth title="Trial Feedback" subtitle="" hideFooter={true}>

      <Helmet><title>Trial Feedback</title></Helmet>

      {submittingInitial ? (
        <div className="bg-gray-700 p-2 px-3 font-medium rounded-sm">
          Loading...
        </div>
      ) : error ? (
        <div className="bg-red-500 p-2 px-3 font-medium rounded-sm">
          {error}
        </div>
      ) : success ? (
        <div>
          <div className="bg-green-500 p-2 px-3 font-medium rounded-sm">
            {success}
          </div>
          <div className="mt-6 text-sm">
            <div className="">
              {/*<span className="opacity-75">Existing user? </span>*/}
              <Link to="/login/" className="hover:underline text-right font-semibold mr-auto">Sign in to your account</Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <form className="relative text-sm" onSubmit={submitFeedback}>   
            <div>
              <label className="text-xs uppercase font-semibold mb-1 block opacity-50">What did you think?</label>
              <Listbox value={selectedReason && selectedReason.key} onChange={(s) => {
                setSelectedReason(allReasons.filter(r => r.key === s)[0])
              }}>
                <div className="relative">
                  <Listbox.Button className="relative w-full cursor-pointer border border-solid border-gray-500 rounded-md shadow-sm pl-3 pr-6 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500">
                    <span className="block truncate">{selectedReason && selectedReason.label || 'Your feedback'}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    
                    {allReasons.map((a) => (
                      <Listbox.Option
                        key={a.key}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-cyan-600' : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-8 pr-4'
                          )
                        }
                        value={a.key}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {a.label}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-cyan-600',
                                  'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Listbox>
            </div>

            {selectedReason && selectedReason.clarification ? (
              <div className="mt-4">
                <label className="text-xs uppercase font-semibold mb-1 block opacity-50">{selectedReason.clarification}</label>
                <textarea
                  type="text" id="clarification"
                  name="clarification"
                  autoComplete="off" //autoFocus={isEffectiveProSubscriber ? "on" : ""}
                  className={`h-32 w-full text-sm cursor-pointer border border-solid bg-transparent border-gray-500 rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500`}
                  placeholder={`Give some more detail`}
                  value={clarification}
                  onChange={(e) => {
                    setClarification(e.target.value);
                  }}
                />
              </div>
            ) : ''}

            {selectedReason ? (
              <div className="mt-4">
                <label className="text-xs uppercase font-semibold mb-1 block opacity-50">Anything else I should know?</label>
                <textarea
                  type="text" id="anything-else"
                  name="anythingelse"
                  autoComplete="off" //autoFocus={isEffectiveProSubscriber ? "on" : ""}
                  className={`h-32 w-full text-sm cursor-pointer border border-solid bg-transparent border-gray-500 rounded-md shadow-sm text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500`}
                  placeholder={`Thank you for your thoughts!`}
                  value={anythingElse}
                  onChange={(e) => {
                    setAnythingElse(e.target.value);
                  }}
                />
              </div>
            ) : ''}

            <button
              type="submit" disabled={submittingEventual || !selectedReason}
              className="mt-4 mx-0 w-full text-sm text-center p-2 border border-solid border-transparent font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            >
              {submittingEventual ? 'Submitting' : 'Submit'} Feedback
            </button>
          </form>
        </div>
      )}

    </LayoutAuth>
  );
}

export default TrialFeedback;
