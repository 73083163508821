import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactTooltip from 'react-tooltip';

import { searchesService } from '../../services/searches';
import { collectionsService } from '../../services/collections';

const EmptySearch = ({ history }) => {

  const [recentSearches, setRecentSearches] = useState([]);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [loadingRecentSearches, setLoadingRecentSearches] = useState(false);
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState('');

  // effects
  useEffect(() => {
    setLoadingRecentSearches(true)
    searchesService.recentSearches({"search_type": "RS,RC"}, (results) => {
      if (results && results.data){
        setRecentSearches(results.data)
        setLoadingRecentSearches(false)
      }
    }, () => {
      setLoadingRecentSearches(false)
    })
  }, []); // Load up recent searches for Submissions & Comments

  useEffect(() => {
    collectionsService.getCollections((results) => {
      if (results && results.data){
        setCollections(results.data)
      }
    }, (response, error) => {

    })
  }, []); // Load up lists of collections

  // if (loadingRecentSearches) return '';

  return (
    <div className="flex-grow justify-center text-center block mx-4">
      <Helmet><title>Conversations | Reddit | Start</title></Helmet>
      
      {/* This element is to trick the browser into centering the modal contents. */}
      <span className="inline-block align-middle h-full" aria-hidden="true">&#8203;</span>
      <ReactTooltip id={`search-helper`} effect="solid" backgroundColor="white" textColor="black" style={{maxWidth: '200px'}}/>
            
      <div className="inline-block bg-gray-800 rounded-lg text-left shadow-xl transform transition-all my-8 align-middle max-w-lg w-full p-6">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-cyan-100">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-cyan-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-white mb-4">
              Search Reddit Conversations
            </h3>
            <div className="text-left text-justify">
                          
              <p className="text-sm text-white opacity-75">
                Search a <span className="border-dotted sm:border-b" data-for='search-helper' data-html={true}
                  data-tip="Start with a broad 1-2 word phrase, then get more specific. The best keywords to try are topics or pain points that your customers might talk about when they need your help.">
                  keyword
                </span> related to your product/business. We recommend searching within a <span className="border-dotted sm:border-b" data-for='search-helper' data-html={true}
                  data-tip="Collections are lists of relevant communities. Make a collection quickly by going to 'Discover Communities'">
                  collection
                </span>, as opposed to all of Reddit.
              </p>
              <p className="text-sm text-white opacity-75 mt-2">
                We'll show you conversations and you can <span className="border-dotted sm:border-b" data-for='search-helper' data-html={true}
                  data-tip="Saved conversations are great for making lists of people to reach out to.">
                  save them
                </span> if you'd like. Once you find relevant results, <span className="border-dotted sm:border-b" data-for='search-helper' data-html={true}
                  data-tip="Tracked keywords will ensure you don't miss new conversations with your keywords, we will notify you when they come up again.">
                  track the keyword
                </span> to stay in the loop
              </p>
              

            </div>
          </div>
        </div>

        <div className="mt-3 text-center sm:mt-5">
          <div className="text-left text-xs font-medium opacity-50 uppercase mb-1">Where to search</div>
          <select
            id="collection"
            name="collection"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-gray-700 border-gray-800 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm rounded-md"
            value={selectedCollection}
            onChange={(e) => {
              const hash = e.target.value;
              setSelectedCollection(hash);
              // if (hash){
              //   setTimeout(() => document.getElementById('subreddits').value = '', 10)
              // } else {
              //   setTimeout(() => document.getElementById('subreddits').focus(), 10)
              // }
            }}
          >
            <option value="">All of Reddit</option>
            {collections.map(c => (
              <option key={c.hash} value={c.hash}>{c.name} - {c.count_items} {c.count_items === 1 ? 'Subreddit' : 'Subreddits'}</option>
            ))}
            {collections.length === 0 ? (
              <option value="" disabled>No subreddit collections available</option>
            ) : ''}
          </select>
        </div>  

        <div className="mt-3 text-center sm:mt-5">
          <div className="text-left text-xs font-medium opacity-50 uppercase mb-1">Keyword</div>
          <input id="search" type="text" placeholder="Type a keyword and press enter" autoComplete="off" spellCheck="off"
              className="block bg-gray-700 w-full border-transparent p-2 placeholder-gray-500 rounded-sm focus:border-transparent sm:text-sm focus:ring-0"
              onKeyDown={(event) => {
                if(event.key === 'Enter') {
                  const value = event.target.value;
                  if (value && value !== ''){
                    history.push(`/reddit/?keyword=${value.trim()}&collection=${selectedCollection || ''}`)
                  }      
                }
              }}
            />
        </div>


        {showMoreOptions ? (
          <React.Fragment>
            {recentSearches && recentSearches.length ? (
              <React.Fragment>
                <div className="py-6 relative">
                  <hr className="opacity-10" />
                  <div className="absolute top-3 w-full text-center">
                    <span className="p-1 text-xs rounded-sm bg-gray-800 text-gray-600">OR</span>
                  </div>
                </div>

                <div>
                  <p className="text-sm text-white text-center mb-2">
                    Pick a recent search you've made
                  </p>
                  <div className="-mx-1">
                    {recentSearches.map(s => (
                      <div key={s.id} className="text-center cursor-pointer font-medium flex items-center mx-1 my-2 px-4 py-2 shadow-sm text-xs rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none"
                        onClick={() => {
                          var url = `/reddit/?keyword=${s.params.keyword}`;
                          if (s.params.collection){
                            url += `&collection=${s.params.collection}`
                          } else if (s.params.subreddits){
                            url += `&subreddits=${s.params.subreddits}`
                          }
                          // TODO: should we be factoring in comments??
                          history.push(url)
                        }}
                      >
                        {s.search_type === 'RS' ? (
                          <svg className="h-4 w-4 opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                          </svg>
                        ) : s.search_type === 'RC' ? (
                          <svg className="h-4 w-4 opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                          </svg>
                        ) : ''}
                        <span className="ml-1">
                          {s.params.keyword ? (
                            <span>"{s.params.keyword}"</span>
                          ) : s.params.sort === 'top' ? (
                            <span>Top {s.search_type === 'RS' ? 'Submissions' : 'Comments'}</span>
                          ) : s.params.sort === 'new' ? (
                            <span>New {s.search_type === 'RS' ? 'Submissions' : 'Comments'}</span>
                          ) : '?'}
                          
                        </span>
                        <span className="opacity-75 mx-1">in</span>
                        {s.params.collection ? (
                          <span className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-25 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                            </svg>{collections.filter(c => c.hash === s.params.collection)[0] && collections.filter(c => c.hash === s.params.collection)[0].name}
                          </span>
                        ) : !s.params.subreddits ? (
                          <span>all subreddits</span>
                        ) : s.params.subreddits.split(',').length === 1 ? (
                          <span>r/{s.params.subreddits}</span>
                        ) : (
                          <span>{s.params.subreddits.split(',').length} subreddits</span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ) : ''}

            {/*<div className="py-6 relative">
              <hr className="opacity-10" />
              <div className="absolute top-3 w-full text-center">
                <span className="p-1 text-xs rounded-sm bg-gray-800 text-gray-600">OR</span>
              </div>
            </div>

            <div>
              <p className="text-sm text-white text-center mb-2">
                Pick one of these for inspiration
              </p>
              <div className="flex space-2 flex-wrap">
                <Link className="flex-1 m-1" to="/reddit/?keyword=SaaS Marketing&example=1">
                  <button type="button" className="text-center w-full items-center px-4 py-2 shadow-sm text-xs font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                    SaaS Marketing
                  </button>
                </Link>
                <Link className="flex-1 m-1" to="/reddit/?keyword=Indie Hackers&example=1">
                  <button type="button" className="text-center w-full items-center px-4 py-2 shadow-sm text-xs font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                    Indie Hackers
                  </button>
                </Link>
                <Link  className="flex-1 m-1" to="/reddit/?keyword=Reddit Search&example=1">
                  <button type="button" className="text-center w-full items-center px-4 py-2 shadow-sm text-xs font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
                    Reddit Search
                  </button>
                </Link>
              </div>
            </div>*/}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="py-6 relative">
              <hr className="opacity-10" />
              <div className="absolute top-3 w-full text-center">
                <span className="p-1 text-xs rounded-sm bg-gray-800 text-gray-600">OR</span>
              </div>
            </div>

            <div>
              <div className="flex flex-wrap -mx-1">
                <button type="button" className="text-center font-medium flex-1 m-1 px-4 py-2 shadow-sm text-sm rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none"
                  onClick={() => setShowMoreOptions(true)}
                >
                  Show Recent Searches
                </button>
              </div>
            </div>
          </React.Fragment>
        )}

        
      </div>
    </div>
  );
}

export default EmptySearch;

