import React, { useEffect, useState } from "react"
import { connect } from 'react-redux';
import {
  Link
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import SubredditTags from "../reddit/subreddittags";
import LoaderFancy from "../common/loaderfancy";
import {
  InformationCircleIcon
} from '@heroicons/react/outline';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend, ResponsiveContainer } from 'recharts';

import { redditService } from '../../services/reddit';
import { readableNumber } from '../../utils/text';

import SubmissionTypeIcon from "../reddit/submissiontypeicon";
import SubredditPopover from "../reddit/subredditpopover";


const SubredditInfo = ({ subreddit, currentUser, audiences, collection }) => {

  const [showContents, setShowContents] = useState(false);

  const currentPlan = (currentUser.subscription && currentUser.subscription.key) || 'free';
  const isProSubscriber = currentPlan === 'pro';
  const isEffectiveProSubscriber = isProSubscriber || currentUser.trial.plan === 'pro';

  // map subreddit names to audiences
  var subredditAudienceDict = {};
  (audiences || []).forEach((a) => {
    a.subreddit_names.forEach((s) => {
      if (subredditAudienceDict[s]){
        subredditAudienceDict[s] += a.name
      } else {
        subredditAudienceDict[s] = [a.name]
      }
    })
  })

  // stupid delay b/c tooltips don't show up right away...
  useEffect(() => {
    setTimeout(() => {
      setShowContents(true)
    }, 50)
  }, []);

  // get the comparison data between this sub and the audience
  var comparisons  = []
  if (subreddit.calculated){
    const categories = [
      {'title': 'Age', 'key': 'percentile_age'},
      {'title': 'Size', 'key': 'percentile_members'},
      {'title': 'Activity', 'key': 'percentile_velocity'},
      // {'title': 'Engagement', 'key': 'percentile_per_user_comments'},
      {'title': 'Growth', 'key':  'percentile_growth_members_monthly'},
      // {'title': 'Moderation', 'key': 'percentile_moderation'},
      {'title': 'Reach', 'key': 'percentile_reach'},
    ]
    categories.forEach(c => {
      if (subreddit.calculated[c.key] !== null && collection.calculated[c.key] !== null){
        var comparison = {
          "title": c.title,
          "This Audience": collection.calculated[c.key],
          "fullMark": 100
        }
        comparison[`r/${subreddit.name}`] = subreddit.calculated[c.key]
        comparisons.push(comparison)
      }
    })
  }

  if (!showContents) return (
    <div></div>
  );  

  return (
    <div id="subreddit-info" className="min-h-full md:grid md:grid-cols-2 md:space-x-8">
      
      <div className="">
        <div className="mb-4">
          <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
            Established
          </h3>
          <div className="text-sm mt-1">
            {subreddit.created}
          </div>
        </div>

        <div className="mb-4">
          <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
            Size
          </h3>
          <div className="text-sm mt-1">
            {readableNumber(subreddit.count_users)} members
          </div>
        </div>

        {subreddit.description ? (
          <div className="mb-4">
            <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
              Description
            </h3>
            <div className="text-sm mt-1">
              {subreddit.description}
            </div>
          </div>
        ) : ''}

        {subreddit && subreddit.tags && subreddit.tags.length ? (
          <div className="mb-4">
            <div className="flex items-center">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                Distinctive Features
              </h3>
              <ReactTooltip id={`tag-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
              <span className="cursor-pointer ml-2" data-for='tag-info' data-tip="Things that stand out about this subreddit when comparing to all others tracked by GummySearch">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
            </div>
            <div className="text-sm mt-1">
              <SubredditTags subreddit={subreddit} tags={subreddit.tags} toolTipPlace="right" tooltipPrefix={'modal-'}/>
            </div>
          </div>
        ) : ''}

        {subreddit && subreddit.related_subreddits && subreddit.related_subreddits.length ? (
          <div className="mb-4" id="related-subreddits">
            <div className="flex items-center">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                Related Subreddits
              </h3>
              <ReactTooltip id={`related-subreddits-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
              <span className="cursor-pointer ml-2" data-for='related-subreddits-info' data-tip="Subreddits that participants in this community also tend to contribute to">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
            </div>
            <div className="text-sm mt-1">
              <div className="flex flex-wrap">
                {subreddit.related_subreddits.map(s => (
                  <SubredditPopover key={s} name={s} getPopoverParentElement={() => document.getElementById('subreddit-info')}>
                    <div className="px-2 py-1 flex space-x-1 items-center text-xs mr-1 mt-1 bg-gray-700 text-white rounded-sm flex items-center">
                      <span>r/{s.replace('r/', '')}</span>
                      {subredditAudienceDict[s.replace('r/', '')] ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-4 w-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      ) : ''}
                    </div>
                  </SubredditPopover>
                ))}
              </div>
            </div>
          </div>
        ) : ''}

      </div>

      <div>
        {comparisons && comparisons.length ? (
          <div className="mb-4" id="subreddit-comparisons">
            <div className="flex items-center">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                In Comparison
              </h3>
              <ReactTooltip id={`audience-comparison`} place="right" effect="solid" backgroundColor="white" textColor="black" />
              <span className="cursor-pointer ml-2" data-for='audience-comparison' data-tip="How this subreddit stacks up against average of others in your audience.">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
            </div>
            <div className="h-72 text-sm mt-1 relative">
              <ResponsiveContainer width="100%" height="100%">
                <RadarChart outerRadius={90} width={'100%'} height={'100%'} data={comparisons}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="title" stroke="rgb(156,163,175)" />
                  {/*<PolarRadiusAxis angle={30} domain={[0, 150]} />*/}
                  
                  <Radar name="This Audience" dataKey="This Audience" stroke="#3498db" fill="#3498db" fillOpacity={0.7} />
                  <Radar name={`r/${subreddit.name}`} dataKey={`r/${subreddit.name}`} stroke="#2ecc71" fill="#2ecc71" fillOpacity={0.5} />
                  <Legend />
                </RadarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : ''}
      </div>

      
    </div>
  );
}

const mapStateToProps = state => {
  const {
    audiences,
  } = state.audiences;

  return {
    audiences,
  }
};

const mapDispatchToProps = (dispatch) => {
  // const fetchAudiences = (callback, onError) => {
  //   dispatch(audiencesOperations.fetchAudiences(callback, onError))
  // };
  return {
    // fetchAudiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubredditInfo);


