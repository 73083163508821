import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import ConfettiExplosion from 'react-confetti-explosion';
import Layout from "../../components/layout";
import ErrorBoundary from "../../components/common/errorboundary";
import ProgressRing from "../../components/common/progressring";
import Loader from "../../components/common/loader";

import { trialService } from '../../services/trial';
import { authService } from "../../services/auth";
import { trialOperations } from "../../redux/trial"


const Trial = ({history, location, match, currentUser, fetchTrial}) => {
  const [loading, setLoading] = useState(false); 
  const [trial, setTrial] = useState(false); 
  const [isExploding, setIsExploding] = useState(false);

  // // effects
  useEffect(() => {
    fetchTrialDetails()
  }, []);

  const fetchTrialDetails = () => {
    // fetch the details on the user's trial (either credit or time)
    setLoading(true)
    fetchTrial((data) => {
      setLoading(false)
      setTrial(data)
    }, () => {
      setLoading(false)
    })
  }

  const initiateTrial = () => {
    setLoading(true)
    trialService.startTrial(() => {
      authService.fetchUser(() => {
        window.location.reload();  // should refetch user config and everything
      });
    })
  }

  const extendTrial = () => {
    setLoading(true)
    trialService.extendTrial(() => {
      fetchTrialDetails()
      setIsExploding(true)
      setLoading(false)
      authService.fetchUser(() => {
        // window.location.reload();  // should refetch user config and everything
      });
    })
  }

  if (!currentUser) return '';

  return (
    <Layout title="Trial" currentUser={currentUser} >
      <Helmet><title>Trial</title></Helmet>
      
      <div className="flex items-center sm:-mt-3 mb-6 pb-5 border-b  border-solid border-gray-800">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-white sm:text-3xl sm:truncate">Free Trial</h2>
        </div>
      </div>

      <ErrorBoundary>

        <div className="p-4 w-full lg:w-1/2 mx-auto">
          {currentUser.trial.exists ? (
            <React.Fragment>
              
              <h3 className="text-lg leading-6 font-medium mb-2 mt-8">Trial Status</h3>
              <div className="rounded-lg bg-gray-800 shadow p-6">
                {loading && !trial ? (
                  <Loader />
                ) : trial && trial.type === 'time' ? (
                  <React.Fragment>
                    <div className="">
                      {isExploding && !loading && <ConfettiExplosion />}
                      <div className="flex items-baseline font-semibold">
                        Status: <span className={`${trial.active ? 'text-green-400' : 'text-red-500'} ml-1`}>{trial.active ? 'Active' : 'Inactive'}</span>
                      </div>
                      {trial.active ? (
                        <div className="flex items-baseline font-semibold">
                          Expires: <span className="text-gray-300 ml-1">{trial.expires}</span>
                        </div>
                      ) : (
                        <div className="flex items-baseline font-semibold">
                          Expired: <span className="text-gray-300 ml-1">{trial.expires}</span>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <label className="text-base font-normal">
                    <span className="mr-2">No trial</span>
                  </label>
                )}

                <div className="mt-4">
                  <Link to="/account/subscription/"
                    className="mt-4 px-4 py-2 border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                  >
                    Browse Paid Plans
                  </Link>
                </div>
              </div>

              <h3 className="text-lg leading-6 font-medium mb-2 mt-8">Trial Extension</h3>
              <div className="rounded-lg bg-gray-800 shadow p-6">
                {loading && !trial ? (
                  <Loader />
                ) : trial && trial.type === 'time' ? (
                  <React.Fragment>
                    <div className="">
                      
                      <div className="flex items-baseline font-semibold">
                        Extension Available: <span className={`${!loading && trial.extension_available ? 'text-green-400' : 'text-gray-200'} ml-1`}>{trial.extension_available ? 'Yes' : 'No'}</span>
                      </div>
                      {!trial.extension_available && trial.extension_available_on ? (
                        <div className="flex items-baseline font-semibold">
                          Available On: <span className="text-gray-300 ml-1">{trial.extension_available_on}</span>
                        </div>
                      ) : ''}

                      <p className="text-gray-400 mt-2">If you trialed GummySearch at some point, and want to see the latest product updates, you may extend your trial.</p>
                      <p className="text-gray-400 mt-2">Trial extensions are available once every 90 days. To get one, press the "bonus button" below to get an extension of either 2, 3, or 7 days.</p>
                      

                      <div className="mt-2">
                        <button
                          onClick={extendTrial}
                          className="px-4 py-2 border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                          disabled={!trial.extension_available || loading}
                        >
                          Bonus Button
                        </button>
                        
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <label className="text-base font-normal">
                    <span className="mr-2">No trial</span>
                  </label>
                )}
              </div>

              <h3 className="text-lg leading-6 font-medium mb-2 mt-8">About the Trial</h3>
              <div className="rounded-lg bg-gray-800 shadow p-6">
                <label className="text-base font-normal">Welcome!</label>
                <div className="text-gray-400 mb-4 mt-2">
                  Thank you for signing up for GummySearch!
                  <br/><br/>
                  I'm excited to have you on as a user and help you accelerate your business using the power of online communities.
                  <br/><br/>
                  I'm happy to let you test drive the platform for free to make sure that it's a fit for you and your business.
                  <br/><br/>
                  So enjoy, and do reach out with feedback if you have it - I'm looking forward to hearing from you!
                  <br/><br/>
                  - Fed
                </div>

                <label className="text-base font-normal">How it works</label>
                <div className="text-gray-400 mb-4 mt-2">
                  The free trial gives you access to most of the the GummySearch Pro features.
                  <br/><br/>
                  {currentUser.features.trial_credit ? (
                    <span>You have a certain quantity of community & conversation searches. You can also save searches to come back to later.</span>
                  ) : (
                    <span>You have unlimited community & conversation searches for the duration of your trial. You can also save searches to come back to later.</span>
                  )}
                  <br/><br/>
                  {currentUser.features.trial_credit ? (
                    <span>When you run out of search credits, you won't be able to perform any more searches until you upgrade to a paid plan.</span>
                  ) : (
                    <span>When you run out of time, you won't be able to perform any more searches until you upgrade to a paid plan.</span>
                  )}
                  <br/><br/>
                  {/*<span>Once a month, trial users can get an extension of their trial, to see what the latest developments are with GummySearch.</span>*/}
                  
                </div>

                <label className="text-base font-normal">Upgrading</label>
                <div className="text-gray-400 mt-2">
                 <div>
                   GummySearch Starter is a cost-effective plan that allows you to discover online communities for your niche, and stay in tune with what they discuss. This is a great plan for startup founders getting started and looking for pre-validated problems to solve.
                   <br/><br/>
                   GummySearch Pro unlocks the advanced features of this platform and allows you track relevant conversations to your business in order to uncover new marketing opportunities. This is best for those validating solutions, looking for new customers from Reddit, or researching content opportunities.
                   <br/><br/>
                   Once you decide that a paid GummySearch plan is right for you, you can upgrade at any time and enjoy unlimited use of the platform.
                 </div>
                </div>

                <div className="mt-4">
                  <Link to="/account/subscription/"
                    className="mt-4 px-4 py-2  border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                  >
                    Go to Subscription Settings
                  </Link>
                </div>
              </div>
            </React.Fragment>
          ) : currentUser.subscription && currentUser.subscription.key ? (
            <div>
              <div>You don't have an active trial for GummySearch.</div>
              <div>This means that either your day pass expired or you have an active subscription.</div>
              <div>If you think something is wrong with your account, please email fed@gummysearch.com.</div>
            </div>
          ) : (
            <div>
              <div>You don't have a trial for GummySearch.</div>
              <div>You might be an OG beta user that had a credit-based trial, which is not available anymore.</div>
              <div>If you wish, you may start a time-based trial by clicking the button below.</div>
              <div className="mt-2">
                <button onClick={initiateTrial} disabled={loading}
                  className="mt-4 px-4 py-2  border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                >
                  Start Timed Trial
                </button>
              </div>
            </div>
          )}

        </div>
      </ErrorBoundary>
    </Layout>
  );
}

const mapStateToProps = state => {
  // const {
  //   used, total, fetching,
  // } = state.trial;

  return {
    // 'trialTotal': total,
    // 'trialUsed': used,
    // 'trialFetching': fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchTrial = (callback, onError) => {
    dispatch(trialOperations.fetchTrial(callback, onError))
  };
  return {
    fetchTrial,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trial);
