// reducers.js
import { combineReducers } from 'redux';

import types from './types';

const INITIAL_STATE = {
  fetching: false,
  status: null
}

const statusReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {

    case types.FETCH_STATUS: {
      return {
        ...state,
        fetching: true,
      }
    }

    case types.FETCH_STATUS_SUCCESS: {
      const { data } = action;
      return {
        ...state,
        status: data,
        fetching: false,
      }
    }

    case types.FETCH_STATUS_FAIL: {
      const { error } = action;
      return {
        ...state,
        fetching: false,
      }
    }

    default: return state;
  }
}

export default statusReducer;