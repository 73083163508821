import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactTooltip from 'react-tooltip';
import Layout from "../components/layout";
import ErrorBoundary from "../components/common/errorboundary";
import ProgressRing from "../components/common/progressring";
import Loader from "../components/common/loader";

import { statusOperations } from "../redux/status"
import { capitalizeFirst } from "../utils/text"


const Status = ({history, location, match, currentUser, status, fetching, fetchStatus}) => {


  if (!currentUser) return '';

  const slowThreshold = 8;
  const backends = Object.keys(status || {}).filter(k => k.includes('backend')).map((k) => {
    return {'name': capitalizeFirst(k.replace('backend_', '')), 'value': status[k]}
  });
  const features = Object.keys(status || {}).filter(k => k.includes('feature')).map((k) => {
    return {'name': capitalizeFirst(k.replace('feature_', '')), 'value': status[k]}
  });

  const faqs = [
    {'question': 'Why am I seeing a red/orange dot?', 'answer': "Some GummySearch features depend on external services, which occasionally incur downtime. If one of our external data sources is unhealthy, some functionality on GummySearch may not work as expected."},
    // {'question': 'What can I do about it?', 'answer': "If the primary data backend is down, please come back in a bit. If the secondary backend is still healthy, contact fed@gummysearch.com to use that one as your primary."},
    {'question': 'What can I do about it?', 'answer': "Please come back later. If you've experienced a lot of downtime and have paid for a subscription/trial, please contact fed@gummysearch.com and I'll try to make things right."},
  ]


  return (
    <Layout title="Service Status" headerTitle="Service Status" currentUser={currentUser} >
      <Helmet><title>Service Status</title></Helmet>
      <ErrorBoundary>
        <div className="p-4 w-full lg:w-1/2 mx-auto">

          <div className="rounded-lg bg-gray-800 shadow p-6">
            {fetching ? (
              <Loader />
            ) : status && Object.keys(status).length ? (
              <React.Fragment>
                <div>
                  <ReactTooltip id='status-info' effect="solid" backgroundColor="white" textColor="black" place="left" />
                  <label className="text-base font-normal">
                    <span className="mr-2 flex items-center">
                      Overall Health

                      <span className="opacity-50 font-bold ml-2 cursor-pointer" onClick={() => fetchStatus()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                      </span>
                    </span>
                  </label>
                  {/*<div className="">
                    {status['backend_primary'] ? (
                      <span className="items-baseline text-2xl font-semibold text-green-500">Healthy</span>
                    ) : !status['backend_primary'] ? (
                      <div>
                        <span className="items-baseline text-2xl font-semibold text-red-500">Major Outage</span>
                        <div className="opacity-50">Come back later. If the primary backend is still down, email <a href="mailto:fed@gummysearch.com" className="underline">fed@gummysearch.com</a>.</div>
                      </div>
                    ) : (
                      <span className="items-baseline text-2xl font-semibold text-gray-500">Unknown Status...</span>
                    )}
                  </div>*/}
                  <div className="">
                    {status['backend_primary'] && status['backend_secondary'] ? (
                      <span className="items-baseline text-2xl font-semibold text-green-500">Healthy</span>
                    ) : status['backend_primary'] && !status['backend_secondary'] ? (
                      <div>
                        <span className="items-baseline text-2xl font-semibold text-yellow-500">Mostly Healthy</span>
                        <div className="opacity-50">Non-core functionality might be slow or not working as expected</div>
                      </div>
                    ) : !status['backend_primary'] && status['backend_secondary'] ? (
                      <div>
                        <span className="items-baseline text-2xl font-semibold text-red-500">Major Outage</span>
                        <div className="opacity-50">Come back later. If the primary backend is still down, email <a href="mailto:fed@gummysearch.com" className="underline">fed@gummysearch.com</a>.</div>
                      </div>
                    ) : !status['backend_primary'] && !status['backend_secondary'] ? (
                      <div>
                        <span className="items-baseline text-2xl font-semibold text-red-500">Total Outage</span>
                        <div className="opacity-50">Please come back later.</div>
                      </div>
                    ) : (
                      <span className="items-baseline text-2xl font-semibold text-gray-500">Unknown Status...</span>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <label className="text-base font-normal">
                    <span className="mr-2">Features</span>
                  </label>
                  <div className="">
                    {features.map(b => (
                      <div key={b.name} className="flex items-center mt-1 text-base font-semibold text-cyan-200">
                        {b.value && b.value < slowThreshold ? (
                          <svg xmlns="http://www.w3.org/2000/svg" data-for='status-info' data-tip="Available" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        ) : b.value  ? (
                          <svg xmlns="http://www.w3.org/2000/svg" data-for='status-info' data-tip="Slow" className="h-6 w-6 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" data-for='status-info' data-tip="Unavailable" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        )}
                        <span className="text-sm font-medium text-gray-500 ml-2">{b.name}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mt-4">
                  <label className="text-base font-normal">
                    <span className="mr-2">Data Backends</span>
                  </label>
                  <div className="">
                    {backends.map(b => (
                      <div key={b.name} className="flex items-center mt-1 text-base font-semibold text-cyan-200">
                        {b.value && b.value < slowThreshold ? (
                          <svg xmlns="http://www.w3.org/2000/svg" data-for='status-info' data-tip="Healthy" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        ) : b.value  ? (
                          <svg xmlns="http://www.w3.org/2000/svg" data-for='status-info' data-tip="Slow" className="h-6 w-6 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        ) : (
                          <svg xmlns="http://www.w3.org/2000/svg" data-for='status-info' data-tip="Unhealthy" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        )}
                        <span className="text-sm font-medium text-gray-500 ml-2">{b.name}</span>
                      </div>
                    ))}
                  </div>

                  {currentUser.env.psaw_warning ? (
                    <div className="mt-2 max-w-xl text-sm flex items-top opacity-50">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2 flex-shrink-0">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                      </svg>

                      <p>{currentUser.env.psaw_warning}</p>
                    </div>
                  ) : ''}

                  {currentUser.env.praw_warning ? (
                    <div className="mt-2 max-w-xl text-sm flex items-top opacity-50">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2 flex-shrink-0">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                      </svg>

                      <p>{currentUser.env.praw_warning}</p>
                    </div>
                  ) : ''}
                </div>
              </React.Fragment>
            ) : (
              <div className="text-base font-normal">
                <div className="mb-2">
                  Can't get service status information... not a good sign.
                  <a onClick={() => fetchStatus()} className="font-bold cursor-pointer"> refetch</a>.
                </div>
                <div className="">Contact <a href="mailto:fed@gummysearch.com" className="font-bold cursor-pointer">fed@gummysearch.com</a> if this persists.</div>
              </div>
            )}
          </div>
        </div>

        <div className="max-w-3xl mx-auto px-4 sm:px-8">
          {/*<h2 className="text-3xl font-extrabold text-center">Frequently asked questions</h2>*/}
          <div className="mt-8">
            <dl className="">
              {faqs.map((faq) => (
                <div key={faq.question} className="space-y-2 mb-8">
                  <dt className="text-lg leading-6 font-medium">{faq.question}</dt>
                  <dd className="text-base text-gray-400">{faq.answer}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </ErrorBoundary>
    </Layout>
  );
}

const mapStateToProps = state => {
  const {
    status,
    fetching,
  } = state.status;

  return {
    status,
    fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchStatus = (callback, onError) => {
    dispatch(statusOperations.fetchStatus(callback, onError))
  };
  return {
    fetchStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Status);
