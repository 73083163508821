import React, { useState } from "react";
import moment from 'moment'

import ReactTooltip from 'react-tooltip';
import { 
  PieChart, Pie, BarChart, Bar, Cell, XAxis,
  YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, Brush, Sector
} from 'recharts';

import { velocityToString } from '../../utils/text';
import { toHistogram } from '../../utils/numbers';
import { timeStampToEasyTime, timeStampToFullTime } from '../../utils/time';



const AggregateVelocity = ({
  results,
  aggregates,
  filteredResults,
  frontendFilters,
  applyFrontendFilter,
  clearFrontendFilter,
  updating,
  hideSections,
}) => {

  // velocity
  const velocity = (aggregates && aggregates.velocity) || 0;
  const filteredRatio = filteredResults.length / results.length;
  const filteredVelocity = filteredRatio * velocity;
  const velicityIsFiltered = filteredVelocity !== velocity;
  const filteredVelocityPercent = velicityIsFiltered ? parseInt(filteredRatio * 100) : 0;

  // get the timestamps
  var timestamps = [];
  results.forEach(r => {
    const name = r.timestamp_utc;
    const index = timestamps.map(r => r.name).indexOf(name);
    index === -1 ? timestamps.push({'name': name, 'count': 1}) : timestamps[index]['count'] += 1
  });
  timestamps = timestamps.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 

  // turn timestamps into a histogram based on buckets
  var timestampToChart = []
  if (timestamps.length){
    const TIME_BUCKETS = timestamps.length >= 50 ? 50 : 20;
    // const TIME_BUCKETS = 20;
    const valuesOnly = timestamps.map(t => t.name)
    const maxTimestamp = valuesOnly[timestamps.length - 1] + 1;
    const minTimestamp = valuesOnly[0];
    const interval = parseInt((maxTimestamp - minTimestamp) / TIME_BUCKETS);
    const timestampHistogram = toHistogram(valuesOnly, TIME_BUCKETS, interval);
    timestampToChart = timestampHistogram.map((c) => {
      return {
        // 'label': c.isLast ? `${c.min}+ Upvotes` : (c.min === c.max - 1) ? `${c.min} Upvote${c.min === 1 ? '' : 's'}` : `${c.min} - ${c.max - 1} Upvote${c.min === 1 ? '' : 's'}`,
        'label': `${timeStampToEasyTime(c.min)} - ${c.isLast ? `now` : timeStampToEasyTime(c.max)}`,
        'min': c.min,
        'max': c.max,
        // 'count': c.count,
        'count': results.filter(r => r.timestamp_utc >= c.min && r.timestamp_utc < c.max).length,
        'filteredCount': filteredResults.filter(r => r.timestamp_utc >= c.min && r.timestamp_utc < c.max).length,
        'inFilters': frontendFilters && frontendFilters.timestamp_utc && frontendFilters.timestamp_utc.map(s => s.min).includes(c.min),
      }
    });
    timestampToChart = timestampToChart.map(c => {c.unfilteredCount = c.count - c.filteredCount; return c;})
  }

  // renders extra elements for velocity
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    // todo
    const isSelected = name === 'filtered';

    return (
      <g>
        <text x={cx} y={cy} dy={velicityIsFiltered ? -10 : 4} textAnchor="middle" fill={velicityIsFiltered ? 'rgb(75, 85, 99)' : '#fff'}
          textDecoration={velicityIsFiltered ? 'line-through' : '0'}
          fontSize={velicityIsFiltered ? '12' : '14'}
        >
          {velocityToString(velocity)}
        </text>
        {velicityIsFiltered ? (
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill={'#fff'} fontSize={'14'}>
            {velocityToString(filteredVelocity)}
          </text>
        ) : ''}
        
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={isSelected ? 'rgb(75, 85, 99)' : 'rgb(31 41 55)'}
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      </g>
    );
  };

  // custom tooltip used for timeseries chart!
  const CustomTooltip = ({ active, payload, min, max }) => {
    if (active && payload && payload.length) {
      const bin = payload[0];
      const data = bin.payload;

      var minDate = timeStampToEasyTime(data.min);
      var maxDate = timeStampToEasyTime(data.max);
      if (minDate === maxDate){
        minDate = timeStampToFullTime(data.min);
        maxDate = timeStampToFullTime(data.max);
      }
      return (
        <div className="p-2 bg-gray-800 text-xs text-white rounded-md">
          <div className="">
            From: {minDate}
          </div>
          <div className="">
            To: {maxDate}
          </div>
          <div>
            <span className="font-semibold mr-1">{bin.name}:</span>
            {data.filteredCount === data.count ? (
              <span>{data.count}</span>
            ) : (
              <React.Fragment>
                <span>{data.filteredCount}</span>
                <span> / {data.count}</span>
              </React.Fragment>
            )}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <div className="flex space-x-4 sm:space-x-8 mb-4">

        
        {/*{keyword && velocity > 10 ? (
          <div className="flex items-center mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
            </svg>
            <div className="text-xs text-gray-500 ml-1">Too many non-relevant results? Try making an <Link to="/audiences/" className="font-semibold underline">audience</Link> for relevant communities in your niche, and searching for "{keyword}" within that audience.</div>
          </div>
        ) : keyword && velocity < 0.1 ? (
          <div className="flex items-center mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
            </svg>
            <div className="text-xs text-gray-500 ml-2">Too few results? Try searching for more general keywords (1-2 words). To ensure relevant results, search within a collection.</div>
          </div>
        ) : ''}*/}

        <div className="flex-auto mr-auto">
          {timestampToChart && timestampToChart.length ? (
            <React.Fragment>
              <h2 className="text-md font-sm mb-0 mt-4 flex items-center">
                <span className="opacity-75">Timeline</span>

                <span className="ml-2 text-xs font-semibold">
                  {frontendFilters && frontendFilters.timestamp_utc && frontendFilters.timestamp_utc.length ? (
                    <React.Fragment>
                      <span className="opacity-50">{frontendFilters.timestamp_utc.length} / {timestampToChart.length}</span>
                      <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                        onClick={() => clearFrontendFilter('timestamp_utc')}>clear</span>
                    </React.Fragment>
                  ) : ''}
                </span>
              </h2>
              <div className="h-28 text-black">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    // width={500}
                    // height={300}
                    data={timestampToChart}
                    margin={{
                      // top: 5,
                      // right: 5,
                      // left: 5,
                      // bottom: 5,
                    }}
                    onClick={(data) => {
                      if (data && data.activePayload && data.activePayload.length > 0) {
                        const payload = data.activePayload[0].payload;
                        applyFrontendFilter('timestamp_utc', {'min': payload.min, 'max': payload.max});
                      }
                    }}
                  >
                    <Tooltip content={<CustomTooltip />} cursor={{ stroke: "rgb(75 85 99)", strokeWidth: 1, fill: "rgb(31 41 55)" }}/>
                    {filteredResults.length !== results.length ? (
                      <Bar dataKey="filteredCount" stackId="a" fill="rgb(75 85 99)" />
                    ) : ''}
                    <Bar dataKey={filteredResults.length === results.length ? "count" : "unfilteredCount"} stackId="a" fill="rgb(31, 41, 55)"/>
                    {/*<Brush dataKey="timestamp_utc" height={10} stroke="rgba(75, 85, 99, 0.5)" fill="none"/>*/}
                  </BarChart>
                </ResponsiveContainer>
                <div className="text-white flex items-center text-xs border-t border-gray-700">
                  <div className="text-gray-500">{timeStampToEasyTime(timestampToChart[0].min)}</div>
                  <div className="text-gray-500 ml-auto">
                    {timeStampToEasyTime(timestampToChart[timestampToChart.length - 1].max)}
                    {!timeStampToEasyTime(timestampToChart[timestampToChart.length - 1].max).includes('/') ? ' Today' : ''}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : ''}
        </div>

        {!hideSections || !hideSections.includes('velocity_frequency') ? (
          <div className="flex-auto mb-2 flex-shrink-0">
            <h2 className="text-md font-sm mb-1 mt-4 flex items-center">
              <span className="opacity-75">Velocity</span>
              {/*<ReactTooltip id='aggregate-keyword-velocity' place="right" effect="solid" backgroundColor="white" textColor="black" />
              <span className="opacity-50 cursor-pointer hover:opacity-100 ml-2" data-for='aggregate-keyword-velocity' data-tip="How frequently your keyword appears in your selected subreddits">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>*/}
              {updating ? (
                <span className="ml-2 text-xs">
                  <svg className="animate-spin h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-50" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </span>
              ) : ''}
            </h2>
            <div className="h-32 w-32 relative">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={[
                      {'name': 'all', 'percent': 100 - filteredVelocityPercent, 'count': velocity + filteredVelocity},
                      {'name': 'filtered', 'percent': filteredVelocityPercent, 'count': filteredVelocity},
                    ]}
                    dataKey="percent" cx="50%" cy="50%"
                    innerRadius={40} outerRadius={50}
                    // fill="rgb(6, 182, 212)"
                    fill="rgb(31 41 55)" stroke="rgba(75, 85, 99, 0.25)"
                    onMouseEnter={(_, index) => {
                      // setActiveSubredditIndex(index)
                    }}
                    activeIndex={1} // always the filtered one
                    activeShape={renderActiveShape}
                  />
                  
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : ''}
      </div>
      
   </React.Fragment>
  )
};

export default AggregateVelocity;

