import React, { useState } from "react";

import ReactTooltip from 'react-tooltip';

// import SubredditPopover from "./subredditpopover";
import AggregateOption from "./aggregateOption";
import SubmissionTypeIcon from "./submissiontypeicon";


const AggregateTypes = ({
  results,
  filteredResults,
  frontendFilters,
  applyFrontendFilter,
  clearFrontendFilter,
}) => {
  // cut out early if we haven't got anything or cleared the search
  if (!results || !results.length) return ''

  const filtersApplied = results.length !== filteredResults.length;

  // get the top types, submission types, & flair
  var conversationTypes = [];
  results.forEach(r => {
    const name = r._meta.type
    const index = conversationTypes.map(r => r.name).indexOf(name);
    index === -1 ? conversationTypes.push({'name': name, 'count': 1}) : conversationTypes[index]['count'] += 1
  });
  conversationTypes.map(s => {
    s['filteredCount'] = filteredResults.filter(r => r._meta.type === s.name).length
    return s
  })
  conversationTypes = conversationTypes.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));   

  // get the submission types specifically
  var submissionTypes = [];
  results.filter(r => r._meta.type === 'reddit-submission').forEach(r => {
    const name = r.type
    const index = submissionTypes.map(r => r.name).indexOf(name);
    index === -1 ? submissionTypes.push({'name': name, 'count': 1}) : submissionTypes[index]['count'] += 1
  });
  submissionTypes.map(s => {
    s['filteredCount'] = filteredResults.filter(r => r.type === s.name).length
    return s
  })
  submissionTypes = submissionTypes.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));

  // statuses (live, removed, deleted)
  var statuses = [];
  results.forEach(r => {
    const name = r.is_deleted ? 'deleted' : r.is_removed ? 'removed' : 'live';
    const index = statuses.map(r => r.name).indexOf(name);
    index === -1 ? statuses.push({'name': name, 'count': 1}) : statuses[index]['count'] += 1
  });
  statuses.map(s => {
    s['filteredCount'] = filteredResults.filter(r => {
      const name = r.is_deleted ? 'deleted' : r.is_removed ? 'removed' : 'live';
      return s.name === name
    }).length
    return s
  })
  statuses = statuses.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));   

  // your statuses (clicked, viewed, new)
  var yourStatuses = [];
  results.forEach(r => {
    const name = r.redirected ? 'clicked' : r.viewed ? 'viewed' : 'new';
    const index = yourStatuses.map(r => r.name).indexOf(name);
    index === -1 ? yourStatuses.push({'name': name, 'count': 1}) : yourStatuses[index]['count'] += 1
  });
  yourStatuses.map(s => {
    s['filteredCount'] = filteredResults.filter(r => {
      const name = r.redirected ? 'clicked' : r.viewed ? 'viewed' : 'new';
      return s.name === name
    }).length
    return s
  })
  yourStatuses = yourStatuses.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));   

  return (
    <React.Fragment>
      <ReactTooltip id='aggregate-types' place="right" effect="solid" backgroundColor="white" textColor="black" />
      
      <div className="sm:flex flex-wrap sm:space-x-12">

        {conversationTypes.length > 1 ? (
          <div className="flex-1 max-w-1/2">
            <div className="text-md font-sm mb-1 mt-4 flex items-center">
              <h2 className="opacity-75">Conversation Type</h2>
              <span className="ml-2 text-xs font-semibold">
                {frontendFilters && frontendFilters.type && frontendFilters.type.length ? (
                  <React.Fragment>
                    <span className="opacity-50">{frontendFilters.type.length} / {conversationTypes.length}</span>
                    <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                      onClick={() => clearFrontendFilter('type')}>clear</span>
                  </React.Fragment>
                ) : (
                  <span className="opacity-50">{conversationTypes.length}</span>
                )}
              </span>
            </div>

            <div className="">
              {conversationTypes.map(t => (
                <AggregateOption 
                  key={t.name}
                  onClick={() => applyFrontendFilter('type', t.name)}
                  label={t.name}
                  count={t.count}
                  maxCount={conversationTypes[0] && conversationTypes[0]['count']}
                  filteredCount={t.filteredCount}
                  filtersApplied={filtersApplied}
                  thisFilterApplied={frontendFilters.type && frontendFilters.type.includes(t.name)}
                  icon={t.name === 'reddit-submission' ? 'text' : t.name === 'reddit-comment' ? 'comment' : ''}
                />
              ))}
            </div>
          </div>
        ) : ''}
        
        {submissionTypes.length > 1 ? (
          <div className="flex-1 max-w-1/2">
            <div className="text-md font-sm mb-1 mt-4 flex items-center">
              <h2 className="opacity-75">Submission Type</h2>
              <span className="ml-2 text-xs font-semibold">
                {frontendFilters && frontendFilters.submission_type && frontendFilters.submission_type.length ? (
                  <React.Fragment>
                    <span className="opacity-50">{frontendFilters.submission_type.length} / {submissionTypes.length}</span>
                    <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                      onClick={() => clearFrontendFilter('submission_type')}>clear</span>
                  </React.Fragment>
                ) : (
                  <span className="opacity-50">{submissionTypes.length}</span>
                )}
              </span>
            </div>

            <div className="">
              {submissionTypes.map(t => (
                <AggregateOption 
                  key={t.name}
                  onClick={() => applyFrontendFilter('submission_type', t.name)}
                  label={t.name}
                  count={t.count}
                  maxCount={submissionTypes[0] && submissionTypes[0]['count']}
                  filteredCount={t.filteredCount}
                  filtersApplied={filtersApplied}
                  thisFilterApplied={frontendFilters.submission_type && frontendFilters.submission_type.includes(t.name)}
                  icon={t.name}
                />
              ))}
            </div>
          </div>
        ) : ''}

        {statuses.length > 1 ? (
          <div className="flex-1 max-w-1/2">
            <div className="text-md font-sm mb-1 mt-4 flex items-center">
              <h2 className="opacity-75">Conversation Status</h2>
              <span className="ml-2 text-xs font-semibold">
                {frontendFilters && frontendFilters.status && frontendFilters.status.length ? (
                  <React.Fragment>
                    <span className="opacity-50">{frontendFilters.status.length} / {statuses.length}</span>
                    <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                      onClick={() => clearFrontendFilter('status')}>clear</span>
                  </React.Fragment>
                ) : (
                  <span className="opacity-50">{statuses.length}</span>
                )}
              </span>
            </div>

            <div className="">
              {statuses.map(t => (
                <AggregateOption 
                  key={t.name}
                  onClick={() => applyFrontendFilter('status', t.name)}
                  label={t.name}
                  count={t.count}
                  maxCount={statuses[0] && statuses[0]['count']}
                  filteredCount={t.filteredCount}
                  filtersApplied={filtersApplied}
                  thisFilterApplied={frontendFilters.status && frontendFilters.status.includes(t.name)}
                  icon={t.name}
                />
              ))}
            </div>
          </div>
        ) : ''}

        {yourStatuses.length > 1 ? (
          <div className="flex-1 max-w-1/2">
            <div className="text-md font-sm mb-1 mt-4 flex items-center">
              <h2 className="opacity-75">Your Status</h2>
              <span className="ml-2 text-xs font-semibold">
                {frontendFilters && frontendFilters.yourStatus && frontendFilters.yourStatus.length ? (
                  <React.Fragment>
                    <span className="opacity-50">{frontendFilters.yourStatus.length} / {yourStatuses.length}</span>
                    <span className="text-cyan-500 ml-2 cursor-pointer opacity-75 hover:opacity-100"
                      onClick={() => clearFrontendFilter('yourStatus')}>clear</span>
                  </React.Fragment>
                ) : (
                  <span className="opacity-50">{yourStatuses.length}</span>
                )}
              </span>
            </div>

            <div className="">
              {yourStatuses.map(t => (
                <AggregateOption 
                  key={t.name}
                  onClick={() => applyFrontendFilter('yourStatus', t.name)}
                  label={t.name}
                  count={t.count}
                  maxCount={yourStatuses[0] && yourStatuses[0]['count']}
                  filteredCount={t.filteredCount}
                  filtersApplied={filtersApplied}
                  thisFilterApplied={frontendFilters.yourStatus && frontendFilters.yourStatus.includes(t.name)}
                  icon={t.name}
                />
              ))}
            </div>
          </div>
        ) : ''}

        {/*<div className="flex-1"></div>*/}

      </div>
   </React.Fragment>
  )
};

export default AggregateTypes;

