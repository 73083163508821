import React, { useState, useEffect } from "react";
import ReactTooltip from 'react-tooltip';

import SubredditPopover from "../../components/reddit/subredditpopover";
import SimilarSubredditCard from "./similarSubredditCard";

import { collectionsService } from '../../services/collections';
import { searchesService } from '../../services/searches';


// shown below an audience, with similar communities
const AudienceAddSimilarTip = ({
  collection,
  refetch
}) => {
  const [similar, setSimilar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [hidden, setHidden] = useState([]);  // names of added/deleted communities
  const displayType = 'card'

  // effects
  useEffect(() => {
    fetchSimilarCommunities();
  }, []);

  // acions
  const fetchSimilarCommunities = () => {
    setLoading(true)
    collectionsService.getSimilar(collection.hash, (results) => {
      if (results && results.data){
        setSimilar(results.data)
        setLoading(false)
      }
    }, (response, error) => {
      setLoading(false)
      setError(error || 'Error fetching similar communtiies');
    })
  }


  const addCommunity = (name) => {
    // add a single communtiy to the collection
    const items = [{'external_type': 'RSR', 'external_id': name}];
    collectionsService.addCollectionItems(collection.hash, items, (results) => {
      refetch();
    }, (response, error) => {
      console.log(error)
      setError("Error adding community to collection")
    });
    setHidden([...hidden, name]);
  }

  const setNotRelevant = (name) => {
    // right now just adds to the user's hidden communities
    // take feedback as to where we hide it from
    // searchesService.addHiddenCommunity("RSR", name, name, {
    //   'type': 'collection',
    //   'type_id': collection.id,
    // });
    setHidden([...hidden, name]);
  }

  const communitiesToShow = similar ? similar.filter(sub => !hidden.includes(sub.name)) : [];
  const hasCommunities = similar && similar.length > 0;
  const hasCommunitiesToShow = communitiesToShow.length > 0;        

  if (loading) return ''
  if (similar && similar.length === 0) return ''
  if (error) return ''

  return (
    <div className="mt-8">
      {show ? (
        <div>
          <div className="flex items-center mb-2">
            <h2 className="text-lg font-medium flex items-center flex-wrap">
              <span>Expand your audience with similar communities</span>
            </h2>
          </div>
          <div className="w-full">
            {loading ? (
              <div className="opacity-25 h-24 mb-3">Loading, this may take up to a minute...</div>
            ) : error ? (
              <div className="opacity-25">{error}</div>
            ) : hasCommunitiesToShow ? (
              <div className={`grid grid-cols-1 ${displayType === 'card' ? 'md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 sm:gap-4' : 'gap-2'}`}>
                {similar.filter(sub => !hidden.includes(sub.name)).map(similarSub => (
                  <SimilarSubredditCard key={similarSub.name} subreddit={similarSub.details} collection={collection}
                    addCommunity={addCommunity} setNotRelevant={setNotRelevant}
                  />
                ))}
              </div>
            ) : (
              <div className="opacity-25 h-24 mb-3">No more suggestions at this time</div>
            )}
          </div>
        </div>
      ) : (
        <div className="text-center text-gray-400 font-semibold p-4 hover:text-gray-300 cursor-pointer"
          onClick={() => setShow(true)}
        >
          Show {similar.length} similar {similar.length === 1 ? 'community' : 'communities'}, like {similar.slice(0, 3).map(s => 'r/' + s.name).join(', ')}
        </div>
      )}
      
    </div>
  );
};

export default AudienceAddSimilarTip;

