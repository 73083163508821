import React, { useState, useEffect } from "react";

import { Popover } from 'react-tiny-popover'
import { FEATURES } from './constants.js';


// component that wraps another component, which represents a feature label
// this popover contains info about the feature, including title, description, & image (gif?)
const FeaturePopover = ({
  id, label, getPopoverParentElement, positions
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const isMobile = window.screen.width < 600;  // no hovering on mobile, just clicks
  const openOnClick = isMobile;
  const openOnHover = !isMobile;

  // get this feature (it's okay if ID doesn't match)
  const feature = FEATURES[id];

  // content for popover
  const getContent = () => {

    if (!feature){
      return '';  // if invalid key, don't show anything
    }

    return (
      <div className="z-40 w-80 sm:w-96 sm:pl-4 sm:-mt-2" role="menu" aria-orientation="vertical" aria-labelledby="options-menu"
        onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
        onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
      >
        <div className="p-4 text-white shadow-lg bg-gray-700 rounded-md">
          {isMobile ? (
            <div className="text-gray-400 mb-2">
              <span onClick={() => setIsOpen(false)} className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
                Close
              </span>
            </div>
          ) : ''}
          <div className="font-bold">{feature.title}</div>
          {feature.video ? (
            <div className="mt-2 bg-gray-900">
              <iframe
                src={feature.video}
                // src={feature.video.replace('https://www.youtube.com/watch', 'https://tube.rvere.com')}
                className="w-full rounded-md"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen={true}></iframe>
            </div>
          ) : feature.gif ? (
            <div className="mt-2 bg-gray-900">
              <iframe
                src={feature.gif}
                className="w-full rounded-md"
                allow="autoplay"></iframe>
            </div>
          ) : ''}
          <div className="mt-2 text-gray-200">{feature.description}
            {feature.demo ? (
              <React.Fragment><a href={feature.demo} target="_blank" className="underline ml-1">View demo</a>.</React.Fragment>
            ) : ''}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Popover
      isOpen={(isOpen || isHovering)}
      positions={positions || isMobile ? ['top', 'bottom', 'right', 'left'] : ['right', 'top', 'bottom', 'left']}
      align="start"
      content={getContent()}
      parentElement={getPopoverParentElement ? getPopoverParentElement() : window.document.body}
      // onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
      // onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
    >
      <span className={`opacity-100 ${feature ? 'cursor-pointer border-b-2 border-dashed hover:border-solid border-gray-400 hover:opacity-100': ''}`}
        onClick={() => openOnClick && setIsOpen(!isOpen)}
        onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
        onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
      >{ label }</span>
    </Popover>
  )
}

export default FeaturePopover;

