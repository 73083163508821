import { postRequest, getRequest } from '../utils/axios';
import axios from 'axios';


export const trialService = {
    fetchTrial,
    startTrial,
    extendTrial,
    submitFeedback,
};

// counts isn't a great name anymore as it's more time-based now
function fetchTrial(onSuccess, onError) {
  return getRequest(
    `/api/v1/trial/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// start trial (should only be for legacy users)
function startTrial(onSuccess, onError) {
  return postRequest(
    `/api/v1/trial-add/`, {},
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// available to some on an "old" trial. can extend
function extendTrial(onSuccess, onError) {
  return postRequest(
    `/api/v1/trial-extend/`, {},
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// start trial (should only be for legacy users)
function submitFeedback(data, onSuccess, onError) {
  return postRequest(
    `/api/v1/trial-feedback/`, data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

