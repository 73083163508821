
// reading time
const wordsToMinutes = (num) => {
    // 240 words per min is average
    return parseInt(num / 240) || 1;
}

const charsToMinutes = (num) => {
    // avg is 5 characters per word
    return wordsToMinutes(num / 5);
}


export {
    wordsToMinutes,
    charsToMinutes,
}

