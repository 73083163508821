import React, { useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom";
import { Popover, Transition } from '@headlessui/react'
import { connect } from 'react-redux';

import ErrorBoundary from "../common/errorboundary";
import ErrorBar from "../common/errorBar";
import LoaderFancy from "../common/loaderfancy";
import Checklist from "./checklist";
import Resources from "./resources";
import StoryContent from "../welcome/storyContent";

import { profileService } from '../../services/profile';
import { authService } from '../../services/auth';
import { userOperations } from '../../redux/user';
import { getIcon } from '../../utils/icons';
import { objectiveOptions, learningStyleOptions } from '../../components/welcome/constants';


const Knowledgebase = ({currentUser, fetchCurrentUser, checklist, updateChecklist}) => {
  // state
  const [error, setError] = useState(null);

  // keep local state, even though we update on current user
  const [objectiveKey, setObjectiveKey] = useState(currentUser.profile.objective || "FP");
  const [learningStyleKey, setLearningStyleKey] = useState(currentUser.profile.learning_style || "BP");

  // refs
  const objectivePopover = useRef();
  const learningStylePopover = useRef();

  // effects
  useEffect(() => {
    updateChecklist();
  }, []); // Gets steps to a user's objective

  // actions
  const saveObjective = (key) => {
    // setLoading(true);
    setObjectiveKey(key);
    profileService.updateProfile(currentUser.profile.id, {
      'objective': key,
    }, (response) => {

      // we updated preferences, update user & get new checklist
      // authService.fetchUser();
      fetchCurrentUser();
      updateChecklist();
    }, (error) => {
      setError(error || "Error updating objective")
      // setLoading(false)
    });
  }

  const saveLearningStyle = (key) => {
    // setLoading(true);
    setLearningStyleKey(key);
    profileService.updateProfile(currentUser.profile.id, {
      'learning_style': key,
    }, (response) => {

      // we updated preferences, update user
      // authService.fetchUser();
      fetchCurrentUser();
      // updateChecklist();
    }, (error) => {
      setError(error || "Error updating learning style")
      // setLoading(false)
    });
  }

  // get from the dictionary we have
  const objective = objectiveOptions.filter(o => o.key === objectiveKey)[0] || objectiveOptions[0];
  const learningStyle = learningStyleOptions.filter(l => l.key === learningStyleKey)[0] || learningStyleOptions[0];
  const splitScreen = ["CH", "ST"].includes(learningStyleKey);

  return (
    <div className="">
      <div className="mb-4">
        <div className="flex items-center mb-2">
          <h2 className="text-lg font-medium">GummySearch Knowledgebase</h2>
        </div>

        <div className="text-gray-400">
          <div className="text-base flex flex-wrap">

            <svg  className="w-4 h-4 mr-2 mt-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>

            <span>My objective is to</span>
            <span className="ml-1 font-semibold border-b-2 border-dashed border-cyan-600 hover:border-cyan-400 cursor-pointer">
              <Popover className="relative inline-block">
                {({ open, setOpen }) => (
                  <>
                    <Popover.Button className={`focus:outline-none focus:ring-transparent focus:border-none flex items-center`} ref={objectivePopover}>
                      <span>{objective.label.toLowerCase()}</span>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </Popover.Button>
                    <Transition
                      show={open}
                      as={React.Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel static className="absolute z-10 right-0 sm:left-full mx-1 top-0 px-2 w-screen max-w-xs sm:px-0">
                        <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white p-4">
                            {objectiveOptions.map(objective => (
                              <div key={objective.key} role="button" className="-m-3 p-3 text-gray-700 flex items-center rounded-lg cursor-pointer hover:bg-gray-100 transition ease-in-out duration-150"
                                onClick={() => {
                                  saveObjective(objective.key);
                                  objectivePopover.current?.click()
                                }}
                              >
                                <div className="mr-2">{ getIcon(objective.icon) }</div>
                                <div className="font-medium m-0 p-0">{objective.label}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </span>
            <span className="ml-1">and I learn best with</span>
            <span className="ml-1 font-semibold border-b-2 border-dashed border-cyan-600 hover:border-cyan-400 cursor-pointer">
              <Popover className="relative inline-block">
                {({ open, setOpen }) => (
                  <>
                    <Popover.Button className={`focus:outline-none focus:ring-transparent focus:border-none flex items-center`} ref={learningStylePopover}>
                      <span>{learningStyle.label.toLowerCase()}</span>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </Popover.Button>
                    <Transition
                      show={open}
                      as={React.Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel static className="absolute z-10 right-0 sm:left-full mx-1 top-0 px-2 sm:w-screen max-w-xs sm:px-0">
                        <div className="rounded-lg shadow-lg ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white p-4">
                            {learningStyleOptions.map(learningStyle => (
                              <div key={learningStyle.key} role="button" className="-m-3 p-3 text-gray-700 flex items-center rounded-lg cursor-pointer hover:bg-gray-100 transition ease-in-out duration-150"
                                onClick={() => {
                                  saveLearningStyle(learningStyle.key);
                                  learningStylePopover.current?.click()
                                }}
                              >
                                <div className="mr-2">{ getIcon(learningStyle.icon) }</div>
                                <div className="font-medium m-0 p-0">{learningStyle.label}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </span>
            .
          </div>
        </div>
      </div>


      <div className="mb-4 grid sm:grid-cols-1 xl:grid-cols-3 gap-x-6 gap-y-6">
        {/*<div className="flex items-center mb-2">
          {learningStyleKey === "CH" ? (
            <h2 className="text-lg font-medium">Your steps to success</h2>
          ) : learningStyleKey === "ST" ? (
            <h2 className="text-lg font-medium">A story for you</h2>
          ) : learningStyleKey === "BP" ? (
            <h2 className="text-lg font-medium">Helpful blog posts for you</h2>
          ) : learningStyleKey === "VI" ? (
            <h2 className="text-lg font-medium">Helpful videos for you</h2>
          ) : (
            <h2 className="text-lg font-medium">Helpful resources for you</h2>
          )}
        </div>*/}


        {learningStyleKey === "CH" ? (
          <div className="text-lg">
            <h3 className="text-base font-medium mb-1 opacity-50">Your steps to success</h3>
            <div className="p-1 sm:p-4 bg-gray-800 rounded-md ">
              <Checklist checklist={checklist}/ >
            </div>
          </div>
        ) : learningStyleKey === "ST" ? (
          <div className="text-lg">
            <h3 className="text-base font-medium mb-1 opacity-50">A story for you</h3>
            <div className="p-4 sm:p-6 bg-gray-800 rounded-md ">
              <StoryContent objective={objective} objectiveKey={objectiveKey} />
            </div>
          </div>
        ) : ''}

        <div className={splitScreen ? 'xl:col-span-2' : 'xl:col-span-3'}>
          {splitScreen ? (
            <h3 className="text-base font-medium mb-1 opacity-50">Helpful resources</h3>
          ) : ''}
          <Resources objectiveKey={objectiveKey} learningStyleKey={learningStyleKey} currentUser={currentUser}
            showImage={!splitScreen}
          />
        </div>
      </div>
    </div>
  )

}



const mapStateToProps = state => {
  const {
    checklist
  } = state.user;

  return {
    checklist
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchCurrentUser = (callback) => {
    dispatch(userOperations.fetchCurrentUser(callback))
  };
  const updateChecklist = (userId, callback, onError) => {
    dispatch(userOperations.updateChecklist(userId, callback, onError))
  };
  return {
    updateChecklist,
    fetchCurrentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Knowledgebase);
