
const organizeProductMakes = (categoryDetails) => {
  /*
   * For a category, organize products by make
   */

  var productMakes = [];
  (categoryDetails && categoryDetails.products || []).filter(p => p.make).forEach(p => {
    var makeIndex = productMakes.map(m => m.make).indexOf(p.make);
    if (makeIndex === -1){
      productMakes.push({
        'make': p.make,
        'products': [], // only store products that have a model here
        'reviews': []
      });
      makeIndex = productMakes.length - 1;
    }
    // productMakes[makeIndex].reviews = [...productMakes[makeIndex].reviews, ...p.products_productreviews]
    //   .sort((a,b) => (a.reddit_comment_data.timestamp_utc < b.reddit_comment_data.timestamp_utc) ? 1 : ((b.reddit_comment_data.timestamp_utc < a.reddit_comment_data.timestamp_utc) ? -1 : 0))
    //   .sort((a,b) => (a.one_to_five_rating < b.one_to_five_rating) ? 1 : ((b.one_to_five_rating < a.one_to_five_rating) ? -1 : 0))
    productMakes[makeIndex].reviews = [...productMakes[makeIndex].reviews, ...p.reviews]
    if (p.model){
      productMakes[makeIndex].products.push(p)
    }
  });

  // count up sum stars and average stars for each make
  productMakes.forEach(m => {
    m.stars_sum = m.reviews.reduce((a,b) => a + b.one_to_five_rating, 0)
    m.positive_stars_sum = m.reviews.filter(r => r.one_to_five_rating > 3).reduce((a,b) => a + b.one_to_five_rating, 0)
    m.stars_average = (m.stars_sum / m.reviews.length).toFixed(1)
  })

  // order by number of stars (first all, then positive)
  productMakes = productMakes.sort((a,b) => (a.stars_sum < b.stars_sum) ? 1 : ((b.stars_sum < a.stars_sum) ? -1 : 0))
  productMakes = productMakes.sort((a,b) => (a.positive_stars_sum < b.positive_stars_sum) ? 1 : ((b.positive_stars_sum < a.positive_stars_sum) ? -1 : 0));

  return productMakes;
}

export {
  organizeProductMakes,
}
  
  