import React, { useState } from "react"
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";

import LayoutAuth from "../../components/auth/layout";

import { authService } from "../../services/auth";
import { getParameterByName } from "../../utils/urls";
import { trackEvent, identifyUser } from '../../utils/tracking';


const Login = ({history, location, match, authenticated, currentUser }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(getParameterByName('successMessage'));
  const [nextRoute] = useState(getParameterByName('next'));

	const submitForm = (e) => {
		e.preventDefault();  // to not post to the current url
		setLoading(true);
		setError(null);
    setSuccessMessage(null);

		authService.login(
			e.target.elements['email'].value,
			e.target.elements['password'].value,
			(response) => {
				setError(null);

				authService.fetchUser((data) => {
          identifyUser(data); // identify in 3rd party tools
          window.location = nextRoute || '/home/'; // take user to their desired page

          // send event
          trackEvent("Login", {
            "subscription_active": "yes" ? data.subscription : "no",
            "subscription_type": data.subscription.key ? data.subscription : "none",
            "subscription_cycle": data.subscription.billing_cycle ? data.subscription : "none",
            "trial_exists": data.trial.exists ? "yes" : "no",
            "trial_active": data.trial.active ? "yes" : "no",
          });
				}, () => {
					console.error("Failed to fetch user info")
				})
				//setLoading(false);  // no need, wait for redirect
			},
			(error, response) => {
				setError(response && response.detail ? response.detail : 'could not log in');
				setLoading(false);
			}
		)
	}

  // const requiredFieldIds = ['email', 'password'];
  // const formFilledOut = requiredFieldIds.map(f => document.getElementById(f) && document.getElementById(f).value).every(v => v);

  return (
    <LayoutAuth title="Welcome back." subtitle={authenticated && currentUser ? '' : "Log in your account."}>
      <Helmet><title>Login</title></Helmet>

      {authenticated && currentUser ? (
        <div className="">
          <div className="mb-3 text-center">You're still logged in!</div>
          <Link to="/audiences/">
            <button className="cursor-pointer w-full justify-center tracking-wider p-3 font-semibold rounded-sm shadow-sm bg-cyan-500 focus:outline-none">
              Step Inside
            </button>
          </Link>
        </div>
      ) : (
        <form action="#" onSubmit={submitForm}>
          {successMessage ? (
            <div className="bg-green-500 p-2 px-3 font-medium rounded-sm mb-6">
              {successMessage}
            </div>
          ) : ''}

          {/*<div className="mb-4 text-white bg-green-500 p-4 rounded-md flex items-center">
            <div className="font-semibold">
              New to GummySearch?
            </div>
            <a href="https://gummysearch.com/?utm_source=login" className="underline ml-auto font-bold">Sign up here</a>
          </div>*/}

          <div>
            <label htmlFor="email" className="block uppercase text-sm opacity-75 font-medium">
              Email Address
            </label>
            <div className="mt-1">
              <input id="email" name="email" type="email" autoFocus autoComplete="email" placeholder="Email Address" required
                autocapitalize="none"
                className="appearance-none bg-gray-700 block w-full p-3 rounded-sm border-solid border-transparent shadow-sm focus:outline-none focus:ring-transparent focus:border-cyan-500"
              />
            </div>
          </div>

          <div className="mt-6">
            <label htmlFor="password" className="block uppercase text-sm opacity-75 font-medium">
              Password
            </label>
            <div className="mt-1">
              <input id="password" name="password" type="password" placeholder="Password" autoComplete="current-password" required minLength="8"
                className="appearance-none bg-gray-700 block w-full p-3 rounded-sm border-solid border-transparent shadow-sm focus:outline-none focus:ring-transparent focus:border-cyan-500"
              />
            </div>
          </div>

          {error ? (
            <div className="bg-red-500 p-2 px-3 font-medium rounded-sm mt-6">
              {error}
            </div>
          ) : ''}

          <div className="mt-6">
            <button type="submit" className="cursor-pointer w-full justify-center tracking-wider p-3 font-semibold rounded-sm shadow-sm bg-cyan-500 focus:outline-none"
              disabled={loading}
            >
              {loading ? 'Logging In...' : 'Log In'}
            </button>
          </div>

          <div className="mt-6 flex text-sm">
            <div className="mb-2 mr-auto">
              
              {true || window.location && window.location.origin && window.location.origin.includes('gummysearch.com') ? (
                <React.Fragment>
                  <span className="opacity-75">New user? </span>
                  <a href="https://gummysearch.com/?utm_source=login" className="hover:underline text-right font-semibold mr-auto">Sign up here</a>
                </React.Fragment>
              ) : ''}
              {/*<Link to="/register/" className="hover:underline text-right font-semibold mr-auto">Sign up</Link>*/}
            </div>
            <div>
              <Link to="/password-reset/" className="ml-auto hover:underline tracking-wider font-semibold">Forgot password?</Link>
            </div>
          </div>

        </form>
      )}
      

      
    </LayoutAuth>
  );
}

export default Login;
