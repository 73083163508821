import React, { useState } from "react"
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { CheckIcon } from '@heroicons/react/solid'
// import { ModalRoute, ModalContainer } from 'react-router-modal';

import { Popover, Transition } from '@headlessui/react'

import FAQs from '../../components/account/faqs';

import { paymentService } from '../../services/payment';
import { authService } from "../../services/auth";
import { timeStampToFullTime } from "../../utils/time";
import { trackEvent } from '../../utils/tracking';

import Testimonials from '../../components/account/testimonials';
import SubscriptionDetails from "../../components/account/subscriptionDetails";
import ExperimentsToggle from "../../components/guide/experimentstoggle";


const Subscription = ({match, currentUser}) => {
  const [activating, setActivating] = useState(false); // TODO: move to day pass module?
  const [error, setError] = useState(null);

  if (!currentUser) return 'Missing user info. Please log out and in again.';

  const subscription = currentUser.subscription;
  const trial = currentUser.trial;
  
  // // const allPlanBenefits = [
  // //   {'title': 'Content Library', 'description': 'Access to helpful product & process guides written by the GummySearch founder.'},
  // //   {'title': 'Curated Guide', 'description': 'Your experience in GummySearch is curated based on your current goal, which you can update.'},
  // //   {'title': 'Email Support', 'description': "Write in with any feedback, issues, or ideas. We'd love to help."}
  // // ]
  // const allPlanBenefits = [
  //   {'title': 'Save Time', 'description': 'No need to waste your precious time reading Reddit posts, GummySearch will show you the important stuff so you can go back to building your business.'},
  //   {'title': 'Be Confident', 'description': "Make informed descisions backed up by conversations happening between real people on Reddit. Confidently dive into conversational insights of any target group."},
  //   {'title': 'Make Money', 'description': 'No matter if you are ideating, validating, or looking for sales leads, the potential gains that will come from using Reddit right will far outweigh $19/month.'},
  // ]

  const activatePass = () => {
    setError(null)
    setActivating(true)
    paymentService.activateDaypass(
    (response) => {
      alert("Day pass activated!")
      window.location.href = window.location.href; // refetch to see everything

      // track it
      trackEvent("ActivateDayPass", {});

      // refetch the user and then 
      // authService.fetchUser((response) => {
      //   // setCurrentUserState(response)
      //   setActivating(false)
      // });
    }, (error, response) => {
      setError(response);
      setActivating(false)
    });
  }


  return (
    <React.Fragment>
      <Helmet><title>Account | Subscription</title></Helmet>
      
      <div className="mt-8">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6">Subscription Plan</h3>
              <p className="mt-2 text-sm text-gray-400">
                Status of your GummySearch subscription
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow rounded-md overflow-hidden">
              <SubscriptionDetails currentUser={currentUser} hideTitle={true} showButtons={true} />
            </div>
          </div>
        </div>
      </div>

      {currentUser.features.account_subscription_day_pass ? (
        <div className="mt-8">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6">Day Passes</h3>
                <p className="mt-2 text-sm text-gray-400">
                  Day passes can be used for one-off research sessions if you do not have an active subscription
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow rounded-md overflow-hidden">
                <div className="p-6 bg-gray-800">
                  <label className="flex items-center text-sm font-medium text-gray-500">
                    <div>Your Unused Day Passes</div>
                  </label>
                  <div className="flex items-center font-semibold">
                    {currentUser.trial.day_passes}
                  </div>

                  {subscription && subscription.billing_cycle === "day" ? (
                    <React.Fragment>
                      <label className="mt-2 flex items-center text-sm font-medium text-gray-500">
                        <div>Status</div>
                      </label>
                      <div className="flex items-center font-semibold">
                        <span className={`text-green-400 font-semibold`}>In Use</span>
                      </div>

                      {subscription.expires ? (
                        <React.Fragment>
                          <label className="mt-2 flex items-center text-sm font-medium text-gray-500">
                            <div>Expires</div>
                          </label>
                          <div className="flex items-center font-semibold">
                            <div>{timeStampToFullTime(subscription.expires)}</div>
                          </div>
                        </React.Fragment>
                      ) : ''}
                    </React.Fragment>
                  ) : ''}

                  <React.Fragment>
                    <hr className="my-2 border-gray-700" />
                    <div className="flex space-x-4 mt-4 flex-wrap">
                      {currentUser.trial.day_passes ? (
                        <button className="px-4 py-2 text-center border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                          disabled={currentUser.subscription || currentUser.trial.active || activating}
                          onClick={activatePass}
                        >
                          Activate
                        </button>
                      ) : ''}

                      <Link to="/account/subscription/day-pass/"
                        className="px-4 py-2 text-center border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                      >
                        Purchase
                      </Link>

                      <Link to="/account/subscription/day-pass/"
                        className="px-4 py-2 text-center cursor-pointer border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
                      >
                        About
                      </Link>

                    </div>
                  </React.Fragment>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      ) : ''}

      <div className="mt-8">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6">Email Address</h3>
              <p className="mt-2 text-sm text-gray-400">
                To modify your email or make any changes to your account, please send an email to fed@gummysearch.com
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow rounded-md overflow-hidden">
              <div className="px-4 py-5 bg-gray-800 space-y-6 sm:p-6">
                {currentUser.identity.email}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*{currentUser.features.experiments ? (
        <div className="mt-8">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6">Gummy Pig Program</h3>
                <p className="mt-2 text-sm text-gray-400">
                  Test and provide feedback on beta features before general release
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="shadow rounded-md overflow-hidden">
                <ExperimentsToggle currentUser={currentUser} />
              </div>
            </div>
          </div>
          
        </div>
      ) : ''}*/}

    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  // console.log("state is", state.user)
  
  return {
    currentUser,
  }
};

const mapDispatchToProps = (dispatch) => {
  // const setUiState = (key, value) => {
  //   dispatch(uiOperations.setUiState(key, value))
  // };
  return {
    // setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
