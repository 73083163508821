import React, { useState, useEffect } from "react"
import {
  Link,
} from "react-router-dom";

// footer on every health history form. mainly for Formik submit button, but also to render page index details
const FormFooter = ({submit, disabled, hideCTA, ...subrouteProps}) => {

  const { previousPage, isLastPage, pageIndex, totalPages } = subrouteProps;

  return (
    <div className="border-t border-dashed border-gray-800 py-6 flex items-center mt-auto">
      <div className="flex-1 text-left">
        {previousPage ? (
          <Link to={`/welcome/${previousPage}/`} className="cursor-pointer font-semibold opacity-50 hover:opacity-100">
            ← Back
          </Link>
        ) : ''}
      </div>
      <div className="flex-1 text-center">
        <div className="mx-auto flex text-center justify-center">
          {[...Array(totalPages).keys()].map(i => (
            <div key={i} className={`h-2 w-2 mx-1 rounded-md ${i === pageIndex ? 'bg-white' : 'bg-gray-700'}`}></div>
          ))}
        </div>
      </div>
      <div className="flex-1 text-right">
        {!hideCTA ? (
          <button className={`cursor-pointer ${disabled ? 'bg-gray-700' : 'bg-cyan-500'} color-white py-4 px-6 sm:px-10 font-semibold rounded-sm shadow-sm focus:outline-none`}
            onClick={() => submit()}
            disabled={disabled}
          >
            {isLastPage ? "Let's Go!" : 'Next'}
          </button>
        ) : ''}
      </div>
      
    </div>
  );
}

export default FormFooter;
