import React, { useState, useEffect } from "react";

import LoaderFancy from "../common/loaderfancy";
import SelectPopover from "../common/selectPopover";
import CommentTree from "../reddit/commenttree";
import Aggregates from "../reddit/aggregates";

import { redditService } from '../../services/reddit';

import { styleKeyword } from '../../utils/text';
import { conversationContainsPhrase } from '../../utils/reddit';

// import { timeStampToFullTime } from '../../utils/time';

// shows comments for a submission
const SubmissionCommentsAggregates = ({submission, keyword, comments, aggregates, fetchComments, loading}) => {

  useEffect(() => {
    if (!comments.length && !loading){
      fetchComments()
    }
  }, [comments, loading]); // get the comments when we load

  // useEffect(() => {
  //   if (comments && comments.length && !topLevelComments.length){
  //     constructCommentTree(comments)
  //   }
  // }, [comments, topLevelComments]); // when comments load, put into tree


  // // constructs nested comment tree
  // const constructCommentTree = (results) => {
  //   // flat comments (at any level in the hierarchy)
  //   const flatComments = results;

  //   // get a map of each parent id's comments
  //   var childrenDict = {}
  //   flatComments.forEach((c) => {
  //     // put into dict if it has a parent
  //     if (c.parent_id){
  //       if (childrenDict[c.parent_id]){
  //         childrenDict[c.parent_id].push(c);
  //       } else {
  //         childrenDict[c.parent_id] = [c];
  //       }
  //     }
  //   })

  //   const getCommentReplies = (id) => {
  //     // recursively get the comment's replies
  //     if (childrenDict[id]){
  //       var replies = childrenDict[id];
  //       replies.forEach(r => {
  //         r.replies = getCommentReplies(r.id);
  //       })
  //     } else {
  //       var replies = []
  //     } 
  //     return replies
  //   }

  //   // now we construct the top-level comments, and add in the replies (and their replies)
  //   var topLevelComments = flatComments.filter(c => !c.parent_id).map(c => {
  //     c.replies = getCommentReplies(c.id)  // recursive
  //     return c
  //   })

  //   setTopLevelComments(topLevelComments)
  // }

  // const containsKeyword = (reply, keyword) => {
  //   // checks comment and it's replies recursively to see if it contains the keyword
  //   if (conversationContainsPhrase(reply, keyword)){
  //     return true;
  //   } else if (reply.replies.length){
  //     var repliesIncludePhrase = false;
  //     reply.replies.forEach(r => {
  //       if (repliesIncludePhrase) return; // exit early if one of the replies already has it
  //       if (containsKeyword(r, keyword)){
  //         repliesIncludePhrase = true;
  //       }
  //     })
  //     if (repliesIncludePhrase){
  //       return true
  //     }
  //   }
  //   return false;
  // }

  // const filterResultsBasedOnKeyword = (replies, keyword) => {
  //   // recursively filters down replies recursively if they contain the keyword
  //   const repliesCopy = JSON.parse(JSON.stringify(replies)); // copy to not mess with things between searches
  //   return repliesCopy
  //     .filter(r => containsKeyword(r, keyword))
  //     .map(r => {
  //       r.replies = filterResultsBasedOnKeyword(r.replies, keyword)
  //       return r;
  //     })
  // }

  // // display utils

  // // filter results based on search keyword
  // var filteredResults = topLevelComments;
  // if (searchTerm){
  //   // filter down the top-level comments, as well as their replies
  //   filteredResults = filterResultsBasedOnKeyword(topLevelComments, searchTerm)
  // }

  // // sort results
  // if (sortOption === "Upvotes"){
  //   filteredResults = filteredResults.sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0));
  // } else if (sortOption === "Replies"){
  //   filteredResults = filteredResults.sort((a,b) => (a.num_comments < b.num_comments) ? 1 : ((b.num_comments < a.num_comments) ? -1 : 0));
  // } else if (!sortOption || sortOption === "Recent"){
  //   // should be default
  //   filteredResults = filteredResults.sort((a,b) => (a.timestamp_utc < b.timestamp_utc) ? 1 : ((b.timestamp_utc < a.timestamp_utc) ? -1 : 0));
  // }

  return (
    <div className="py-4 xl:pt-4 xl:pb-0">
      {loading ? (
        <div className="h-96 relative">
          <LoaderFancy />
        </div>
      ) : comments && comments.length !== 0 ? (
        <div className="">
          <div className="bg-gray-800 mb-2 p-2 text-sm rounded-md flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" />
            </svg>
            <div>This feature is currently a work in progress. Ability to filter coming soon...</div>
          </div>
          <div className="-mx-4">
            <Aggregates
              results={comments}
              aggregates={aggregates}
              filteredResults={comments}
              frontendFilters={{}}
              // frontendFilters={frontendFilters}
              applyFrontendFilter={() => {}}
              clearFrontendFilter={() => {}}
              // fetching={fetching}
              fetchingExtra={false}
              fetchingAggregates={false}
              hideTitle={true}
              hideSections={['velocity_frequency', 'topics_flair']}

              // keyword={params.keyword}
              // velocity={results && results.velocity}
              // allKeywords={results && results.keywords}
            />
          </div>
        </div>
      ) : (
        <div>
          no comments...
        </div>
      )}
    </div>
  );
}

export default SubmissionCommentsAggregates;

