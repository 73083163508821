import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import LoaderFancy from "../common/loaderfancy";
import Loader from "../common/loader";
// import SelectPopover from "../common/selectPopover";
import ErrorBar from "../common/errorBar";


import ResultsAnalyzePatterns from "../../components/language/patterns";


import { redditService } from '../../services/reddit';

// analyzes filtered results for tracked keyword
const SavedConversationsAnalyze = ({
  history,
  location,
  savedSearch,
  currentUser,
  // baseUrl, // if needed b/c this gets shown on multiple potential screens
  results,
  // fetching,
}) => {
  
  return (
    <div className="p-4 overflow-auto">
     <Helmet><title>Conversations | Saved | Patterns</title></Helmet>
     {results && results.length ? (
          <ResultsAnalyzePatterns
            currentUser={currentUser}
            results={results}
            ids={results.map(r => r.id)}
            filterParams={
              {'ids': results.map(r => r.id)}
            }
            // limitOptions={[100, 200, 500]}
          />
      ) : (
        <div className="p-6 bg-gray-800 rounded-lg border border-solid border-gray-700 text-white">
          <div className="">
            <h3 className="text-lg leading-6 font-medium">
              No results at this time...
            </h3>
          </div>
        </div>
      )}
    </div>
  );
}

export default SavedConversationsAnalyze;

