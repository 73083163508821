import React, { useState, useEffect } from "react"
import {
  Link
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Helmet } from "react-helmet";

import LayoutAuth from "../../components/auth/layout";

import { authService } from "../../services/auth";
import { betaService } from "../../services/beta";
import { getParameterByName } from "../../utils/urls";
import { trackEvent, identifyUser } from '../../utils/tracking';


const BetaRegister = ({history, location, match, authenticated }) => {
	const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
	const [error, setError] = useState(null);
  const [nextRoute] = useState(getParameterByName('next'));

  const betaHash = match.params.betaHash;

  // makes sure token is valid
  useEffect(() => {
    if (!betaHash){
      setError('Invalid beta link');
      return 
    }

    setLoading(true);
    betaService.confirmBetaLink(
      betaHash,
      (response) => {
        setLoading(false);
      },
      (error, response) => {
        setError(response);
        // setLoading(false);
      }
    )
  }, [
    match.params.betaHash,
  ]); // Refetches user config when the access token is set


  // actions
	const submitForm = (e) => {
		e.preventDefault();  // to not post to the current url
		setSubmitting(true);
		setError(null);

    const affiliateReferrer = typeof(window.Rewardful) !== "undefined" && window.Rewardful.affiliate ? window.Rewardful.affiliate.token : '';

		authService.register(
			{
				'email': e.target.elements['email'].value.toLowerCase(),
				'password': e.target.elements['password'].value,
        'beta': betaHash,
        'referrer': affiliateReferrer,
			},
			(response) => {
				setError(null);

        trackEvent("Register", {"beta": "yes", "referrer": affiliateReferrer});
				authService.fetchUser((data) => {
          trackEvent("Login", {});
          identifyUser(data); // identify in 3rd party tools
					window.location = nextRoute || '/welcome/?beta=1'; // take user to their desired page
				}, () => {
					console.error("Failed to fetch user info")
				})
				//setLoading(false);  // no need, wait for redirect
			},
			(error, response) => {
				setError(response ? response : 'could not sign up');
				setSubmitting(false);
			}
		)
	}

  return (
    <LayoutAuth title="Welcome to GummySearch!" subtitle="Let's set your account password">

      <Helmet><title>Beta</title></Helmet>

      {error ? (
        <div className="bg-red-500 p-2 px-3 font-medium rounded-sm mb-4">
          {error}
        </div>
      ) : ''}

      {!loading ? (
        <React.Fragment>
          <form className="space-y-6" action="#" onSubmit={submitForm}>

    	      <div>
    	        <label htmlFor="email" className="block uppercase text-sm opacity-75 font-medium">
    	          Email Address
    	        </label>
    	        <div className="mt-1">
    	          <input id="email" name="email" type="email" autoComplete="email" required autoFocus
                  placeholder="Email Address" autocapitalize="none"
                  className="appearance-none bg-gray-700 block w-full p-3 rounded-sm border-solid border-transparent shadow-sm focus:outline-none focus:ring-transparent focus:border-cyan-500"
                />
    	        </div>
    	      </div>

    	      <div>
    	        <label htmlFor="password" className="block uppercase text-sm opacity-75 font-medium">
    	          Password
    	        </label>
    	        <div className="mt-1">
                
    	          <input id="password" name="password" type="password" autoComplete="new-password" required minLength="8"
                  placeholder="Password"
                  className="appearance-none bg-gray-700 block w-full p-3 rounded-sm border-solid border-transparent shadow-sm focus:outline-none focus:ring-transparent focus:border-cyan-500"
    	          	data-for='password-info'
                  data-html={true}
    	          	data-tip="<div>
    								8 or more characters<br/>
    								Alphanumeric characters<br/>
    								No common passwords<br/>
    								Password can't be similar to email
    							</div>"
    	          />
                <ReactTooltip id='password-info' place="right" backgroundColor="#F3F1EE" textColor="#000000" effect="solid" />
    	        </div>
    	        
    	      </div>

    	      {/*<div className="flex items-center">
              	<input id="terms" name="terms" type="checkbox" required className="bg-evvy-cream rounded-sm border-cyan-500 cursor-pointer focus:outline-none focus:ring-cyan-500 active:ring-cyan-500 checked:bg-cyan-500" />
              	<label htmlFor="terms" className="block font-medium ml-2">
    	          I read and agree to the <a href="/terms-of-service" className="underline uppercase text-sm font-semibold tracking-wider" target="_blank" rel="noreferrer">Terms and Conditions</a>
    	        </label>
    	      </div>*/}

            <div className="mt-6">
              <button type="submit" className="cursor-pointer w-full justify-center tracking-wider p-3 font-semibold rounded-sm shadow-sm bg-cyan-500 focus:outline-none"
                disabled={submitting}
              >
                {loading ? 'Signing up...' : 'Sign up'}
              </button>
            </div>

    	    </form>


        </React.Fragment>
      ) : loading && !error ? (
        <div className="bg-gray-900 p-2 px-3 font-medium rounded-sm">
          Loading...
        </div>
      ) : ''}

      <div className="mt-6 text-sm">
        <div className="">
          <span className="opacity-75">Existing user? </span>
          <Link to="/login/" className="hover:underline text-right font-semibold mr-auto">Sign in</Link>
        </div>
      </div>

    </LayoutAuth>
  );
}

export default BetaRegister;
