import React, { useState, useEffect } from "react"
// import { PlusSmIcon } from '@heroicons/react/solid'

import MakeReviews from './productMakeReviews';

import { productsService } from "../../services/products";

import { capitalizeFirst } from '../../utils/text';
import { organizeProductMakes } from '../../utils/products';

// search for a new product category
const ProductNewCategory = ({
  collection,
  categories,
  loadingCategories,
  baseUrl,
  fetchProductCategories,
}) => {
  const [searchResults, setSearchResults] = useState(null);
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [searchAllSubreddits, setSearchAllSubreddits] = useState(false);
  const [searching, setSearching] = useState(false);
  const [adding, setAdding] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [countPostsToShow, setCountPostsToShow] = useState(10);
  const countToShow = 10;
  const [showAllProducts, setShowAllProducts] = useState(false);

  useEffect(() => {
    // if all makes have 0 or 1 reviews, show all products
    if (productMakes.filter((m) => m.reviews.length >= 2).length === 0) {
      setShowAllProducts(true);
    } else {
      setShowAllProducts(false);
    }
  }, [categoryDetails])

  /* Actions */
  const clearResults = () => {
    setSearchResults(null);
    setSearching(false);
    setAdding(false);
    setCountPostsToShow(8);
    setCategoryDetails(null)
  }

  const searchForCategory = () => {
    if (!keyword) return;
    setSearching(true);
    const params = {
      'category': keyword,
      'subreddit_names': searchAllSubreddits ? [] : collection.subreddit_names,
    }
    productsService.searchProductCategory(params, (response) => {
      setSearchResults(response.data);
      setSearching(false);
    }, (error) => {
      console.log(error);
      alert("Error searching for category");
      setSearching(false);
    })
  }

  // organize reviews by make (with nested models)
  const productMakes = categoryDetails ? organizeProductMakes(categoryDetails) : [];

  return (
    <div style={{minHeight: "350px"}}>
      {!searchResults ? (
        <div className="sm:p-20">
          <div className="mb-4">
            <h3 className="text-lg leading-6 font-medium">Search for a product category</h3>
            <div className="text-sm text-gray-400">
              Enter a keyword to search for a product category.
            </div>
          </div>

          <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                searchForCategory()
              }
            }}
            placeholder="Keyword Research Tool"
            className="border-2 border-gray-500 bg-transparent rounded-md p-2 w-full"
            autoFocus="yes" autoComplete="off"
          />

          <div className="my-2">
            <input id="searchCollectionSubreddits" type="checkbox" checked={!searchAllSubreddits} onChange={(e) => setSearchAllSubreddits(!e.target.checked)} />
            <label htmlFor="searchCollectionSubreddits" className="ml-2 mr-8">This Audience</label>

            <input id="searchAllSubreddits" type="checkbox" checked={searchAllSubreddits} onChange={(e) => setSearchAllSubreddits(e.target.checked)} />
            <label htmlFor="searchAllSubreddits" className="ml-2">All Subreddits</label>
          </div>

          <button onClick={searchForCategory}
            className="mt-2 bg-cyan-500 text-white rounded-md p-2 w-full"
            disabled={searching || !keyword}
          >
            {searching ? "Searching..." : "Search for Category"}
          </button>
        </div>
      ) : !categoryDetails ? (
        <div className="pr-2">
          <h3>
            Found relevant posts <span className="text-gray-400">{searchResults.recommendation_submissions.length}</span>
          </h3>
          <ul className="list-disc pr-4 ml-6">
            {searchResults.recommendation_submissions.slice(0, countPostsToShow).map((submission) => (
              <li className="list-disc" key={submission.id}>
                <h4 className="w-full text-nowrap overflow-hidden whitespace-nowrap text-ellipsis">
                  <span className="text-gray-400">{submission.subreddit_name}</span> {submission.title}
                </h4>
              </li>
            ))}
          </ul>

          {countPostsToShow < searchResults.recommendation_submissions.length && (
            <span className="pl-2 cursor-pointer text-gray-500 hover:text-gray-200" onClick={() => setCountPostsToShow(searchResults.recommendation_submissions.length)}>
              + Show {searchResults.recommendation_submissions.length - countPostsToShow} more
            </span>
          )}

          {searchResults.recommendation_submissions.length == 0 ? (
            <div className="mt-4 text-gray-400">
              No results found, <span className="text-cyan-400 cursor-pointer" onClick={clearResults}>try another search</span>, or expand to all subreddits.
            </div>
          ) : (
            <div className="mt-4">
              <p>Do these posts look like what you're looking for?</p>
              <button className="mt-2 bg-cyan-500 text-white rounded-md p-2 w-full"
                disabled={adding || searchResults.recommendation_submissions.length == 0}
                onClick={() => {
                  setAdding(true);
                  const params = {
                    'category': keyword,
                    'subreddit_names': searchAllSubreddits ? [] : collection.subreddit_names,
                  }
                  productsService.addProductCategory(params, (response) => {
                    setCategoryDetails(response.data);
                    setAdding(false);

                    // in the background, fetch the updated product categories
                    fetchProductCategories();
                  }, (error) => {
                    console.log(error);
                    alert("Error adding category");
                    setAdding(false);
                  })
                }}>
                  {adding ? "Analyzing, could take up to 60 seconds" : "Yes, analyze the best category products"}
              </button>
              <button className="mt-2 bg-gray-800 text-white rounded-md p-2 w-full"
                disabled={adding}
                onClick={() => {
                  setCategoryDetails(null);
                  setSearchResults(null);
                }}>
                  No, try another search
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="pr-2">
          <h3 className="flex text-sm items-center w-full">
            <div className="text-gray-300 font-semibold flex items-center">
              {capitalizeFirst(categoryDetails.name)} options
              <p className="ml-2 text-gray-500 truncate">
                <span>{productMakes.length}</span>
              </p>
            </div>
          </h3>
          <div className="mt-2 space-y-2">
            {productMakes.filter((m, i) => showAllProducts || i < countToShow).map((m , i) => (
              <MakeReviews key={m.make} make={m} index={i}/>
            ))}
            {productMakes.length === 0 && (
              <div className="py-2 text-center">
                <span className="pl-2 cursor-pointer text-gray-500 hover:text-gray-200" onClick={clearResults}>
                  No product reviews found. Try another search.
                </span>
              </div>
            )}

            {!showAllProducts && (productMakes.length > countToShow) && (
              <div className="py-2 text-center">
                <span className="pl-2 cursor-pointer text-gray-500 hover:text-gray-200" onClick={() => setShowAllProducts(true)}>
                  + Show {productMakes.length - countToShow} more
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}


export default ProductNewCategory;
