import React, { useState } from "react";
// import {
//   withRouter,
// } from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";

// import { collectionsService } from '../../services/collections';
// import ErrorBar from "../common/errorBar";


const AudiencesInfoModal = ({ closeModal, history }) => {
  

  return (
    <Modal close={closeModal} widthClass={'max-w-3xl'}>
      <Helmet><title>Audiences | Info</title></Helmet>
      
      <div className="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 opacity-50 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>

        <h3 className="text-lg leading-6 font-medium">
          Audiences
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="text-md">
        <div className="mt-4">
          <div className="opacity-50 mb-1">About Audiences on GummySearch</div>
          <p>
            Audiences are collections of Subreddits dedicated to one topic. They help you get organized so that you can analyze a niche quickly or search for targeted conversations.
          </p>
          <p className="mt-2">
            If you have multiple customer types or niches to research, set up multiple audiences to search/explore them independently.
          </p>
        </div> 

        {/*<div className="mt-4">
          <div className="opacity-50 mb-1">Best Practices</div>
          <ul className="pl-6 list-disc">
            <li>Set up multiple audiences if you have multiple customer types</li>
            <li>Go broad with your audience, like "Software Developers" instead of "Software Developers that like using ___________"</li>
            <li>Only after setting up your broad audience, search conversations related to your specific product/business/idea</li>
          </ul>
        </div> */}

        <div className="mt-4">
          <div className="opacity-50 mb-1">Creating Audiences</div>
          <div className="sm:flex space-x-4">
            <div className="flex-1">
              <p>
                You can create a new one by searching for keywords that your target audience might mention, and then selecting the relevant subreddits. You may also select an example audience to get started.
              </p>
              <p className="mt-2">
                To learn more about creating audiences, read <a className="underline" href="https://gummysearch.com/how-to/discover-subreddits/" target="_blank">this blog post</a> or watch this video.
              </p>
            </div>
            <div className="flex-1 mt-2 sm:mt-0">
              <iframe width="100%" height="180px" src="https://www.youtube.com/embed/phN1HSgmTO4" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div> 

        <div className="mt-4">
          <div className="opacity-50 mb-1">Explore Growing Communities</div>
          <div className="sm:flex space-x-4">
            <div className="flex-1">
              For those who don't have an audience type in mind, GummySearch keeps track of active, largest, engaged, and growing communities on Reddit. Go to the "Trending" page to explore them and create audiences from these communities.
            </div>
            <div className="flex-1 mt-2 sm:mt-0">
              <iframe width="100%" height="180px" src="https://www.youtube.com/embed/TUDM6gcoC28" title="YouTube video player" frameborder="0" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>


    </Modal>
  );
};

export default AudiencesInfoModal;

