import { postRequest, getRequest, patchRequest, deleteRequest } from '../utils/axios';
import { toQueryString } from '../utils/urls';
import axios from 'axios';


export const savedSearchesService = {
    getSavedSearches,
    getSavedSearch,
    updateSavedSearch,
    createSavedSearch,
    createSavedList,
    deleteSavedSearch,
    getVelocities,
    filterSavedSearches,

    getManualSavedSearches,
    getManualConversations,
    addManualConversation,
    addManualConversations,
    deleteManualConversation,

    // fetching the unread counts
    getUnreadCounts,  // old method, to remove
};

function getSavedSearches(onSuccess, onError) {
  return getRequest(
    `/api/v1/saved-searches/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function filterSavedSearches(params, onSuccess, onError) {
  return getRequest(
    `/api/v1/saved-searches/${toQueryString(params)}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getSavedSearch(hash, onSuccess, onError) {
  return getRequest(
    `/api/v1/saved-searches/${hash}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function updateSavedSearch(hash, data, onSuccess, onError) {
  return patchRequest(
    `/api/v1/saved-searches/${hash}/`, data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}


function deleteSavedSearch(hash, onSuccess, onError) {
  return deleteRequest(
    `/api/v1/saved-searches/${hash}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function createSavedSearch(name, type, params, onSuccess, onError) {
  return postRequest(
    `/api/v1/saved-searches/`,
    {'name': name, 'params': params, 'search_type': type},
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// specifically for a manual type save
function createSavedList(params, onSuccess, onError) {
  const paramsToSave = Object.assign({'search_type': 'MA'}, params);
  return postRequest(
    `/api/v1/saved-searches/`, paramsToSave,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// fetches ALL counts
function getUnreadCounts(onSuccess, onError) {
  return getRequest(
    `/api/v1/saved-searches/unread_counts/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// TODO: add hashes param?
function getVelocities(onSuccess, onError) {
  return getRequest(
    `/api/v1/saved-searches/velocity/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getManualSavedSearches(onSuccess, onError) {
  return getRequest(
    `/api/v1/saved-searches/?search_type=MA`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function getManualConversations(hash, onSuccess, onError) {
  return getRequest(
    `/api/v1/saved-search-conversations/?saved_search__hash=${hash}`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function addManualConversation(data, onSuccess, onError) {
  return postRequest(
    `/api/v1/saved-search-conversations/`, data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

// data is list of arrays
function addManualConversations(data, onSuccess, onError) {
  console.log("data", data)
  return postRequest(
    `/api/v1/saved-search-conversations/bulk_add/`, data,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

function deleteManualConversation(hash, onSuccess, onError) {
  return deleteRequest(
    `/api/v1/saved-search-conversations/${hash}/`,
    (response) => {
      if (onSuccess){
        return onSuccess(response)
      }
    },
    (error, response) => {
      if (onError){
        return onError(error, response)
      }
    }
  )
}

