import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {
  Link
} from "react-router-dom";
import { withRouter } from "react-router";

import { Popover } from 'react-tiny-popover'

import SubredditPopover from "./subredditpopover";

import { getRequest } from '../../utils/axios';
import { readableNumber } from '../../utils/text';
import { getParamsFromUrl, toQueryString } from '../../utils/urls';
import { trackEvent } from '../../utils/tracking';
import { searchesService } from '../../services/searches';


// component that wraps another component, which represents a redditor name
// always opens on hover on web. can open on click optionally.
const RedditorPopover = ({name, positions, children, getPopoverParentElement, audiences}) => {
  const openOnClick = false;  // disable for now. if you want this, move from here to props
  const [isOpen, setIsOpen] = useState(false);
  const [redditor, setRedditor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelaying, setIsDelaying] = useState(false);
  const [error, setError] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  // const isValidName = name && name.substr(0, 2) !== 'u/' && name.substr(0, 4) !== 'r/u_';
  const strippedName = (name && name.substr(0, 2) === 'u/') ? name.slice(2) : name; 
  const isMobile = window.screen.width < 600;  // no hovering on mobile, just clicks
  const openOnHover = !isMobile;

  // map subreddit names to audiences
  var subredditAudienceDict = {};
  (audiences || []).forEach((a) => {
    a.subreddit_names.forEach((s) => {
      if (subredditAudienceDict[s]){
        subredditAudienceDict[s] += a.name
      } else {
        subredditAudienceDict[s] = [a.name]
      }
    })
  })

  // effects
  useEffect(() => {
    if (!isLoading && strippedName){
      if (!redditor || redditor.name !== strippedName){
        if (openOnClick && isOpen){
          fetchRedditor(strippedName)
        } else if (openOnHover && isHovering){
          fetchRedditor(strippedName)
        }
      }
    }
  }, [
    isOpen,
    isHovering,
  ]); // when opening, go fetch redditor info

  // actions
  const fetchRedditor = (name) => {
    if (!name) return;

    // make request and set results
    setIsLoading(true);
    setError(false);
    getRequest(`/api/v1/reddit/redditor/${name}/`,
      (response) => {
        setRedditor(response.data);
        setIsLoading(false);
      }, (error) => {
        setRedditor(null);
        setError("Error fetching user")
        setIsLoading(false);
      });
  }

  const setDelay = () => {
    // delay showing the popover for a bit
    if (isDelaying) return;

    setIsDelaying(true);
    setTimeout(() => {
      setIsDelaying(false)
    }, 500)
  }


  // content for popover
  const getContent = () => {

    // if (isLoading){
    //   return '';  // dont show anything on load now, as we have a fast cache
    // }

    return (
      <div className="z-50 w-96 rounded-md overflow-hidden shadow-lg bg-white" role="menu" aria-orientation="vertical" aria-labelledby="options-menu"
        onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
        onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
      >
        {isDelaying ? (
          <div className="flex-1 bg-white p-6 text-center text-red-400">...</div>
        ) : isLoading ? (
          <div className="flex-1 bg-white p-6 text-center text-gray-400">loading...</div>
        ) : error ? (
          <div className="flex-1 bg-white p-6 text-center text-red-500">{error}</div>
        ) : false && redditor && redditor.over_18 ? (
          <div className="flex-1 bg-white p-6 text-center text-red-500">
            {/* off for now */}
            This reddit profile contains adult content.<br/>
            That's not really what GummySearch is for.<br/>
            If you'd like to see it, go to Reddit directly.
          </div>
        ) : redditor ? (
          <React.Fragment>
            <div className="flex-1 bg-white p-4 flex flex-col justify-between">
              <div className="flex items-center">
                {redditor.icon ? (
                  <img className="h-12 w-12 mr-4 rounded-md" src={redditor.icon} alt={redditor.name} />
                ) : ''}
                <div>
                  <h2 className="text-xl font-semibold text-gray-900 flex items-center">
                    <a href={redditor.link} target="_blank" rel="noreferrer" className="cursor-pointer hover:underline"
                      onClick={() => {
                        trackEvent("ExternalRedirect", {
                          "platform": "Reddit",
                          "type": "Redditor",
                          "platform_id": redditor.name,
                        });
                        searchesService.saveRedirect("RUS", redditor.id, redditor.name);
                      }}
                    >
                      u/{ redditor.name }
                    </a>
                    {redditor.is_moderator ? (
                      <div className="text-xs py-1 px-2 text-xs ml-2 bg-gray-100 text-gray-800 rounded-sm">Moderator</div>
                    ) : ''}
                  </h2>
                  <div>
                    <Link to={`/reddit/?type=submissions&usernames=${redditor.name}&timeframe=all&backend=praw`}
                      className={`text-xs font-bold mt-2 text-cyan-700 opacity-80 hover:opacity-100 hover:underline`}>
                      View Submissions
                    </Link>
                  </div>
                </div>
              </div>

              <div className="flex-1">
                <p className={`mt-3 text-gray-500 text-sm overflow-ellipsis ${true ? '' : 'cursor-pointer line-clamp-3'}`}
                  // onClick={() => setShowDescription(true)}
                >
                  { redditor.description }
                </p>
              </div>
              
              
              <div className="flex items-center text-sm">
                <div className="flex-1 mt-3">
                  <dt className="text-sm font-normal text-gray-900">
                    Joined
                  </dt>
                  <dd className="mt-1 flex items-center">
                    <div className="flex items-baseline text-md font-semibold text-cyan-600">
                      { redditor.created }
                    </div>
                  </dd>
                </div>

                <div className="flex-1 mt-3">
                  <dt className="text-sm font-normal text-gray-900">
                    Total Karma
                  </dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-md font-semibold text-cyan-600">
                      { readableNumber(redditor.karma_total) }
                    </div>
                  </dd>
                </div>

                <div className="flex-1 mt-3">
                  <dt className="text-sm font-normal text-gray-900">
                    Comment Karma
                  </dt>
                  <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-md font-semibold text-cyan-600">
                      { readableNumber(redditor.karma_comments) }
                    </div>
                  </dd>
                </div>
              </div>

              {redditor.subreddits && redditor.subreddits.length ? (
                <div id={`redditor-popover-trigger-${name}`} className="flex items-center text-sm">
                  <div className="flex-1 mt-3">
                    <dt className="text-sm font-normal text-gray-900">
                      Subreddits Frequented
                    </dt>
                    <dd className="mt-1 flex items-center flex-wrap">
                      {redditor.subreddits.map((s) => (
                        <SubredditPopover key={s}  name={s} openOnClick={true} openOnHover={true} positions={["top", "left", "bottom", "right"]}
                          getPopoverParentElement={() => document.getElementById(`redditor-popover-trigger-${name}`)}
                        >
                          <div className={`cursor-pointer text-xs py-1 px-2 text-xs mr-2 mb-1 bg-gray-100 text-gray-800 rounded-sm flex items-center`}>
                            <span>{s.includes('r/') ? s : `r/${s}`}</span>
                            {subredditAudienceDict[s.replace('r/', '')] ? (
                              <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-4 w-4 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                            ) : ''}
                          </div>
                        </SubredditPopover>
                      ))}
                    </dd>
                  </div>
                </div>
              ) : ''}
            </div>
          </React.Fragment>
        ) : ''}
      </div>
    )
  }

  var parentElement = window.document.body;
  if (getPopoverParentElement && getPopoverParentElement()){
    parentElement = getPopoverParentElement()
  }

  return (
    <Popover
      isOpen={(isOpen || isHovering) && !isDelaying}
      positions={positions || ['bottom', 'top', 'right', 'left']}
      align="start"
      content={getContent()}
      parentElement={parentElement}
      // onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
      // onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
    >
      <span className={openOnClick ? 'cursor-pointer': ''}
        
        onClick={() => openOnClick && setIsOpen(!isOpen)}
        onMouseEnter={() => {
          if (openOnHover){
            setDelay(); // delay if first time
            setIsHovering(true)
          }
        }}
        onMouseLeave={() => {
          if (openOnHover){
            setIsDelaying(false)
            setIsHovering(false)
          }
        }}
      >{ children }</span>
    </Popover>
  )
}


const mapStateToProps = state => {
  const {
    audiences,
  } = state.audiences;

  return {
    audiences,
  }
};

const mapDispatchToProps = (dispatch) => {
  // const fetchAudiences = (callback, onError) => {
  //   dispatch(audiencesOperations.fetchAudiences(callback, onError))
  // };
  return {
    // fetchAudiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedditorPopover);

