const defaultParams = {
	// 'backend': 'psaw',  // now comes from user config env section
	'timeframe': 'all',
	'sort': 'new',
	'subreddits': '',
    'collection': '',
	'usernames': '',
    'before-timestamp': '',
    'exclude-keyword': '',
    'exclude-subreddits': '',
    'exclude-usernames': '',
    'exclude-duplicates': '',
    // 'ids': '',
    'limit': 100,
}

export {
    defaultParams
}

