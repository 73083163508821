import React, { useState, useEffect } from "react";
import {
  Link,
} from "react-router-dom";
import { withRouter } from "react-router";
import {
  TrendingUpIcon, InformationCircleIcon, QuestionMarkCircleIcon, LightBulbIcon
} from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
import { Popover, Transition } from '@headlessui/react'

import ErrorBoundary from "../common/errorboundary";

// import { readableNumber } from '../../utils/text';
// import { getParamsFromUrl, toQueryString } from '../../utils/urls';
// import { trackEvent } from '../../utils/tracking';

import { searchesService } from '../../services/searches';


// shows search bar for within audience. Also shows recent searches.
const AudienceSearch = ({
  collection,
  history,
}) => {
  const [keyword, setKeyword] = useState('');
  const [recentSearches, setRecentSearches] = useState([]);
  const [loadingRecentSearches, setLoadingRecentSearches] = useState(false);

  // effects
  useEffect(() => {
    // fetch recent searches in this audience
    fetchRecentSearches();
  }, []);

  const fetchRecentSearches = () => {
    setLoadingRecentSearches(true)
    searchesService.recentSearches({'collection': collection.hash}, (results) => {
      if (results && results.data){
        setRecentSearches(results.data)
        setLoadingRecentSearches(false)
      }
    }, () => {
      setLoadingRecentSearches(false)
    })
  }

  const performSearch = (e) => {
    e.preventDefault()
    history.push(`/audience/${collection.hash}/search/?keyword=${encodeURIComponent(keyword)}`);
    setKeyword(''); // clear the form when we open the drawer
  }

  return (
    <div className="flex-1 mt-4 sm:mt-0">
      <label className="text-lg font-medium">
        Keyword Search
      </label>

      <div className="mt-2 relative text-gray-400 focus-within:text-white">
        <form onSubmit={performSearch}>
          <input id="keyword-search" type="text" placeholder="Keyword search in audience" autoComplete="off" spellCheck="off" autoFocus="on"
            className="text-sm sm:text-base block bg-gray-800 w-full border-transparent p-4 pl-12 placeholder-gray-500 rounded-md focus:border-transparent focus:ring-0"
            disabled={!collection}
            value={keyword} onChange={(e) => {
              setKeyword(e.target.value);
            }}
          />
        </form>
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-4">
          <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
          </svg>
        </div>
      </div>

      <div className="flex flex-wrap">
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={`mt-2 mr-2 p-1 text-xs text-white border hover:bg-gray-800 border-gray-700 cursor-pointer rounded-md flex items-center focus:outline-none focus:ring-transparent focus:border-none`}
                // className={`flex-shrink-0 inline-flex p-2 items-center justify-center focus:outline-none focus:ring-transparent focus:border-none rounded-lg ${open ? 'bg-gray-900 text-white' : 'text-gray-400 hover:bg-gray-700'}`}
              >
                <LightBulbIcon className="h-4 w-4 mr-1 opacity-25"/>
                <span>Examples</span>
              </Popover.Button>

              <Transition
                show={open}
                as={React.Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  static
                  className="absolute z-10 bg-white text-gray-900 shadow-lg rounded-lg left-full top-2 p-2 w-screen max-w-xs sm:max-w-md"
                >
                  <div className="text-sm p-2">
                    <div className="mb-2 font-semibold">
                      Read the <a className="underline" href="https://gummysearch.com/insights/keywords-to-track-on-reddit/" target="_blank">
                      keyword guide
                    </a> for useful words to search/track</div>

                    <label className="opacity-30 text-xs font-bold">Standard fuzzy search</label>
                    <div className="mb-1">Reddit Marketing</div>

                    <label className="opacity-30 text-xs font-bold">Quotes ensure exact match</label>
                    <div className="mb-1">"Reddit is"</div>

                    <label className="opacity-30 text-xs font-bold">Either keyword</label>
                    <div className="mb-1">"customer development" OR "audience research"</div>

                    <label className="opacity-30 text-xs font-bold">Both keywords</label>
                    <div className="mb-1">"customer development" AND "audience research"</div>

                    <label className="opacity-30 text-xs font-bold">Complex AND/OR</label>
                    <div className="mb-1">customer AND (research OR development)</div>

                    <label className="opacity-30 text-xs font-bold">Exclude keyword</label>
                    <div className="mb-1">customer AND NOT research</div>

                    <label className="opacity-30 text-xs font-bold">Search title only</label>
                    <div className="mb-1">title:reddit</div>

                    <label className="opacity-30 text-xs font-bold">Search title with OR statement</label>
                    <div className="mb-1">title:reddit OR title:twitter</div>

                    
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
        {Array.from(new Set(recentSearches.filter(s => s.params.keyword && !s.params.keyword.includes(',')).map(s => s.params.keyword))).map(k => (
          <Link key={k} to={`/audience/${collection.hash}/search/?keyword=${encodeURIComponent(k)}`}
            className={`mt-2 mr-2 p-1 text-xs text-white border ${keyword === k ? 'bg-gray-700 border-gray-600' : 'hover:bg-gray-800 border-gray-700'} cursor-pointer rounded-md flex items-center`}
            // onClick={(e) => {
            //   // go to that search page
            //   history.push(`/audience/${collection.hash}/search/?keyword=${encodeURIComponent(k)}`);
            // }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 mr-1 opacity-25">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

            <div>{k}</div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default withRouter(AudienceSearch);

