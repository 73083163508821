import React, { useState, useEffect } from "react";
// import {
//   withRouter,
// } from "react-router-dom";
import Modal from "../common/modal";
// import { Helmet } from "react-helmet";

// import { collectionsService } from '../../services/collections';
// import ErrorBar from "../common/errorBar";

import { recordAskedToSaveKeyword, recordDontAskAgain } from '../../utils/keywords';
import { trackEvent } from '../../utils/tracking';


const SaveKeywordInfoModal = ({ closeModal, keyword, trackKeywordButton }) => {

  useEffect(() => {
    if (keyword){
      // indicate that we saw this tip for this keyword
      recordAskedToSaveKeyword(keyword)
    }
  }, [keyword]);
  
  return (
    <Modal close={() => {
      closeModal(); // close the modal

      trackEvent("ClickedButton", {
        "type": "save-keyword-modal",
        "value": "close-modal-background"
      });

      if (trackKeywordButton){
        setTimeout(() => {
          trackKeywordButton.focus(); // show user where to track
        }, 200);
      }
    }}>
      {/*<Helmet><title>Save Keyword</title></Helmet>*/}
      
      <div className="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="flex-shrink-0 h-6 w-6 opacity-50 mr-2" fill="rgba(8, 145, 178,0.15)" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
        </svg>

        <h3 className="text-lg leading-6 font-medium">
          Save a tracked keyword for "{keyword}"?
        </h3>

        <button className="focus:outline-none ml-auto" onClick={() => {
          closeModal(); // close the modal

          trackEvent("ClickedButton", {
            "type": "save-keyword-modal",
            "value": "close-modal"
          });
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="text-md">
        <div className="mt-4">
          <div>
            Looks like you found a useful conversation on Reddit, nice!
          </div>
          <div className="mt-4">
            If you want to see future ones like it, press the <span className="px-1.5 py-1 rounded-md relative top-0.5 bg-gray-700 inline-block">
              <div className="flex items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="rgba(8, 145, 178,0.15)" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg> Track
              </div>
            </span> button to track this keyword!
          </div>
          <div className="mt-4">
            You can name this tracked keyword search and access the results from your conversations tab anytime you want.
          </div>
        </div> 


        <div className="mt-8 text-center">
          <button className="mt-2 md:mt-0 px-20 mx-auto text-center p-2 px-4 border border-solid border-transparent font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
            onClick={() => {
              closeModal(); // close the modal
              trackEvent("ClickedButton", {
                "type": "save-keyword-modal",
                "value": "main-cta"
              });

              if (trackKeywordButton){
                setTimeout(() => {
                  trackKeywordButton.focus(); // show user where to track
                }, 200);
              }
            }}
          >
            Got it!
          </button>
          <div className="text-center p-4 pb-2">
            <span className="text-sm opacity-90 hover:opacity-100 cursor-pointer"
              onClick={() => {
                recordDontAskAgain(); // don't show any more tips
                closeModal(); // close the modal
                trackEvent("ClickedButton", {
                "type": "save-keyword-modal",
                "value": "dont-show"
              });
              }}
            >
                Don't show me keyword tracking tips
            </span>
          </div>
        </div>
      </div>


    </Modal>
  );
};

export default SaveKeywordInfoModal;

