import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";

import { Popover } from 'react-tiny-popover'

import SubredditPopover from "./subredditpopover";
import PreviewRedditSubmission from "./submissionpreview";
import PreviewRedditComment from "./commentpreview";
import SubmissionTypeIcon from "../reddit/submissiontypeicon";

import { getRequest } from '../../utils/axios';
import { readableNumber } from '../../utils/text';
import { getParamsFromUrl, toQueryString } from '../../utils/urls';
import { trackEvent } from '../../utils/tracking';
import { searchesService } from '../../services/searches';
import { redditService } from '../../services/reddit';



// displays either comments or submissions in a popover
const ConversationPopover = ({type, ids, positions, children, getPopoverParentElement}) => {
  const openOnClick = false;  // disable for now. if you want this, move from here to props
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const isMobile = window.screen.width < 600;  // no hovering on mobile, just clicks
  const openOnHover = !isMobile;

  // effects
  useEffect(() => {
    if (!isLoading && type && ids && !results.length){
      if (openOnClick && isOpen){
        fetchResults()
      } else if (openOnHover && isHovering){
        fetchResults()
      }
    }
  }, [
    isOpen,
    isHovering,
  ]); // when opening, go fetch results

  // actions
  const fetchResults = () => {
    
    if (!ids.length || isLoading) return;

    var searchType = null;
    if (type === "submissions"){
      searchType = redditService.performSubmissionSearch
    } else if (type === "comments"){
      searchType = redditService.performCommentSearch
    } else {
      alert("invalid search type");
      return;
    }

    // track the search
    const trackParams = {
      "platform": "Reddit",
      "type": type,
    }
    trackEvent("PerformConversationSearch", trackParams);

    // go fetch!
    setIsLoading(true);
    setError(false);
    searchType({'ids': ids, 'backend': 'praw'},
      (response) => {
        setResults(response.data.results);
        setIsLoading(false);
      }, (error, response) => {
        setIsLoading(false);
        if (error.response && error.response.status === 500){
          setError("Error fetching content from Reddit.")
        } else {
          setError(response || "Error fetching content from Reddit.")
        }
      });

  }


  // content for popover
  const getContent = () => {

    if (!ids || !ids.length){
      return '';  // dont show anything if there are no ids here
    }

    return (
      <div className="z-40 w-96 rounded-md overflow-hidden shadow-lg bg-white" role="menu" aria-orientation="vertical" aria-labelledby="options-menu"
        onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
        onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
      >
        {isLoading ? (
          <div className="flex-1 bg-white p-6 text-center text-gray-400">loading...</div>
        ) : error ? (
          <div className="flex-1 bg-white p-6 text-center text-red-500">{error}</div>
        ) : results ? (
          <React.Fragment>
            <div className="rounded-md bg-white text-gray-700 flex flex-col w-80 sm:w-96">
              {/*<div className="flex border-b-2 border-gray-200 p-2 sm:px-4 w-full items-center flex-shrink-0">
                <div className="opacity-50">
                  <SubmissionTypeIcon type={'text'} />
                </div>
                <div className="flex-grow ml-2">
                  <h2 className="text-md font-medium m-0">Submissions</h2>
                </div>
              </div>*/}
              <div className="max-h-60 overflow-y-scroll">
                <ul className="divide-y divide-gray-200 py-2 px-2 sm:px-4">
                  {results.map(item => (
                    <React.Fragment key={item.id}>
                      {item._meta.type === 'reddit-submission' ? (
                        <PreviewRedditSubmission item={item} showMetadata={true} showRedirect={true} trackRedirect={true} />
                      ) : item._meta.type === 'reddit-comment' ? (
                        <PreviewRedditComment item={item} showMetadata={true} showRedirect={true} trackRedirect={true} />
                      ) : ''}
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
          </React.Fragment>
        ) : ''}
      </div>
    )
  }

  return (
    <Popover
      isOpen={(isOpen || isHovering)}
      positions={positions || ['left', 'bottom', 'top', 'right']}
      align="start"
      content={getContent()}
      parentElement={getPopoverParentElement ? getPopoverParentElement() : window.document.body}
      // onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
      // onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
    >
      <span className={openOnClick ? 'cursor-pointer': ''}
        
        onClick={() => openOnClick && setIsOpen(!isOpen)}
        onMouseEnter={() => openOnHover ? setIsHovering(true) : null}
        onMouseLeave={() => openOnHover ? setIsHovering(false) : null}
      >{ children }</span>
    </Popover>
  )
}

export default ConversationPopover;

