// reducers.js
import { combineReducers } from 'redux';

import types from './types';

/*
 * This is a reducer that uses localstorage to save UI preferences
 * It's different than other reducers. look at loadState and saveState
 */

// initial state
const INITIAL_STATE = {
  audienceDisplayType: 'card', // other option is 'row'
  subredditDisplayType: 'card', // other option is 'row'
  
  audienceSort: 'Recent', // options are Recent/Largest/Growth
  subredditSort: 'Recent', // options are Recent/Largest/Growth

  // note that we can can set any key using SET_UI_STATE
}

// these guys will be loaded from localstorage when the app loads
const persistingKeys = [
  'audienceDisplayType',
  'subredditDisplayType',

  'audienceSort',
  'subredditSort',
]

// get state. ideally from localstorage
const loadState = () => {
  try {
    // get state from local storage if it exists
    var serializedState = localStorage.getItem('uiState');
    if (serializedState === null) {
      return INITIAL_STATE;
    }
    serializedState = JSON.parse(serializedState);

    // we have an allow list of keys to persist from this reducer
    var persistedState = {};
    persistingKeys.forEach((k) => {
      persistedState[k] = serializedState[k] || INITIAL_STATE[k];
    });

    // overlay initial state with the localstorage state if it exists
    return Object.assign(INITIAL_STATE, persistedState);
  } catch (err) {
    return INITIAL_STATE;
  }
}; 

// save state to localstorage, then return.
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('uiState', serializedState);
  } catch (err) {
    // ignore write errors
    console.error("failed to write state to ui reducer");
  }
  return state;  // in either case, return (it's used in uiReducer)
};

const uiReducer = (state=loadState(), action) => {
  switch(action.type) {

    // generic, can set any key here
    case types.SET_UI_STATE: {
      const { key, value } = action;
      return saveState({
        ...state,
        [key]: value,
      })
    }

    default: return state;
  }
}

export default uiReducer;