import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Helmet } from "react-helmet";

import Modal from "../common/modal";
import NavBar from "../common/navbar";
import PropsRoute from "../common/propsroute";
import SubscriptionLimitResource from "./subscriptionLimitResource";
import SubscriptionLimitLTD from "./subscriptionLimitLTD";

import { RESOURCES } from "./constants";

// modal to increase limits of various resources
const SubscriptionLimitModal = ({ limits, closeModal, fetchCurrentUser, currentUser, setSuccess, setRefetchLimits }) => {

  const currentUserPlan = currentUser && currentUser.subscription && currentUser.subscription.key;
  if (!currentUserPlan) return ''; // no plan, don't show anything

  const isLtd = currentUser && currentUser.subscription && currentUser.subscription.billing_cycle === 'lifetime'

  // show tabs for resources where current plan has at least 1 quantity
  const tabs = RESOURCES.filter(r => r['allowances'][currentUserPlan]).map(r => {
    return {'name': r.name, 'link': `/account/subscription/limits/${r.id}/`, 'key': r.id}
  });
  if (!tabs || !tabs.length) return;

  return (
    <Modal close={closeModal} widthClass={'max-w-2xl bg-gray-800'}>
      <Helmet><title>Account | Subscription | Limits</title></Helmet>

      <div className="">
        <NavBar tabs={tabs}/>
      </div>

      <div className="py-4 h-80">
        
        {!isLtd ? (
          <Switch>
            <PropsRoute path="/account/subscription/limits/:resource/"
              component={SubscriptionLimitResource}
              currentUser={currentUser}
              closeModal={closeModal}
              setRefetchLimits={setRefetchLimits}
              fetchCurrentUser={fetchCurrentUser}
              setSuccess={setSuccess}
              limits={limits}
            />
            <Redirect exact path="/account/subscription/limits/" to={`/account/subscription/limits/${tabs[0]['key']}/`} />
          </Switch>
        ) : (
          <React.Fragment>
            <SubscriptionLimitLTD
              closeModal={closeModal}
              fetchCurrentUser={fetchCurrentUser}
              currentUser={currentUser}
              setSuccess={setSuccess}
            />
            <Redirect exact path="/account/subscription/limits/" to={`/account/subscription/limits/ltd/`} />
          </React.Fragment>
        )}
        
      </div>
    </Modal>
  );
};

export default SubscriptionLimitModal;

