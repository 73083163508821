import { FullStoryAPI } from 'react-fullstory';

// track an event
const trackEvent = (eventName, props) => {
  // GA
  if (typeof(window.gtag) !== "undefined"){
    // always track as custom event
    window.gtag('event', eventName, props);

    // also track certain events sa mapped to default events
    const eventMap = {
      'Register': 'StartTrial',
      'PaymentIntent': 'InitiateCheckout',
      'Subscribed': 'Subscribe',
    }
    var gtagEventName = eventMap[eventName];
    
    // customize props/event name for purchases (just made same as meta)
    var gtagProps = {};
    if (gtagEventName === 'Subscribe'){
      if (props.billing_cycle === 'day'){
        gtagEventName = 'Purchase';
        gtagProps = {'value': 10.0, currency: 'USD', transaction_id: ''}
      } else if (props.plan === 'pro'){
        gtagProps = {'value': 59.0, currency: 'USD'}
      } else if (props.plan === 'starter'){
        gtagProps = {'value': 29.0, currency: 'USD'}
      } else if (props.plan === 'mega'){
        gtagProps = {'value': 199.0, currency: 'USD'}
      }
    }
    if (gtagEventName){
      const sentToMap = {
        'StartTrial': 'AW-10893129705/4gv6CIXqtN8ZEOnvn8oo',
        'InitiateCheckout': 'AW-10893129705/pl4ACIjqtN8ZEOnvn8oo',
        'Subscribe': 'AW-10893129705/lnIfCP_ptN8ZEOnvn8oo',
        'Purchase': 'AW-10893129705/l_vrCILqtN8ZEOnvn8oo',
      }
      var sentToEventId = sentToMap[gtagEventName];
      window.gtag('event', 'conversion', Object.assign(gtagProps, {
        'send_to': sentToEventId,
      }));
    }
  }

  // plausible
  if (typeof(window.plausible) !== "undefined"){
    window.plausible(eventName, {
      'props': props
      // callback: () => console.info('Sent event'),
    });
  }

  // posthog
  if (typeof(window.posthog) !== "undefined"){
    window.posthog.capture(eventName, {
      'props': props
    });
  }

  // meta
  if (typeof(window.fbq) !== "undefined"){
    // always track as custom event
    window.fbq('trackCustom', eventName, props);

    // also track certain events based on mapping
    const eventMap = {
      'Register': 'StartTrial',
      'PaymentIntent': 'InitiateCheckout',
      'Subscribed': 'Subscribe',
    }
    var metaEventName = eventMap[eventName];
    
    // customize props/event name for purchases
    var metaProps = {};
    if (metaEventName === 'Subscribe'){
      if (props.billing_cycle === 'day'){
        metaEventName = 'Purchase';
        metaProps = {'value': '10.00', currency: 'USD'}
      } else if (props.plan === 'pro'){
        metaProps = {'value': '59.00', currency: 'USD', predicted_ltv: '0.00'}
      } else if (props.plan === 'starter'){
        metaProps = {'value': '29.00', currency: 'USD', predicted_ltv: '0.00'}
      } else if (props.plan === 'mega'){
        metaProps = {'value': '199.00', currency: 'USD', predicted_ltv: '0.00'}
      }
    }
    if (metaEventName){
      window.fbq('track', metaEventName, metaProps);
    }
  }
}

// identify user to fullstory
const identifyUser = (userConfig) => {
  if (typeof(FullStoryAPI) !== "undefined" && userConfig){
    const userId = `${userConfig.env.name}-${userConfig.identity.id}`
    FullStoryAPI('identify', userId, {
      environment: userConfig.env.name,
      is_staff: userConfig.identity.is_staff,
      is_beta: userConfig.identity.is_beta,
      objective: userConfig.profile.objective,
      subscription: userConfig.subscription && userConfig.subscription.key,
    });
  }

  if ((typeof(window.posthog) !== "undefined") && userConfig){
    const userId = `${userConfig.env.name}-${userConfig.identity.id}`
    window.posthog.identify(
      userId,  // Replace 'distinct_id' with your user's unique identifier
      {
        email: userConfig.identity.email,
        environment: userConfig.env.name,
        objective: userConfig.profile.objective,
        subscription: userConfig.subscription && userConfig.subscription.key,
        billing_cycle: userConfig.subscription && userConfig.subscription.billing_cycle,
        login_days: userConfig.identity.login_days,
        is_new: userConfig.identity.is_new,
      },
    );
  }
}

const deidentifyUser = () => {
  if (typeof(FullStoryAPI) !== "undefined"){
    try {
      FullStoryAPI('anonymize') // ideally would use FullStoryAPI.anonymize() but it's not in the package yet
    } catch (error) {
      console.error("Tried FS anonymize but threw an error", error);
    }
  }
}

export {
    trackEvent,
    identifyUser,
    deidentifyUser,
}