import React, { useState } from "react";
import {
  Link, Switch, Route, Redirect
} from "react-router-dom";
import Modal from "../common/modal";
import { Helmet } from "react-helmet";

import { productsService } from "../../services/products";

import NavBar from "../common/navbar";
import ErrorBar from "../common/errorBar";
import PropsRoute from "../common/propsroute";
import FeaturePaywallWrapper from "../account/featurePaywallWrapper"

import ProductNewCategory from "./productNewCategory";
import ProductNewProduct from "./productNewProduct";


const AudienceProductsNewModal = ({
    collection,
    categories,
    loadingCategories,
    closeModal,
    baseUrl,
    fetchProductCategories,
    currentUser,
}) => {

  var tabs = [
      {'name': 'Category', 'link': `${baseUrl}category/`},
      {'name': 'Product', 'link': `${baseUrl}product/`},
  ]
  
  return (
    <Modal close={closeModal} widthClass={'max-w-3xl bg-gray-900'}>
      <Helmet><title>Audience | Products | New</title></Helmet>
      
      <div className="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 opacity-50 mr-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>

        <h3 className="text-lg leading-6 font-medium">
          Search a new product or category
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="mt-2 border-b border-solid border-gray-800">
        <NavBar tabs={tabs}/>
      </div>


      <div className="mt-4 text-md overflow-auto min-h-96">
        <FeaturePaywallWrapper featureKey="products" currentUser={currentUser}>
          <Switch>
            <Redirect from={`${baseUrl}`} exact to={`${baseUrl}category/`} />
            <PropsRoute component={ProductNewCategory} path={`${baseUrl}category/`}
              collection={collection}
              categories={categories}
              loadingCategories={loadingCategories}
              baseUrl={baseUrl}
              fetchProductCategories={fetchProductCategories}
            />
            <PropsRoute component={ProductNewProduct} path={`${baseUrl}product/`}
              collection={collection}
              categories={categories}
              loadingCategories={loadingCategories}
              baseUrl={baseUrl}
              fetchProductCategories={fetchProductCategories}
            />
          </Switch>
        </FeaturePaywallWrapper>

      </div>
    </Modal>
  );
};


export default AudienceProductsNewModal;



