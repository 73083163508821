import React, { useEffect, useState } from "react";
import Toggle from 'react-toggle'
import { InView } from 'react-intersection-observer';

import LoaderFancy from "../common/loaderfancy";
import SelectPopover from "../common/selectPopover";

import ResultSortMenu from "./resultsortmenu";
import ResultRowSubmission from "./resultrowsubmission";
import ResultRowComment from "./resultrowcomment";

import { getIcon } from '../../utils/icons';

const Results = ({
    results,
    filteredResults,
    filters,
    frontendFilters,
    setSingleFrontendFilter,
    fetching,
    keyword,
    location,
    applyNewFilters,
    clearFilters,
    clearFrontendFilters,
    currentUser,
    selectedIds, setSelectedIds, toggleSelectedId,
    className
  }) => {
  const count = results ? results.length : 0;
  const SHOW_INTERVAL = 10;
  const [numResultsToShow, setNumResultsToShow] = useState(SHOW_INTERVAL);

  const resultTypes = [
    {'name': 'Submissions', 'value': 'submissions'},
    {'name': 'Comments', 'value': 'comments'},
    // {'name': 'Submissions + Comments', 'value': 'reddit-all'},
  ]
  const currentResultType = resultTypes.filter(r => r.value === (filters.type || 'submissions'))[0]

  const sortTypes = [
    {'name': 'Recent', 'value': 'recent'},
    {'name': 'Upvotes', 'value': 'upvotes'},
    {'name': 'Comments', 'value': 'comments'},
    // {'name': 'Submissions + Comments', 'value': 'reddit-all'},
  ];
  const currentSortType = sortTypes.filter(r => r.value === (frontendFilters.sort || 'recent'))[0]

  // // effects
  useEffect(() => {
    // scroll to the top of the results
    setTimeout(() => {
      const div = document.getElementById('results-pane');
      if (div){
        div.scrollTop = 0
      }
    });
    
    // reset scroll interval
    setNumResultsToShow(SHOW_INTERVAL);
  }, [
    filteredResults,
    filteredResults.length,
  ]); // Run when results update

  // see if there are frontend filters
  const filtersApplied = filteredResults.length !== results.length;

  // how many subs we're filtering down to
  // var subredditFilterCount = null;
  // if (!frontendFilters.collection){
  //   if (frontendFilters && frontendFilters.subreddits && frontendFilters.subreddits.length){
  //     subredditFilterCount = frontendFilters.subreddits.length
  //   } else if (filters && filters.subreddits && filters.subreddits.length){
  //     subredditFilterCount = filters.subreddits.split(',').length
  //   }
  // }
	
  return (
    <div className={` ${className}`}>
      <div className="pb-2">
        <h2 className="flex-1 flex text-lg font-medium items-center">
          <span>Results</span>
          
          <p className="ml-2 mt-1 text-xs text-gray-500 truncate">
            {fetching ? (
              <span>
                <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </span>
            ) : selectedIds && selectedIds.length ? (
              <span>
                {selectedIds.length} selected
                <span className="ml-2 text-cyan-500 font-semibold cursor-pointer opacity-80 hover:opacity-100"
                  onClick={() => setSelectedIds([])}
                >
                  clear
                </span>
              </span>
            ) : (
              <span>{filtersApplied ? `${filteredResults.length} / ${count}`  : count}
                {false && filtersApplied ? (
                  <span className="ml-2 text-cyan-500 font-semibold cursor-pointer opacity-80 hover:opacity-100"
                    onClick={clearFrontendFilters}
                  >
                    clear
                  </span>
                ) : ''}
              </span>
            )}
          </p>

          {results && results.length ? (
            <div className="ml-auto flex items-center space-x-4" disabled={fetching || !filteredResults}>
              
              {filters.backend !== "praw" && currentUser.env.default_reddit_backend !== "praw" ? (
                <div className="ml-auto flex items-center">
                  <div className="text-xs opacity-50 mr-2">Type</div>
                  <SelectPopover
                    options={resultTypes}
                    currentOption={currentResultType}
                    setCurrentOption={(o) => {
                      applyNewFilters({
                        'type': o.value,
                        'backend': o.value === "comments" ? "psaw" : filters.backend, // switch from subs to comments turns on psaw
                      })
                    }}
                    labelField={'name'}
                  />
                </div>
              ) : ''}
              
              {filters.sort !== "top" && setSingleFrontendFilter ? (
                <div className="ml-auto flex items-center">
                  <div className="text-xs opacity-50 mr-2">Sort</div>
                  <SelectPopover
                    options={sortTypes}
                    currentOption={currentSortType}
                    setCurrentOption={(o) => {
                      setSingleFrontendFilter('sort', o.value);
                    }}
                    labelField={'name'}
                  />
                </div>
              ) : ''}

            </div>
          ) : ''}
        </h2>
        {/*{false && subredditFilterCount ? (
          <p className="mt-1 text-xs">
            <span className="opacity-50 mr-1">
              Filtered to {subredditFilterCount} subreddit{subredditFilterCount === 1 ? '' : 's'}
              {filters.timeframe && filters.timeframe !== 'all' ? ' and time-limited' : ''}
              .
            </span>
            <span className="text-cyan-500 text-semibold cursor-pointer hover:underline" onClick={() => {
              applyNewFilters({'subreddits': '', 'timeframe': 'all'})
            }}>clear filters</span>
          </p>
        ) : ''}*/}
      </div>

      {results && filteredResults && filteredResults.length !== 0 ? (
        <ul className={`relative z-0 ${fetching ? 'opacity-50' : ''}`}>
          {filteredResults.map((item, index) => (
            <div className="border-b border-gray-800" key={item.id}>
              {index > numResultsToShow ? (
                <div className={item._meta.type === 'reddit-submission' ? 'h-36' : 'h-24'}>
                  {/* Spacer (show for scrollbar, but don't render anything) */}

                  {numResultsToShow < filteredResults.length ? (
                    <InView as="div" onChange={(inView, entry) => inView ? setNumResultsToShow(numResultsToShow + SHOW_INTERVAL) : null}>
                      <div style={{height: '1px'}}></div>
                    </InView>
                  ) : ''}
                </div>
              ) : item._meta.type === 'reddit-submission' ? (
                <ResultRowSubmission key={item.id} item={item} location={location} keyword={keyword} backend={filters.backend}
                  isSelected={selectedIds.includes(item.id)} toggleSelected={() => toggleSelectedId(item.id)}
                />
              ) : item._meta.type === 'reddit-comment' ? (
                <ResultRowComment key={item.id} item={item} location={location} keyword={keyword} backend={filters.backend}
                  isSelected={selectedIds.includes(item.id)} toggleSelected={() => toggleSelectedId(item.id)}
                />
              ) : ''}
            </div>
          ))}

          {/*{numResultsToShow < filteredResults.length ? (
            <InView as="div" onChange={(inView, entry) => inView ? setNumResultsToShow(numResultsToShow + SHOW_INTERVAL) : null}>
              <div style={{height: '1px'}}></div>
            </InView>
          ) : (
            <div className="p-4 text-center text-xs m-auto text-white opacity-50 font-semibold">End of results</div>
          )}*/}

          <div className="p-8 text-center text-xs m-auto text-white opacity-50 font-semibold">End of results</div>

          {frontendFilters && frontendFilters.subreddits && frontendFilters.subreddits.length && filteredResults.length !== results.length ? (
            <div className="flex p-4 text-xs">
              <div className="cursor-pointer m-auto text-white opacity-75 hover:opacity-100 font-semibold"
                onClick={() => applyNewFilters({'subreddits': frontendFilters.subreddits.join(',')})}
              >Limit query to {frontendFilters.subreddits.join(', ')} to get older results</div>
            </div>
          ) : ''}
        </ul>
      ) : results && results.length > 0 ? (
        <div className="bg-gray-800 rounded-lg border border-gray-700 text-white">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium">
              No results for this series of filters
            </h3>
            <div className="mt-2 max-w-xl text-sm">
              <p>
                Please update your filters
              </p>
            </div>
          </div>
        </div>
      ) : results && results.length === 0 && !fetching ? (
        <div className="bg-gray-800 rounded-lg border border-gray-700 text-white">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium">
              Uh oh, I couldn't find anything
            </h3>
            <div className="mt-2 max-w-xl text-sm">
              <p>
                Please try another keyword
              </p>
            </div>
            {filters.backend !== "psaw" && currentUser.env.psaw_warning ? (
              <div className="mt-2 max-w-xl text-sm flex items-top opacity-50">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-2 flex-shrink-0">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>

                <p>{currentUser.env.psaw_warning}</p>
              </div>
            ) : ''}
            
          </div>
        </div>
      ) : fetching ? (
        <div className="h-96">
          <LoaderFancy />
        </div>
      ) : ''}
    </div>
  );
}

export default Results;

