import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import LoaderFancy from "../common/loaderfancy";
import Loader from "../common/loader";
// import SelectPopover from "../common/selectPopover";
import ErrorBar from "../common/errorBar";


import ResultsAnalyzePatterns from "../../components/language/patterns";


import { redditService } from '../../services/reddit';

// analyzes filtered results for tracked keyword
const TrackedKeywordAnalyze = ({
  history,
  location,
  savedSearch,
  currentUser,
  // baseUrl, // needed b/c this gets shown on multiple potential screens
  results,
  // fetching,
  keyword,
  filterParams,
  // setDownloadableResults,
}) => {
  
  return (
    <div className="p-4">
      <Helmet><title>Conversations | Tracked | Patterns</title></Helmet>
      <ResultsAnalyzePatterns
        currentUser={currentUser}
        results={results}
        filterParams={filterParams}
        limitOptions={[100, 200, 500]}
      />
    </div>
  );
}

export default TrackedKeywordAnalyze;

