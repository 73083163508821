import React, { useState, useEffect } from "react"

import Loader from "../../components/common/loader";
import ReactTooltip from 'react-tooltip';
import AiMinutePopover from "../language/aiminutepopover";
import AiMinuteUsage from "../language/aiminuteusage";

import {
  InformationCircleIcon
} from '@heroicons/react/outline'

import { profileService } from '../../services/profile';

const Limits = ({currentUser, refetchLimits}) => {
  const [loadingLimits, setLoadingLimits] = useState(false); 
  const [limits, setLimits] = useState(null); 

  useEffect(() => {
    getSubscriptionLimits();
  }, [refetchLimits]); // fetch when loading on init, also if refetchLimits changes

  const getSubscriptionLimits = () => {
    if (loadingLimits) return;

    // fetch the limits & current usage of the account
    setLoadingLimits(true)
    profileService.getLimits((response) => {
      const data = response.data
      setLimits(Object.assign(data, {

        // override tracked keywords allowed with the display value
        'tracked_keywords_allowed': Object.keys(data).includes('tracked_keywords_allowed_display') ? data.tracked_keywords_allowed_display : data.tracked_keywords_allowed,
      }))
      setLoadingLimits(false)
    })
  }

  if (loadingLimits || !limits) return (
    <div className="p-3.5 flex">
      <div className="mx-auto"><Loader /></div>
    </div>
  )

  return (
    <div className="space-y-1 max-w-md">
      <ReactTooltip id={`limits-tooltip`} effect="solid" place="right" backgroundColor="#fafafa" textColor="black" className="w-72"/>
            
      <div className="flex items-center">
        <div className="">Conversation Searches</div>
        <div className="ml-auto w-48 h-5 bg-gray-700 rounded-sm relative flex-shrink-0">
          <div className="absolute h-full p-0.5 text-white text-xs">{limits.conversation_searches_used} / {limits.conversation_searches_allowed || '∞'}</div>
          <div className={`rounded-sm p-0.5 h-full ${limits.conversation_searches_allowed && (limits.conversation_searches_used >= limits.conversation_searches_allowed) ? 'bg-yellow-500' : 'bg-cyan-600'}`}
            style={{width: (!limits.conversation_searches_allowed ? 1 : 100 * Math.min(limits.conversation_searches_used / limits.conversation_searches_allowed, 1)) + '%'}}></div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="">Audiences</div>
        <div className="ml-auto w-48 h-5 bg-gray-700 rounded-sm relative flex-shrink-0">
          <div className="absolute h-full p-0.5 text-white text-xs">{limits.audiences_used} / {limits.audiences_allowed}</div>
          <div className={`rounded-sm p-0.5 h-full ${limits.audiences_used >= limits.audiences_allowed ? 'bg-yellow-500' : 'bg-cyan-600'}`}
            style={{width: (100 * Math.min(limits.audiences_used / limits.audiences_allowed, 1)) + '%'}}></div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="">Tracked Keywords</div>
        <div className="ml-auto w-48 h-5 bg-gray-700 rounded-sm relative flex-shrink-0">
          <div className="absolute h-full p-0.5 text-white text-xs">{limits.tracked_keywords_used} / {limits.tracked_keywords_allowed}</div>
          <div className={`rounded-sm p-0.5 h-full ${limits.tracked_keywords_used >= limits.tracked_keywords_allowed ? 'bg-yellow-500' : 'bg-cyan-600'}`}
            style={{width: (!limits.tracked_keywords_allowed ? 1 : 100 * Math.min(limits.tracked_keywords_used / limits.tracked_keywords_allowed, 1)) + '%'}}></div>
        </div>
      </div>
      <div className="flex items-center">

        <div className="flex items-center">
          Monthly AI Minutes

          {/*<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 h-5 opacity-50 ml-2 -bottom-1"
            data-for={`limits-tooltip`}
            data-tip={"AI features are based on how much time they save you. Summarizing 100 reddit posts will take GummySearch <10 seconds but save you 60 minutes of reading time."}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
          </svg>*/}

          <AiMinutePopover limits={limits} hideBar={true} posiitons={['top', 'right', 'bottom', 'left']}>
            <InformationCircleIcon className="h-5 h-5 opacity-50 ml-2 mr-6 -bottom-1" aria-hidden="true"
              // data-for={`limits-tooltip`}
              // data-tip={"AI features are based on how much time they save you. Summarizing 100 reddit posts will take GummySearch <10 seconds but save you 60 minutes of reading time."}
            />
          </AiMinutePopover>

        </div>
        <div className="ml-auto w-48 h-5 bg-gray-700 rounded-sm relative flex-shrink-0">
          <div className="absolute h-full p-0.5 text-white text-xs">{limits.ai_minutes_used} / {limits.ai_minutes_allowed}</div>
          <div className={`rounded-sm p-0.5 h-full ${limits.ai_minutes_used >= limits.ai_minutes_allowed ? 'bg-yellow-500' : 'bg-cyan-600'}`}
            style={{width: (!limits.ai_minutes_allowed ? 1 : 100 * Math.min(limits.ai_minutes_used / limits.ai_minutes_allowed, 1)) + '%'}}></div>
        </div>

      </div>

      {limits.ai_minutes_used ? (
        <div className="pt-1">
          <AiMinuteUsage limits={limits} />
        </div>
      ) : ''}

    </div>
  );
}

export default Limits;
