import React, { useState, Fragment } from "react";
import ReactTooltip from 'react-tooltip';
import { Menu, Transition, Popover } from '@headlessui/react'
// import {
//   Link
// } from "react-router-dom";

import { searchesService } from '../../services/searches';
import { redditService } from '../../services/reddit';

import ErrorBoundary from "../common/errorboundary";
import SubredditTags from "../reddit/subreddittags";
import KeywordVelocity from "./keywordvelocity";

import { readableNumber } from '../../utils/text';
import { trackEvent } from '../../utils/tracking';
import { classNames } from '../../utils/tailwind';
// import { getParamsFromUrl, toQueryString } from '../../utils/urls';



import {
  DotsVerticalIcon
} from '@heroicons/react/solid'


// renders a subreddit for the audience discovery screen
const AudienceDiscoverySubreddit = ({
  name, subreddit, keywords, allKeywords, maxVelocity, loading,
  select, unselect, selected,
  isHiddenCommunity, isInCollection,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [hidden, setHidden] = useState(false);  // only if we just hid it (future hidden subs will be managed in discover page)

  // map of all keywords in order they show up in the main list
  // reinforced with the specifics of this subreddit's keywords
  const keywordsToDisplay = allKeywords.map(phrase => {
    if (keywords.map(k => k.phrase).includes(phrase)){
      return keywords.filter(k => k.phrase === phrase)[0]
    } else {
      return {'phrase': phrase, 'velocity': 0, 'instances': []}  // shell to display that there's nothing
    }
  });

  if (hidden) return '';  // if added to hidden community list
  const isMobile = window.innerWidth <= 500; // determines length of placeholder

  return (
    <div disabled={isInCollection} className={`rounded-md shadow-lg bg-gray-700 border-2 border-solid p-4 cursor-pointer text-white ${selected || isInCollection ? 'border-cyan-500' : 'border-transparent hover:border-gray-300'}`}
      onClick={(e) => {
        // toggle open/close, as long as we're not ckicking on the button or the panel
        const target = e.target;
        const button = document.getElementById(`popover-button-${name}`);
        const panel = document.getElementById(`popover-panel-${name}`);
        
        if (button && (button === target || button.contains(target))){
          return
        }
        if (panel && (panel === target || panel.contains(target))){
          return
        }

        // okay we can toggle it
        selected ? unselect(subreddit) : select(subreddit);
      }}
    >
      <ErrorBoundary>
        <div className="flex items-center">
          {subreddit && subreddit.icon ? (
            <img className="h-10 w-10 mr-2 rounded-md" src={subreddit.icon} alt={subreddit.name} />
          ) : (
            <div className="h-10 w-10 mr-2 rounded-md bg-gray-600">
              <svg className="h-6 w-6 m-2 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
              </svg>
            </div>
          )}

          <h2 className="text-md whitespace-nowrap overflow-ellipsis flex-shrink-1 truncate">
            <span className="font-semibold">
              r/{ name }
            </span>
            <div className="text-xs font-base opacity-50">
              {subreddit && !subreddit.loading ? (
                <span>{readableNumber(subreddit.count_users)} members</span>
              ) : (
                <span>Fetching Details...</span>
              )}
            </div>
          </h2>

          {isInCollection ? (
            <div className="flex items-center text-xs ml-auto text-green-400">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <div>Included</div>
            </div>
          ) : (
            <Popover className="relative ml-auto">
              {({ open, close }) => (
                <>
                  <Popover.Button
                    className={`text-white opacity-50 hover:opacity-100 font-medium focus:outline-none border-none`}
                  >
                    <DotsVerticalIcon
                      id={`popover-button-${name}`}
                      className={`h-5 w-5 mx-1`}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-20 w-64 text-gray-900 mt-3 transform right-0">
                      <div id={`popover-panel-${name}`} className="bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        
                        <div className="flex items-center p-2 rounded-md hover:bg-gray-100"
                          onClick={(e) => {
                            select(subreddit)
                            close()
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
                          </svg>
                          <div>
                            <div className="text-sm">Relevant Subreddit</div>
                            <div className="text-xs">Add to your audience</div>
                          </div>
                        </div>

                        <div className="flex items-center p-2 rounded-md hover:bg-gray-100"
                          onClick={(e) => {
                            setExpanded(!expanded)
                            close()
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <div>
                            <div className="text-sm">{!expanded ? 'Show More Details' : 'Hide Details'}</div>
                            <div className="text-xs">Including context of your keywords</div>
                          </div>
                        </div>

                        <div className="flex items-center p-2 rounded-md hover:bg-gray-100"
                          onClick={(e) => {
                            if (!isHiddenCommunity){
                              searchesService.addHiddenCommunity("RSR", subreddit.name, subreddit.name, {'type': 'discover'});
                              setHidden(true);
                            }
                            close()
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 mr-2 ${isHiddenCommunity ? 'text-red-500' : 'text-gray-500'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                          </svg>
                          {!isHiddenCommunity ? (
                            <div>
                              <div className="text-sm">Not Relevant</div>
                              <div className="text-xs">Hide from all your results</div>
                            </div>
                          ) : (
                            <div>
                              <div className="text-sm">Community Hidden</div>
                              <div className="text-xs">Manage hidden communities in account settings</div>
                            </div>
                          )}
                        </div>
                        
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          )}
        </div>

        {expanded ? (
          <div className="mt-2 pr-2">
            <dt className="text-sm font-normal opacity-50">
              Keyword Context
            </dt>
            <dd className="mt-1">
              {keywordsToDisplay.map(keyword => (
                <KeywordVelocity key={keyword.phrase} noPadding={true}
                  phrase={keyword.phrase} velocity={keyword.velocity}
                  instances={keyword.instances} instanceType="reddit-submission"
                  maxVelocity={maxVelocity} showInstances={true} />
              ))}
            </dd>
          </div>
        ) : ''}

        <div className="mt-2">
          {expanded ? (
            <dt className="mb-1 text-sm font-normal opacity-50">
              Subreddit Description
            </dt>
          ) : ''}
          {!loading ? (
            <div>
              {subreddit && subreddit.tags ? (
                <div>
                  <SubredditTags subreddit={subreddit} tags={subreddit.tags} />
                </div>
              ) : ''}
              <p className={`text-xs opacity-100 overflow-ellipsis ${expanded ? '' : 'cursor-pointer line-clamp-3'}`}>
                { subreddit && subreddit.description || 'No Description...' }
              </p>
            </div>
          ) : (
            <p className={`h-12`}>
              
            </p>
          )}
        </div>
        

      </ErrorBoundary>
    </div>
  )
}

export default AudienceDiscoverySubreddit;

