import React, { useState, useEffect } from "react"
import {
  Link
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Helmet } from "react-helmet";

import LayoutAuth from "../../components/auth/layout";

import { emailService } from "../../services/emails";
import { getParameterByName } from "../../utils/urls";
import { trackEvent } from '../../utils/tracking';

// hitting this with a valid profile hash unsubscribes you from all emails
const Unsubscribe = ({history, location, match }) => {
  const [profile_hash] = useState(getParameterByName('profile_hash'));
  const [template_id] = useState(getParameterByName('template_id'));
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(null); 
  const [success, setSuccess] = useState(null); 


  // go submit it!
  useEffect(() => {
    if (!profile_hash){
      setError('Missing profile_hash in url');
      return 
    }

    if (loading) return;

    // go submit the feedback
    setLoading(true);
    emailService.unsubscribeEmails(
      {'profile_hash': profile_hash, 'template_id': template_id},
      (response) => {
        setLoading(false);
        setSuccess("You're unsubscribed from all emails. Have a nice day!");

        trackEvent("EmailUnsubscribed", {
          "template_id": template_id
        });
      },
      (error, response) => {
        setLoading(false);
        setError(response);
        // setLoading(false);
      }
    )
  }, []); 


  return (
    <LayoutAuth title="Email Unsubscribe" subtitle="" hideFooter={true}>

      <Helmet><title>Email Unsubscribe</title></Helmet>

      {loading ? (
        <div className="bg-gray-500 p-2 px-3 font-medium rounded-sm mb-4">
          Loading...
        </div>
      ) : error ? (
        <div className="bg-red-500 p-2 px-3 font-medium rounded-sm mb-4">
          {error}
        </div>
      ) : success ? (
        <div className="bg-green-500 p-2 px-3 font-medium rounded-sm mb-4">
          {success}
        </div>
      ) : ''}

      <div className="mt-6 text-sm">
        <div className="">
          {/*<span className="opacity-75">Existing user? </span>*/}
          <Link to="/login/" className="hover:underline text-right font-semibold mr-auto">Sign in to your account</Link>
        </div>
      </div>

    </LayoutAuth>
  );
}

export default Unsubscribe;
