import React, { useState, useEffect, useRef } from "react";
import {
  Link, useHistory
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Popover } from 'react-tiny-popover'

import { redditService } from '../../services/reddit';
import { velocityToString } from '../../utils/text';
import { trackEvent } from '../../utils/tracking';
import { toQueryString } from '../../utils/urls';

import SelectPopover from "../common/selectPopover";
import RedditorPopover from "../reddit/redditorpopover";
import ConversationPopover from "../reddit/conversationpopover";
import SubmissionTypeIcon from "../reddit/submissiontypeicon";
import PreviewRedditSubmission from "../reddit/submissionpreview";
import FeaturePaywallWrapper from "../account/featurePaywallWrapper"


const SubredditInfluencers = ({ subreddit, currentUser, hasPro }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [influencers, setInfluencers] = useState([]);
  const influencersRef = useRef();  // reference to influencers list
  const sortOptions = ['Karma', 'Submission Karma', 'Comment Karma', '# Contributions', '# Submissions', '# Comments'];
  const [showCount, setShowCount] = useState(12);
  const [sortOption, setSortOption] = useState(sortOptions[0]);

  useEffect(() => {
    if (!hasPro) return;

    setLoading(true)
    redditService.fetchSubredditInfluencers(subreddit.name, (response) => {
      setInfluencers(response.data)
      setLoading(false)
    }, (error, response) => {
      console.error(response)
      setLoading(false)
    });
  }, []);

  // lets sort based on user input
  var sortedItems = influencers.map(i => {
    i.count_submissions = i.submissions.length;
    i.count_comments = i.comments.length;
    i.count_contributions = i.count_submissions + i.count_comments;
    return i
  });

  // field we're sorting on
  const sortFields = {'Karma': 'score_total', 'Submission Karma': 'score_submissions', 'Comment Karma': 'score_comments', '# Contributions': 'count_contributions', '# Submissions': 'count_submissions', '# Comments': 'count_comments'}
  if (sortOption && sortFields[sortOption]){
    const sortField = sortFields[sortOption]
    sortedItems = sortedItems.sort((a,b) => (a[sortField] < b[sortField]) ? 1 : ((b[sortField] < a[sortField]) ? -1 : 0));
  }


  if (loading){
    return (
      <div className="flex items-center text-center h-full justify-center">
        <svg className="animate-spin h-6 block mx-auto w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    )
  }

  var maxScore = 1;
  if (influencers && influencers.length){
    influencers.forEach(i => {
      if (i.score_total > maxScore){
        maxScore = i.score_total
      }
    })
  }

  return (
    <FeaturePaywallWrapper featureKey={"subreddit-influencers"} className={'max-w-full py-0'} currentUser={currentUser}>
      <div id="subreddit-influencers" className="text-sm pr-2" ref={influencersRef}>
        <div>
          <dt className="flex items-center mb-2 flex-wrap">
            <div className="flex items-center">
              <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
                Top Contributors <span className="ml-1 opacity-50">{influencers.length}</span>
              </h3>
              <ReactTooltip id={`influencers-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
              <span className="cursor-pointer ml-2" data-for='influencers-info' data-tip="Biggest contributors to this subreddit in the past month">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </span>
            </div>

            {sortedItems.length >= 2 ? (
              <div className="ml-auto flex items-center mb-1">
                <div className="text-xs opacity-50">Sort by</div>
                <div className="ml-1 flex items-center flex-wrap">
                  
                  <SelectPopover
                    options={sortOptions}
                    currentOption={sortOption}
                    setCurrentOption={(s) => setSortOption(s)}
                    labelField={undefined}
                  />
                </div>
              </div>
            ) : ''}
          </dt>
          <ul className="divide-y divide-gray-800">
            {sortedItems
              .filter((s, i) => i < showCount)
              .map((influencer, index) => (
              <div key={influencer.username} className="flex mb-2">
                <div className="flex-1 whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate">
                  <RedditorPopover name={influencer.username} openOnClick={true} openOnHover={true} getPopoverParentElement={() => document.getElementById('subreddit-influencers')}>
                    <span className="text-gray-300 hover:text-white">u/{influencer.username}</span>
                  </RedditorPopover>
                </div>
                <div className="flex-1 bg-gray-700 rounded-sm relative flex-shrink-0">
                  <div className="absolute p-0.5 text-white text-xs">{influencer.score_total} karma</div>
                  <div className="flex items-center h-full">
                    <div className="bg-green-500 rounded-sm p-0.5 h-full" style={{width: (100 * influencer.score_submissions / maxScore) + '%'}}></div>
                    <div className="bg-cyan-600 rounded-sm p-0.5 h-full" style={{width: (100 * influencer.score_comments / maxScore) + '%'}}></div>
                  </div>
                </div>
                <div className="flex ml-2 text-gray-300 space-x-2 text-xs">
                  <ConversationPopover type="submissions" ids={influencer.submissions} getPopoverParentElement={() => document.getElementById('subreddit-influencers')}>
                    <div className={`flex items-center ${influencer.submissions.length > 0 ? 'text-green-400' : 'text-gray-400'}`}>
                      <span className="mr-0 text-sm w-6 text-center">{influencer.submissions.length}</span><SubmissionTypeIcon type={'text'} />
                    </div>
                  </ConversationPopover>
                  <ConversationPopover type="comments" ids={influencer.comments} getPopoverParentElement={() => document.getElementById('subreddit-influencers')}>
                    <div className={`flex items-center ${influencer.comments.length > 0 ? 'text-cyan-400' : 'text-gray-400'}`}>
                      <span className="mr-0 text-sm w-6 text-center">{influencer.comments.length}</span><SubmissionTypeIcon type={'comment'} />
                    </div>
                  </ConversationPopover>
                </div>

              </div>
            ))}
          </ul>

          {sortedItems.length > showCount ? (
            <div className="py-1 mb-2 text-xs text-gray-400 hover:text-gray-200 rounded-sm flex space-x-1 cursor-pointer"
              onClick={() => setShowCount(sortedItems.length)}
            >
              {/*<span>+ {Math.min(SUBREDDIT_COUNT_INCREMENT, allSubreddits.length - subredditsToShow.length)} more</span>*/}
              <span>+ show more</span>
            </div>
          ) : ''} 

          {influencers.length === 0 ? (
            <div className="opacity-50">
              None yet... If this is a recently added Subreddit, this could take up to an hour.
            </div>
          ) : ''}
        </div>
      </div>
    </FeaturePaywallWrapper>
  );
}

export default SubredditInfluencers;
