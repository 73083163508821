import React, { useState } from "react";
import {
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout";
import PropsRoute from '../../components/common/propsroute.jsx';

import Intro from "../../components/welcome/intro";
import Objective from "../../components/welcome/objective";
import Steps from "../../components/welcome/steps";
import Story from "../../components/welcome/story";
import Feedback from "../../components/welcome/feedback";
import Plan from "../../components/welcome/plan";
import FreePlan from "../../components/welcome/freePlan";


// welcome page, contains components based on 
const WelcomePage = ({ history, location, match, currentUser }) => {
  const page = match.params.page;

  // available pages
  const defaultPages = [
    'intro',
    'objective',
    'plan',
    'free-plan',
  ];
  var additionalPages = []; // we might include some conditional pages for the user
  // if (currentUser && currentUser.ab_tests && currentUser.ab_tests['onboarding-free-plan-info'] === 'include') {
  //   additionalPages = ['free-plan'] // info about what is and isnt included in the free plan
  // }

  const allPages = [...defaultPages, ...additionalPages]

  // get current page's context regarding all pages
  const isValidPage = allPages.includes(page);
  const pageIndex = isValidPage ? allPages.indexOf(page) : null;  // starts at 0. increment page number to user appropriately
  const isFirstPage = isValidPage ? pageIndex === 0 : null;
  const isLastPage = isValidPage ? pageIndex === allPages.length - 1 : null;
  const previousPage = !isFirstPage ? allPages[pageIndex - 1] : null;
  const nextPage = !isLastPage ? allPages[pageIndex + 1] : null;

  // pass the pagination props to the subroutes (mainly for the footer)
  const subrouteProps = {
    'page': page,
    'isLastPage': isLastPage,
    'previousPage': previousPage,
    'pageIndex': pageIndex,
    'totalPages': allPages.length,
    'nextPage': nextPage,
    'currentUser': currentUser,
  }

  return (
    <React.Fragment>
      <Switch>
        <PropsRoute path="/welcome/intro/" component={Intro} {...subrouteProps} />
        <PropsRoute path="/welcome/objective/" component={Objective} {...subrouteProps} />
        <PropsRoute path="/welcome/steps/" component={Steps} {...subrouteProps} />
        <PropsRoute path="/welcome/story/" component={Story} {...subrouteProps} />
        <PropsRoute path="/welcome/plan/" component={Plan} {...subrouteProps} />
        <PropsRoute path="/welcome/free-plan/" component={FreePlan} {...subrouteProps} />
        <PropsRoute path="/welcome/feedback/" component={Feedback} {...subrouteProps} />
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(WelcomePage);

