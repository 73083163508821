import React, { useState } from "react";
import { withRouter } from "react-router";

import { Popover } from 'react-tiny-popover'

import { velocityToString } from '../../utils/text';
// import { getParamsFromUrl, toQueryString } from '../../utils/urls';

import PreviewRedditSubmission from "../reddit/submissionpreview";


// renders a keyword and how fast it's being talked about
const KeywordVelocity = ({
    phrase, velocity, maxVelocity,
    instances, instanceType, communityName,
    noPadding, history, location, clear,
    noWrap, showInstances
}) => {
  // instances is just submissions only right now

  const [isHovering, setIsHovering] = useState(false);

  // actions
  // const goToKeyword = (value) => {
  //   var params = getParamsFromUrl()
  //   params = Object.assign(params, {'keyword': value})
  //   if ('example' in params) delete params['example'];
  //   const searchQuery = toQueryString(params)
  //   history.push(`/reddit/${searchQuery}`)
  // }

  const velocityPercentWidth = Math.min(100 * (velocity / maxVelocity), 100);

  return (
    <div key={phrase} className={`rounded-sm text-sm bg-gray-700 text-white flex items-center mb-2 items-stretch ${noPadding ? 'p-0' : 'p-2 mr-2'}`}>
      {clear ? (
        <div className="mr-2 flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 cursor-pointer opacity-50 hover:opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            onClick={() => clear(phrase)}
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
      ) : ''}
      <div className={`whitespace-nowrap flex-shrink-0 ${noWrap ? '' : 'overflow-ellipsis truncate flex-1'}`}>
        {phrase}
      </div>
      <div className="ml-2 w-16 bg-gray-600 rounded-sm relative flex-shrink-0">
        <div className="relative p-0.5 z-10 text-white text-xs">{velocity ? velocityToString(velocity) : ''}</div>
        {velocity ? (
          <div className="absolute z-0 top-0 bg-cyan-600 rounded-sm p-0.5 h-full" style={{width: velocityPercentWidth + '%'}}></div>
        ) : ''}
      </div>

      {showInstances ? (
        <div className={`ml-4 flex items-center ${instances && instances.length ? '' : 'opacity-25 pointer-events-none'}`}>
          <Popover
            isOpen={isHovering}
            align="start"
            content={() => (
              <div className="z-40 absolute right-0 pl-2 pb-2 pr-2"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              >
                <div className="rounded-md bg-white text-gray-700 flex flex-col w-80 sm:w-96">
                  <div className="flex border-b-2 border-gray-200 p-2 sm:px-4 w-full items-center flex-shrink-0">
                    <div className="opacity-50">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                      </svg>
                    </div>
                    <div className="flex-grow ml-2">
                      <h2 className="text-md font-medium m-0">Sample Conversations</h2>
                      <div className="text-sm font-medium opacity-50">
                        To browse all, include in your audience
                      </div>
                    </div>
                  </div>
                  <div className="max-h-60 overflow-y-scroll">
                    <ul className="divide-y divide-gray-200 py-2 px-2 sm:px-4">
                      {instances.map(item => (
                        <React.Fragment key={item.id}>
                          {instanceType === 'reddit-submission' ? (
                            <PreviewRedditSubmission item={item} keyword={phrase} showRedirect={false} />
                          ) : ''}
                        </React.Fragment>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          >
            <span className="cursor-pointer opacity-50 hover:opacity-75"
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
              </svg>
            </span>
          </Popover>
          
        </div>
      ) : ''}
    </div>
  )
}

export default withRouter(KeywordVelocity);

