// types.js
const FETCH_AUDIENCES = 'audiences/fetch';
const FETCH_AUDIENCES_SUCCESS = 'audiences/fetch-success';
const FETCH_AUDIENCES_FAIL = 'audiences/fetch-fail';

const FETCH_AUDIENCE_SUBREDDITS = 'audiences/subreddits/fetch';
const FETCH_AUDIENCE_SUBREDDITS_SUCCESS = 'audiences/subreddits/fetch-success';
const FETCH_AUDIENCE_SUBREDDITS_FAIL = 'audiences/subreddits/fetch-fail';

const FETCH_AUDIENCE_ITEMS = 'audiences/items/fetch';
const FETCH_AUDIENCE_ITEMS_SUCCESS = 'audiences/items/fetch-success';
const FETCH_AUDIENCE_ITEMS_FAIL = 'audiences/items/fetch-fail';

export default {
  FETCH_AUDIENCES,
  FETCH_AUDIENCES_SUCCESS,
  FETCH_AUDIENCES_FAIL,

  FETCH_AUDIENCE_SUBREDDITS,
  FETCH_AUDIENCE_SUBREDDITS_SUCCESS,
  FETCH_AUDIENCE_SUBREDDITS_FAIL,

  FETCH_AUDIENCE_ITEMS,
  FETCH_AUDIENCE_ITEMS_SUCCESS,
  FETCH_AUDIENCE_ITEMS_FAIL,
}