import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Transition, Popover } from '@headlessui/react'
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';

import SubmissionTypeIcon from "../reddit/submissiontypeicon";

import { savedSearchOperations } from "../../redux/saved"
import { savedSearchesService } from '../../services/savedSearches';

import { flatColors } from './constants';


// shows a popover with conversation lists, or create a new one
const ConversationListPopover = ({
  children,
  // onSelect,
  className,
  disabled,
  selectedIds, // list of submission/comment ids
  selectedType, // comment/submission
  getPopoverParentElement, positions,
  savedLists, fetchingSavedLists,
  fetchAllSavedSearches,
  updateSavedSearches,
  currentLists,
  onAdd, onRemove,
}) => {
  const [newListName, setNewListName] = useState('');
  const [newListColor, setNewListColor] = useState('red');
  const [saving, setSaving] = useState(false);

  // runs whenever we open up the popover
  const confirmSavedLists = () => {
    // makes sure that we have saved lists in the redux store
    // dont need to do this anymore
    // if ((!savedLists || !savedLists.length) && !fetchingSavedLists){
    //   fetchLists();
    // }

    // set the right color for the next list
    const currentlyUsedColors = savedLists.map(l => l.color)
    const nextColor = flatColors.filter(c => !currentlyUsedColors.includes(c))[0] || 'red'
    setNewListColor(nextColor)
  }

  const createListAndAdd = (name, color, close) => {
    if (saving) return;

    setSaving(true)
    savedSearchesService.createSavedList({'name': name, 'color': color}, (results) => {
      fetchAllSavedSearches(); // refetch all and put into store
      if (results && results.data){
        addToList(results.data.hash, close);
      }
    }, (response, error) => {
      console.log(error)
      setSaving(false)
      alert("Error creating bookmark")
    })
  }

  const addToList = (hash, close) => {
    if (saving) return;

    if (selectedIds.length > 1){
      return addToListMultiple(hash, close) // handles in list
    }

    setSaving(true)
    savedSearchesService.addManualConversation({
      'saved_search': hash,
      'conversation_type': selectedType === 'submission' ? 'RSU' : selectedType === 'comment' ? 'RCO' : null,
      'platform_id': selectedIds[0], // TODO: handle multiple
    }, (results) => {
      if (results && results.data){
        setSaving(false);
        // update right away
        if (onAdd){
          onAdd(results.data)
        }
        if (close){
          close()
        }
        updateSavedSearches({'search_type': 'MA'}); // refetch to update redux store
      }
    }, (response, error) => {
      console.log(error)
      setSaving(false)
      alert("Error saving conversation")
    })
  }

  // same as addToList but bulk endpoint and doesn't have a return
  const addToListMultiple = (hash, close) => {
    if (saving) return;

    setSaving(true)
    savedSearchesService.addManualConversations(selectedIds.map((i) => {
      return {
        'saved_search': hash,
        'conversation_type': selectedType === 'submission' ? 'RSU' : selectedType === 'comment' ? 'RCO' : null,
        'platform_id': i,
      }
    }), (results) => {
      if (results && results.data){
        setSaving(false);
        if (close){
          close()
        }
        toast.success(`Added bookmark to ${selectedIds.length} ${selectedType}${selectedIds.length === 1 ? '' : 's'}!`)
        updateSavedSearches({'search_type': 'MA'}); // refetch to update redux store
      }
    }, (response, error) => {
      console.log(error)
      setSaving(false)
      alert("Error bookmarking conversations")
    })
  }

  const removeFromList = (bookmark, close) => {
    if (saving) return;

    setSaving(true)
    savedSearchesService.deleteManualConversation(bookmark.hash, (results) => {
      setSaving(false);
      // update right away
      if (onRemove){
        onRemove(bookmark)
      }
      if (close){
        close()
      }
      updateSavedSearches({'search_type': 'MA'}); // refetch to update redux store
    }, (response, error) => {
      console.log(error)
      setSaving(false)
      alert("Error removing bookmark")
    })
  }


  // display utils
  var parentElement = window.document.body;
  if (getPopoverParentElement && getPopoverParentElement()){
    parentElement = getPopoverParentElement()
  }

  return (
    <Popover
      // className={`w-6 relative ${currentLists && currentLists.length > 1 ? '' : 'h-6'}`}
      className={className || ''}
      positions={positions || ['right', 'top', 'bottom', 'left']}
      align="start"
    >
      {({ open, close }) => (
        <>
          <Popover.Button disabled={disabled}
            className={`focus:outline-none ${open ? 'pointer-events-none' : ''}`}
            onClick={() => confirmSavedLists()}
          >
            { children }
          </Popover.Button>

          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className={`absolute left-8 -top-2 z-50 w-72 bg-white text-black p-4 rounded-md overflow-hidden shadow-lg bg-white`}
            >
              {/*<div className="pt-6 relative -top-4">
                <hr className="opacity-10" />
                <div className="absolute top-3 w-full text-center">
                  <span className="p-1 text-xs rounded-sm uppercase">
                    <span>Bookmark {selectedIds.length === 1 ? 'conversation' : `${selectedIds.length} conversations`} </span>
                  </span>
                </div>
              </div>*/}

              <ReactTooltip id={`bookmark-info`} effect="solid" place="right" backgroundColor="#fafafa" textColor="black" className="w-72"/>
            
              <div className="text-xs uppercase font-semibold opacity-50 mb-1 flex items-center">
                <div>Create new bookmark</div>

                
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="w-4 h-4 ml-2"
                  data-for={`bookmark-info`} data-tip="Bookmarks allow you to label Reddit posts/comments and quickly find them later in your conversations page."
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
              </div>
              
              <div className="flex items-center rounded-md">
                <input id="new-list-color" type="color" className="h-8 w-8 p-1 pl-1.5 bg-gray-200 rounded-l-md"
                  value={newListColor} onChange={(e) => {setNewListColor(e.target.value)}}
                />
                <input id="new-list-name" type="text"
                  className="bg-gray-200 h-8 p-1 text-sm rounded-r-md border-none w-full focus:ring-0"
                  placeholder={saving ? "Saving..." : "Name bookmark"} autoComplete="off" spellCheck="off"
                  autoFocus={true} disabled={saving}
                  value={newListName}
                  onChange={(e) => {setNewListName(e.target.value)}}
                  onKeyDown={(event) => {
                    if(event.key === 'Enter') {
                      const value = event.target.value;
                      if (value && value !== ''){
                        createListAndAdd(value, document.getElementById('new-list-color').value, close);
                        setNewListName('')
                      }      
                    }
                  }}
                />
              </div>

              {savedLists && savedLists.length ? (
                <React.Fragment>
                  <div className="text-xs uppercase font-semibold opacity-50 mt-4 mb-1">Or add existing bookmark</div>
                  <div className="gap-y-1">
                    {savedLists.map(s => (
                      <div key={s.hash}
                        className={`flex items-center`}
                      >
                        <div className="group-scope flex items-center p-1 rounded-md hover:bg-gray-100 flex-grow cursor-pointer"
                          onClick={() => {
                            if (currentLists.map(l => l.saved_search_hash).includes(s.hash)){
                              removeFromList(currentLists.filter(l => l.saved_search_hash === s.hash)[0], close)
                            } else {
                              addToList(s.hash, close)
                            }
                          }}
                        >
                          <div className="">
                            {currentLists.map(l => l.saved_search_hash).includes(s.hash) ? (
                              <React.Fragment>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="w-6 h-6 text-green-500 block group-scope-hover:hidden"
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                  className="w-6 h-6 text-red-500 hidden group-scope-hover:block"
                                >
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>

                              </React.Fragment>
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                className="w-6 h-6 text-gray-300 group-scope-hover:text-green-500"
                              >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            )}
                          </div>
                          <div className="mx-2">
                            <div className="text-sm font-semibold flex items-center">
                              <SubmissionTypeIcon type={'bookmark'} stroke={s.color} fill={`${s.color}75`}
                                className="h-4 w-4 mr-1" />
                              {s.name}
                            </div>
                            <div className="text-xs opacity-50 ml-1">{s.count_conversations} conversation{s.count_conversations === 1 ? '' : 's'}</div>
                          </div>
                        </div>
                        <Link className="ml-auto p-3 hover:bg-gray-100 rounded-md" to={`/conversations/saved/${s.hash}/all/`}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                            className="w-4 h-4"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                          </svg>
                        </Link>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ) : ''}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}


const mapStateToProps = state => {
  const {
    savedLists,
    fetchingSavedLists,
  } = state.savedSearch;

  return {
    savedLists,
    fetchingSavedLists,
  }
};

const mapDispatchToProps = (dispatch) => {
  const updateSavedSearches = (params, callback, onError) => {
    dispatch(savedSearchOperations.updateSavedSearches(params, callback, onError))
  };
  const fetchAllSavedSearches = (callback, onError) => {
    dispatch(savedSearchOperations.fetchAllSavedSearches(callback, onError))
  };
  return {
    updateSavedSearches,
    fetchAllSavedSearches,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConversationListPopover);

