// operations.js
// import fetch from 'cross-fetch';
import types from './types.js';
import axios from 'axios';

import store from '../../store';

import { authService } from "../../services/auth";
import { profileService } from "../../services/profile";

// just sets to redux state. called from services/auth
const setCurrentUser = (currentUser) => {
  return dispatch => {
    dispatch({type: types.SET_CURRENT_USER, data: currentUser});
  }
}

// gets current user and sets to redux state
const fetchCurrentUser = (callback, onError) => {
  return dispatch => {
    return authService.fetchUser((data) => {
      dispatch({type: types.SET_CURRENT_USER, data: data});
      if (callback){
        callback(data)
      }
    }, (error, response) => {
      if (onError){
        onError()
      }
    })
  }
}

// gets current user's checklist and set to reducer
const updateChecklist = (currentUserId, callback, onError) => {
  return dispatch => {
    return profileService.getObjectiveChecklist(currentUserId, (data) => {
      dispatch({type: types.SET_CHECKLIST, data: data.data});
      if (callback){
        callback(data.data)
      }
    }, (error, response) => {
      if (onError){
        onError()
      }
    })
  }
}

export default {
  setCurrentUser,
  fetchCurrentUser,
  updateChecklist,
}