import React, { useState, useEffect } from "react";
import {
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import Modal from "../common/modal";
import SubscriptionSelect from "./subscriptionSelect";

// serves a simple plan selection modal with the subscriptions component
const SubscriptionSelectModal = ({ closeModal, fetchCurrentUser, currentUser, setSuccess, setRefetchLimits }) => {
  
  return (
    <Modal close={closeModal} widthClass={'max-w-5xl bg-gray-800'}>
      <Helmet><title>Account | Subscription | Select</title></Helmet>

      <SubscriptionSelect closeModal={closeModal} closeModal={closeModal}
        fetchCurrentUser={fetchCurrentUser} currentUser={currentUser}
        setSuccess={setSuccess} setRefetchLimits={setRefetchLimits}
        showTeam={false} showFree={true}
      />
    </Modal>
  );
};

export default SubscriptionSelectModal;

