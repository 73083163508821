import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import {
  Link, Switch
} from "react-router-dom";
import { ModalRoute } from 'react-router-modal';
import Toggle from 'react-toggle'
import {
  CheckIcon, InformationCircleIcon, ViewListIcon, ViewBoardsIcon, TrendingUpIcon, TrendingDownIcon
} from '@heroicons/react/outline'

import { collectionsService } from '../../services/collections';
import { searchesService } from '../../services/searches';
import { uiOperations } from "../../redux/ui"
// import { hasStarter } from "../../utils/account"

import ErrorBoundary from "../common/errorboundary";
import SelectPopover from "../common/selectPopover";
import LoaderFancy from '../common/loaderfancy.jsx';
import ErrorBar from "../common/errorBar";
import PropsRoute from "../common/propsroute";
import SubmissionTypeIcon from '../reddit/submissiontypeicon'

import AudienceSuggestionDrawer from "./audiencesuggestiondrawer";
// import AudienceSearch from "./audienceSearch";

import { velocityToString, capitalizeFirst } from '../../utils/text';
import { readableNumber } from '../../utils/text';


const AudienceTheme = ({
  collection,
  match,
  history,
  subreddits,
  suggestions,
  loadingSuggestions,
  topics,
  loadingTopics,
  currentUser,
  onClose, // mobile view only
}) => {
  const { slug } = match.params;
  const theme = suggestions.filter(t => t.slug === slug)[0];
  const [themeDetails, setThemeDetails] = useState(null);
  const [loadingTheme, setLoadingTheme] = useState(false);
  const [summaryExpanded, setSummaryExpanded] = useState(true);
  const [error, setError] = useState(null);
  const isMobile = window.screen.width < 800;  // on mobile, show detail view in drawer

  // effects
  useEffect(() => {
    // fetch theme
    if (!themeDetails || themeDetails.slug !== slug){
      fetchCollectionTheme(slug);
    }
  }, [slug]);

  const fetchCollectionTheme = (s) => {
    setLoadingTheme(true)
    // setSummaryExpanded(false); // not currently doing this
    setThemeDetails(null)
    setError(null)
    const params = {'slug': s};
    collectionsService.getCollectionTheme(collection.hash, params, (results) => {
      if (results && results.data){
        setThemeDetails(results.data)
        setLoadingTheme(false)
      }
    }, (response, error) => {
      setError(error);
    })

    if (!isMobile && document.getElementById('main').scrollTop > 250){
      document.getElementById("topics-detail") && document.getElementById("topics-detail").scrollIntoView({behavior: 'smooth'});
    }
  }

  // get the top posts for this theme
  const countPostsToInclude = 5;
  const postsToDisplay = (themeDetails && themeDetails.submissions || [])
    // .filter(p => p.title.toLowerCase().includes(topic.topic.toLowerCase()))
    .sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0))
    .slice(0, countPostsToInclude);

  // collect the top subreddits and their submissions
  var subredditsToDisplay = themeDetails && Object.keys(themeDetails.subreddits).map(k => {
    const submissions = (themeDetails && themeDetails.submissions || []).filter(s => (themeDetails.subreddits[k] || []).includes(s.id));
    return {
      'name': k,
      'subreddit': subreddits.filter(s => s.external_id === k)[0] && subreddits.filter(s => s.external_id === k)[0].item,
      'count': themeDetails.subreddits[k].length,
      // 'submission_ids': topic.subreddits[k],
      // 'submission_preview': submissions
      //   .sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0))[0], // prioritize having topic in the title
    }
  }).filter(t => t.count > 0).sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)) || [];
  const maxSubredditCount = subredditsToDisplay.length > 0 ? subredditsToDisplay[0].count : 1;
  subredditsToDisplay = subredditsToDisplay.map(s => Object.assign(s, {'percent': s.count * 100 / maxSubredditCount}));

  // get topics for this theme
  var topicsToDisplay = (!themeDetails ? [] : themeDetails.topics)
    .map(t => {
      const submissions = (themeDetails && themeDetails.submissions || []).filter(s => t.submission_ids.includes(s.id));
      return Object.assign(t, {
        'submissions': submissions,
        // 'submission_preview': submissions
        //   .filter(s => s.title.toLowerCase().includes(t.name.toLowerCase()))
        //   .sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0))[0], // prioritize having topic in the title
      })
    })
    .sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));
  const maxTopicCount = topicsToDisplay.length > 0 ? topicsToDisplay[0].count : 1;
  topicsToDisplay = topicsToDisplay.map(t => Object.assign(t, {'percent': t.count * 100 / maxTopicCount}));

  return (
    <ErrorBoundary>
      <Helmet><title>Audience | Theme</title></Helmet>
      
      <div className="">
        {onClose ? (
          <div className="mr-2 mb-2 opacity-50 hover:opacity-100"
            onClick={onClose}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>

          </div>
        ) : ''}          
        
        {!themeDetails ? (
          <div className="h-96 relative">
            <LoaderFancy height={300}/>
          </div>
        ) : (
          <div className={``}>
            {/*<div className="mt-4 mb-2 flex items-center">
              <h3 className="flex-1 flex text-sm items-center">
                <span className="text-gray-300 font-semibold">About {name}</span>
              </h3>
            </div>*/}

            <div className="p-6 bg-gray-800 rounded-lg text-white">
              <div className="flex border-b border-gray-500 pb-4 mb-4">
                <h2 className="text-xl font-medium flex items-center flex-wrap mr-2">
                  <span>{themeDetails.name}</span>
                </h2>

                {themeDetails ? (
                  <p className="ml-auto text-gray-500 truncate flex items-center ml-2">
                    
                  </p>
                ) : (
                  <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                )}
              </div>
              
              <div>
                <div className="text-lg whitespace-pre-wrap">{themeDetails.summary}</div>
              </div>

              <div className="mt-4 flex">
                <Link
                  to={`/audience/${collection.hash}/themes/${slug}/results/`}
                  type="button"
                  className="ml-auto cursor-pointer inline-flex items-center px-3 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-700 hover:bg-cyan-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-cyan-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 opacity-50 hidden sm:block">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                  </svg>
                  Browse all posts
                </Link>

                <Link
                  to={`/audience/${collection.hash}/themes/${slug}/results/patterns/`}
                  type="button"
                  className="ml-2 cursor-pointer inline-flex items-center px-3 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 opacity-50 hidden sm:block">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                  </svg>
                  Common patterns
                </Link>


                <Link
                  to={`/audience/${collection.hash}/themes/${slug}/results/ask/`}
                  type="button"
                  className="ml-2 cursor-pointer inline-flex items-center px-3 py-2 border border-solid border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 opacity-50 hidden sm:block">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                  </svg>
                  Ask question
                </Link>
              </div>
            </div>

            {/*{postsToDisplay.length ? (
              <div className="mt-4">
                <h3 className="flex text-sm items-center w-full">
                  <div className="text-gray-300 font-semibold">Popular Posts</div>
                </h3>
                <div className="mt-2 space-y-1">
                  {postsToDisplay.map(p => (
                    <div key={p.id} className="flex items-center">
                      <div className="relative flex items-center space-x-1 sm:space-x-2 text-gray-600 text-center">
                        <SubmissionTypeIcon type={p.type} className={"text-gray-500 h-5 w-5 text-center mr-2"}/>
                      </div>
                      <div className="singleLineClip">
                        <a href={`${p.link.includes('https://') ? p.link : `https://reddit.com${p.link}`}`}
                          rel="nofollow"
                          className="hover:underline" target="_blank">
                            {p.title}
                        </a>
                      </div>
                      <div className="ml-auto pl-2 flex items-center flex-shrink-0 space-x-4 text-xs text-gray-300 mt-1">
                        <span className="text-sm text-gray-500 font-medium flex">
                          <svg className="self-center flex-shrink-0 h-4 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                          </svg>{p.score}
                        </span>
                      </div>
                    </div>
                  ))}

                  <div className="w-full text-center">
                    <Link
                      to={`/audience/${collection.hash}/themes/${slug}/results/`}
                      type="button"
                      className="text-center text-sm hover:underline mt-2 mx-auto text-gray-400 hover:text-gray-200"
                    >
                      View more
                    </Link>
                  </div>
                </div>
              </div>
            ) : ''}*/}

            <div className="space-y-4 sm:space-y-0 mt-4 sm:flex sm:space-x-8">
              {topicsToDisplay.length && !['hot', 'top'].includes(slug) ? (
                <div className="flex-1">
                  <h3 className="flex text-sm items-center">
                    <span className="text-gray-300 font-semibold">Topics</span>

                    <p className="ml-2 text-gray-500 truncate">
                      {themeDetails ? (
                        <span>{topicsToDisplay.length}</span>
                      ) : (
                        <span>
                          <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </span>
                      )}
                    </p>
                  </h3>
                  {themeDetails ? (
                    <div className="mt-2 relative">
                      {topicsToDisplay.map(t => (
                        <Link
                          to={`/audience/${collection.hash}/themes/${slug}/results/?topic=${t.name}`}
                          key={t.name} className="flex items-center mb-1 text-sm group"
                        >
                          <div className="flex-grow bg-transparent rounded-sm relative flex-shrink-0 h-7">
                            <div className="absolute bg-gray-800 group-hover:bg-gray-700 rounded-sm p-1 h-full" style={{width: (t.percent) + '%'}}></div>
                            <div className="absolute p-1 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4 mx-1 opacity-25">
                                <path fillRule="evenodd" d="M5.25 2.25a3 3 0 00-3 3v4.318a3 3 0 00.879 2.121l9.58 9.581c.92.92 2.39 1.186 3.548.428a18.849 18.849 0 005.441-5.44c.758-1.16.492-2.629-.428-3.548l-9.58-9.581a3 3 0 00-2.122-.879H5.25zM6.375 7.5a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" clipRule="evenodd" />
                              </svg>
                              <div className="ml-1"> {capitalizeFirst(t.name)}</div>
                            </div>
                          </div>
                          <div className={`absolute right-0 pr-2 text-right text-gray-300`}>
                            {t.count}
                          </div>
                        </Link>
                      ))}
                    </div>
                  ) : ''}
                </div>
              ) : ''}

              {subredditsToDisplay.length && !['hot', 'top'].includes(slug) ? (
                <div className="flex-1">
                  <h3 className="flex text-sm items-center">
                    <span className="text-gray-300 font-semibold">Subreddits</span>
                    <p className="ml-2 text-gray-500 truncate">
                      <span>{subreddits.length}</span>
                    </p>
                  </h3>
                  <div className="mt-2 relative">
                    {subredditsToDisplay.map(s => (
                      <Link
                        to={`/audience/${collection.hash}/themes/${slug}/results/?subreddits=${s.name}`}
                        key={s.name} className="flex items-center mb-1 text-sm group"
                      >
                        <div className="flex-grow bg-transparent rounded-sm relative flex-shrink-0 h-7">
                          <div className="absolute bg-gray-800 group-hover:bg-gray-700 rounded-sm p-1 h-full" style={{width: (s.percent) + '%'}}></div>
                          <div className="absolute p-1 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                            {s.subreddit && s.subreddit.icon ? (
                              <img className="h-4 w-4 mx-1 rounded-md" src={s.subreddit.icon} alt={s.name} />
                            ) : (
                              <svg className="h-4 w-4 mx-1 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                                <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                              </svg>
                            )}
                            <div className="ml-1"> r/{s.name}</div>
                          </div>
                        </div>
                        <div className={`absolute right-0 pr-2 text-right text-gray-300`}>
                          {s.count}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              ) : ''}
            </div>
          </div>
        )}
      </div>

      <Switch>
        {theme ? (
          <ModalRoute component={AudienceSuggestionDrawer}
            path={`/audience/${collection.hash}/themes/${theme.slug}/results/`}
            parentPath={`/audience/${collection.hash}/themes/${theme.slug}/`}
            props={{
              'collection': collection,
              'currentUser': currentUser,
              'baseUrl': `/audience/${collection.hash}/themes/${theme.slug}/`,
              'theme': theme,
            }}
          />
        ) : ''}
      </Switch>

      {error ? (
        <ErrorBar error={error} setError={setError} onRetry={() => {fetchCollectionTheme(slug)}}/>
      ) : ''}
    </ErrorBoundary>
  );
}

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  const {
    // topicSort,
  } = state.ui;

  return {
    currentUser,
    // topicSort,
  }
};

const mapDispatchToProps = (dispatch) => {
  const setUiState = (key, value) => {
    dispatch(uiOperations.setUiState(key, value))
  };
  return {
    setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceTheme);

