import React, { useState, useEffect } from "react"
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  PencilAltIcon
} from '@heroicons/react/outline'

import Loader from "../../components/common/loader";

import { hasStarter } from '../../utils/account';
import { searchesService } from '../../services/searches';
import { languageService } from '../../services/language';


const Results = ({history, location, match, currentUser}) => {
  const [loadingHiddenCommunities, setLoadingHiddenCommunities] = useState(true); 
  const [hiddenCommunities, setHiddenCommunities] = useState(false); 
  const [loadingCategoryKeywords, setLoadingCategoryKeywords] = useState(true); 
  const [categoryKeywords, setCategoryKeywords] = useState(null); 
  const [editingCategory, setEditingCategory] = useState(null); 
  const [editingCategoryText, setEditingCategoryText] = useState(''); 

  const isEffectiveStarterSubscriber = hasStarter(currentUser);

  // // effects
  useEffect(() => {
    fetchHiddenCommunities();
    fetchCategoryKeywords();
  }, []); // even though we got in sidebar, fetch it anyways

  const fetchHiddenCommunities = () => {
    setLoadingHiddenCommunities(true);
    searchesService.hiddenCommunities((response) => {
      setLoadingHiddenCommunities(false);
      setHiddenCommunities(response.data)
    }, (error) => {
      console.error(error)
      setLoadingHiddenCommunities(false)
    });
  }

  const fetchCategoryKeywords = () => {
    setLoadingCategoryKeywords(true);
    languageService.fetchCategories((response) => {
      setLoadingCategoryKeywords(false);
      setCategoryKeywords(response.data)
    }, (error) => {
      console.error(error)
      setLoadingCategoryKeywords(false)
    });
  }

  const startEditingCategory = (category) => {
    setEditingCategoryText(category.keywords.join(","))
    setEditingCategory(category.slug)
  }

  const saveKeywords = (newKeywordText) => {
    var keywords = null; // if no newKeywordText passed, we're setting to defaults
    if (newKeywordText){
      // clean category keywords entered
      keywords = newKeywordText.split(',').map(k => k.trim()).filter(k => k);
    }

    // save keywords for this category
    languageService.setCategoryKeywords({
      'category': editingCategory,
      'keywords': keywords,
    }, (response) => {
      setCategoryKeywords(response.data)
    }, (error) => {
      console.error(error)
      error("Error saving category keywords")
    });

    // 
    setEditingCategory(null)
  }

  return (
    <div className="my-8 pb-8">
      <Helmet><title>Account | Results</title></Helmet>
      
      <div>
        {/*<div className="md:grid md:grid-cols-3 md:gap-6 mb-8">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6">Category Keywords</h3>
              <p className="mt-2 text-sm text-gray-400">
                Customize the keywords that surface types of conversations in your audience.
              </p>
              <p className="mt-2 text-sm text-gray-400">
                Use this option if you have additional ideas for insightful keywords, or want to translate the defaults to a non-English language.
              </p>
              {!isEffectiveStarterSubscriber ? (
                <p className="mt-2 text-sm text-gray-400">
                  Requires <Link to="/account/subscription/select/" className="underline hover:text-gray-200">GummySearch Starter</Link> subscription.
                </p>
              ) : ''}
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2" disabled={!isEffectiveStarterSubscriber}>
            <div className="shadow rounded-md overflow-hidden">
              <div className="px-4 py-5 bg-gray-800 space-y-6 sm:p-6">
                {loadingCategoryKeywords || !categoryKeywords || !categoryKeywords.length ? (
                  <div><Loader /></div>
                ) : (
                  <div className="grid grid-cols-3 gap-6">
                    {categoryKeywords.map(c => (
                      <div key={c.slug} className="col-span-3 sm:col-span-2">
                        <label className="flex items-center t ext-sm font-medium text-gray-500">
                          <div>{c.name}</div>

                          {editingCategory !== c.slug ? (
                            <button className="ml-2 focus:outline-none">
                              <PencilAltIcon onClick={() => startEditingCategory(c)}
                                className={`h-6 w-6 hover:text-gray-300 cursor-pointer ${c.is_custom ? 'text-cyan-500' : ''}`} aria-hidden="true" />
                            </button>
                          ) : ''}

                          {editingCategory === c.slug ? (
                            <div className="ml-2 flex items-center space-x-2">
                              <div 
                                className="bg-cyan-500 hover:bg-cyan-600 cursor-pointer text-white text-xs px-2 py-1 rounded-sm"
                                onClick={() => saveKeywords(editingCategoryText)}
                                disabled={!editingCategoryText}
                              >
                                Save
                              </div>
                              <div className="text-cyan-500 font-semibold text-xs opacity-75 hover:opacity-100 cursor-pointer"
                                onClick={() => saveKeywords(null)}>
                                Set to defaults
                              </div>
                            </div>
                          ) : ''}
                        </label>
                        <div>
                          {editingCategory === c.slug ? (
                            <div className="mt-2">
                              <textarea id={`input-${c.slug}`} autoFocus={true}
                                className="border-gray-500 bg-gray-800 p-2 w-full rounded-md text-sm focus:ring-cyan-500"
                                placeholder="Add several comma-separated keywords which you want to search to surface conversations for this category"
                                value={editingCategoryText} onChange={(e) => {
                                  setEditingCategoryText(e.target.value)
                                }}
                              />
                            </div>
                          ) : (
                            <div className="flex flex-wrap">
                              {c.keywords.map(k => (
                                <div key={k} className="flex items-center mt-2 mr-2 bg-gray-700 px-2 py-1 text-xs rounded-sm">
                                  <span>{k}</span>
                                </div>
                              ))}
                            </div>
                          )}
                          
                        </div>
                      </div>  
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>*/}

        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6">Hidden Communities</h3>
              <p className="mt-2 text-sm text-gray-400">
                Hide non-relevant communities from your new audience searches.
              </p>
              <p className="mt-2 text-sm text-gray-400">
                Communities are added to this list from the new audience results page, and are hidden from all audience searches unless removed here.
              </p>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow rounded-md overflow-hidden">
              <div className="px-4 py-5 bg-gray-800 space-y-6 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label htmlFor="hidden-subreddits" className="flex items-center text-sm font-medium text-gray-500">
                      <svg className="flex-shrink-0 w-6 h-6 text-gray-400 mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="rgb(107, 114, 128)" viewBox="0 0 24 24">
                        <path fillRule="evenodd" d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"/>
                      </svg>
                      <div>Subreddits</div>
                    </label>
                    <div className="mt-1 flex">
                      {loadingHiddenCommunities ? (
                        <div><Loader /></div>
                      ) : hiddenCommunities.length ? (
                        <div className="flex flex-wrap">
                          {hiddenCommunities.map(c => (
                            <div key={c.platform_id} className="flex items-center mt-2 mr-2 bg-gray-700 px-2 py-1 text-xs rounded-sm">
                              <span>r/{c.platform_id}</span>
                              <span className="opacity-25 hover:opacity-75 cursor-pointer ml-2"
                                onClick={() => {
                                  searchesService.removeHiddenCommunity(c.id);
                                  setHiddenCommunities(hiddenCommunities.filter(hc => hc.id !== c.id))
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                </svg>
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>No hidden subreddits.</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>*/}

    </div>
  )
}

export default Results;

