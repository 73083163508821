import React, { useState, useEffect } from "react"
import { connect } from 'react-redux';
import {
  Link
} from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ErrorBoundary from "../../components/common/errorboundary";
import ProgressRing from "../../components/common/progressring";
import Loader from "../../components/common/loader";

import { trialService } from '../../services/trial';
import { trialOperations } from "../../redux/trial"


const TrialDisplay = ({history, location, match, currentUser, fetchTrial}) => {
  const [loading, setLoading] = useState(false); 
  const [trial, setTrial] = useState(false); 

  // // effects
  useEffect(() => {
    // fetch the details on the user's trial (either credit or time)
    setLoading(true)
    fetchTrial((data) => {
      setLoading(false)
      setTrial(data)
    })
  }, []); // even though we got in sidebar, fetch it anyways

  if (!currentUser) return '';

  return (
    <div>
      <div className="flex items-center mb-2">
        <h2 className="text-lg font-medium">Your Account</h2>

        <div className="ml-auto">
          <Link to="/trial/"
            className="text-sm opacity-75 hover:opacity-100"
          >
            Trial info
          </Link>
        </div>
      </div>
      <div className="bg-gray-800 p-4 rounded-md flex items-center">
        <div>
          {loading ? (
            <Loader />
          ) : trial && trial.type === 'time' ? (
            <React.Fragment>
              <div className="">
                <div className="font-semibold">
                  Timed Trial: <span className={`${trial.active ? 'text-green-400' : 'text-red-500'} ml-1`}>{trial.active ? 'Active' : 'Inactive'}</span>
                </div>
                {trial.active ? (
                  <div className="font-semibold">
                    Expires: <span className="text-gray-300">{trial.expires}</span>
                  </div>
                ) : (
                  <div className="font-semibold">
                    <div>
                      Expired: <span className="text-gray-300">{trial.expires}</span>
                    </div>
                    {trial.extension_available ? (
                      <div>
                        Extension Available: <span className={`${trial.extension_available ? 'text-green-400' : 'text-gray-200'}`}>{trial.extension_available ? 'Yes' : trial.extension_available_on}</span>
                      </div>
                    ) : ''}
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : (
            <label className="text-base font-normal">
              <span className="mr-2">No trial</span>
            </label>
          )}
        </div>

        <div className="ml-auto">
          <Link to="/account/subscription/"
            className="mt-4 px-4 py-2 text-center border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 sm:text-sm"
          >
            Upgrade Account
          </Link>
          {trial.extension_available ? (
            <Link to="/trial/"
              className="block mt-2 px-4 py-2 text-center border-solid border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
            >
              Extend Trial
            </Link>
          ) : ''}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  // const {
  //   used, total, fetching,
  // } = state.trial;

  return {
    // 'trialTotal': total,
    // 'trialUsed': used,
    // 'trialFetching': fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchTrial = (callback) => {
    dispatch(trialOperations.fetchTrial(callback))
  };
  return {
    fetchTrial,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrialDisplay);
