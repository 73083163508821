import React, { useState, useEffect } from "react";
import {
  Link
} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { Popover } from 'react-tiny-popover'
import SelectPopover from "../common/selectPopover";

import { redditService } from '../../services/reddit';
import { velocityToString, capitalizeFirst } from '../../utils/text';

import PreviewRedditSubmission from "../reddit/submissionpreview";
import SubmissionTypeIcon from "../reddit/submissiontypeicon";

// this is the "in a nutshell" view of a subreddit
// used in both internal & publically-shared product. watch out!
const SubredditTopics = ({ subreddit, hideIfBlankSection, showTerminologyExamples, dontTrackRedirect }) => {
  const [loadingPhrases, setLoadingPhrases] = useState(true);
  const [terminology, setTerminology] = useState({});
  const [loadingFlair, setLoadingFlair] = useState(true);
  const [loadingTypes, setLoadingTypes] = useState(true);
  const [loadingTopics, setLoadingTopics] = useState(true);
  const [types, setTypes] = useState([]);
  const [flair, setFlair] = useState([]);
  const [topics, setTopics] = useState([]);
  const [showTopicsCount, setShowTopicsCount] = useState(12);
  const [showFlairCount, setShowFlairCount] = useState(5);
  const [isHovering, setIsHovering] = useState(null); // tracks the phrase that's currently hovering
  const sortOptions = ['Velocity', 'Growth'];
  const [sortOption, setSortOption] = useState(sortOptions[0]);

  useEffect(() => {
    // fetchPhrases();
    fetchTopics();
    fetchFlair();
    fetchTypes();
  }, []);

  // topics from analysis
  const fetchTopics = () => {
    setLoadingTopics(true)
    redditService.fetchSubredditTopics(subreddit.name, (response) => {
      setTopics(response.data)
      setLoadingTopics(false)
    }, (error, response) => {
      console.error(response)
      setLoadingTopics(false)
    });
  }

  // frequent keywords from recent submissions
  // const fetchPhrases = () => {
  //   setLoadingPhrases(true)
  //   redditService.fetchSubredditKeywords(subreddit.name, (response) => {
  //     setTerminology(response.data)
  //     setLoadingPhrases(false)
  //   }, (error, response) => {
  //     console.error(response)
  //     setLoadingPhrases(false)
  //   });
  // }

  const fetchFlair = () => {
    setLoadingFlair(true)
    redditService.fetchSubredditFlair(subreddit.name, (response) => {
      setFlair(response.data)
      setLoadingFlair(false)
    }, (error, response) => {
      console.error(response)
      setLoadingFlair(false)
    });
  }

  const fetchTypes = () => {
    setLoadingTypes(true)
    redditService.fetchSubredditTypes(subreddit.name, (response) => {
      setTypes(response.data)
      setLoadingTypes(false)
    }, (error, response) => {
      console.error(response)
      setLoadingTypes(false)
    });
  }

  // to make width
  var maxVelocity = 0.01;
  if (topics && topics.length){
    maxVelocity = Math.max(...topics.filter(k => k.stats.velocity_current).map(k => k.stats.velocity_current));
  }

  // field we're sorting on
  const sortFields = {'Velocity': 'velocity_current', 'Growth': 'growth_past'}
  var sortedItems = topics;
  if (sortOption && sortFields[sortOption]){
    const sortField = sortFields[sortOption]
    sortedItems = sortedItems.sort((a,b) => (a.stats[sortField] < b.stats[sortField]) ? 1 : ((b.stats[sortField] < a.stats[sortField]) ? -1 : 0));
  }

  if (false && loadingPhrases){
    return (
      <div className="flex items-center text-center h-96 justify-center">
        <svg className="animate-spin h-6 block mx-auto w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    )
  }

  return (
    <div id="subreddit-topics" className="md:grid md:grid-cols-3 md:space-x-8">

      <div className="md:col-span-2">
        
        <div className="" id={`subreddit-topics-${subreddit.name}`}>
          <dt className="flex items-center">
            <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
              Popular Topics
            </h3>
            <ReactTooltip id={`terminology-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
            <span className="cursor-pointer ml-2" data-for='terminology-info' data-tip="Common topics discussed in the most recent submissions in the subreddit">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>

            <div className="ml-auto flex items-center mb-1">
              <div className="text-xs opacity-50">Sort by</div>
              <div className="ml-1 flex items-center flex-wrap">
                
                <SelectPopover
                  options={sortOptions}
                  currentOption={sortOption}
                  setCurrentOption={(s) => setSortOption(s)}
                  labelField={undefined}
                />
              </div>
            </div>
          </dt>

          {loadingTopics ? (
            <div className="mt-1 opacity-50 text-xs">Loading...</div>
          ) : topics && topics.length ? (
            <div className="mt-1">
              {sortedItems.filter((f, i) => i < showTopicsCount).map((k) => (
                <div key={k.topic} className="flex mb-1 relative items-center text-sm">
                  
                  <div className="flex-grow bg-transparent rounded-md relative flex-shrink-0 h-11">
                    <div className="absolute bg-gray-700 rounded-sm p-1 h-full" style={{width: parseInt((100 * k.stats.velocity_current) / maxVelocity) + '%'}}></div>
                    <div className="absolute p-1 px-3 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke='rgba(255,255,255,0.5)' className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776" />
                      </svg>

                      <div className="ml-2">
                        <div>{capitalizeFirst(k.topic)}</div>
                        <div className="text-xs flex items-center">
                          <span className="opacity-50">{velocityToString(k.stats.velocity_current)}</span>

                          <span className={`ml-2 flex items-center ${k.stats.growth_past >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                            {k.stats.growth_past > 0 ? (
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />
                              </svg>
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
                              </svg>
                            )}
                            <span>{k.stats.growth_past}%</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<div className={`absolute right-0 text-right text-gray-300`}>
                    {parseInt(100 * k.count / topics[0].count)}%
                  </div>*/}

                  <div className="ml-2 flex items-center space-x-1 text-sm">
                    <Popover
                      isOpen={isHovering === k.topic}
                      align="start"
                      positions={['left', 'top', 'bottom', 'right']}
                      reposition={true}
                      parentElement={document.getElementById('subreddit-topics')}
                      // parentElement={document.getElementById(`subreddit-topics-${subreddit.name}`)}
                      content={() => (
                        <div className="z-40 absolute right-0 pl-2 pb-2 pr-2"
                          onMouseEnter={() => setIsHovering(k.topic)}
                          onMouseLeave={() => setIsHovering(null)}
                        >
                          <div className="rounded-md bg-white text-gray-700 flex flex-col w-80 sm:w-96">
                            <div className="flex border-b-2 border-gray-200 p-2 sm:px-4 w-full items-center flex-shrink-0">
                              <div className="opacity-50">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke='currentColor' className="w-5 h-5">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 00-1.883 2.542l.857 6a2.25 2.25 0 002.227 1.932H19.05a2.25 2.25 0 002.227-1.932l.857-6a2.25 2.25 0 00-1.883-2.542m-16.5 0V6A2.25 2.25 0 016 3.75h3.879a1.5 1.5 0 011.06.44l2.122 2.12a1.5 1.5 0 001.06.44H18A2.25 2.25 0 0120.25 9v.776" />
                                </svg>
                              </div>
                              <div className="flex-grow ml-2">
                                <h2 className="text-md font-medium m-0">{capitalizeFirst(k.topic)} <span className="ml-1 text-sm opacity-50">(Preview)</span></h2>
                                {/*<div className="text-sm font-medium opacity-50">
                                  Containing this popular topic
                                </div>*/}
                                <div className="text-sm text-cyan-600 font-medium opacity-90 hover:opacity-100">
                                  <Link to={`/reddit/?type=submissions&subreddits=${subreddit.name}&topic=${k.topic}`}>View {k.submission_ids.length} submissions on {k.topic}</Link>
                                </div>
                              </div>
                            </div>
                            <div className="max-h-60 overflow-y-scroll">
                              <ul className="divide-y divide-gray-200 py-2 px-2 sm:px-4">
                                {k.examples.map(item => (
                                  <React.Fragment key={item.id}>
                                    {true || item.type === 'reddit-submission' ? (
                                      <PreviewRedditSubmission item={item} keyword={k.topic} showMetadata={true} showRedirect={true} trackRedirect={!dontTrackRedirect} />
                                    ) : ''}
                                  </React.Fragment>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    >
                      <div className="cursor-pointer text-cyan-500 hover:bg-gray-700 p-1 rounded-sm"
                        onMouseEnter={() => setIsHovering(k.topic)}
                          onMouseLeave={() => setIsHovering(null)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </div>
                    </Popover>

                    <Link to={`/reddit/?topic=${k.topic}&subreddits=${subreddit.name}`}
                      className="cursor-pointer text-cyan-500 hover:bg-gray-700 p-1 rounded-sm"
                      data-for={`search-tooltip-${k.topic}`} data-tip="Advanced Search"
                    >
                      <ReactTooltip id={`search-tooltip-${k.topic}`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                      </svg>
                    </Link>
                  </div>
                  
                </div>
              ))}

              {topics.length > showTopicsCount ? (
                <div className="py-1 mb-2 text-xs text-gray-400 hover:text-gray-200 rounded-sm flex space-x-1 cursor-pointer"
                  onClick={() => setShowTopicsCount(topics.length)}
                >
                  {/*<span>+ {Math.min(SUBREDDIT_COUNT_INCREMENT, allSubreddits.length - subredditsToShow.length)} more</span>*/}
                  <span>+ show more</span>
                </div>
              ) : ''} 
            </div>
          ) : (
            <div className="mt-1 opacity-50">No popular topics at this time.</div>
          )}

        </div>
      </div>

      <div className="mt-4 md:mt-0">
        <div className="mb-4" id="subreddit-post-types">
          <dt className="flex items-center">
            <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
              Submission Types
            </h3>
            <ReactTooltip id={`types-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
            <span className="cursor-pointer ml-2" data-for='types-info' data-tip="Types of content that users post to this subreddit">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
          </dt>
          
          {loadingTypes ? (
            <div className="mt-1 opacity-50 text-xs">Loading...</div>
          ) : (
            <div className="mt-1 relative">
              {types.map(t => (
                <div key={t.label} className="flex items-center mb-1 text-sm">
                  <div className="flex-grow bg-transparent rounded-sm relative flex-shrink-0 h-7">
                    
                    <div className="absolute bg-gray-700 rounded-sm p-1 h-full" style={{width: (t.percent) + '%'}}></div>
                    <div className="absolute p-1 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                      <SubmissionTypeIcon type={t.label} />
                      <div className="ml-1">{t.label}</div>
                    </div>
                  </div>
                  <div className={`absolute right-0 text-right text-gray-300`}>
                    {t.percent}%
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>


        
        <div className="mb-4" id="subreddit-flair">
          <dt className="flex items-center">
            <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wide">
              Flair Used
            </h3>
            <ReactTooltip id={`flair-info`} place="right" effect="solid" backgroundColor="white" textColor="black" />
            <span className="cursor-pointer ml-2" data-for='flair-info' data-tip="Labels that users add to their posts on Reddit">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </span>
          </dt>

          {loadingFlair ? (
            <div className="mt-1 opacity-50 text-xs">Loading...</div>
          ) : flair && flair.length ? (
            <div className="mt-1 relative">
              {flair.filter((f, i) => i < showFlairCount).map(f => (
                <div key={f.label} className="flex items-center mb-1 text-sm">
                  <div className="flex-grow bg-transparent rounded-sm relative flex-shrink-0 h-7">
                    
                    <div className="absolute bg-gray-700 rounded-sm p-1 h-full" style={{width: (f.percent) + '%'}}></div>
                    <div className="absolute p-1 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={f.color || 'rgba(255,255,255,0.5)'} className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 6h.008v.008H6V6z" />
                      </svg>

                      <div className="ml-1">{f.label}</div>
                    </div>
                  </div>
                  <div className={`absolute right-0 text-right text-gray-300`}>
                    {f.percent}%
                  </div>
                </div>
              ))}

              {flair.length > showFlairCount ? (
                <div className="py-1 mb-2 text-xs text-gray-400 hover:text-gray-200 rounded-sm flex space-x-1 cursor-pointer"
                  onClick={() => setShowFlairCount(flair.length)}
                >
                  {/*<span>+ {Math.min(SUBREDDIT_COUNT_INCREMENT, allSubreddits.length - subredditsToShow.length)} more</span>*/}
                  <span>+ show more</span>
                </div>
              ) : ''} 
            </div>
          ) : (
            <div className="mt-2 text-sm opacity-50">
              This community does not use flair in their submissions
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SubredditTopics;
