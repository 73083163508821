// reducers.js
import { combineReducers } from 'redux';
import { hasPro, hasStarter } from '../../utils/account';
import { localStorageAvailable } from "../../utils/localStorage";

import types from './types';

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorageAvailable() && localStorage.getItem('currentUser')),
  checklist: null,
  hasPro: false,
  hasStarter: false,
}

const userReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {

    case types.SET_CURRENT_USER: {
      const { data } = action;
      return {
        ...state,
        currentUser: data,
        hasPro: hasPro(data),
        hasStarter: hasStarter(data),
      }
    }

  case types.SET_CHECKLIST: {
      const { data } = action;
      return {
        ...state,
        checklist: data,
      }
    }

    default: return state;
  }
}

export default userReducer;