// used for cleanHTML method below
import sanitizeHtml from 'sanitize-html';


// gets most accuract regex for keyword matching (experimenting with different ones)
const getKeywordMatchRegex = (keyword) => {
    // matches all keyword (even if in link)
    // const regexpattern = keyword

    // matches all keyword if not in an <a></a> tag
    // const regexpattern = `${keyword}(?!(?:(?!<\/?a\b[^>]*>).)*?<\/a>)`

    // not prefaced with href
    // const regexpattern = `(?<!.*href=".*)${keyword}`

    // not prefaced with href and https directly
    // watch out: lookbehind might not be available in all browsers? https://caniuse.com/js-regexp-lookbehind
    const regexpattern = `(?<!.*href="(https|http):\/\/[a-z0-9.]*)${keyword}`
    
    try {
        return new RegExp(regexpattern, "gi");  // to match all instances (case insensitive)
    } catch (error) {
        // safari doesn't do lookbehinds.
        // so we fallback to standard keyword match (not inside <a/> tag)
        const fallbackpattern = `${keyword}(?!(?:(?!<\/?a\b[^>]*>).)*?<\/a>)`;
        
        try {
            return new RegExp(fallbackpattern, "gi");
        } catch (error) {
          // really shouldn't get here but in that case just return something that's not correct
          return new RegExp(`---------------------`, "gi");
        }
    }
}

// finds keyword in the text, wraps it in <span className="foundKeyword"></span>
const styleKeyword = (text, keyword, bgClass) => {
	if (!keyword || !text) return text;

	const re = getKeywordMatchRegex(keyword)
    const toReplaceWith = `<span class="foundKeyword ${bgClass || 'bg-cyan-600'} text-white px-0.5 -mx-0.5">${keyword}</span>`;
	return text.replace(re, toReplaceWith);
	// TODO: handle capitalization. in case match isn't capitalized but keyword is
}

const textContainsKeyword = (text, keyword) => {
	if (!keyword || !text) return false

  const re = getKeywordMatchRegex(keyword)
	const matches = text.match(new RegExp(re))
	return matches && matches.length > 0;
}

// when given a long text, find all instances of that keyword.
// Then truncate around them to show all instances
var findKeywordsAndTruncate = (text, keyword, maxChars) => {
	if (!keyword || !text) return ''

	const MAX_INSTANCES = 2;
	const MAX_CHARACTERS = maxChars || 200;

    const re = getKeywordMatchRegex(keyword)
	const matches = text.match(new RegExp(re));

	// cut out early in certain scenarios
	if (text.length <= MAX_CHARACTERS) return text;
	if (!matches) return text.slice(0, MAX_CHARACTERS) + '...';

	// how many characters before and after each hit to include
	const countHits = Math.min(MAX_INSTANCES, matches.length);  // cap out after max
	const charbuffer = parseInt((MAX_CHARACTERS - countHits * keyword.length) / countHits / 2);

	// get positions of each match
	var match;
	var matchPositions = [];  // [[firstIndex, lastIndex], ]
	while (match = re.exec(text)) {
		matchPositions.push([match.index, re.lastIndex])
	}
	const includePositions = matchPositions.slice(0, countHits);

	// construct string from match positions
	var toReturn = '';
	includePositions.forEach((mp, index) => {
		// if first instance, add pre-buffer
		if (index === 0){
			// first instance, add pre-buffer
			if (mp[0] > charbuffer) toReturn += '...'  // in case we're truncating beginning
			toReturn += text.slice(Math.max(mp[0] - charbuffer, 0), mp[0]);  // buffer right before word
		}

		// in all cases, add word
		toReturn += text.slice(mp[0], mp[1]);

		// if there's a future word, fill the void
		if (index < includePositions.length - 1){
			const nextMatch = includePositions[index + 1];
			if (nextMatch[0] - 1 - mp[1] <= charbuffer * 2){
				// no need to break apart
				toReturn += text.slice(mp[1], nextMatch[0]);
			} else {
				// add postbuffer, "...", prebuffer to next match
				toReturn += text.slice(mp[1], mp[1] + charbuffer);
				toReturn += '...'
				toReturn += text.slice(nextMatch[0] - charbuffer, nextMatch[0]);
			}
		}

		// last word, end with a postbuffer
		if (index === includePositions.length - 1){
			toReturn += text.slice(mp[1], mp[1] + charbuffer);
			if (text.length > mp[1] + charbuffer) toReturn += '...'
		}
	})
	return toReturn;
}

// converts 10000000 into 10M
const readableNumber = (count) => {
	const mil = 1000000;
	const thou = 1000;

	if (count >= mil){
		return `${(count / mil).toFixed(1)}M`
	} else if (count >= thou){
		return `${(count / thou).toFixed(0)}k`
	}
	return count
}

// velocity is in occurances/day. show something that is friendly to humans
const velocityToString = (velocity) => {
  if (velocity < (1 / 30)){ // less than once a month
    return `${(velocity * 365).toFixed(1)} / yr`
  } else if (velocity < (1 / 7)){ // less than once a week
    return `${(velocity * 30).toFixed(1)} / mo`
  } else if (velocity < 1){ // less than once a day
    return `${(velocity * 7).toFixed(1)} / week`
  } else if (velocity < 24){ // less than once a hour
    return `${(velocity).toFixed(1)} / day`
  // } else if (velocity < (24 * 60)){ // less than once a minute
  //   return `${(velocity / 24).toFixed(1)} / hr`
  // } else if (velocity < (24 * 60 * 60)){ // less than once a second
  //   return `${(velocity / (24 * 60)).toFixed(1)} / min`
  // } else {
  //   return `${(velocity / (24 * 60 * 60)).toFixed(1)} / sec`
  }

  // lowest we go is day
  return `${(velocity).toFixed(0)} / day`
}

const capitalizeFirst = (word) => {
    if (!word) return ''
    return word[0].toUpperCase() + word.slice(1, word.length)
}

const slugify = (word) => {
    if (!word) return ''
    return word.toLowerCase()
             .replace(/ /g, '-')
             .replace(/[^\w-]+/g, '');
}

const cleanHtml = (html) => {
	// makes sure that any html we render with dangerouslySetInnerHTML is clean

	return sanitizeHtml(html, {
    allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'img', 'br', 'span', 'ul', 'li'],
    allowedAttributes: {
      'a': [ 'href', 'target'],
      'img': [ 'src', 'height', 'width'],
      'span': ['style', 'data-user-id', 'class'],
    },
    allowedSchemes: [ 'http', 'https'], // do not include "mailto"
  });  // not all html, but just allowed tags
}

export {
    styleKeyword,
    textContainsKeyword,
    findKeywordsAndTruncate,
    readableNumber,
    velocityToString,
    capitalizeFirst,
    slugify,
    cleanHtml,
}

