import React from "react"
import {
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import FormFooter from "./footer";


const Feedback = ({history, ...subrouteProps}) => {

  const { nextPage, isLastPage } = subrouteProps;

  const onSubmit = () => {
    history.push(isLastPage ? '/guide/' : `/welcome/${nextPage}/`);
  }
  
  return (
    <div className="max-w-5xl w-full mx-auto min-h-full flex flex-col">
      <Helmet><title>Welcome | Feedback</title></Helmet>

      <div className="inline-block bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle p-6">
        
        <div className="text-center">
          <div className="relative mb-4">
            <hr className="opacity-25" />
            <div className="absolute -top-3 w-full text-center">
              <span className="p-1 text-sm rounded-sm bg-gray-800 text-gray-500 uppercase font-semibold">
                Improve this product
              </span>
            </div>
          </div>

          <p className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            How you can help
          </p>
          <p className="mt-4 max-w-2xl text-gray-400 mx-auto">
            GummySearch is in it's infant stages. As an early user, you have a lot of influence.
          </p>
          <p className="mt-4 max-w-2xl text-gray-400 mx-auto">
            Here's how you can be involved as the this product matures.
          </p>

          <div className="mt-8 text-left">
          <dl className="block md:flex flex-wrap">

            <div className="flex-1 flex flex-col sm:p-4">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium">Provide Feedback</p>
              </dt>
              <dd className="mt-1 ml-16 text-md text-gray-400">
                If you have any questions or suggestions, or experience any issues, please let me know! You can use the feedback form in the upcoming left sidebar or reach out to <a href="https://twitter.com/foliofed" target="_blank" className="text-white">@foliofed</a> on Twitter. Your feedback is immensely valueable to me so that I can continue to improve GummySearch.
              </dd>
            </div>

            <div className="flex-1 flex flex-col mt-4 sm:mt-0 sm:p-4">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium">Become a Supporter</p>
              </dt>
              <dd className="mt-1 ml-16 text-md text-gray-400">
                I included a free trial in this product to help as many early-stage founders as possible. However, GummySearch Pro subscriptions keep the lights on. If you are getting value out of this product, consider signing up for a subscription to support future development... there's a lot of it :)
              </dd>
            </div>

          </dl>
        </div>
        </div>
      </div>

      <FormFooter submit={onSubmit} {...subrouteProps} />
    </div>
  );
}

export default Feedback;
