/*
 * src/store.js
 * No initialState
*/
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

function configureStore() {
 return createStore(
  rootReducer,
  applyMiddleware(thunk)
 );
}

// we configure it here and export
// const store = configureStore()
export default configureStore()