import React, { useEffect, useState } from "react";

import { getRequest } from '../../utils/axios';
import { trackEvent } from '../../utils/tracking';

import { styleKeyword, textContainsKeyword } from '../../utils/text';
import { timeStampToFullTime, timeStampToEasyTime } from '../../utils/time';

import { searchesService } from '../../services/searches';

import ErrorBar from "../../components/common/errorBar";

import SubmissionTypeIcon from "./submissiontypeicon";
import SubmissionBody from "./submissionbody";
import RedditorPopover from "./redditorpopover";
import SubredditPopover from "./subredditpopover";

const CommentContents = ({
  comment, 
  close, 
  keyword, 
  onRedirect,
  bgClass,
  topBorder,
  hideHeader
}) => {

  const [context, setContext] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [viewed, setViewed] = useState(comment.viewed || false);
  const commentId = comment && comment.id;

  // effects
  useEffect(() => {
    if (document.getElementById('commentSidebar')){
      document.getElementById('commentSidebar').scrollTop = 0;
    }
    // set the context to null
    setContext(null);

    // record view if we haven't already
    if (comment && !comment.viewed){
      searchesService.saveView({conversation_type: "RCO", platform_id: comment.id, platform_name: comment.body});
      setViewed(true)
    }
  }, [
    commentId
  ]); // Run when comment switches

  // actions
  const fetchContext = () => {
    // gets main submission and any parent comments
    const url = `/api/v1/reddit/comments/${comment.id}/context/`;
    setFetching(true)
    setError(null)
    getRequest(url,  // TODO: just set the url to update query params?
      (response) => {
        setContext(response.data);
        setFetching(false);
      }, (response, error) => {
        setFetching(false);
        setError(error || 'Error fetching comment contents')
      });
  }

  // when nothing loaded yet
  if (!comment) return ''

  const link = `https://www.reddit.com${comment.link}`;
  
  // display either single comment, or whole thread if we have it
  const commentsToShow = context ? [...context.parents, comment] : [comment];

  // find matching keyword (if exists, also handle list)
  // TODO: match more than the first one?
  var matchingKeyword = null;
  if (comment.body && keyword){
    keyword.split(',').forEach(k => {
      if (!matchingKeyword && textContainsKeyword(comment.body, k)){
        matchingKeyword = k;
      }
    })
  }
	
  return (
    <div className={`${bgClass}`}>
      <div className={`flex space-x-3 items-center pb-2 ${topBorder ? 'pt-4 border-t border-gray-700' : ''}`}>
        
        <div className="flex items-center space-x-4 w-full">
          <div className="h-7 flex items-center mr-auto text-sm">
            <button onClick={close} className="rounded-md text-gray-400 hover:text-gray-300 focus:outline-none flex items-center">
              <span className="sr-only">Close panel</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
              Close
            </button>
          </div>
          
          <a href={link} target={"_blank"} rel="noreferrer"
            className="ml-auto"
            onClick={() => {
              trackEvent("ExternalRedirect", {
                "platform": "Reddit",
                "type": "Comment",
                "keyword": keyword,
                "platform_id": comment.id,
              });
              searchesService.saveRedirect("RCO", comment.id, comment.body);
              if (onRedirect){
                onRedirect(comment.id)
              }
            }}
          >
            <button style={{backgroundColor: '#FF4500'}} type="button" className="inline-flex px-4 py-2 shadow-sm text-sm text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
              <svg className="-ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#FFF" viewBox="0 0 24 24">
                <path fillRule="evenodd" d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"/>
              </svg>
              <span>Reply</span>
            </button>
          </a>
        </div>
      </div>

      {!hideHeader ? (
        <div className="border-b border-gray-600 pb-4">
          <p className="mt-2 text-sm flex space-x-1 flex-wrap">
            <span className="font-medium inline">
              <SubmissionTypeIcon type={'comment'} />
            </span>
            <span className="opacity-75">by</span>
            <RedditorPopover name={comment.author} openOnClick={true} openOnHover={true}>
              <span className="font-medium">{comment.author}</span>
            </RedditorPopover>
            <span className="opacity-75">in</span>
            <SubredditPopover name={comment.subreddit_name} openOnClick={true} openOnHover={true}>
              <span className="font-medium">{comment.subreddit_name}</span>
            </SubredditPopover>
          </p>

          <p className="mt-2 text-sm flex space-x-1 flex-wrap">
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="text-sm font-medium">{timeStampToFullTime(comment.timestamp_utc)}</span>
          </p>
        </div>
      ) : ''}

      <div className="flow-root py-4">
        <ul className="-mb-8">

          {!context ? (
            <li>
              <div className="relative pb-8">
                <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                <div className="relative flex items-start space-x-3">
                  <div>
                    <div className="relative px-1">
                      <div className="h-8 w-8 bg-gray-800 rounded-full ring-8 ring-gray-700 flex items-center justify-center cursor-pointer"
                        onClick={fetchContext}
                        disabled={fetching}
                      >
                        <svg className={`h-5 w-5 ${fetching ? 'animate-spin' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="min-w-0 flex-1">
                    <button
                      className={`py-2 px-3 text-xs bg-cyan-600 hover:bg-cyan-700 text-white rounded-md cursor-pointer`}
                      onClick={fetchContext}
                      disabled={fetching}
                    >
                      {fetching ? 'Fetching context...' : 'Fetch full conversation'}
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ) : context.submission ? (
            <li>
              <div className="relative pb-8">
                <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                <div className="relative flex items-start space-x-3">
                  <div>
                    <div className="relative px-1">
                      <div className="h-8 w-8 bg-gray-800 rounded-full ring-8 ring-gray-700 flex items-center justify-center">
                        <SubmissionTypeIcon type={context.submission.type} />
                      </div>
                    </div>
                  </div>
                  <div className="min-w-0 flex-1">
                    <h2 className={`font-semibold opacity-75 ${context.submission.is_removed ? 'line-through opacity-25' : ''}`}>{context.submission.title}</h2>
                    <div>
                      <div className="text-xs mb-1">
                        <RedditorPopover name={context.submission.author} openOnClick={true} openOnHover={true}>
                          <span className="font-medium opacity-75 hover:opacity-100">{context.submission.author}</span>
                        </RedditorPopover>
                      </div>
                      <p className="mt-0.5 text-xs flex space-x-1">
                        <span className="flex">
                          <svg className="self-center flex-shrink-0 h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                          </svg> {context.submission.score}
                        </span>
                        <span aria-hidden="true">&middot;</span>
                        <span className="flex">
                          <svg className="self-center flex-shrink-0 h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                          </svg> {context.submission.num_comments}
                        </span>
                        <span aria-hidden="true">&middot;</span>
                        <span>{timeStampToEasyTime(context.submission.timestamp_utc)}</span>
                      </p>
                    </div>
                    <div className="mt-2 text-sm">
                      <SubmissionBody submission={context.submission} keyword={keyword} />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ) : ''}

          {commentsToShow.map(c => (
            <li key={c.id}>
              <div className="relative pb-8">
                {c.id !== comment.id ? (
                  <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                ) : ''}
                <div className="relative flex items-start space-x-3">
                  <div className="relative px-1">
                    <div className="h-8 w-8 bg-gray-800 rounded-full ring-8 ring-gray-700 flex items-center justify-center">
                      <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                      </svg>
                    </div>
                  </div>
                  <div className="min-w-0 flex-1">
                    <div>
                      <div className="text-xs mb-1">
                        <RedditorPopover name={c.author} openOnClick={true} openOnHover={true}>
                          <span className="font-medium opacity-75 hover:opacity-100">{c.author}</span>
                        </RedditorPopover>
                      </div>
                      <p className="mt-0.5 text-xs flex space-x-1 opacity-50">
                        <span className="flex">
                          <svg className="self-center flex-shrink-0 h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                          </svg> {c.score}
                        </span>
                        <span aria-hidden="true">&middot;</span>
                        <span>{timeStampToEasyTime(c.timestamp_utc)}</span>
                      </p>
                    </div>
                    <div className="mt-2 text-sm">
                      <div className="prose font-small break-words embedTarget containsKeywords"
                        dangerouslySetInnerHTML={{
                          __html: styleKeyword(c.body_html, matchingKeyword)
                            .replace(`href=`, `target="_blank" rel="noreferrer" href=`)
                        }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {error ? (
        <ErrorBar error={error} setError={setError} />
      ) : ''}

    </div>
  );
}

export default CommentContents;

