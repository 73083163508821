import React, { useState } from "react"
import {
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

import { profileService } from '../../services/profile';
// import { authService } from '../../services/auth';
import { userOperations } from '../../redux/user';
import { objectiveOptions, roleOptions, redditKnowledgeOptions, learningStyleOptions } from './constants';

import FormFooter from "./footer";


const Objective = ({history, currentUser, fetchCurrentUser, ...subrouteProps}) => {
  const [ objective, setObjective ] = useState(currentUser.profile.objective);
  const [ objectiveOther, setObjectiveOther ] = useState(currentUser.profile.objective === "OT" ? currentUser.profile.objective_other : '');
  const [ role, setRole ] = useState(currentUser.profile.role);
  const [ roleOther, setRoleOther ] = useState(currentUser.profile.role === "OT" ? currentUser.profile.role_other : '');
  const [ redditKnowledge, setRedditKnowledge ] = useState(currentUser.profile.reddit_knowledge);
  const [ learningStyle, setLearningStyle ] = useState(currentUser.profile.learning_style);
  const [ biggestImpact, setBiggestImpact ] = useState(currentUser.profile.biggest_impact);
  const [ loading, setLoading ] = useState(false);

  const { nextPage, isLastPage } = subrouteProps;

  const onSubmit = () => {
    // in case a user doesn't have an initial trial, go make one
    setLoading(true);
    profileService.updateProfile(currentUser.profile.id, {
      'objective': objective,
      'objective_other': objectiveOther,
      'role': role,
      'role_other': roleOther,
      'reddit_knowledge': redditKnowledge,
      'learning_style': learningStyle,
      // 'biggest_impact': biggestImpact,
    }, (response) => {

      // we updated preferences, go fetch the updated state for the rest of the app
      fetchCurrentUser(() => {
        setLoading(false);

        // take user to next screen
        history.push(isLastPage ? '/audiences/' : `/welcome/${nextPage}/?objective=${objective}`);
      }, () => {
        console.error("Failed to fetch user info")
      })
    }, (error) => {
      console.error(error)
      setLoading(false)
      alert("Error submitting details")
    });
  }


  const isComplete = objective && (objective !== 'OT' || objectiveOther) && role && (role !== 'OT' || roleOther) && learningStyle && redditKnowledge;
  
  return (
    <div className="max-w-5xl w-full mx-auto min-h-full flex flex-col">
      <Helmet><title>Welcome | Objective</title></Helmet>

      <div className="inline-block bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle py-6 px-4 sm:p-6">
        <div className="text-center">
          <div className="relative mb-4">
            <hr className="opacity-25" />
            <div className="absolute -top-3 w-full text-center">
              <span className="p-1 text-sm rounded-sm bg-gray-800 text-gray-500 uppercase font-semibold">
                Your goals
              </span>
            </div>
          </div>

          <p className="mt-2 text-xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            Let's get acquainted
          </p>

          <p className="mt-4 max-w-xl text-gray-400 mx-auto">
            Tell us a bit about yourself to configure GummySearch for your goals.
          </p>

          <div className="text-left mt-8">
            <div className="text-gray-300 font-semibold">What is your main objective?</div>
            <div className="flex flex-wrap">
              {objectiveOptions.map(o => (
                <div key={o.key} className={`mr-2 mt-2 rounded-sm text-sm px-1 sm:px-2 py-1 sm:py-1 cursor-pointer text-white ${o.key === objective ? 'bg-cyan-500' : 'bg-gray-700 hover:bg-gray-600'}`}
                  onClick={() => setObjective(o.key)}
                >
                  {o.label}
                </div>
              ))}
            </div>
          </div>
          {objective === "OT" ? (
            <div className="text-left mt-4">
              <div className="text-gray-300 font-semibold mb-2">Please describe "Other"</div>
              <div>
                <input type="text" onChange={(e) => setObjectiveOther(e.target.value)} value={objectiveOther}
                  className="block bg-gray-800 w-full border-gray-600 text-sm p-2 placeholder-gray-500 rounded-sm focus:border-gray-400 focus:ring-0"
                  placeholder="Your main objective" spellCheck="off" autoComplete="off" autoFocus={true}
                />
              </div>
            </div>
          ) : ''}

          <div className="text-left mt-8">
            <div className="text-gray-300 font-semibold">What is your role?</div>
            <div className="flex flex-wrap">
              {roleOptions.map(o => (
                <div key={o.key} className={`mr-2 mt-2 rounded-sm text-sm px-1 sm:px-2 py-1 sm:py-1 cursor-pointer text-white ${o.key === role ? 'bg-cyan-500' : 'bg-gray-700 hover:bg-gray-600'}`}
                  onClick={() => setRole(o.key)}
                >
                  {o.label}
                </div>
              ))}
            </div>
          </div>
          {role === "OT" ? (
            <div className="text-left mt-4">
              <div className="text-gray-300 font-semibold mb-2">Please describe "Other"</div>
              <div>
                <input type="text" onChange={(e) => setRoleOther(e.target.value)} value={roleOther}
                  className="block bg-gray-800 w-full border-gray-600 text-sm p-2 placeholder-gray-500 rounded-sm focus:border-gray-400 focus:ring-0"
                  placeholder="Your role" spellCheck="off" autoComplete="off" autoFocus={true}
                />
              </div>
            </div>
          ) : ''}

          <div className="text-left mt-8">
            <div className="text-gray-300 font-semibold">How comfortable are you with Reddit?</div>
            <div className="flex flex-wrap">
              {redditKnowledgeOptions.map(o => (
                <div key={o.key} className={`mr-2 mt-2 rounded-sm text-sm px-1 sm:px-2 py-1 sm:py-1 cursor-pointer text-white ${o.key === redditKnowledge ? 'bg-cyan-500' : 'bg-gray-700 hover:bg-gray-600'}`}
                  onClick={() => setRedditKnowledge(o.key)}
                >
                  {o.label}
                </div>
              ))}
            </div>
          </div>

          <div className="text-left mt-8">
            <div className="text-gray-300 font-semibold">What is your preferred learning style?</div>
            <div className="flex flex-wrap">
              {learningStyleOptions.map(o => (
                <div key={o.key} className={`mr-2 mt-2 rounded-sm text-sm px-1 sm:px-2 py-1 sm:py-1 cursor-pointer text-white ${o.key === learningStyle ? 'bg-cyan-500' : 'bg-gray-700 hover:bg-gray-600'}`}
                  onClick={() => setLearningStyle(o.key)}
                >
                  {o.label}
                </div>
              ))}
            </div>
          </div>

          {/*<div className="text-left mt-8">
            <div className="text-gray-300 font-semibold mb-2">How did you hear about GummySearch, and why are you excited to try it out?</div>
            <div>
              <input type="text" onChange={(e) => setBiggestImpact(e.target.value)} value={biggestImpact}
                className="block bg-gray-800 w-full border-gray-600 text-sm p-2 placeholder-gray-500 rounded-sm focus:border-gray-400 focus:ring-0"
                placeholder="What's your story?" spellCheck="off" autoComplete="off"
              />
            </div>
          </div>*/}
          
        </div>
      </div>

      <FormFooter submit={onSubmit} disabled={loading || !isComplete} {...subrouteProps} />
    </div>
  );
}

const mapStateToProps = state => {
  // const {
  //   used, total, fetching,
  // } = state.trial;

  return {
    // 'trialTotal': total,
    // 'trialUsed': used,
    // 'trialFetching': fetching,
  }
};

const mapDispatchToProps = (dispatch) => {
  const fetchCurrentUser = (callback) => {
    dispatch(userOperations.fetchCurrentUser(callback))
  };
  return {
    fetchCurrentUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Objective);
