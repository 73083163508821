
// list of colors from flatUIcolors.com
const flatColors = [
  '#9b59b6', // ametheyst
  '#1abc9c', // turquoise
  '#e74c3c', // alizarin
  '#f1c40f', // sunflower
  '#2ecc71', // emerald
  '#3498db', // peter river
  '#e67e22', // carrot,
  '#34495e', // wet asphalt
  '#95a5a6', // concrete
  '#ecf0f1', // clouds,
  '#16a085', // green sea
  '#27ae60', // nephritis
  '#2980b9', // belize hole
  '#8e44ad', // wisteria
  '#2c3e50', // midnight blue
  '#f39c12', // orange
  '#d35400', // pumpkin
  '#c0392b', // pomegranate
  '#bdc3c7', // silver
  '#7f8c8d', // asbestos
];

export {
  flatColors
}