import React, { useState, useEffect } from "react";
// import {
//   Link, Switch, Route
// } from "react-router-dom";
// import { Helmet } from "react-helmet";

import CommentTreeItem from "../reddit/commenttreeitem";

// import { redditService } from '../../services/reddit';
// import { savedSearchOperations } from "../../redux/saved"
// import { trackEvent } from '../../utils/tracking';
// import { toQueryString } from '../../utils/urls';


// comment tree that can be expanded with children of each one
const CommentTree = ({
  comments,
  keyword,
  showDivider,
  showRedirect,
  // expandReplies,
}) => {

  return (
    <div className={`relative ${showDivider ? 'divide-y divide-gray-700 -mt-1' : ''}`}>
      {comments.map(item => (
        <CommentTreeItem key={item.id}
          item={item} keyword={keyword} //expandReplies={expandReplies}
          showRedirect={showRedirect} trackRedirect={true}
        />
      ))}
    </div>
  );
}

export default CommentTree;

