import React, { useState } from "react"
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import {
  Link, Switch
} from "react-router-dom";
import { ModalRoute } from 'react-router-modal';
import Toggle from 'react-toggle'
import ReactTooltip from 'react-tooltip';
import {
  CheckIcon, InformationCircleIcon, ViewListIcon, ViewBoardsIcon, TrendingUpIcon, WifiIcon,
} from '@heroicons/react/outline';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend, ResponsiveContainer } from 'recharts';

import { collectionsService } from '../../services/collections';
import { searchesService } from '../../services/searches';
import { uiOperations } from "../../redux/ui"
import { hasStarter } from "../../utils/account"
import { readableNumber } from '../../utils/text';

import ErrorBoundary from "../common/errorboundary";
import SelectPopover from "../common/selectPopover";
import AudienceSubredditCard from "./audiencesubredditcard";
import AudienceAddSimilarTip from "./audienceAddSimilarTip";
import SubredditModal from "./subredditModal";


const AudienceSubreddits = ({
  collection,
  items,
  loadingItems,
  setItems,
  subredditDisplayType,
  subredditSort,
  setUiState,
  currentUser,
  showSimilarTip,
  fetchCollectionItems,
  history,
}) => {
  // state
  var sortOptions = ['Recent', 'Largest']; 
  if (currentUser && currentUser.features.subreddit_stats){
    sortOptions.push("Growth");
  }
  const sortOption = subredditSort;
  const [includeStats, setIncludeStats] = useState(false);
  // const displayType = subredditDisplayType;
  const displayType = 'card';
  // const [displayType, setDisplayType] = useState('card');
  const [keywordSearch, setKeywordSearch] = useState('');
  const [hoveredSubreddit, setHoveredSubreddit] = useState(null);
  

  // actions
  const deleteCollectionItem = (item) => {
    collectionsService.deleteCollectionItem(item.id, (results) => {
      setItems(items.filter(i => i.id !== item.id))
    }, (response, error) => {
      console.log(error)
    })
  };

  var sortedItems = items;
  if (sortOption === "Largest"){
    sortedItems = sortedItems.sort((a,b) => (a.item.count_users < b.item.count_users) ? 1 : ((b.item.count_users < a.item.count_users) ? -1 : 0));
  } else if (sortOption === "Growth"){
    // first sort by daily growth, then weekly, then monthly
    sortedItems = sortedItems.sort((a,b) => (a.item.calculated && b.item.calculated && a.item.calculated.growth_members_daily < b.item.calculated.growth_members_daily) ? 1 : ((a.item.calculated && b.item.calculated && b.item.calculated.growth_members_daily < a.item.calculated.growth_members_daily) ? -1 : 0))
    sortedItems = sortedItems.sort((a,b) => (a.item.calculated && b.item.calculated && a.item.calculated.growth_members_weekly < b.item.calculated.growth_members_weekly) ? 1 : ((a.item.calculated && b.item.calculated && b.item.calculated.growth_members_weekly < a.item.calculated.growth_members_weekly) ? -1 : 0))
    sortedItems = sortedItems.sort((a,b) => (a.item.calculated && b.item.calculated && a.item.calculated.growth_members_monthly < b.item.calculated.growth_members_monthly) ? 1 : ((a.item.calculated && b.item.calculated && b.item.calculated.growth_members_monthly < a.item.calculated.growth_members_monthly) ? -1 : 0))
  } else if (sortOption === "Recent"){
    // recently added
    sortedItems = sortedItems.sort((a,b) => (a.id < b.id) ? 1 : ((b.id < a.id) ? -1 : 0));
  }

  // get the stats to show
  const stats = {
    'total': items.map(s => s.item.count_users).reduce((a, b) => a + b, 0),
    'online': items.map(s => s.item.count_users_active).reduce((a, b) => a + b, 0),
  }

  // compile comparison data for this audience (to show in chart)
  var comparisons  = []
  if (collection.calculated){
    const categories = [
      {'title': 'Age', 'key': 'percentile_age'},
      {'title': 'Size', 'key': 'percentile_members'},
      {'title': 'Activity', 'key': 'percentile_velocity'},
      // {'title': 'Engagement', 'key': 'percentile_per_user_comments'},
      {'title': 'Growth', 'key': 'percentile_growth_members_monthly'},
      // {'title': 'Moderation', 'key': 'percentile_moderation'},
      {'title': 'Reach', 'key': 'percentile_reach'},
    ]
    categories.forEach(c => {
      if (collection.calculated[c.key] !== null && (!hoveredSubreddit || (hoveredSubreddit.calculated && hoveredSubreddit.calculated[c.key] !== null))){
        var comparison = {
          "title": c.title,
          "Reddit Avg": 50,
          // "Audience": collection.calculated[c.key],
          "fullMark": 100
        }
        comparison[collection.name] = collection.calculated[c.key]
        if (hoveredSubreddit && hoveredSubreddit.calculated && hoveredSubreddit.calculated[c.key] !== null){
          comparison[`r/${hoveredSubreddit.name}`] = hoveredSubreddit.calculated[c.key]
        }
        comparisons.push(comparison)
      }
    })
  }

  return (
    <ErrorBoundary>
      <Helmet><title>Audience | Subreddits</title></Helmet>

      <div className={`mt-4 sm:grid lg:grid-cols-4 gap-8`}>
        <div className="col-span-3">
          <div className="flex items-center mb-2">
            <h2 className="text-lg font-medium flex items-center flex-wrap">
              <span>Subreddits</span>

              <p className="ml-2 text-gray-500 truncate">
                {loadingItems ? (
                  <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  <span>{/*{items.length}*/}</span>
                )}
              </p>
            </h2>

            <div className="ml-auto flex">

              {items.length >= 2 ? (
                <div className="ml-4 flex items-center">
                  <div className="text-xs opacity-50 hidden sm:block">Sort by</div>
                  <div className="ml-2 flex items-center">
                    <SelectPopover
                      options={sortOptions}
                      currentOption={sortOption}
                      setCurrentOption={(s) => setUiState('subredditSort', s)}
                      labelField={undefined}
                    />
                  </div>
                </div>
              ) : ''}

              {/*{items.length >= 2 ? (
                <div className="ml-4 flex items-center">
                  <div className="text-xs opacity-50 hidden sm:block">Display</div>
                  <div className="ml-1 flex items-center space-x-1">
                    <ViewBoardsIcon
                      className={`cursor-pointer h-5 w-5 ${displayType === 'card' ? 'text-gray-200' : 'text-gray-500 hover:text-gray-300'}`} 
                      onClick={() => setUiState('subredditDisplayType', 'card')} />
                    <ViewListIcon
                      className={`cursor-pointer h-5 w-5 ${displayType === 'row' ? 'text-gray-200' : 'text-gray-500 hover:text-gray-300'}`} 
                      onClick={() => setUiState('subredditDisplayType', 'row')} />
                  </div>
                </div>
              ) : ''}*/}
            </div>
          </div>
            
          <div className={`grid grid-cols-1 ${displayType === 'card' ? 'md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 sm:gap-4' : 'gap-2'}`}>
            {sortedItems.map(collectionItem => (
              <AudienceSubredditCard key={collectionItem.id} collectionItem={collectionItem}
                subreddit={collectionItem.item} deleteCollectionItem={deleteCollectionItem}
                displayType={displayType}
                onMouseEnter={() => {
                  setHoveredSubreddit(collectionItem.item)
                }}
                onMouseLeave={() => {
                  setHoveredSubreddit(null)
                }}
              />
            ))}
          </div>

          {showSimilarTip && items && items.length ? (
            <AudienceAddSimilarTip collection={collection} refetch={fetchCollectionItems}/>
          ) : ''}
        </div>

        {!loadingItems ? (
          <div className="mt-4 sm:mt-0">
            <div className="">
              {/*<h2 className="text-lg font-medium flex items-center flex-wrap mb-2">
                <span>Keyword Search</span>

                <a href="https://gummysearch.com/insights/keywords-to-track-on-reddit/" target="_blank" className="ml-auto">
                  <svg className="w-4 h-4 opacity-50 hover:opacity-75 cursor-poiner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                  </svg>
                </a>
              </h2>
              <div className="relative">
                <form onSubmit={(e) => {
                  e.preventDefault()
                  history.push(`/audience/${collection.hash}/search/?keyword=${encodeURIComponent(keywordSearch)}`);
                  setKeywordSearch(''); // clear the form when we open the drawer
                }}>
                  <input id="keyword-search" type="text" placeholder={`Search in ${collection.name}`} autoComplete="off" spellCheck="off" //autoFocus="off"
                    className="text-xs sm:text-sm block bg-gray-800 w-full border-transparent p-2 pl-10 placeholder-gray-500 rounded-md focus:border-transparent focus:ring-0"
                    value={keywordSearch} onChange={(e) => {
                      setKeywordSearch(e.target.value);
                    }}
                  />
                </form>
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-3 opacity-75">
                  <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>*/}
            </div>

            <h2 className="text-lg font-medium flex items-center flex-wrap mb-2">
              <span>About this audience</span>
            </h2>
            <div className="space-y-1">
              {collection.curated ? (
                <div className="hidden sm:flex items-center text-gray-300">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 mr-2 opacity-25">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4.098 19.902a3.75 3.75 0 005.304 0l6.401-6.402M6.75 21A3.75 3.75 0 013 17.25V4.125C3 3.504 3.504 3 4.125 3h5.25c.621 0 1.125.504 1.125 1.125v4.072M6.75 21a3.75 3.75 0 003.75-3.75V8.197M6.75 21h13.125c.621 0 1.125-.504 1.125-1.125v-5.25c0-.621-.504-1.125-1.125-1.125h-4.072M10.5 8.197l2.88-2.88c.438-.439 1.15-.439 1.59 0l3.712 3.713c.44.44.44 1.152 0 1.59l-2.879 2.88M6.75 17.25h.008v.008H6.75v-.008z" />
                  </svg>
                  Curated Audience
                </div>
              ) : (
                <div className="hidden sm:flex items-center text-gray-300">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 mr-2 opacity-25">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
                  </svg>
                  Custom Audience
                </div>
              )}
              
              {/*<div className="flex items-center text-gray-300">
                <svg className="h-4 w-4 mr-2 opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                  <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                </svg>
                {items.length} Subreddit{items.length === 1 ? '' : 's'}
              </div>*/}
              <div className="flex items-center text-gray-300">
                <svg className="h-4 w-4 mr-2 opacity-25" xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
                {readableNumber(stats.total)} Members
              </div>
              {/*<div className="flex items-center text-gray-300">
                <svg className="h-4 w-4 mr-2 opacity-25" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z" />
                </svg>

                {readableNumber(stats.online)} Online
              </div>*/}

              <ReactTooltip id={`audience-stats-${collection.hash}`} place="right" effect="solid" backgroundColor="white" textColor="black" />                
              {collection.calculated && collection.calculated.growth_members_monthly !== null ? (
                <div className={`flex items-center text-base text-gray-300`}>
                  <TrendingUpIcon className="h-4 w-4 items-center mr-2 opacity-25" />
                  <div>↑{collection.calculated.growth_members_monthly.toFixed(1)}% / month</div>
                </div>
              ) : collection.calculated && collection.calculated.growth_members_weekly !== null ? (
                <div className={`flex items-center text-base text-gray-300`}>
                  <TrendingUpIcon className="h-4 w-4 items-center mr-2 opacity-25" />
                  <div>↑{collection.calculated.growth_members_weekly.toFixed(2)}% / week</div>
                </div>
              ) : collection.calculated && collection.calculated.growth_members_daily !== null ? (
                <div className="text-gray-300 text-base flex items-center">
                  <TrendingUpIcon className="h-4 w-4 mr-2 opacity-25" />
                  <div>↑{collection.calculated.growth_members_daily.toFixed(2)}% / day</div>
                  <InformationCircleIcon className="h-4 w-4 ml-1 text-gray-500" 
                    data-for={`audience-stats-${collection.hash}`} data-tip="No weekly growth data yet. Showing growth over the past day."
                  />
                </div>
              ) : ''}

            </div>

            {comparisons && comparisons.length ? (
              <div>
                {/*<div className="flex items-center">
                  <h2 className="mt-4 text-lg font-medium flex items-center flex-wrap mb-2">
                    <span>In Comparison</span>
                  </h2>
                  <ReactTooltip id={`audience-comparison`} place="right" effect="solid" backgroundColor="white" textColor="black" />
                  <span className="cursor-pointer ml-2" data-for='audience-comparison' data-tip="How this subreddit stacks up against average of others in your audience.">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 opacity-50" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                </div>*/}

                <div className="relative h-72 text-sm mt-1 overflow-visible">
                  <ResponsiveContainer width="100%" height="100%">
                    <RadarChart outerRadius={90} width={'100%'} height={'100%'} data={comparisons}>
                      <PolarGrid />
                      <PolarAngleAxis dataKey="title" stroke="rgb(156,163,175)" />
                      {/*<PolarRadiusAxis angle={30} domain={[0, 150]} />*/}
                      
                      <Radar name="Reddit Avg" dataKey="Reddit Avg" stroke="#FF5700" fill="#FF5700" fillOpacity={0.4} />
                      <Radar name={collection.name} dataKey={collection.name} stroke="#3498db" fill="#3498db" fillOpacity={0.7} />
                      {hoveredSubreddit ? (
                        <Radar name={`r/${hoveredSubreddit.name}`} dataKey={`r/${hoveredSubreddit.name}`} stroke="#2ecc71" fill="#2ecc71" fillOpacity={0.5} />
                      ) : ''}
                      <Legend />
                    </RadarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            ) : ''}
          </div>
        ) : ''}
        
      </div>

      <Switch>
        <ModalRoute component={SubredditModal}
          path={`/audience/:collectionHash/subs/:subredditName/`}
          parentPath={`/audience/${collection.hash}/subs/`}
          props={{
            'collection': collection,
            'items': items,
            'currentUser': currentUser,
          }}
        />
      </Switch>
      
    </ErrorBoundary>
  );
}

const mapStateToProps = state => {
  const {
    currentUser
  } = state.user;
  const {
    subredditDisplayType,
    subredditSort,
  } = state.ui;

  return {
    currentUser,
    subredditDisplayType,
    subredditSort,
  }
};

const mapDispatchToProps = (dispatch) => {
  const setUiState = (key, value) => {
    dispatch(uiOperations.setUiState(key, value))
  };
  return {
    setUiState,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceSubreddits);

