import React from "react";
// import {
//   Link
// } from "react-router-dom";

import ErrorBoundary from '../common/errorboundary.jsx';

import { styleKeyword, findKeywordsAndTruncate, textContainsKeyword, cleanHtml } from '../../utils/text';
import { timeStampToEasyTime } from '../../utils/time';
import { trackEvent } from '../../utils/tracking';

import { searchesService } from '../../services/searches';


const PreviewRedditSubmission = ({ item, keyword, showMetadata, showRedirect, trackRedirect }) => (

  <li className={`relative py-2 rounded-sm`}>
    <ErrorBoundary>
      <div className="items-center justify-between">
        <div className="min-w-0">
          <div className="flex">
            <div className="block max-w-full">
              <h2 className="text-sm font-normal">
                <div className="flex">
                  <span className="absolute inset-0" aria-hidden="true"></span>
                  <span className={`containsKeywords doubleLineClip font-medium`}
                    dangerouslySetInnerHTML={{__html: cleanHtml(styleKeyword(item.title, keyword))}}
                  ></span>
                </div>
              </h2>
              {item.body && textContainsKeyword(item.body, keyword) ? (
                <div className="text-xs mt-1 containsKeywords doubleLineClip opacity-75"
                  dangerouslySetInnerHTML={{__html: cleanHtml(styleKeyword(
                    findKeywordsAndTruncate(item.body, keyword),
                    keyword
                  ))}}>
                </div>
              ) : item.body ? (
                <div className="text-xs mt-1 containsKeywords doubleLineClip opacity-75">
                  {item.body}
                </div>
              ) : ''}
            </div>
            {/*
            <div className="flex ml-auto flex-shrink-0 items-center space-x-4">
              <a href={`https://www.reddit.com${item.link}`} target="_blank" rel="noreferrer" className="text-blue-400 hover:text-blue-500">
                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </a>
            </div>*/}
          </div>

          <div className="flex items-center flex-wrap">
            <div className="relative flex items-center space-x-2 text-gray-500">
              {/*<span className="text-xs font-medium flex">
                <svg className="self-center flex-shrink-0 h-3 w-4" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                  <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                </svg>
              </span>*/}
              {/*<span aria-hidden="true">&middot;</span>
              <span className="text-xs hover:text-white font-medium truncate">
                {item.subreddit_name}
              </span>*/}

              {showMetadata ? (
                <React.Fragment>
                  {/*<span aria-hidden="true">&middot;</span>*/}
                  <span className="text-xs">
                    {timeStampToEasyTime(item.timestamp_utc)}
                  </span>
                  <span aria-hidden="true">&middot;</span>
                  <span className="text-xs font-medium flex">
                    <svg className="self-center flex-shrink-0 h-3 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                    </svg>{item.score}
                  </span>
                  <span aria-hidden="true">&middot;</span>
                  <span className="text-xs font-medium flex">
                    <svg className="self-center flex-shrink-0 h-3 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>{item.num_comments}
                  </span>
                </React.Fragment>
              ) : ''}

              {showRedirect ? (
                <React.Fragment>
                  <span aria-hidden="true">&middot;</span>
                  <span className="text-sm font-medium flex">
                    <a href={`https://www.reddit.com${item.link}`} target="_blank" rel="noreferrer" className="cursor-pointer opacity-75 hover:opacity-100"
                      onClick={() => {
                        if (trackRedirect){
                          trackEvent("ExternalRedirect", {
                            "platform": "Reddit",
                            "type": "SubmissionSource",
                            "platform_id": item.id,
                            "title": item.title,
                            // "keyword": keyword,
                          });
                          searchesService.saveRedirect("RSU", item.id, item.title);
                        }
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="self-center flex-shrink-0 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                      </svg>
                    </a>
                  </span>
                </React.Fragment>
              ) : ''}
            </div>
            
          </div>
        </div>
        
      </div>
    </ErrorBoundary>
  </li>
);

export default PreviewRedditSubmission;

