import React, { useState } from "react";
import {
  Switch,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import PropsRoute from "../common/propsroute";
import Modal from "../common/modal";
import NavBar from "../common/navbar";

import AudienceAdd from "./audienceAdd";
import AudienceDiscovery from "./discovery"

import { collectionsService } from '../../services/collections';

// import SimilarCommunities from "./similarCommunities";

// modal to add a new 
const AudienceAddModal = ({
  collection,
  collectionItems,
  refetch,
  addCommunity,
  hideCommunity,
  currentUser,
  closeModal
}) => {
  // state
  const baseUrl = `/audience/${collection.hash}/add/`;
  const tabs = [
    { name: 'Add Subreddits', link: `${baseUrl}`, exact: true },
    { name: 'Discover Communities', link: `${baseUrl}discover/`},
  ]

  return (
    <Modal close={closeModal} widthClass={'max-w-5xl'}>
      <Helmet><title>Audience | Add</title></Helmet>
      
      <div className="flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 opacity-50 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>

        <h3 className="text-lg leading-6 font-medium">
          Add to {collection.name}
        </h3>

        <button className="focus:outline-none ml-auto" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div className="py-4">
        <NavBar tabs={tabs} />
      </div>

      <div>
        <Switch>
          <PropsRoute path={`${baseUrl}discover/`} component={AudienceDiscovery}
            currentCollection={collection}  currentCollectionItems={collectionItems}
            refetchCurrentCollection={refetch}
          />
          <PropsRoute path={`${baseUrl}`} exact={true} component={AudienceAdd}
            collection={collection} refetch={refetch} closeModal={closeModal}/>
        </Switch>
      </div>

    </Modal>
  );
};

export default AudienceAddModal;

